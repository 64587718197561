<!-- **  View fields in a specific pipeline

  <pipeline-view :item="item" />

-->

<template>
  <v-row>
    <v-col cols="12" md="7">
      <v-row>
        <c-field cols="12" md="6" :label="$t('pipelines.field.name')" wrap>{{ item.name }}</c-field>
        <c-field cols="12" md="6" :label="$t('campaigns.field.label')" wrap>{{ item.campaign.name }}</c-field>
      </v-row>

      <v-row>
        <c-field-date cols="12" md="6" :label="$t('pipelines.field.start_at')" :date="item.startAt" :error="dateError" not-set />
        <c-field-date cols="12" md="6" :label="$t('pipelines.field.end_at')" :date="item.endAt" :error="dateError" not-set />

      </v-row>
    </v-col>

    <v-col cols="12" md="5">
      <v-row no-gutters>
        <c-check-field cols="6" :value="item.archived" wrap hide-false small :label="$t('def.archived')" />
        <c-check-field cols="6" :value="item.protected" wrap hide-false small :label="`${$t('def.delete')} ${$t('def.protection')}`" />

        <c-check-field
          cols="6"
          :value="item.template"
          wrap
          hide-false
          small
          :label="$t('pipelines.field.template')"
        />
        <c-check-field
          cols="6"
          :value="item.public"
          wrap
          hide-false
          small
          :disabled="!item.template"
          :label="$t('pipelines.field.public')"
          on-color="accent"
        />

        <c-field cols="6" :label="$t('pipelines.field.stages')" wrap class="mt-4 pr-6">
          <stage-stepper :task-matrix="item.taskMatrix" small style="max-width: 180px;" />
        </c-field>

        <c-field v-if="item.template && item.templateOwnerId" cols="6" :label="$t('pipelines.field.owner')" wrap class="mt-4">
          <div class="d-flex align-center">
            <avatar :size="22" :user="item.templateOwner" show-business-card />
            <span class="ml-2">{{ item.templateOwner.displayName }}</span>
          </div>
        </c-field>
      </v-row>
    </v-col>

    <c-field cols="12" md="7" :label="$t('pipelines.field.description')" wrap>
      {{ item.description }}
    </c-field>

    <c-field cols="12" md="5" :label="$t('def.tags')" class="mt-2">
      <list-tags v-model="item.tags" chips />
    </c-field>

    <c-field cols="12" md="7" :label="$t('pipelines.field.industries.label')" class="mt-2">
      <c-select
        :value="item.workflowIndustries"
        :items="$db.doc('configuration', null, { $clone: true }).workflowCodes.industries"
        list-class="caption three-lines"
        readonly
        sort
      />
    </c-field>

    <c-field cols="12" md="5" :label="$t('pipelines.field.tactics.label')" class="mt-2">
      <c-select
        :value="item.workflowTactics"
        :items="$db.doc('configuration', null, { $clone: true }).workflowCodes.tactics"
        list-class="caption three-lines"
        readonly
        sort
      />
    </c-field>

    <c-field v-if="item.assetIds && item.assetIds.length > 0" cols="12" md="7" :label="$t('assets.labels')">
      <list-assets v-model="item.assetIds" :width="30" />
    </c-field>

    <v-col cols="12" md="5">
      <!-- populated from tasks -->
      <select-stories
        v-if="item.storyIds && item.storyIds.length > 0"
        :value="item.storyIds"
        :label="`${$t('def.all')} ${$t('tasks.label')} ${$t('tasks.field.stories')}`"
        hide-edit
        class="mt-2"
      />
    </v-col>
  </v-row>
</template>

<script>

import Avatar from '@/components/users/profile/Avatar'

import StageStepper from '@/components/tasks/form/progress-status/StageStepper'
import ListTags from '@/components/_lib/form/tags/ListTags'

import ListAssets from '@/components/assets/form/ListAssets'
import SelectStories from '@/components/stories/form/SelectStories'

export default {
  components: {
    Avatar,
    StageStepper,
    ListTags,
    ListAssets,
    SelectStories
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    dateError () {
      return this.item.startAt > this.item.endAt
    }
    // templateOwner () {
    //   return this.$db.get('users', this.item.templateOwnerId, { $noCommit: true })
    // }
  }
}
</script>
