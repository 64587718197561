<!-- Show a dialog popup with fields to edit or create in a service

- Get item from database
- Item can be existing document or 'undefined' to create a new record
- Component only saves *changed* fields

* Events:
    @input // toggle dialog
    @deleteItemId  // returns id of the item that was deleted
    @closeDialog
    @itemCreated="newItem" // returns the item created OR an { error } object
    @itemChanged

* Slots:
  item-edit-form // the form itself, passing item
  item-edit-actions // optional additional button in action bar at center of bottom

  Example
    <template v-slot:item-edit-form="{ item }">
      <information-edit :item="item" />
    </template>
    <template v-slot:item-edit-actions="{ item }">
      <v-btn :small="$app_isMobile" :to="`/admin/information/${item._id}`" nuxt>edit article</v-btn>
    </template>

* Props:
  required
    itemId = 'T_100'
    service = 'tasks'
  optional
    width / max-width of dialog
    title="Edit account"
    title-bar-color="info"
    title-text-color="secondary"
    info-slug="home-intro"
    hide-activity-drawer="true"

Example:
    <edit-item-dialog
      v-model="dialog"
      :item-id="itemId"
      service="accounts"
      :width="600"
      :hide-activity-drawer="!currentItem._id"
      @deleteItemId="deleteCurrentItem"
      @itemCreated="newItem"
    >
      <template v-slot:item-edit-form="{ item }">
        <v-text-field v-model="item.name" :label="$t('accounts.field.name')" />
        <v-text-field v.model.number="niceNumber" :rules="[$validRule.required, $validRule.number]" />
      </template>
    </edit-item-dialog>

Form validation; form can use rules such as :rules="$validRule.number"
  $validRule is injected by @/plugins/validation-rules/index.js, we now have:
    required
    // special types
    email
    slug // @TODO !
    // input character type
    letters
    digits
    // numerical values
    number
    min: (n): higher or equal
    max: (n): lower or equal
    // size of the input
    exactLength: (n)
    minLength: (n)
    maxLength: (n)

-->

<template>
  <div>
    <v-dialog
      v-model="editDialog"
      :fullscreen="$app_isMobile"
      :width="width"
      scrollable
      :max-width="maxWidth"
      @keydown="checkChanges()"
      @update="checkChanges()"
      @click:outside="cancelEdit()"
    >
      <v-card elevation="4" style="width: 100%;" :tile="$app_isMobile">
        <!-- ** The right side activity drawer -->
        <item-activities-drawer
          v-if="activityDrawer&& !hideActivityDrawer"
          v-model="activityDrawer"
          :item-id="itemId"
          :service="service"
          :title-text-color="titleTextColor"
          :title-bar-color="titleBarColor || titleTextColor"
        />

        <!-- ** The edit dialog itself -->
        <v-system-bar :color="titleBarColor || service" :class="`${titleTextColor}--text elevation-1`" :height="40">
          <v-btn v-if="valid" icon class="ml-2 mr-4" @click="closeDialogs(true)">
            <v-icon :color="titleTextColor">mdi-arrow-left</v-icon>
          </v-btn>

          <v-btn v-else icon class="ml-2 mr-4" @click="cancelEdit()">
            <v-icon :color="titleTextColor">mdi-close</v-icon>
          </v-btn>

          <div class="text-capitalize">
            {{ title || `${item._id ? $t('def.edit'): $t('def.new')} ${$t(`${service}.label`)}` }}
          </div>

          <v-spacer />
          <v-btn v-if="!hideDelete && showDeleteIcon" icon @click.stop="deleteDialog = true">
            <v-icon :color="titleTextColor">mdi-trash-can-outline</v-icon>
          </v-btn>

          <c-info-btn
            v-if="infoSlug"
            :small="$app_isMobile"
            :slug="infoSlug"
            :icon-color="titleTextColor"
          />

          <!-- refresh button to reload data from server -->
          <c-icon-btn
            v-if="!hideRefresh"
            icon="refresh"
            class="ml-0 ml-md-1"
            :color="titleTextColor"
            small
            i18n-tooltip-text="def.refresh"
            @click="refreshData()"
          />

          <v-btn icon class="mx-2" @click.stop="cancelEdit()">
            <v-icon :color="titleTextColor">mdi-close</v-icon>
          </v-btn>

          <v-btn v-if="!hideActivityDrawer" icon :class="{'rotate-ninety-right': activityDrawer}" @click.stop="showEditRightDrawer()">
            <v-icon :color="titleTextColor">mdi-dots-vertical</v-icon>
          </v-btn>
        </v-system-bar>

        <v-card-text style="height: 80%;" @click="checkChanges()">
          <!-- *** The actual edit form, from the  <template v-slot:item-edit-form="{ item }">...
          The v-model="valid" allows use of field checks, such as :rules="[ $validRule.max(20).val ]", see plugins/validation-rules/index.js
        -->
          <v-form
            ref="form"
            v-model="valid"
            class="pt-6"
            @submit.prevent=""
            @keydown.prevent.enter
          >
            <slot name="item-edit-form" :item="editItem || {}" :valid="valid" />
          </v-form>

        </v-card-text>
        <v-divider />

        <!-- *** Bottom actions -->
        <v-card-actions class="flex-wrap" @mouseover="checkChanges()">
          <span class="info--text caption mx-2 d-none d-md-flex ">
            <!-- {{ JSON.stringify(item) }} -->
            {{ item.accountId ? item.accountId + ' /':'' }}
            {{ item.campaignId ? item.campaignId + ' /':'' }}
            {{ item.pipelineId ? item.pipelineId + ' /':'' }}
            <span>&nbsp;{{ itemId }}</span>
          </span>

          <v-spacer />
          <slot name="item-edit-actions" :item="editItem || {}" :valid="valid" />
          <v-spacer />

          <v-btn v-if="itemId && !hideDelete" color="primary" :small="$app_isMobile" text @click="deleteDialog = true">{{ $t('def.delete') }}</v-btn>
          <v-btn :small="$app_isMobile" :color="changed ? 'warning':'info'" text @click="cancelEdit()">{{ $t('def.cancel') }}</v-btn>
          <v-btn :small="$app_isMobile" color="success" :disabled="!changed || !valid" text @click="saveItem()">{{ $t('def.save') }}</v-btn>
        </v-card-actions>

      </v-card>

    </v-dialog>

    <c-alert-btn
      v-model="cancelDialog"
      hide-button
      :ok-label="$t('def.discard')"
      show-action
      :action-disabled="!valid"
      action-label="save"
      action-color="success"
      @action="saveItem()"
      @cancel="cancelDialog = false; editDialog = true"
      @ok="closeDialogs()"
    >
      <p>{{ $t('table.alert.cancel' ) }}</p>
      <p>{{ $t('table.alert.no_revert' ) }}</p>
    </c-alert-btn>

    <c-alert-btn
      v-model="deleteDialog"
      hide-button
      :ok-label="$t('def.delete')"
      @cancel="deleteDialog=false; editDialog = true"
      @ok="deleteItem(itemId)"
    >
      <p>{{ $t('table.alert.delete',{ d: item.name || service } ) }}</p>
      <p>{{ $t('table.alert.no_revert' ) }}</p>
      <p class="caption info--text">"{{ service }}" item: {{ itemId }}</p>
    </c-alert-btn>
  </div>
</template>

<script>

// eslint-disable-next-line no-unused-vars
import Log from '@/lib/log'
import ItemActivitiesDrawer from '@/components/activities/form/ItemActivitiesDrawer'
export default {
  components: {
    ItemActivitiesDrawer
  },
  props: {
    // *** REQUIRED PROPS
    value: {
      type: Boolean,
      default: false
    },
    // actual item to edit
    service: {
      type: String,
      default: ''
    },
    itemId: {
      type: String,
      default: ''
    },

    // *** OPTIONAL PROPS
    // do not focus the first field
    blurFirstField: {
      type: Boolean,
      default: false
    },
    // show delete button and are you sure popup
    hideDelete: {
      type: Boolean,
      default: false
    },

    // show delete icon in topbar, aligned to the right
    showDeleteIcon: {
      type: Boolean,
      default: false
    },
    // the right drawer with notifications and time tracker
    hideActivityDrawer: {
      type: Boolean,
      default: false
    },

    // *** The Edit Dialog appearance
    width: {
      type: Number,
      default: 800
    },
    maxWidth: {
      type: Number,
      default: undefined
    },
    titleBarColor: {
      type: String,
      default: '' // background top bar
    },
    // show custom title, default = `Edit ${service}.label`
    title: {
      type: String,
      default: ''
    },
    titleTextColor: {
      type: String,
      default: 'white'
    },
    // show button to refresh data with new version, if any
    hideRefresh: {
      type: Boolean,
      default: false
    },
    infoSlug: {
      type: String,
      default: '' // possible help button in edit dialog
    }
  },
  data () {
    return {
      item: {},
      activityDrawer: false,
      changed: false,
      cancelDialog: false, // show alert: "are you sure to not save"
      deleteDialog: false, // "are you sure to delete?" alert
      editItem: {}, // the item being edited
      valid: false // Form validation;
    }
  },
  computed: {
    editDialog: {
      get () { return this.value },
      set (value) { this.$emit('input', value) }
    }
  },
  watch: {
    // when the app is visible on screen, some other client might have changed the data in between
    '$signal.visibility' (value) {
      if (value) {
        this.refreshData()
      }
    },
    async editDialog (value) {
      this.checkChanges()
      // remember original data each time the popup is switched on to keep track of changed
      if (value) {
        await this.loadItemData()

        this.$nextTick(() => {
          const form = this.$refs.form
          // focus the first field in the form
          if (!this.blurFirstField && form.inputs && form.inputs[0] && typeof form.inputs[0].focus === 'function') {
            form.inputs[0].focus()
          }
          // check if fields all contain valid values
          form.validate()
        })
      }
    }
  },
  mounted () {
    // this.loadItemData()
  },
  methods: {

    // return only the items that are not the same in item
    getChangedData () {
      const changedData = {}
      Object.keys(this.editItem).forEach((key) => {
        // see if this key can be patched AND is changed
        if (key in this.$db.initData(this.service) &&
          JSON.stringify({ content: this.editItem[key] }) !== JSON.stringify({ content: this.item[key] })) {
          changedData[key] = this.editItem[key]
        }
      })
      return changedData
    },

    // return an object with only keys that can be patched
    stripNonPatchableData (data) {
      const patchData = {}
      const allowedKeys = Object.keys(this.$db.patchableData(this.service))
      Object.keys(data).forEach((key) => {
        if (allowedKeys.includes(key)) { patchData[key] = data[key] }
      })
      return patchData
    },
    // check if a newer version of the data exists and if so, overwrite it
    async refreshData () {
      if (this.itemId) {
        const item = await this.$db.get(this.service, this.itemId, { $clone: true, $noCommit: true })
        if (item.updatedAt > this.editItem.updatedAt) {
          this.item = item
          this.editItem = JSON.parse(JSON.stringify(this.item))
        }
      }
    },
    async loadItemData () {
      if (!this.itemId) {
        this.editItem = this.$db.initData(this.service)
        // Log.i(`[edit-item-dialog] new "${this.service}".`)
      } else {
        // Log.w('Load Data', this.itemId)
        // $noCommit as, for example, when admin edits user table, we do not want to change user
        this.item = await this.$db.get(this.service, this.itemId, { $clone: true, $noCommit: true })
        this.editItem = JSON.parse(JSON.stringify(this.item))
        // Log.w(`[edit-item-dialog] Edit existing "${this.service}" item id="${this.itemId}" with:`, this.item)
      }
    },

    checkChanges () {
      const changedKey = Object.keys(this.editItem).find((key) => {
        return (key in this.$db.initData(this.service) &&
          JSON.stringify({ content: this.editItem[key] }) !== JSON.stringify({ content: this.item[key] }))
      })
      this.changed = !!changedKey
    },

    cancelEdit () {
      this.checkChanges()
      if (this.changed) {
        // do not cancel immediately, show dialog to confirm to ignore changes
        this.cancelDialog = true
        this.editDialog = true
      } else {
        this.closeDialogs()
      }
    },

    async deleteItem (id) {
      this.closeDialogs()
      await this.$db.remove(this.service, id)
      this.$emit('deleteItemId', id)
    },

    showEditRightDrawer () {
      this.activityDrawer = true
      this.$signal.closeDrawerPanel('activities')
    },

    async closeDialogs (save = false) {
      this.checkChanges()
      if (this.changed && save) {
        await this.saveItem()
      }
      this.editItem = {}
      this.cancelDialog = false
      this.deleteDialog = false
      this.editDialog = false
      this.activityDrawer = false
      this.$emit('closeDialog')
    },

    async saveItem () {
      if (this.valid) {
        const id = this.editItem._id || this.itemId
        if (!id) {
          // Log.i('[edit-item-dialog] save new ', this.editItem._id)
          try {
            const response = await this.$db.create(this.service, this.editItem)
            this.$emit('itemCreated', response)
          } catch (error) {
            this.$emit('itemCreated', { error })
          }
        } else if (this.changed) {
          // Log.i('[edit-item-dialog] patch existing ', this.editItem._id)
          const changedItems = this.getChangedData(this.editItem)
          const strippedItems = this.stripNonPatchableData(changedItems)
          const response = await this.$db.patch(this.service, id, strippedItems)
          this.$emit('itemChanged', response)
        }
      }
      this.closeDialogs()
    }

  }
}
</script>
