// Return a formatted link with a given value
//
// based on locale cookie 'site_language'
//
// Example:
//   {{ '/blog' | i18n() }}  ==> /nl/blog

import Vue from 'vue'

function i18nLink (value) {
  if (!value) { return '' }
  let locale = getCookie('site_language') || 'en'
  locale = locale === 'en' ? '' : '/' + locale
  return `${locale}${value}`
}

Vue.filter('i18nLink', i18nLink)

// local function
function getCookie (name) {
  if (process.browser) {
    const value = '; ' + document.cookie
    const parts = value.split('; ' + name + '=')
    if (parts.length === 2) { return parts.pop().split(';').shift() }
  }
  return null
}
