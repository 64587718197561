/* STORE: THE GLOBAL CONFIGURATION */

import { initialStateProperties } from '~/plugins/vuex-injects/vuex'

import Config from '@/config/constants'
const languages = Config.LANGUAGES.map(i => i.value)

export default initialStateProperties({
  service: 'configuration',

  loadCollectionOnStart: { id: '1' },

  initData: {
    // version = 1 for the first record; probably only one version in installation
    version: 0,

    // for invoices
    financial: {
      vatPercentage: 21.00, // %
      lastInvoiceNumber: 0
    },
    sender: {
      name: '', // { type: String },
      address: '', // { type: String },
      zip: '', // { type: String },
      city: '', // { type: String },
      state: '', // { type: String },
      country: '' // { type: String },
    },

    // how users can contact Bligson:
    bligsonEmails: {
      sales: '', // email,
      marketing: '', // email,
      mentoring: '', // email,
      consultancy: '', // email,
      support: '', // email,
      admin: '', // email,
      press: '' // email
    },

    // the initial configuration of new user's dashboard
    initDashWidgets: [['user_onboarding'], [], []],

    defaultInfoBaseTags: [],
    tocInfoBase: [], // { type: Object, default: () => ({})},
    onboardingInfoBase: [], // [{ type: slug  }]

    workflowTags: {
      expertise: [], // expert criteria
      industry: [], // [{ type: String }],   // 100# en::video | nl::video
      goals: [],
      tactics: []
    },

    // pseudo field to restart server , see hook in backend: src/services/configuration/hooks/system-command.js
    systemCommand: {}

  },

  // fields not to send to server
  populatedData: {
    // Usage: url = this.$db.doc('configuration').assetUrl
    assetUrl: '', // http://localhost:3030/data/assets  or  https://bapi.nuricon.net/data/assets
    assetVariants: {}, // { lg: {x,y,quality,fit,forceFormat='webp'}, md: {}, sm: {}, th: {}}

    workflowCodes: {
      tactics: [], // [{id: 100, en: '', nl: ''}, {},..]
      strategies: [],
      industries: []
    },

    userExpertise: [], // [ { id: 10, en:'seo'}, ...]

    integrations: {
      info: {
        // "apiEndPoint": "/v1/pages",
        // "hosts": [ "localhost:3000", "localhost:3030",.. ],
        // "key" :"BLIGSON_INFO_API_KEY",
        // "$select": ["_id","slug",...]
      },
      accounts: { // see in server .../configuration.hooks.js
        // "apiEndPoint": "/v1/posts",
        // "key" :"BLIGSON_ACCOUNT_API_KEY",
        // "$select": [...]
        // "host"
      }
    }
  },
  afterCommitHook: (state, item) => {
    // let's make sure we have a label for each language the frontEnd uses
    // useful with <v-select> or <c-select> components
    languages.forEach((lang) => {
      item.workflowCodes.tactics.forEach((i) => { i[lang] = i[lang] || i.en })
      item.workflowCodes.strategies.forEach((i) => { i[lang] = i[lang] || i.en })
      item.workflowCodes.industries.forEach((i) => { i[lang] = i[lang] || i.en })

      item.userExpertise.forEach((i) => { i[lang] = i[lang] || i.en })
    })
  }
})
