<template>
  <div class="d-block">
    <label v-if="label" class="caption info--text text-truncate">
      {{ label.toLowerCase() }}
    </label>

    <div>
      <v-btn small rounded :color="color" @click="dialog = true" />
    </div>

    <v-dialog v-model="dialog" :width="dialogWidth">
      <v-card class="pa-1">

        <v-color-picker
          v-model="color"
          :swatches="swatches"
          mode="hexa"
          show-swatches
          hide-mode-switch
          elevation="5"
        />

        <v-card-actions>
          <v-spacer />
          <v-btn color="info" text @click="dialog=false">{{ $t('def.ok') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: 'Select color'
    },
    value: {
      type: String,
      default: ''
    },
    dialogWidth: {
      type: Number,
      default: 300
    }
  },
  data: () => ({
    dialog: false,
    swatches: [
      // See assets/styles/variables.scss
      ['#893e59', '#ce4c68', '#FFFFFF'], // primary, accent
      ['#c88d01', '#e4b331', '#c7c7c7'], // secondary, secondary_2
      ['#005a6f', '#20abb1', '#6a6a6a'], // info, info_2
      ['#8e2626', '#c56363', '#2d2b2d'], // error, warning
      ['#1f711f', '#31a131', '#000000'] // success, ok
    ]
  }),
  computed: {
    color: {
      get () { return this.value },
      set (value) { this.$emit('input', value) }
    }
  }
}
</script>
