<!-- Allow user to enter array field as comma separated string

Examples:

  <c-array-field v-model="telNrs" dense :label="$t('address.tel') />
  <c-array-field v-model="urls" no-http hint-length />
  <c-array-field v-model="item.tel" dense :label="$t('address.tel')" hint-length hint="total=" />

-->

<template>
  <v-text-field v-model="items" v-bind="$attrs" :hint="hint + (hintLength ? value.length : '')" />
</template>

<script>

export default {
  props: {
    value: {
      type: Array,
      default: () => []
    },
    noHttp: {
      type: Boolean,
      default: false
    },
    // @TODO: check emails input validation
    emails: {
      type: Boolean,
      default: false
    },
    hint: {
      type: String,
      default: ''
    },
    hintLength: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    items: {
      get () { return this.value ? this.value.join(',') : '' },
      set (value = '') {
        if (this.noHttp) {
          value = value.replace(/https:\/\//g, '').replace(/http:\/\//g, '')
        }
        value = value.trim() // remove spaces before/after
        value = value.replace(/\t+/g, '') // no tabs or spaces
        value = value.replace(/ +(?= )/g, '') // remove double spaces
        value = value.replace(/,,/g, ',') // replace multiple comma's with one  @TODO: this is not perfect
        this.$emit('input', value.split(','))
      }
    }
  }
}
</script>

<style>

</style>
