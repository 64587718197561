/* Discount terms, months x discount % */

import { initialStateProperties } from '~/plugins/vuex-injects/vuex'

export default initialStateProperties({
  service: 'discount-terms',
  loadCollectionOnStart: true,

  initData: {
    months: 1,
    discount: 0 // %
  }
})
