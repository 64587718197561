// Shared getters by all db services

// import Log from '@/lib/log'

export default {
  //
  // Get a document field
  //   this.email = this.$store.getters['users/get']('email')
  // Note: also used to determine if service is valid in plugins/vuex-injects/db-api-service.js  existService()
  get: state => (param) => {
    return state[param]
  },

  // Return current Document = complete state but without the fields that start with '__'
  //   store.getters[`${service}/getCurrentDocument`]
  //
  // NOTE: this getter name is also used to determine our service list in $db
  //
  getCurrentDocument: (state) => {
    return Object.keys(state).reduce((newDoc, item) => {
      if (!item.includes('__')) {
        newDoc[item] = state[item]
      }
      return newDoc
    }, {})
  },

  // get the current service name. Useful in actions such as action-find.js
  getService: (state) => {
    return state.__service
  },

  // Get __collection with all loaded docs
  //   store.getters[`${service}/getCollection`]
  getCollection: (state) => {
    return state.__collection
  },
  getInitData: (state) => {
    return state.__initData
  },
  // the query to run on each init find
  getDefaultQuery: (state) => {
    return state.__defaultQuery
  },

  // Return the fields that are stored in this collection + populated fields
  //   store.getters[`${service}/getCollectionFields`]
  getCollectionFields: (state) => {
    return [...state.__collectionFields].sort()
  },
  // Get __collection with all loaded docs
  //   store.getters[`${service}/getLoadCollectionOnStart`]
  getLoadCollectionOnStart: (state) => {
    return state.__loadCollectionOnStart
  },
  // To get the field name in the user service current user is working with store.getters[`${service}/getCurrentIdUserKey`]
  getCurrentIdUserKey: (state) => {
    return state.__currentIdUserKey
  },

  // Get the the last find Query of a service. Used in $cache
  getLastQuery: (state) => {
    return state.__lastQuery
  },
  isChannelSubscribed: (state) => {
    return state.__channelSubscription
  }
}
