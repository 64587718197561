/** APP SETTINGS for USER's PROFILE ***

- all reactive parameters should be *preset* in @/store/_app_settings.js
- saved to local memory by @/plugins/vuex-persist

- @/store/_app_settings.js contains the vuex state fields
- @/plugins/vuex-injects/app-settings.js loads this mixin globally in each component

Example:
  <div v-if="$app_darkMode">Very dark</div>
  methods: { toggleDarkMode() { this.$app_darkMode = !this.$app_darkMode }  }
*/

import { mapMutations, mapState } from 'vuex'
import store from '@/store/_app_settings'

export default {
  computed: {
    // [Vue warn]: The client- side rendered virtual DOM tree is not matching server- rendered content.
    // Example: :label="$app_isMobile ? toDayStringShort(i-1) : toDayStringLong(i-1)"
    $app_isMobile () {
      return this.$vuetify.breakpoint.xs
    },
    // True when state is loaded from memory:  async mounted () { await this.$app_storeLoaded; [...] },
    async $app_storeLoaded () {
      await store.restored
    },

    // toggle the light/dark mode of the app
    $app_darkMode: {
      get () {
        this.$vuetify.theme.dark = this.app_get.dark_mode
        return this.app_get.dark_mode
      },
      set (value) {
        this.app_set(['dark_mode', value])
        this.$vuetify.theme.dark = value
      }
    },

    // ***Information Articles / Docs Slugs of Info Tips looked at and clicked away  not see again
    //   see: @bligman/plugins/components/infoButton
    $app_infoTipsSeen: {
      get () { return this.app_get.info_tips_seen },
      set (value) { this.app_set(['info_tips_seen', value]) }
    },
    // Comma separated slugs of unseen tips: @/components/information/widget/InformationRandom
    $app_randSlugTipsUnseen: {
      get () { return this.app_get.rand_slug_tips_unseen },
      set (value) { this.app_set(['rand_slug_tips_unseen', value]) }
    },

    // *** Drawers
    // Toggle the appearance of the right/left/mini panel true|false
    $app_leftDrawer: {
      get () { return this.app_get.__left_drawer },
      set (value) { this.app_set(['__left_drawer', value]) }
    },
    $app_leftMiniDrawer: {
      get () { return this.app_get.__left_mini_drawer },
      set (value) { this.app_set(['__left_mini_drawer', value]) }
    },
    $app_rightDrawer: {
      get () { return this.app_get.__right_drawer },
      set (value) { this.app_set(['__right_drawer', value]) }
    },

    // *** Activities
    $app_activitiesHideComments: { // true | false
      get () { return this.app_get.activities_hide_comments },
      set (value) { this.app_set(['activities_hide_comments', value]) }
    },
    $app_activitiesHideBelowUrgency: { // 0,1,2,3
      get () { return this.app_get.activities_hide_below_urgency },
      set (value) { this.app_set(['activities_hide_below_urgency', value]) }
    },
    $app_activitiesOnlyOthers: { // true | false
      get () { return this.app_get.activities_hide_others },
      set (value) { this.app_set(['activities_hide_others', value]) }
    },
    // $signal.zoomedEditor = true, show font with .blog-post-zoomed class
    $app_zoomedEditor: {
      get () { return this.app_get.editor_zoomed },
      set (value) { this.app_set(['editor_zoomed', value]) }
    },

    ...mapState({
      app_get: state => state._app_settings
    })
  },

  methods: {
    ...mapMutations({
      app_set: '_app_settings/set'
    }),

    // Global
    $app_initialize () {
      this.$store.commit('_app_settings/initialize')
    },

    // Helper to abbreviate a table header on mobile.
    // You can pass parameters to not abbreviate over certain breakpoint
    //    xs="{{ $t_abr('experiences.labels') }} - {{ $vuetify.breakpoint.xs }}"
    //    sm="{{ $t_abr('experiences.labels', {sm: true}) }} - {{ $vuetify.breakpoint.smAndUp }}"
    //    md="{{ $t_abr('experiences.labels', {md: true}) }} - {{ $vuetify.breakpoint.mdAndUp }}"
    //    lg="{{ $t_abr('experiences.labels', {lg: true}) }} - {{ $vuetify.breakpoint.lgAndUp }}"
    //    xl="{{ $t_abr('experiences.labels', {xl: true}) }} - {{ $vuetify.breakpoint.xlOnly }}"
    //
    //  For a table, to only show 4 letters on
    //   data: vm => ({
    //     headers: [
    //       { value: 'experiences', text: vm.$t_abr('experiences.labels', {l:4, md: true } ), ...}
    //     ]
    //   }),
    //
    $t_abr (i18nKey, { l, sm, md, lg, xl } = {}) {
      const noAbbreviate =
        (this.$vuetify.breakpoint.smAndUp && sm) ||
        (this.$vuetify.breakpoint.mdAndUp && md) ||
        (this.$vuetify.breakpoint.lgAndUp && lg) ||
        (this.$vuetify.breakpoint.xlOnly && xl)

      if (noAbbreviate) {
        // only show first l characters and a .
        return this.$i18n.t(i18nKey)
      } else {
        return this.$i18n.t(i18nKey).substr(0, l || 3) + '.'
      }
    }
  }
}
