<!-- **  View fields of a specific asset

  <asset-view :item="item"  />

-->

<template>
  <v-row no-gutters>
    <v-col cols="12" md="8">
      <v-row>
        <c-field cols="12" md="4" :label="$t('assets.field.title')" wrap>{{ item.title }}</c-field>
        <c-field cols="12" md="8" :label="$t('def.tags')">
          <list-tags v-model="item.tags" chips />
        </c-field>
      </v-row>

      <v-row>
        <c-field cols="12" :label="$t('assets.field.description')" wrap>{{ item.description }}</c-field>
      </v-row>
    </v-col>

    <v-col cols="12" md="4">
      <list-embeds :item-id="item._id" max-width="400" max-height="400" />
    </v-col>
  </v-row>

</template>

<script>

import ListTags from '@/components/_lib/form/tags/ListTags'

import ListEmbeds from '@/components/embeds/form/ListEmbeds'

export default {
  components: {
    ListEmbeds,
    ListTags
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  }
}
</script>
