//
// Validation rules to be used throughout form field validations to validate the v-model.
// Injected as $validRule ...
// Please see for Vuetify <v-form></v-form>
//
// NOTE: DO NOT FORGET <v-form>...</v-form>
//
// Example:
//   <v-form v-model="valid" @submit.prevent="" @keydown.prevent.enter>
//     <v-text-field v.model="inviteEmail" :rules="[ $validRule.required, $validRule.email ]" />
//
// Special number field:
//     <v-text-field v.model.number="niceNumber" :rules="[$validRule.required, $validRule.number]" />
// Or:
//     <v-text-field
//       v-model="niceNumber"
//       :rules="[
//          $validRule.required, // value may not be undefined
//   example: if a number:
//          $validRule.number,  // we expect digits only
//          $validRule.minLength(3).val,  // we expect value of minimal 3.. or:
//          $validRule.max(100).val,  // value should be less than 100
//
//   example: if a string:
//          $validRule.exactLength(16).val,  // we expect exact 16 characters
//          $validRule.notIncludes(tags).val
//          $validRule.maxIncludes(allTypes, 2).val // maximal two of one type
//
//   slug
//          :counter="40"
//          :rules="[$validRule.required, $validRule.slug]"
//        ]"
//     />
//   </v-form>
//
//
// ***Validate helper
//
// To prevent a compute to run, we can use $validateForm(form, conditionalFunction).
// Especially useful for compute setters, as you need to wait for $nextTick before valid can be used
//
// Make sure you have   <v-form ref="form" v-model="valid"> ...</v-form>
// Example:
//
//   computed: {
//     email: {
//       get () { return this.$db.doc('users').email },
//       set (email) {
//         this.$validateForm(this.$refs.form, () => {
//           this.$db.patch('users', null, { email }, { $debounce: 2000 })
//         })
//       }
//     }
//   }
//

import Vue from 'vue'

// eslint-disable-next-line no-unused-vars
import Log from '@/lib/log'

export default ({ app, store }, inject, context) => {
  const { i18n } = app

  const nrOccurrences = (n, v) => {
    return n.reduce((s, val) => {
      return s + (val === v)
    }, 0)
  }

  const validationRules = {
    // field can not be empty
    required: v => !!v || i18n.t('validation_rules.field'),

    // field must be in array or string
    //  $validRule.contains('linkedin.com').val
    contains: (n) => {
      return { val: v => !v || (v.includes(n)) || i18n.t('validation_rules.contains', { s: n }) }
    },

    // $validRule.equals(myPassword).val
    equals: (n) => {
      return { val: v => v === n || i18n.t('validation_rules.equals', { s: n, v: n }) }
    },

    // the reverse of contains
    includes: (n) => {
      return { val: v => !v || (n.includes(v)) || i18n.t('validation_rules.includes') }
    },

    // field not in array or string
    //   $validRule.notIncludes(tags).val
    // Example prevent spaces $validRule.notIncludes(' ').val or use $validRule.notIncludeSpaces.val for this example
    notIncludes: (n) => {
      return { val: v => !v || (!n.includes(v)) || i18n.t('validation_rules.not_includes') }
    },

    // [ $validRule.notIncludeSpaces ]
    notIncludeSpaces: v => !v || !v.includes(' ') || i18n.t('validation_rules.not_include_spaces'),

    // value not more than i times in n (array):  [ $validRule.maxIncludes(allTypes, 2).val ]
    maxIncludes: (n, i) => {
      return {
        val: v => (nrOccurrences(n, v) < i) || i18n.t('validation_rules.max_includes', { i })
      }
    },

    // valid email address: $validRule.email
    // Note: empty is allowed also, so use combine with required if need to
    email: v => !v || (/.+@.+\..+/.test(v) && v.split(' ').length <= 1) || i18n.t('validation_rules.email'),
    // valid http(s): $validRule.url
    url: v => !v || (/^((https):\/\/)?[a-z0-9-_~]+\.?[a-z0-9-_~]+\.[a-z]+(\/[a-zA-Z0-9#-_~]+\/?)*$/.test(v)) || i18n.t('validation_rules.url'),

    // letters and dashes: $validRule.slug
    slug: v => !v || (/^[a-z0-9-]+$/.test(v) && v.length <= 40) || i18n.t('validation_rules.slug'),

    letters: v => /^[a-z]+$/.test(v) || i18n.t('validation_rules.letters'),
    // digits only
    digits: v => (/[0-9]$/.test(v)) || i18n.t('validation_rules.digits'),
    number: v => (/^\d*\.?\d*$/.test(v)) || i18n.t('validation_rules.number'),

    // input limits
    min: (n) => {
      return { val: v => (v >= n) || i18n.t('validation_rules.min_value', { n }) }
    },
    max: (n) => {
      return { val: v => (v <= n) || i18n.t('validation_rules.max_value', { n }) }
    },

    // size of the input
    exactLength: (n) => {
      return { val: v => (v && v.length === n) || i18n.t('validation_rules.exact_length', { n }) }
    },
    // Example: $validRule.minLength(3).val
    // please note that if v === undefined, these rules match as well, so also so $validRule.required if so desires
    minLength: (n) => {
      return { val: v => (!v || v.length >= n) || i18n.t('validation_rules.minimal_length', { n }) }
    },
    maxLength: (n) => {
      return {
        val: v => (!v || v.length <= n) ||
          (Array.isArray(v) ? i18n.t('validation_rules.maximal_length_array', { n }) : i18n.t('validation_rules.maximal_length', { n }))
      }
    }
  }

  // make the rules globally available as this.$validRule
  inject('validRule', validationRules)

  // use to only execute if valid: this.$validatedForm(this.$refs.form, () => {...})
  const validatedForm = (refForm, validFunction) => {
    if (!refForm) {
      Log.e('[validate] refForm this.$refs.form missing. Also make sure you include <v-form ref="form" v-model="valid"> in the outer scope?')
      return
    }
    refForm.validate()
    Vue.nextTick(() => {
      if (refForm.value) {
        validFunction()
      }
    })
  }

  inject('validatedForm', validatedForm)
}
