<template>
  <div>
    <embed-table
      v-model="itemId"
      selectable
      small
      show-gallery
      hide-gallery-size-toggle
      @select="embedDialog = true"
    />

    <story-embed-dialog v-model="embedDialog" media-type="embed" :item="item">
      <c-img contain :max-height="200" :src="item.thumbSrc || item.src" />

      <template v-slot:description>
        <c-field :label="$t('editor.image.caption')">
          {{ item.title }}
        </c-field>

        <list-tags v-model="item.tags" color="info" chips class="mt-2" />
      </template>
    </story-embed-dialog>
  </div>
</template>

<script>
import Log from '@/lib/log' // eslint-disable-line no-unused-vars

import EmbedTable from '@/components/embeds/table/EmbedTable'
import ListTags from '@/components/_lib/form/tags/ListTags'
import StoryEmbedDialog from '@/components/stories/form/StoryEmbedDialog'

export default {
  components: {
    EmbedTable,
    ListTags,
    StoryEmbedDialog
  },

  data () {
    return {
      embedDialog: false,
      itemId: ''
    }
  },
  computed: {
    item () {
      return this.$db.doc('embeds', this.itemId)
    }
  }
}
</script>
