<template>
  <v-menu offset-y bottom left width="300">
    <template v-slot:activator="{ on }">
      <v-btn icon class="mt-n1 mr-n2 mr-md-1" v-on="on">
        <c-tooltip :max-width="300" :open-delay="1000" bottom>
          <template #act>
            <v-icon>mdi-factory</v-icon>
          </template>
          {{ $t('accounts.title') }}: {{ $t('accounts.subtitle') }}
        </c-tooltip>
      </v-btn>
    </template>

    <v-card max-width="300">
      <v-card-text>

        <c-list-item
          to="/accounts/overview"
          :label="$t('accounts.overview')"
          :tooltip="$t('accounts.title') + ': ' + $t('accounts.subtitle')"
          :icon="$config.SERVICE_ICON.accounts"
        />
        <c-list-item
          to="/accounts/members"
          :label="$t('accounts.member.labels')"
          :tooltip="$t('accounts.member.title') + ': ' + $t('accounts.member.subtitle')"
          :icon="$config.SERVICE_ICON.users"
        />

        <c-list-item
          to="/accounts/time-trackers"
          :label="$t('time-trackers.labels')"
          :tooltip="$t('time-trackers.title') + ': ' + $t('time-trackers.subtitle')"
          :icon="$config.SERVICE_ICON['time-trackers']"
        />
        <c-list-item
          to="/accounts/activities"
          :label="$t('activities.labels')"
          :tooltip="$t('activities.title') + ': ' + $t('activities.subtitle')"
          :icon="$config.SERVICE_ICON.activities"
        />
        <c-list-item
          to="/accounts/integrations"
          :label="$t('integrations.labels')"
          :tooltip="$t('integrations.title') + ': ' + $t('integrations.subtitle')"
          :icon="$config.SERVICE_ICON.integrations"
        />
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>

export default {

}
</script>
