/*  Global Signals between various components such as api progress bar */

// NOTE: Use exclusively via @/plugins/vuex-injects/signals.js   this.$signal

// import Log from '@/lib/log'

export const state = () => ({

  // to tell rest of app we are logged in and have initial database loaded
  //   see also @/plugins/feathers/feathers-client.js
  //   store.getters['_signals/get']('isLoggedIn')
  isLoggedIn: false,
  // window / app visible on screen
  visibility: false,

  // array of confirmation data: [{ current: { email: 'tom@lorinthe.com }, intended: { email: 'tom@bligson.com' } }]
  // confirmation_data: [],

  // signals that we have a newly active currentAccount
  activeAccountId: '', // will be watched by ~/plugins/feathers/activate-current-account.js
  activeCampaignId: '',
  activePipelineId: '',

  // WidgetDrawer will push widget with this id
  widget_insert: '',
  // list of panels that should be opened
  open_drawer_panels: [],

  // Editor push into the queue : { type: 'image', src: 'https://', alt: 'Good image' }
  editor_insert: {},

  // Id of pipelineTemplate to find tasks to insert
  insert_pipeline_template_id: '',

  // Response result from call to backend, use with components/_default/topbar/ApiStatusBar.
  //  $signal.apiActionBar = {..}
  apiAction: {
    status: '', // [ 'loading', 'success', 'error', 'done' ]
    code: 0, // 0 = off; 100 = ongoing api call; 200-300 = ok; >300 = error
    message: '', // Message received from api call
    log: '' // Log message for console
  },

  // *** Time Tracker
  timeTrackerStartAt: '', //  if null or undefined:  timeTrackerRunning: true,, see plugins/vuex-injects/signals.js
  timeTrackerPauseAt: 0, // in seconds
  timeTrackerData: {} // additional params to save such as service, itemId, accountId
})

const getters = {
  // store.getters['_signals/get']('apiAction')
  //
  get: state => (param) => {
    return state[param]
  }
}

const mutations = {
  // store.commit('_signals/set', { progressBar: {status: 'error', code: 0, message: '' } })
  //
  set (state, payload) {
    Object.keys(payload).forEach((key) => { // loop for Vue reactivity
      state[key] = payload[key]
      // Log.i(`[S] signals.${key} =`, state[key])
    })
  }
}
const actions = {
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
