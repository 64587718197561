
<!-- Show profile image or avatar with a badge indicating (super)admin and/ or online status (@TODO)

<avatar :size="28" badge :user="$db.doc('users')" />
-->

<template>
  <div :class="{ 'cursor-pointer' : showBusinessCard}" @click="dialog=true">
    <v-badge
      v-if="adminBadge"
      avatar
      :dot="showDot"
      :color="$config.USERS.USER_ROLES.COLORS[user.permissions]"
      overlap
      bordered
      bottom
      right
      :icon="size < 50 ? null : 'mdi-star'"
    >
      <avatar-image
        :user="user"
        :size="size"
        :hover-zoom="hoverZoom"
        :hover-size="hoverSize"
      />
    </v-badge>

    <!-- do not show badge -->
    <avatar-image v-else :user="user" :size="size" :hover-zoom="hoverZoom" :hover-size="hoverSize" />

    <v-dialog v-if="showBusinessCard" v-model="dialog" :width="600">
      <business-card :user="user" :max-width="600" :min-width="600" />
    </v-dialog>

  </div>
</template>

<script>

import AvatarImage from '@/components/users/profile/AvatarImage'
import BusinessCard from '@/components/users/view/BusinessCard'

export default {
  components: {
    AvatarImage,
    BusinessCard
  },
  props: {
    user: {
      type: [Promise, Object],
      default: () => {}
    },
    size: {
      type: Number,
      default: 60
    },
    hoverSize: {
      type: Number,
      default: 200
    },
    badge: {
      type: Boolean,
      default: false
    },
    showBusinessCard: {
      type: Boolean,
      default: false
    },
    hoverZoom: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dialog: false
    }
  },
  computed: {
    showDot () {
      return this.user.permissions === 'user' || this.size <= 40
    },
    adminBadge () {
      return this.badge && this.user.permissions && (this.user.permissions.includes('admin') || this.user.permissions.includes('super'))
    }
  }
}
</script>

<style lang="scss">
.v-chip .v-avatar {
  height: auto !important;
  min-width: auto !important;
  width: auto !important;
}
</style>
