<template>
  <div>
    <general-table
      v-model="selectedIds"
      service="embeds"
      dialog-bar-color="assets"
      :headers="headers"
      :small="small"
      :items-per-page="15"
      show-tag-select
      show-expand

      :show-gallery="showGallery"
      :show-gallery-toggle="showGalleryToggle"
      :hide-gallery-size-toggle="hideGallerySizeToggle"
      :show-new-on-empty="showNewOnEmpty"
      :selectable="selectable"
      :select-multiple="selectMultiple"

      :query="totQuery"
      :filter-item-id="filterItemId"
      :filter-list="types"
      :filter-placeholder="`${$t('def.select')} ${$t('def.type')}` "

      @itemCreated="refreshItem"
      @itemChanged="refreshItem"
      @select="$emit('select', $event)"
    >
      <template v-slot:left-toolbar />

      <template v-slot:right-toolbar />

      <template v-slot:item-table-row="{ item }">
        <gen-td :headers="headers" :item="item" value="type" class="d-flex justify-start align-center">
          <list-embeds :item-id="item._id" :width="44" :max-height="40" />
        </gen-td>

        <gen-td :headers="headers" :item="item" value="title" />

        <gen-td :headers="headers" :item="item" value="tags" />

        <gen-td :headers="headers" :item="item" value="description" :small="small" />
      </template>

      <template v-slot:item-expand-pane="{ item }"> <!-- headers, -->
        <embed-view :item="item" />
      </template>

      <template v-slot:item-edit-form="{ item }">
        <embed-edit :item="item" />
      </template>

      <template v-slot:gallery-card="{ item }">
        <list-embeds :item-id="item._id" />
        <c-field class="caption pl-1 text-lowercase ">{{ item.title }}</c-field>
      </template>
      <!--
      <template v-slot:gallery-empty>
        <p class="accent--text caption text-center">No embeds</p>
      </template> -->
    </general-table>

  </div>
</template>

<script>
import Log from '@/lib/log' // eslint-disable-line no-unused-vars

import GeneralTable from '@/components/_lib/form/table/GeneralTable'
import GenTd from '@/components/_lib/form/table/GenTd'

import ListEmbeds from '@/components/embeds/form/ListEmbeds'

import EmbedView from '@/components/embeds/table/EmbedView'
import EmbedEdit from '@/components/embeds/table/EmbedEdit'

export default {
  components: {
    GeneralTable,
    GenTd,
    ListEmbeds,
    EmbedView,
    EmbedEdit
  },
  props: {
    // selected Id(s)
    value: {
      type: [String, Array],
      default: () => []
    },
    selectable: {
      type: Boolean,
      default: false
    },
    selectMultiple: {
      type: Boolean,
      default: false
    },
    // do not allow user to select table view
    showGallery: {
      type: Boolean,
      default: false
    },
    showGalleryToggle: {
      type: Boolean,
      default: false
    },
    showNewOnEmpty: {
      type: Boolean,
      default: false
    },
    // use prop 'small' to mount size at first in small
    hideGallerySizeToggle: {
      type: Boolean,
      default: false
    },
    // open the gallery on first start with small cards
    small: {
      type: Boolean,
      default: false
    },
    query: {
      type: Object,
      default: () => {}
    },
    filterItemId: {
      type: String,
      default: ''
    }
  },
  data: vm => ({
    headers: [
      { value: 'type', text: '', width: '85', format: 'custom' },
      { value: 'title', text: 'title', format: 'textarea' },
      // { value: 'src', text: 'src', responsive: true },
      // { value: 'totalSize', text: 'size', width: '80', format: 'custom', responsive: true },
      { value: 'tags', text: vm.$i18n.t('def.tags'), align: 'start', format: 'tags' },
      { value: 'description', text: 'description', format: 'textarea', width: '300', responsive: true }
    ]
  }),
  computed: {
    totQuery () {
      return this.provider !== 'all' ? { ...this.query, provider: this.provider } : this.query
    },
    types () {
      const typeArr = Object.keys(this.$config.EMBEDS.TYPE) // [image, youtube, video,..]
      return typeArr.map((typeId) => {
        return {
          value: typeId,
          text: this.$i18n.t(`embeds.type.${typeId}`)
        }
      })
      // [ { value: 'image', text: 'My Image' }, { value: 'embed', text: 'Embed' },.. ]
    },
    selectedIds: {
      get () { return this.value },
      set (value) { this.$emit('input', value) }
    }
  },
  methods: {
    async refreshItem (item) {
      await this.$db.get('embeds', item._id)
      // Log.i(`[campaign-table] new campaign ${item._id} created, nr campaigns in current account ${item.accountId}= ${this.$db.currentAccount.campaigns.length} `)
    }
  }
}
</script>
