<template>
  <v-menu offset-y bottom left width="300">
    <template v-slot:activator="{ on }">
      <v-btn icon class="mt-n1 mr-n2 mr-md-1" v-on="on">
        <c-tooltip :max-width="300" :open-delay="1000" bottom>
          <template #act>
            <avatar :size="30" badge :user="$db.doc('users')" />
          </template>
          {{ $t('user.title') }}: {{ $t('user.subtitle') }}
        </c-tooltip>
      </v-btn>
    </template>

    <v-card max-width="300">
      <v-list>
        <v-list-item>
          <avatar :size="40" badge :user="$db.doc('users')" />

          <v-list-item-content class="py-4 ml-5">
            <v-list-item-title class="title">
              {{ user.displayName }}
            </v-list-item-title>

            <v-list-item-subtitle>
              {{ user.title }}
            </v-list-item-subtitle>

            <v-list-item-subtitle class="subtitle-2">
              {{ user.company }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider />

      <v-card-text>
        <c-list-item to="/user/interface" :label="$t('user.appearance.label')" icon="earth" />
        <c-list-item to="/user/profile" :label="$t('user.profile.label')" icon="account" />
        <c-list-item to="/user/expert" :label="$t('user.expert.label')" icon="account-star-outline" />
        <c-list-item to="/user/plan" :label="$t('user.plan.label')" icon="credit-card" />
        <c-list-item to="/user/invoices" :label="$t('user.invoices.label')" icon="cash-multiple" />

        <v-switch
          v-model="$app_darkMode"
          class="ml-4"
          hide-details
          :label="$t('user.interface.dark_mode.label')"
        />
      </v-card-text>

      <v-divider />
      <v-card-actions>
        <v-spacer />
        <logout-button />
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import Avatar from '@/components/users/profile/Avatar'
import LogoutButton from '@/components/users/profile/LogoutButton'

export default {
  components: {
    Avatar,
    LogoutButton
  },
  computed: {
    user () {
      return this.$db.doc('users')
    }
  }
}
</script>
