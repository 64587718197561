 <!-- ** Show a filter top bar for a Table.

  slot:
    top left menu area
    top right menu area

  Events / slot actions:
    @updateQuery = there is a change in the query object (tags)
    @updateFilter = there is a change in the filter string
    @updateSearch = there is a change in the search string
    @refresh = user clicked reload button
    @toggleSize = toggle of zoom button
    @new = user clicks the "+" button/icon in top bar

-->

<template>
  <v-row class="d-flex flex-wrap-reverse align-center" no-gutters style="min-height: 40px; padding-bottom: 2px;">

    <!-- from the top left slot, for example to select campaign in General Table  -->
    <div class="d-flex align-center mt-3 mt-xs-0">
      <slot name="left-toolbar" />
    </div>

    <div v-if="title">
      <v-spacer />
      <span class="subtitle-1">{{ title }}</span>
    </div>
    <v-spacer />

    <div class="d-flex pl-1 grow align-center mb-n3" style="max-width: 100%;">
      <v-spacer />

      <table-tag-select
        v-if="!hideTagSelect"
        v-model="tableTags"
        class="ml-0 ml-md-1"
        style="max-width: 400px;"
        @update="updateTagQuery"
      />

      <table-filter
        v-if="!hideFilter"
        v-model="tableFilter"
        :select-list="filterList"
        :placeholder="filterPlaceholder"
        class="ml-0 ml-md-1"
        @update="$emit('updateFilter',tableFilter)"
      />

      <table-search
        v-if="!hideSearch"
        v-model="tableSearch"
        :placeholder="$t('def.search')"
        class="ml-0 ml-md-1"
        @update="$emit('updateSearch',tableSearch)"
      />

      <div class="ml-0 ml-md-1 d-flex align-center">
        <slot name="right-toolbar" />
      </div>

      <c-icon-btn
        v-if="!hideNew"
        icon="plus"
        class="ml-0 ml-md-1"
        color="primary"
        :disabled="disabledNew"
        i18n-tooltip-text="table.tooltip.add_new"
        @click="$emit('new')"
      />

      <c-icon-btn
        v-if="showSizeToggle"
        icon="resize"
        class="ml-0 ml-md-1"
        color="primary"
        small
        i18n-tooltip-text="table.tooltip.size_toggle"
        @click="$emit('toggleSize')"
      />

      <c-icon-btn
        v-if="!hideRefresh"
        icon="refresh"
        class="ml-0 ml-md-1"
        color="primary"
        small
        i18n-tooltip-text="table.tooltip.refresh"
        @click="$emit('refresh')"
      />
    </div>
  </v-row>
</template>

<script>

import TableSearch from '@/components/_lib/form/table/TableSearch'
import TableFilter from '@/components/_lib/form/table/TableFilter'
import TableTagSelect from '@/components/_lib/form/table/TableTagSelect'

import Log from '@/lib/log' // eslint-disable-line no-unused-vars

export default {
  components: {
    TableSearch,
    TableFilter,
    TableTagSelect
  },

  props: {
    // value: {
    //   type: Object,
    //   default: () => ({
    //     filter: '',
    //     search: ''
    //   })
    // },
    title: {
      type: String,
      default: ''
    },
    disabledNew: {
      type: Boolean,
      default: false
    },
    // toggle buttons
    hideNew: {
      type: Boolean,
      default: false
    },

    hideSearch: {
      type: Boolean,
      default: false
    },
    hideFilter: {
      type: Boolean,
      default: false
    },
    hideRefresh: {
      type: Boolean,
      default: false
    },
    showSizeToggle: {
      type: Boolean,
      default: false
    },

    // hide the tag select (when backend service does not have tags field or $all not whitelisted)
    hideTagSelect: {
      type: Boolean,
      default: false
    },
    filterList: { // [ { value: 'S_01', text: 'ongoing'}, {...} ,.. ]
      type: Array,
      default: () => []
    },
    filterPlaceholder: {
      type: String,
      default: 'filter'
    }
  },
  data () {
    return {
      tableSearch: '',
      tableFilter: '',
      tableTags: []
    }
  },
  mounted () {
    // tags can be pre-filled with a query: http://localhost:3000/stories?tags=blog,employer%20branding,marketing
    if (this.$route.query && this.$route.query.tags) {
      this.tableTags = this.$route.query.tags.split(',')
      this.updateTagQuery()
    };
  },
  methods: {
    updateTagQuery () {
      if (this.tableTags.length === 0) {
        this.$emit('updateQuery', {})
      } else {
        // should match all, do not forget to whitelist in service
        this.$emit('updateQuery', { tags: { $all: this.tableTags } })
      }
    }
  }
}
</script>
