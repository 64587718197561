<template>
  <div class="d-flex align-center">
    <!-- Contact us -->
    <c-dialog-btn
      button-class="ml-0 mr-2"
      icon="email-outline"
      icon-color="grey lighten-2"
      icon-small
      :title="$t('admin.settings.contacts.title')"
      title-color="info"
      title-text-color="white"
    >
      <div v-for="(value, type) in contactEmails" :key="type" class="d-flex align-end body-2 mt-4">
        <div style="width: 90px;">
          {{ type }}
        </div>

        <a :href="`mailto:${value}`">
          {{ value }}
        </a>
      </div>
    </c-dialog-btn>

    <!-- Version and copyright hover -->
    <c-tooltip show-mobile>
      <template #act>
        <div class="cursor-pointer body-2 grey--text text--lighten-2">
          &copy;&nbsp;copyright {{ thisYear }}
        </div>
      </template>

      <div class="">
        version {{ $config.VERSION }} &nbsp;&copy; copyright {{ thisYear }} Lorinthe BV, The Netherlands. All rights reserved.
      </div>
    </c-tooltip>
  </div>
</template>

<script>

export default {
  data (vm) {
    return {
      thisYear: new Date().getFullYear()
    }
  },
  computed: {
    contactEmails () {
      return this.$db.doc('configuration').bligsonEmails
    }
  }
}
</script>
