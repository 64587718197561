// Register all components we like to use globally by this Plugin
//
// NOTE: Don't forget to load plugins in nuxt.config

import Vue from 'vue'

import Page from './page.vue'
import ResponsiveSpacer from './responsiveSpacer.vue'
import TableRowDivider from './tableRowDivider.vue'
import ShadowBar from './shadowBar.vue'

import NavButton from './navButton.vue'
import AlertButton from './alertButton.vue'
import IconButton from './iconButton.vue'
import DialogButton from './dialogButton.vue'
import InfoButton from './infoButton.vue'
import Tooltip from './tooltip.vue'

import ColorPicker from './colorPicker.vue'
import ColorSelect from './colorSelect.vue'

import CopyToClipboard from './copyToClipboard'

import ButtonSelectObject from './buttonSelectObject.vue'
import SelectIcon from './selectIcon.vue'

// import StarSelect from './starSelect.vue'
import ListSelect from './listSelect.vue'
import ArrayField from './arrayField.vue'

import nestedDraggable from './nestedDraggable.vue'

import Link from './link.vue'
import CImg from './imgSecure.vue'
import CDate from './dateFormatted.vue'
import CDatePicker from './datePicker.vue'
import DDateDuration from './dateDuration.vue'

import Currency from './currency.vue'
import BytesToSize from './bytesToSize.vue'
import ListItem from './listItem.vue'
import ViewField from './viewField'
import ViewFieldDate from './viewFieldDate.vue'
import CheckField from './checkField'

Vue.component('c-page', Page)

Vue.component('c-img', CImg)

Vue.component('c-date', CDate)
Vue.component('c-date-picker', CDatePicker)
Vue.component('c-date-duration', DDateDuration)

Vue.component('cur', Currency)
Vue.component('c-bytes-to-size', BytesToSize)
Vue.component('c-spacer', ResponsiveSpacer) // different space (mb & mt) on mobile, tablet and desktop
Vue.component('t-divider', TableRowDivider) // divider to be used as <tr></tr>
Vue.component('c-shadow', ShadowBar) // divider to be used as <tr></tr>

Vue.component('c-nav-btn', NavButton)
Vue.component('c-dialog-btn', DialogButton)
Vue.component('c-info-btn', InfoButton)
Vue.component('c-tooltip', Tooltip)

Vue.component('c-color-picker', ColorPicker)
Vue.component('c-color-select', ColorSelect)

Vue.component('c-clipboard-copy', CopyToClipboard)

Vue.component('c-select', ListSelect)
Vue.component('c-array-field', ArrayField)

Vue.component('c-btn-select-object', ButtonSelectObject)
// Vue.component('c-star-select', StarSelect) // @TODO: remove , use standard vuetify component
Vue.component('c-icon-select', SelectIcon)

Vue.component('c-nested-draggable', nestedDraggable)

Vue.component('c-alert-btn', AlertButton) // show "ok" alert
Vue.component('c-icon-btn', IconButton) // show "ok" alert

Vue.component('c-link', Link) // different space (mb & mt) on mobile, tablet and desktop
Vue.component('c-list-item', ListItem) // for in menu, tables and so on. Note: use within <v-list><c-list-item .../></list-item></v-list>

Vue.component('c-field', ViewField) // to show non-editable fields from a database record
Vue.component('c-field-date', ViewFieldDate) // date wrapped in c-field, all props passed to c-field bt $attr
Vue.component('c-check-field', CheckField) // to show non-editable fields from a database record
