  <!-- Show icon within a button, and a tooltip

  Pass props to the icon

    <c-icon-btn
      icon="chevron-down"
      small
      color="info"
       icon-class="no-print"
      i18n-tooltip-title="widgets.title"
      i18n-tooltip-text="widgets.open"
      :i18n-tooltip-vars="{ name: 'John', age: 24 }"
      @click="visibility = 'compact'"
    />

    <c-icon-btn
      icon="pencil"
      :size="20"
      tooltip="Hello there click me"
      ...
    />
  -->
<template>
  <c-tooltip :bottom="tooltipBottom">
    <template #act>
      <!-- :x-small="xSmall" -->
      <v-btn
        icon
        :small="xSmall"
        :large="large"
        :fab="fab"
        :disabled="disabled"
        @click.stop="$emit('click')"
      >
        <v-icon
          v-bind="$attrs"
          :small="small"
          :x-small="xSmall"
          :large="large"
          :color="color"
          :class="iconClass"
        >
          mdi-{{ icon }}
        </v-icon>
        <span v-if="label" class="overline primary--text">{{ label }}</span>
      </v-btn>
    </template>

    <span v-if="tooltip">{{ tooltip }}</span>

    <template v-else-if="i18nTooltipTitle || i18nTooltipText">
      <h4 v-if="i18nTooltipTitle" class="font-weight-medium">{{ $t(i18nTooltipTitle, i18nTooltipVars) }}</h4>
      <span v-if="i18nTooltipText" :class="{'text-lowercase': !i18nTooltipTitle}">{{ $t(i18nTooltipText, i18nTooltipVars) }}</span>
    </template>
  </c-tooltip>
</template>

<script>

export default {
  props: {
    icon: {
      type: String,
      default: 'close'
    },
    label: {
      type: String,
      default: ''
    },
    // do not set icon color to allow following dark / light theme
    color: {
      type: String,
      default: ''
    },
    iconClass: {
      type: String,
      default: ''
    },

    disabled: {
      type: Boolean,
      default: false
    },
    fab: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    xSmall: {
      type: Boolean,
      default: false
    },
    large: {
      type: Boolean,
      default: false
    },

    // tooltip
    tooltipBottom: {
      type: Boolean,
      default: false
    },

    tooltip: {
      type: String,
      default: ''
    },
    // tooltip in $t() format
    i18nTooltipTitle: {
      type: String,
      default: ''
    },
    i18nTooltipText: {
      type: String,
      default: ''
    },
    i18nTooltipVars: {
      type: Object,
      default: () => {}
    }
  }
}
</script>
