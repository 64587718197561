import { render, staticRenderFns } from "./GeneralTable.vue?vue&type=template&id=4106e88a&scoped=true&"
import script from "./GeneralTable.vue?vue&type=script&lang=js&"
export * from "./GeneralTable.vue?vue&type=script&lang=js&"
import style0 from "./GeneralTable.vue?vue&type=style&index=0&id=4106e88a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4106e88a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/@nuxtjs/vuetify/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSystemBar } from 'vuetify/lib/components/VSystemBar';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VDataTable,VDialog,VDivider,VIcon,VPagination,VSelect,VSpacer,VSystemBar})
