<!-- ** ALERT DIALOG

Show button with icon that shows an alert dialog.

Events:
  button:
    @click - emit when user clicks on actual button in the first place
  alert dialog:
    @ok - click on a button with label 'ok'
    @cancel - click on a button with label 'cancel'
    @action - additional button with label 'action-label'

Examples:
  <c-alert-btn
    type="info"
    color="primary"
    :label="$t('user.interface.reset.label') "
    :ok-disabled="okDisabled"
    tooltip="you can delete your data with this button"
    @ok="$app_initialize()"
  >
   Reset all data?
   <v-btn @click="okDisabled=false">Yes really ;-)) !</v-btn>
  </c-alert-btn>

  <c-alert-btn
    icon-color="primary"
    icon="trash-can-outline"
    @ok="deleteItem()"
    @cancel="sheCancelled()"
  >
    {{ $t('table.alert.delete', { type: itemType, id: `(#${itemId})` } ) }}
  </c-alert-btn>

 Without button, controlled by model:
   <c-alert-btn
      v-model="delDialog"
      hide-button
      show-action
      action-label="save"
      action-color="success"
      @action="saveItem(item)"
      @ok="deleteItem(item)"
   >
     You want to delete?
   </c-alert-btn>

-->
<template>
  <div>
    <c-tooltip :disabled="!tooltip">
      <template #act>
        <v-btn
          v-if="!hideButton"
          v-bind="$attrs"
          :text="text"
          :small="xSmall"
          :outlined="outlined"
          :disabled="disabled"
          :icon="!!icon"
          :color="color || type"
          :class="buttonClass"
          @click="dialog=true; $emit('click')"
        >
          <v-icon
            v-if="icon"
            :small="small"
            :color="iconColor"
            :class="iconClass"
          >mdi-{{ icon }}</v-icon>
          <span :class="{'ml-n3': !outlined && !icon && text, 'text-lowercase': icon && !text}">{{ label }}</span>
        </v-btn>
      </template>

      {{ tooltip }}
    </c-tooltip>

    <v-dialog v-model="dialog" :width="wide ? 1200: 750" @click:outside="closeDialog()">
      <v-card class="background">
        <!-- text -->
        <v-alert
          outlined
          :type="type"
          border="left"
          class="ma-0 pt-6"
        >
          <slot />

          <div class="d-flex mt-4 ml-n12">
            <v-spacer />

            <v-btn
              v-if="!hideCancel"
              color="info"
              outlined
              small
              @click="closeDialog()"
            >{{ cancelLabel || $i18n.t('def.cancel') }}</v-btn>

            <v-btn
              :color="type"
              small
              outlined
              class="ml-4"
              :disabled="okDisabled"
              @click="okDialog()"
            >{{ okLabel || $i18n.t('def.ok') }}</v-btn>

            <v-btn
              v-if="showAction"
              :color="actionColor"
              :disabled="actionDisabled"
              small
              outlined
              class="ml-4"
              @click="actionDialog()"
            >{{ actionLabel || $i18n.t('def.save') }}</v-btn>

          </div>
        </v-alert>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import Log from '@/lib/log'

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    type: {
      type: String, // success, info, warning or error
      default: 'warning'
    },
    wide: {
      type: Boolean,
      default: false
    },

    // show/not show button, if not use show-dialog to show alert
    hideButton: {
      type: Boolean,
      default: false
    },

    label: {
      type: String,
      default: ''
    },
    tooltip: {
      type: String,
      default: ''
    },
    // ** Button appearance
    color: {
      type: String,
      default: ''
    },
    buttonClass: {
      type: String,
      default: ''
    },
    text: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    xSmall: {
      type: Boolean,
      default: false
    },
    // Note: other button props passed as v-bind="$attrs"

    // ** icon in the Button
    icon: {
      type: String,
      default: ''
    },
    iconColor: {
      type: String,
      default: 'info'
    },
    iconClass: {
      type: String,
      default: ''
    },

    // The action buttons in the Alert dialog
    okLabel: {
      type: String,
      default: 'ok'
    },
    okDisabled: {
      type: Boolean,
      default: false
    },
    hideCancel: {
      type: Boolean,
      default: false
    },
    cancelLabel: {
      type: String,
      default: ''
    },
    // show an addition button
    showAction: {
      type: Boolean,
      default: false
    },
    actionDisabled: {
      type: Boolean,
      default: false
    },
    actionLabel: {
      type: String,
      default: ''
    },
    actionColor: {
      type: String,
      default: 'success'
    }
  },
  data: () => ({
    dialog: false
  }),
  watch: {
    value () {
      this.dialog = this.value
    }
  },
  mounted () {
    this.dialog = this.value
  },
  methods: {
    closeDialog () {
      this.dialog = false
      this.$emit('input', false)
      this.$emit('cancel')
    },
    okDialog () {
      this.dialog = false
      this.$emit('input', false)
      this.$emit('ok')
    },
    actionDialog () {
      this.dialog = false
      this.$emit('input', false)
      this.$emit('action')
    }
  }

}
</script>
