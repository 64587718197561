<!-- show a business card based on a user's document
  <business-card
    :user="user"
    :selected="isMember(user._id)"
    color="background-1"
    class="fill-height"
    style="max-height: 230px;"
  >
    <v-btn>Hello</v-btn>
  </business-card>

Events:
  @click

-->
<template>
  <v-card
    :color="color"
    :min-height="240"
    :min-width="minWidth"
    :max-width="maxWidth"
  >
    <!-- style="max-width: 92vw !important;" -->
    <v-sheet height="5" :class="selected ? 'secondary' : color" />

    <v-list-item two-line>
      <v-list-item-avatar :size="40" color="grey">
        <avatar-image :size="40" :user="user" hover-zoom :hover-size="120" />
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title class="pt-1">
          <div>
            <span>{{ user.displayName }}</span>
            <certified-consultant-badge :item="user" small class="mb-2" />
          </div>

          <div class="d-flex flex-wrap">
            <span class="body-2 text-truncate mr-2">{{ user.title || '&nbsp;' }}</span>
            <v-spacer />
            <list-country class="caption info--text" :code="user.address.countryCode" />
          </div>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-card-text class="pt-0 ">

      <template v-if="!alert">
        <v-divider class=" mb-2" />
        <div class="body-2 text-truncate-lines cursor-pointer" style="--nr_lines: 4;" @click="alert=true">
          {{ user.resume }}
        </div>
        <v-divider class="mt-2" />
      </template>

      <v-alert v-model="alert" type="info" dismissible class="mx-n4" style="z-index: 2;">
        <span class="body-2">{{ user.resume }}</span>
      </v-alert>
    </v-card-text>

    <v-card-actions class="mt-n4">
      <v-btn
        v-if="user.linkedInUrl"
        icon
        :href="user.linkedInUrl"
        target="_blank"
        class="mr-2"
      >
        <v-icon color="info">mdi-linkedin</v-icon>
      </v-btn>

      <div class="caption info--text">
        <c-select
          :value="user.expertise"
          :items="$db.doc('configuration').userExpertise"
          list-class="caption three-lines"
          readonly
          sort
        />
        <c-select
          :value="user.industries"
          :items="$db.doc('configuration').workflowCodes.industries"
          list-class="caption three-lines"
          readonly
          sort
        />
      </div>
      <v-spacer />

      <slot />
    </v-card-actions>

  </v-card>
</template>

<script>

import AvatarImage from '@/components/users/profile/AvatarImage'
import ListCountry from '@/components/_lib/form/address/ListCountry'
import CertifiedConsultantBadge from '@/components/users/view/CertifiedConsultantBadge'

export default {
  name: 'BusinessCard',
  components: {
    AvatarImage,
    ListCountry,
    CertifiedConsultantBadge
  },
  props: {
    user: {
      type: Object,
      default: () => {}
    },
    selected: {
      type: Boolean,
      default: false
    },
    minWidth: {
      type: Number,
      default: 240
    },
    maxWidth: {
      type: Number,
      default: 380
    },
    color: {
      type: String,
      default: 'background-1'
    }
  },
  data () {
    return {
      alert: false
    }
  }

}
</script>
