<template>
  <div class="d-flex elevation-2">
    <v-sheet :color="item.color || 'grey'" :width="4" class="pr-2" />

    <v-row no-gutters class="pl-2 py-2">
      <v-col cols="12">
        <span class="font-weight-medium body-2">{{ item.name }}</span>
      </v-col>

      <v-col cols="5">
        <v-card v-if="item.assetIds && item.assetIds.length>0" outlined class="pa-1 background-1">
          <list-assets v-model="item.assetIds" :width="52" :height="40" />
        </v-card>
      </v-col>

      <v-col cols="7" style="max-height: 200px; overflow-y: auto;" class="caption">
        {{ item.description }}
      </v-col>

    </v-row>
  </div>
</template>

<script>
import ListAssets from '@/components/assets/form/ListAssets'

export default {
  components: {
    ListAssets
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  }
}
</script>
