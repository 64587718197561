<!--
 List tasks, grouped in expansion panels by stage. Useful for the RightDrawer

 <task-list :items="item.tasks" class="mt-2" hide-empty />
-->

<template>
  <v-card outlined>
    <v-expansion-panels accordion>
      <v-expansion-panel v-for="stage in stages" :key="stage" :disabled="nrStages(stage)===0">
        <!-- <div class="d-flex pl-2 background-1" style="height: 40px;"> -->
        <!-- <v-icon small :color="$config.TASKS.STAGE[stage].color">mdi-circle</v-icon> -->

        <v-expansion-panel-header class="background-1" style="height: 30px;">
          {{ $t(`tasks.stage.${stage}`) }}  &nbsp;{{ nrStages(stage) }}x
        </v-expansion-panel-header>
        <!-- </div> -->

        <v-expansion-panel-content class="pa-0 pt-4 background">
          <template v-for="(item, index) in items">
            <task-card
              v-if="item.stage === stage"
              :key="`${stage}_${index}`"
              :item="item"
              class="mx-n4 my-2"
            />

          </template>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>

import TaskCard from '@/components/tasks/form/TaskCard'

export default {
  components: {
    TaskCard
  },
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    stages () {
      return Object.keys(this.$config.TASKS.STAGE)
    }
  },
  methods: {
    nrStages (stage) {
      return this.items.filter(i => i.stage === stage).length
    }
  }

}
</script>
