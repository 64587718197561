/**
  A Lorinthe project.

  @Author: Tom Voorneveld <tom>
  @Date:   2019-04-28T17:44:34+03:00
  @Email:  tom@lorinthe.com
  @Project: Lorinthe
  @Last modified by:   tom
  @Last modified time: 2019-04-29T17:40:25+03:00
  @Copyright: (c) copyright Lorinthe BV, all rights reserved

  Log depending on run environment

    import Log from '@/lib/log'

    Log.i('It is working', 1, 2, 3) // does not log in production; preferred usage

    Log.n(`[!] Button ${i} tap`)

*/

// ** Debug parameter
//
const OVERRIDE_DEV_MODE = 'dev' // 'prod' || 'test' || 'dev' = no override

// Get RUN Environment
let RE

if (process.browser && window) {
  if (window.location.href.includes('localhost') || window.location.href.includes('127.0.0.1')) {
    RE = OVERRIDE_DEV_MODE
  } else if (window.location.href.includes('nuricon.net')) {
    RE = 'test'
  } else {
    RE = 'prod'
  }
} else {
  RE = 'dev'
}
const IS_PROD = 'prod'.includes(RE)
const IS_DEV = 'dev'.includes(RE)

/* eslint no-console: 0 */
const Log = {
  // Log client dev & text, so not production  (this is preferred!)
  l () { if (!IS_PROD) { console.log(...arguments) } }, // info
  log () { if (!IS_PROD) { console.log(...arguments) } }, // info
  a () { if (!IS_PROD) { console.warn(...arguments) } }, // alert
  i () { if (!IS_PROD) { console.info(...arguments) } }, // info
  info () { if (!IS_PROD) { console.info(...arguments) } }, // info

  // log always on client (careful!):

  error () { console.error(...arguments) }, // error
  warn () { console.warn(...arguments) }, // warn
  e () { console.error(...arguments) }, // error
  w () { console.warn(...arguments) }, // warn
  n () { console.info(...arguments) }, // note

  // log on client on dev only, if you need to really spam the console:
  v () { if (IS_DEV) { console.log(...arguments) } }, // verbose

  // for server only logging
  se () { if (!process.browser) { console.error(...arguments) } }, // error
  sw () { if (!process.browser) { console.warn(...arguments) } }, // warn
  sn () { if (!process.browser) { console.info(...arguments) } }, // note

  sa () { if (!process.browser) { console.warn(...arguments) } }, // alert
  si () { if (!process.browser) { console.info(...arguments) } } // info
}

if (process.browser) {
  Log.i('Browser logging activated on:', RE)
} else {
  Log.i('Server logging activated on:', RE)
}

export default Log
