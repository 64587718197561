<!--  Select scheduled

show button group that selects a $db.find query
-->

<template>
  <c-btn-select-object
    v-model="scheduledQuery"
    :items="selectOptions"
    i18n-prefix="stories.select"
    style="min-width: 115px;"
  />
  <!-- 115px = 4 buttons -->

</template>

<script>

export default {
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },

  data: (vm) => {
    return {
      selectOptions: [
        { id: 'scheduled.all', value: { }, icon: vm.$config.SELECT_ICON.scheduled.all },
        { id: 'scheduled.on', value: { startAt: { $lt: new Date() }, endAt: { $gt: new Date() } }, icon: vm.$config.SELECT_ICON.scheduled.on },
        { id: 'scheduled.plan', value: { startAt: { $gt: new Date() } }, icon: vm.$config.SELECT_ICON.scheduled.plan },
        { id: 'scheduled.fin', value: { endAt: { $lt: new Date() } }, icon: vm.$config.SELECT_ICON.scheduled.fin }
      ]
    }
  },
  computed: {
    scheduledQuery: {
      get () { return this.value },
      set (value) { this.$emit('input', value) }
    }
  }
}
</script>
