<template>
  <v-avatar
    :max-width="size"
    :max-height="size"
    :min-width="10"
    :min-height="10"
    :width="size"
    :height="size"
    class="elevation-2"
  >

    <c-tooltip v-if="user.avatarPrefix === 0" top :disabled="!hoverZoom">
      <template #act>
        <c-img
          :alt="user.displayName"
          :src="user.avatarImageSrc"
          contain
          :height="size + 4"
          :width="size + 4"
        />
        <!-- content-class="contain-v-img" -->
        <!-- :max-width="size"
          :max-height="size" -->
      </template>

      <c-img
        :alt="user.displayName"
        :src="user.avatarImageSrc"
        contain
        class="ma-n3"
        :max-width="hoverSize"
        :max-height="hoverSize"
      />
    </c-tooltip>

    <v-gravatar
      v-else
      :alt="user.displayName"
      :email="user.avatarPrefix + '.' + user.email"
      :size="size"
      default-img="wavatar"
    />

  </v-avatar>
</template>

<script>
export default {
  props: {
    user: {
      type: [Promise, Object],
      default: () => {}
    },
    size: {
      type: Number,
      default: 60
    },
    hoverZoom: {
      type: Boolean,
      default: false
    },
    hoverSize: {
      type: Number,
      default: 200
    },
    badge: {
      type: Boolean,
      default: false
    }
  }
}
</script>
