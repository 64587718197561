<!--
Insert simple link, conforming the nuxt and locale property

Example, shows "1KB"

  <c-bytes-to-size :bytes="123456"/>

To use basis of 1000 instead of 1024
  <c-bytes-to-size :bytes="123456" decimal-basis />

-->

<template>
  <div style="display: inline-block;">
    <v-chip v-if="chip && (!hideZero || bytes!==0)" class="info--text" label x-small outlined>
      <slot />
      {{ bytesToSize }}
    </v-chip>

    <template v-else-if="!hideZero || bytes!==0">
      <slot />
      {{ bytesToSize }}
    </template>
  </div>
</template>

<script>

export default {
  props: {
    bytes: {
      type: Number,
      default: 0
    },
    decimalBasis: {
      type: Boolean,
      default: false
    },
    hideZero: {
      type: Boolean,
      default: false
    },
    // chip around label
    chip: {
      type: Boolean,
      default: false
    },
    // square chip label
    label: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    basis () {
      return this.decimalBasis ? 1000 : 1024
    },
    bytesToSize () {
      const b = this.bytes
      const sizes = ['B', 'KB', 'MB', 'GB', 'TB']
      if (b === 0) { return '0 Byte' }
      const i = parseInt(Math.floor(Math.log(b) / Math.log(this.basis)))
      return Math.round(b / Math.pow(this.basis, i), 2) + ' ' + sizes[i]
    }
  }
}
</script>
