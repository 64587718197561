<!--
 Show item that fits in the left navigation drawer

  label = printed as item title
  to:
    if contains http(s) renders to normal href link
    else renders "to" vue/nuxt router link

 Examples:

  <c-list-item label="home.label" to="index" icon="home" icon-after info="welcome" show-tooltip />
  <c-list-item label="google.label" to="https://www.google.com" icon="home" icon-after show-tooltip tooltip="Go google!!" />
  <c-list-item label="services.list.editing" icon="cash-100" icon-small  activeClass="blue" labelClass="red" />
-->

<template>
  <c-tooltip
    :disabled="!showTooltip && !tooltip"
    :max-width="300"
    :open-delay="600"
  >
    <template #act>
      <v-list-item
        :two-line="info !== ''"
        :to="to | i18nLink()"
        :href="hrefLink"
        nuxt

        ripple
        :dense="dense"
        :style="`min-height: ${dense ? '36':'40'}px !important; `"
        class="nav-item"
        :color="color"
        :active-class="activeClass"
      >
        <div class="d-flex">
          <v-list-item-icon v-if="icon && !iconAfter" :class="{ 'mr-3 my-1': dense, 'my-2': !dense}">
            <v-icon :size="dense ? 20:24" :color="iconColor">mdi-{{ icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-title
            style="font-size: inherit;"
            class="font-weight-regular text-uppercase"
            :class="labelClass"
          >
            {{ label }}
          </v-list-item-title>
        </div>

        <v-list-item-icon v-if="icon && iconAfter">
          <v-icon>mdi-{{ icon }}</v-icon>
        </v-list-item-icon>
      </v-list-item>
    </template>

    {{ tooltip || label }}
  </c-tooltip>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    labelClass: { type: String, default: '' },

    showTooltip: { type: Boolean, default: false },
    tooltip: { type: String, default: '' }, // tooltip default is same as label

    activeClass: { type: String, default: 'background-1' },

    info: { type: String, default: '' },
    dense: { type: Boolean, default: false },

    color: { type: String, default: 'info' },
    icon: { type: String, default: '' },
    iconColor: { type: String, default: '' },
    iconAfter: { type: Boolean, default: false },

    to: { type: String, default: '' }
  },
  data () {
    return {
      hrefLink: this.to.includes('http://') || this.to.includes('https://') ? this.to : ''
    }
  }
  // computed: {
  //   // do we have external link?
  //   hrefLink() {
  //     return
  //   }
  // },
}
</script>

<style lang="scss" scoped>
#left-drawer .v-list-group__items .v-list-item__title {
  font-size: 0.9rem;
}
</style>
