<!-- the First signup email confirmation warning

Show if:
  - user has not set the emailConfirmed field
  - there is an outstanding confirmation w

-->

<template>
  <div>
    <v-sheet v-if="!$db.doc('users').emailConfirmed && !hasOutstandingConfirm" color="warning">
      <div class="col-md-8 offset-md-2 px-10  white--text body-2 ">
        <span class="mb-2">{{ $t('confirmations.signup') }}</span>

        <v-btn small class="ml-2" outlined color="white" text @click="signupConfirmEmail()">
          <v-icon class="ml-2 mr-2" small>mdi-email-send-outline</v-icon>{{ $db.currentUser.email }}
        </v-btn>
      </div>
    </v-sheet>

    <!-- message after sending a new confirmation  -->
    <v-snackbar v-model="checkEmailAlert" top color="background" multi-line timeout="10000">
      <v-btn icon class="float-right mt-n2 mr-n4" @click="checkEmailAlert=false;">
        <v-icon color="info">mdi-close</v-icon>
      </v-btn>

      <div class="foreground--text">
        <h3 class="font-weight-bold mt-2">{{ $t('confirmations.email_sent') }}</h3>
        <v-sheet :height="3" color="success" class="mb-4" />

        <p class="font-weight-bold">{{ $t('confirmations.notifier.check') }}</p>
        <p>{{ $t('confirmations.notifier.note', { m: timeoutMinutes }) }}</p>
      </div>
    </v-snackbar>

  </div>
</template>

<script>
export default {
  data () {
    return {
      checkEmailAlert: false
    }
  },
  computed: {
    // find all confirmations that have at least one message with the relevant field
    hasOutstandingConfirm () {
      return this.$db.collection('confirmations').findIndex(c =>
        c.execData.includes('emailConfirmed')
      ) > -1
    },
    timeoutMinutes () {
      return Math.floor(this.$config.CONFIRMATIONS.TIME_OUT_SECONDS / 60)
    }
  },
  methods: {
    async signupConfirmEmail () {
      await this.$db.patch('users', this.currentUserId, {
        emailConfirmed: true
      })
      this.checkEmailAlert = true

      await this.$db.find('confirmations', {
        userId: this.currentUserId
      })
    }
  }
}
</script>
