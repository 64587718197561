// ** GET - STATE CRUD DATABASE ACTIONS
//
// Get one document from the database by its id and store it as the current document in the state

// data may also be an array; but in the feathers service the "multi" option has to be set
//
//
// await store.dispatch(`${service}/get`, { id, params })
//
//   Pro tip: With pagination enabled, to just get the number of available records set $limit to 0.
//   This will only run a(fast) counting query against the database and return a page object with the total and an empty data array.

import Log from '@/lib/log'

export default {

  async get ({ commit, getters, dispatch, rootGetters }, { id, params = {} }) {
    const { $noCommit, ...query } = params

    const service = getters.getService

    if (id === undefined) {
      throw new Error(`[S] ${service} get action failed: _id missing.`)
    }

    let response
    try {
      this.$signal.apiActionBar = { status: 'loading' }
      response = await this.$api.service(service).get(id, { query })
      this.$signal.apiActionBar = { status: 'success' }
    } catch ({ code, message }) {
      this.$signal.apiActionBar = { status: 'error', code, message }
      Log.e(`[S] get failed in "${service}" with error: [${code}]: "${message}" and query:`, query)
      return {}
    }

    if (!$noCommit && response && response._id) {
      commit('afterCommitHook', response)
      commit('set', response)
      commit('updateCollection', { id: response._id, data: response })
    }

    const paramsTxt = Object.keys(query).length > 0 ? `, { query:${JSON.stringify(query)} }` : ''
    Log.i(`[S] get("${service}",${id}${paramsTxt})${$noCommit ? ' NOT' : ''} & commit `, JSON.parse(JSON.stringify(response)))
    return response
  }
}
