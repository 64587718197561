<!--
Insert simple link, conforming the nuxt and locale property

Examples:

  <c-link to="#" cols="12 sm="8" md="6">{{ toLabel }}</c-link>
  <c-link to="#">{{ toLabel }}</c-link>
  <c-link to="/company/faq" class="ml-1 link" v-html="$t('company.faq.title').toLowerCase()" />

-->

<template>
  <!-- NOTE: User of Filter is depreciated ion Vue 3.0 !!! -->
  <nuxt-link
    exact
    :to="to === '#' ? to : to | i18nLink()"
    :class="(cols ? `col col-${cols}`: '') + (md ? ` col-md-${md}`: '') + (sm ? ` col-sm-${sm}`: '')"
  >
    <slot />
  </nuxt-link>
</template>

<script>
export default {
  props: {
    to: {
      type: String,
      default: '#'
    },
    cols: {
      type: String,
      default: ''
    },
    md: {
      type: String,
      default: ''
    },
    sm: {
      type: String,
      default: ''
    }
  }
}
</script>
