<template>
  <v-row v-if="item && item.fileName">
    <v-col cols="12" md="6">
      <v-row>
        <c-field cols="8" :label="$t('assets.field.org_filename')" wrap>
          {{ item.fileName }}
        </c-field>

        <c-field cols="4" :label="$t('assets.field.total_storage')" wrap show>
          <c-bytes-to-size :bytes="item.totalStorage" decimal-basis />
        </c-field>
      </v-row>

      <c-field :label="$t('assets.field.id')" wrap show class="caption">
        {{ item._id }}
      </c-field>

    </v-col>

    <c-field cols="12" md="6" :label="$t('assets.field.sizes')" wrap show>
      <v-simple-table dense>
        <thead>
          <tr>
            <th class="text-left">id</th>
            <th class="text-left">px</th>
            <th class="text-left">quality</th>
            <th class="text-left">fit</th>
            <th class="text-left">format</th>
            <th class="text-left">size</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(imgCfg, key) in item.variants" :key="key" class="caption">
            <td><a :href="imgCfg.src" target="_blank" class="ml-2 font-weight-bold" @click.stop>{{ key }}</a></td>
            <td>{{ imgCfg.width }}x{{ imgCfg.height }}</td>
            <td> {{ imgCfg.quality }}%</td>
            <td>{{ imgCfg.fit ? imgCfg.fit : '' }}</td>
            <td>{{ imgCfg.format }}</td>
            <td><c-bytes-to-size v-if="imgCfg.size" :bytes="imgCfg.size" decimal-basis /></td>
          </tr>
        </tbody>
      </v-simple-table>
    </c-field>
  </v-row>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    }

  },
  methods: {
    idUrl (id, size) {
      return `${this.$config.REPO_URL}/${this.item.accountId}/${size}/${id}`
    }
  }
}
</script>
