/*
The user selectable App settings.
Will be remembered in local storage for next time user starts the app

Persistent state we use https://github.com/championswimmer/vuex-persist
Configure in: @/plugins/vuex-persist/index.js

Usage see:   @/plugins/mixins/_app_settings.js

Examples:
  this.app_set(['dark_mode' , !this.app_setting.dark_mode])
  async mounted () { await this.$app_storeLoaded; [...] },
*/

import CONFIG from '@/config/constants'
import Log from '@/lib/log'

//
// APP's PARAMETER STORE: initialize parameters here to make them REACTIVE!!
//
const INIT_STATE = {
  serial_version: CONFIG.SERIAL_VERSION, // also store app version in local storage in case future upgrades need to be handled

  dark_mode: false, // reversed colors for easy reading at night

  // *** Drawers (=side panels on all screens)
  __right_drawer: true,
  __left_drawer: true,
  __left_mini_drawer: false,

  // toggle in Story / Article editor pane
  editor_zoomed: false,

  // *** Info-Articles see @/plugins/components/infoButton.vue
  rand_slug_tips_unseen: [], // info slugs for random tips
  info_tips_seen: [], // info slugs user clicked away to not see again
  hide_alert_infos: '', // string with comma sep. slugs user dismissed: 'task-loader,camp-intro'

  activities_hide_below_urgency: 0, // to show all activities if urgency level >0
  activities_hide_comments: false, // to show all activities or only the message-activities
  activities_hide_others: false // to show all activities not of your own,

}

export const state = () => (Object.assign({}, state, INIT_STATE))

const getters = {
  //
  // GET example: store.getters['_app_settings/setting']('currency')
  //
  setting: state => (param) => {
    // Log.i(`Getters param ${param}= ${state[param]}`)
    return state[param]
  },
  get: state => (param) => {
    return state[param]
  }
}

const mutations = {
  //
  // SET: note the *array*: this.$store.commit('_app_settings/set', ['location', false ])
  //
  set (state, payload) {
    const PAR = payload[0]
    const VALUE = payload[1]
    state[PAR] = VALUE
    // do not log mutations to internal fields such as '__collection' or '__fetchIsOngoing'
    if (!PAR.includes('__')) {
      Log.i(`[S] app.${PAR}= ${state[PAR]}`)
    }
  },
  setVal (state, { key, value }) {
    state[key] = value
  },
  // this.$store.commit('data/initialize')
  initialize (state) {
    Object.keys(INIT_STATE).forEach((key) => { state[key] = INIT_STATE[key] })
    // also clear the browser storage
    localStorage.clear()
    Log.w('All app state settings reset to default.')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
  // actions
}
