<!-- show icon with tooltip to copy value from content to the clipboard

Example:

   <c-clipboard-copy small :copy-value="integrations.key" />

   <c-clipboard-copy
    text
    small
    :copy-value="inviteLink"
    :label="$t('achievers.invite')"
    class="overline primary--text"
    color="primary"
  />

 -->

<template>
  <span>
    <c-tooltip :disabled="!tooltip">
      <template #act>
        <span v-if="label" class="pt-1">{{ label }}</span>

        <v-btn
          v-clipboard:copy="copyValue"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
          :disabled="disabled"
          :icon="!details"
          :text="details"
          :color="color"
          :small="small"
          dense
          class="ml-n2"
        >
          <v-icon :small="small" :color="color">mdi-clipboard-arrow-up-outline</v-icon>
          <span v-if="details" class="ml-2">{{ $t('def.clipboard.copy.label') }}</span>
        </v-btn>
      </template>
      {{ tooltip }}
    </c-tooltip>

    <v-snackbar v-model="snackbarOk" :timeout="4000" top absolute>
      {{ $t('def.clipboard.copy.ok') }}
      <v-btn color="secondary" text class="float-right" @click="snackbarOk = false">{{ $t('def.close') }}</v-btn>
    </v-snackbar>

    <v-snackbar v-model="snackbarError" :timeout="4000" color="warning" top absolute>
      {{ $t('def.clipboard.copy.error') }}
      <v-btn color="white" text class="float-right" @click="snackbarError = false">{{ $t('def.close') }}</v-btn>
    </v-snackbar>
  </span>
</template>

<script>
export default {
  props: {
    copyValue: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    tooltip: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'grey'
    },

    // show 'copy to clipboard'
    details: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      snackbarOk: false,
      snackbarError: false
    }
  },
  methods: {
    onCopy (e) {
      this.snackbarOk = true
    },
    onError (e) {
      this.snackbarError = true
    }
  }

}
</script>
