<!-- show a circle with 3 stages

Events:

Example:
  <stage-progress
    :plan-percentage="stageCompletion[stage].plan"
    :done-percentage="stageCompletion[stage].done"
    :ongoing-percentage="stageCompletion[stage].ongoing"
    :selected="stageId===stage"
    large
    dark
  />
-->

<template>
  <div
    :class="{'semi-transparent': hover }"
    :style="`margin-top: -${nudgeUp}px; height: ${height}px; width: ${height}px; position: relative;`"
    @dragover.prevent="hover = droppable "
    @dragleave.prevent="hover = false"
    @drop.prevent="hover = false; $emit('drop',$event)"
  >
    <!-- background if no tasks at all in this stage -->
    <!-- final color as, when ongoing or plan, background will become plan color anyway -->
    <div
      class="rounded-circle"
      :class="(totalNr>0 && OF===0 && PF === 0) ? taskStatus.fin.color :'background-2'"
      :style="`position: absolute; height: ${iconSize+2*border}px; width: ${iconSize+2*border}px;  `"
    />

    <!-- ongoing; plan == background: class with border radius -->
    <v-progress-circular
      v-if="OF>0 || PF > 0"
      :rotate="270 + (360 * PF)"
      :color="taskStatus.ong.color"
      :size="iconSize"
      :width="progressWidth"
      :value="OF * 100"
      class="rounded-circle"
      :class="taskStatus.pln.color"
      :style="`position: absolute;  margin-top: ${border}px; margin-left: ${border}px;`"
    />

    <!-- done (class will hide the underlay, see below in style -->
    <v-progress-circular
      class="progress-done rounded-circle"
      :rotate="270 + (360 * (PF + OF))"
      :color="taskStatus.fin.color"
      :size="iconSize"
      :width="progressWidth"
      :value="FF * 100"
      :style="`position: absolute; margin-top: ${border}px; margin-left: ${border}px; border: 1px solid #888;`"
    >
      <template v-if="totalNr>0">
        <span v-if="!smallLabel" class="background--text body-2 ">{{ totalNr }}</span>
        <!-- <span v-else class="foreground--text caption">{{ totalNr }}</span> -->
      </template>
    </v-progress-circular>

  </div>
</template>

<script>

export default {
  props: {
    nrPlan: {
      type: Number,
      default: 0
    },
    nrOngoing: {
      type: Number,
      default: 0
    },
    nrFinished: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    },
    selected: {
      type: Boolean,
      default: false
    },
    droppable: {
      type: Boolean,
      default: false
    }

  },
  data (vm) {
    return {
      hover: false,
      border: 1,
      taskStatus: vm.$config.TASKS.STATUS
    }
  },
  computed: {
    smallLabel () {
      return this.height <= 24
    },
    progressWidth () {
      return this.iconSize / 2 - (this.smallLabel ? 0 : 9) // 6
    },
    totalNr () {
      return this.nrPlan + this.nrOngoing + this.nrFinished
    },
    PF () {
      return this.totalNr !== 0 ? (this.nrPlan / this.totalNr) : 0
    },
    OF () {
      return this.totalNr !== 0 ? (this.nrOngoing / this.totalNr) : 0
    },
    FF () {
      return this.totalNr !== 0 ? (this.nrFinished / this.totalNr) : 0
    },
    iconSize () {
      return parseInt(this.height * (this.selected ? 1.35 : 1.0))
    },
    nudgeUp () {
      // to reposition the large version in center line
      return this.selected ? (this.height / 4) : 0
    }
  }
}
</script>

<style lang="scss" >
// do not show the transparent underlay
.progress-done {
  .v-progress-circular__underlay {
    display: none;
  }
}
</style>
