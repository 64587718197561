/* Assets */

import { initialStateProperties } from '@/plugins/vuex-injects/vuex'

// import Config from '@/config/constants' // NOTE >> ******* : .env parameters are not valid here yet !!!!

import Log from '@/lib/log' // eslint-disable-line no-unused-vars

export default initialStateProperties({
  service: 'snippets',
  channelSubscription: true, // see plugins/feathers/feathers-client.js

  defaultQuery: { accountId: true },

  initData: {
    accountId: '', // { type: String, ref: 'accounts', required: true, maxlength: 20, index: 1 },

    // general asset information
    title: '', //  { type: String, maxlength: 100 },
    textSnippet: '', // { type: String, maxlength: 10000 },
    officiallyEndorsed: false, // { type: Boolean, default: false },
    tags: [] // [{ type: String, maxlength: 50, index: 1 }]
  },

  populatedData: {
    // @TODO
    // tasks: [{}]
  },

  // collectionFields: [...Object.keys(initData),...], // all

  afterCommitHook: (state, item) => {
    // some fields that will ease search and filter
  }
})
