<template>
  <div>
    <div class="d-flex px-4 align-center background-1">
      <v-spacer />
      <!-- <span class="body-2 info--text mr-4">{{ $t('activities.label') }} {{ $t('def.filter') }}:</span> -->

      <template v-if="showFilter">
        <c-icon-btn
          :icon="urgencyIcon"
          :color="$app_activitiesHideBelowUrgency > 0 ? 'primary' :'grey'"
          i18n-tooltip-text="activities.filter.urgent_only"
          :i18n-tooltip-vars="{ l : $app_activitiesHideBelowUrgency +1 }"
          @click="toggleUrgency()"
        />
        <c-icon-btn
          icon="account-multiple"
          :color="$app_activitiesOnlyOthers? 'primary' :'grey'"
          :i18n-tooltip-text="`activities.filter.${$app_activitiesOnlyOthers ? 'all_members' :'others_only'}`"
          @click="$app_activitiesOnlyOthers=!$app_activitiesOnlyOthers"
        />
        <c-icon-btn
          icon="comment-check-outline"
          :color="$app_activitiesHideComments? 'primary' :'grey'"
          :i18n-tooltip-text="`activities.filter.${$app_activitiesHideComments ? 'including_messages': 'messages_only'}`"
          @click="$app_activitiesHideComments=!$app_activitiesHideComments"
        />
      </template>

      <c-icon-btn
        icon="filter-outline"
        class="ml-0 ml-md-1"
        :color="showFilter ? 'grey': 'primary'"
        small
        i18n-tooltip-text="table.tooltip.filter"
        @click="showFilter=!showFilter;"
      />
    </div>

    <v-sheet height="1" color="primary" />

    <v-list dense tile>
      <template v-if="items && items.length ===0">
        <p class="body-2 mt-4 ml-4 font-weight-medium">{{ $t('activities.filter.no_found') }}</p>

        <p v-if="filterActive" class="body-2 ml-4">
          {{ $t('activities.filter.try_reset') }}
          <a href="#" @click="resetFilter()">
            {{ $t('def.reset') }} {{ $t('def.filter') }}
          </a>
        </p>
      </template>

      <v-list-item-group v-else class="grey mt-n2">
        <template v-for="(item, index) in items">
          <v-list-item
            v-if="editCommentId !== item._id"
            :key="index"
            class="background"
            style="margin-top: 1px;"
            :class="{'darken-1': item.message && !item.seenByUserIds.includes(userId)}"
            @mouseover="setSeenBy(item)"
          >
            <v-list-item-avatar>
              <avatar v-if="item.user" :user="item.user" :size="35" />
            </v-list-item-avatar>

            <v-list-item-content :class="{'grey--text text--darken-2': item.seenByUserIds.includes(userId)}">
              <c-tooltip left :open-delay="500" :max-width="800" :min-width="400" :disabled="!item.user">
                <!-- the list content -->
                <template #act>
                  <div class="two-lines">
                    <span class="body-2 font-weight-medium">{{ item.user.displayName }}</span>
                    <span class="body-2" :class="{ 'foreground-2--text cursor-default': !item.itemId, 'primary--text': item.itemId && item.service }" @click="goTo(item)">{{ actionTextList(item).join('; ') }} </span>
                    <span class="caption primary--text">{{ item.message }}</span>
                  </div>
                </template>

                <!-- the hover-tooltip with details of the activity-->
                <div class="grey darken-4 mx-n3 pa-4">
                  <span v-if="item.user" class="subtitle-2 font-weight-bold"> {{ item.user.displayName }}:</span>
                  <ul>
                    <li v-for="(act, i) in actionTextList(item)" :key="i">{{ act }}</li>
                  </ul>

                  <blockquote v-if="item.message" class="caption">
                    {{ item.message }}
                  </blockquote>

                  <div class="mt-2 caption">
                    {{ item.itemId }}
                  </div>
                </div>
              </c-tooltip>

              <v-list-item-action-text class="d-flex align-center justify-end">
                <c-date :date="item.createdAt" />
                <template v-if="item.seenByUserIds && item.seenByUserIds.length > 1">
                  <v-icon small color="primary" class="ml-1">mdi-eye-outline</v-icon>
                  <span class="primary--text ml-1">{{ item.seenByUserIds.length }}</span>
                </template>
                <v-spacer />

                <!-- Button to edit a comment; by own user only -->
                <c-tooltip v-if="item.message && item.userId === $db.currentUserId" top>
                  <template #act>
                    <v-icon small color="primary" @click.stop="editCommentId = item._id">mdi-pencil</v-icon>
                  </template>
                  {{ $t('def.edit') }} {{ $t('def.comment') }}
                </c-tooltip>

                <!-- Delete a comment; by own user only -->
                <c-tooltip v-if="item.message && item.userId === $db.currentUserId" top>
                  <template #act>
                    <v-icon small color="primary" class="ml-2" @click.stop="removeActivity(item._id)">mdi-delete-outline</v-icon>
                  </template>
                  {{ $t('def.delete') }} {{ $t('def.comment') }}
                </c-tooltip>
              </v-list-item-action-text>
            </v-list-item-content>

            <!-- // the urgency icon -->
            <v-list-item-icon v-if="$config.ACTIVITIES.urgency[item.urgency]" class="ml-0 mr-n2">
              <v-icon small :color="$config.ACTIVITIES.urgency[item.urgency].color">
                mdi-{{ $config.ACTIVITIES.urgency[item.urgency].icon }}
              </v-icon>
            </v-list-item-icon>
          </v-list-item>

          <!-- edit the message when selected -->
          <activity-message
            v-else
            :key="index"
            :item-id="editCommentId"
            :service="service"
            :current-message="item.message"
            class="pa-2"
            @sent="editCommentId=null"
          />
        </template>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import Log from '@/lib/log'

import Avatar from '@/components/users/profile/Avatar'

import ActivityMessage from '@/components/activities/form/ActivityMessage'
// import ListUsers from '@/components/users/form/ListUsers'

export default {
  components: {
    Avatar,
    ActivityMessage
    // ListUsers
  },
  props: {
    itemId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      editCommentId: null,
      showFilter: false
    }
  },
  computed: {
    userId () {
      return this.$db.doc('users')._id
    },
    itemIdQuery () {
      return this.itemId ? { itemId: this.itemId } : {}
    },
    itemUrgentQuery () {
      return this.$app_activitiesHideBelowUrgency > 0 ? { urgency: { $gt: this.$app_activitiesHideBelowUrgency } } : {}
    },
    itemCommentQuery () {
      // @NOTE:: * whitelist: ['$exists'] * in feathers: https://docs.feathersjs.com/api/databases/common.html#new-service-options
      return this.$app_activitiesHideComments ? { message: { $exists: true } } : {}
    },
    itemOthersQuery () {
      return this.$app_activitiesOnlyOthers ? { userId: { $ne: this.$db.currentUserId } } : {}
    },
    items () {
      return this.$db.collection('activities', { $sort: { updatedAt: -1 } }) // sort needed to show new arrivals from other subscribed clients
    },
    filterActive () {
      return this.$app_activitiesHideBelowUrgency > 0 || this.$app_activitiesHideComments || this.$app_activitiesOnlyOthers
    },
    urgencyIcon () {
      const icon = this.$config.ACTIVITIES.urgency[this.$app_activitiesHideBelowUrgency + 1]
        ? this.$config.ACTIVITIES.urgency[this.$app_activitiesHideBelowUrgency + 1].icon || ''
        : ''
      return icon || 'alert-circle-check-outline'
    }

  },
  watch: {
    async itemId () {
      await this.loadItems()
    },
    async $app_activitiesHideComments () {
      await this.loadItems()
    },
    async $app_activitiesHideBelowUrgency () {
      await this.loadItems()
    },
    async $app_activitiesOnlyOthers () {
      await this.loadItems()
    }
  },

  // activities are loaded by plugins/feathers/activate-current-account.js
  async mounted () {
    await this.$app_storeLoaded
    this.loadItems()
  },

  methods: {
    async loadItems () {
      await this.$db.find('activities', {
        ...this.itemIdQuery,
        ...this.itemCommentQuery,
        ...this.itemUrgentQuery,
        ...this.itemOthersQuery,
        accountId: this.$db.currentAccountId
      })
    },
    resetFilter () {
      this.$app_activitiesHideBelowUrgency = 0
      this.$app_activitiesHideComments = false
      this.$app_activitiesOnlyOthers = false
    },
    toggleUrgency () {
      this.$app_activitiesHideBelowUrgency = this.$app_activitiesHideBelowUrgency > 2 ? 0 : this.$app_activitiesHideBelowUrgency += 1

      Log.w('test', this.urgencyIcon)
    },
    // find service label and upper case the first character
    upCase (service) {
      const lbl = this.$i18n.te(`${service}.field.label`) ? this.$i18n.t(`${service}.field.label`) : service
      return lbl ? lbl[0].toUpperCase() + lbl.slice(1) : ''
    },
    // create the text string to show in the list
    actionTextList (item) {
      const acts = []
      item.actions.forEach((act) => {
        acts.push(this.$i18n.t(`activities.act.${act}`, {
          m: item.method,
          s: item.service,
          u: this.upCase(item.service)
        }))
      })
      return acts
    },
    // set if user hovers the message-type list item
    async setSeenBy (item) {
      if (item.message && !item.seenByUserIds.includes(this.userId)) {
        await this.$db.patch('activities', item._id, { seenByUserIds: [...item.seenByUserIds, this.userId] })
        item.seenByUserIds.push(this.userId)
      }
    },
    // delete the item
    async removeActivity (id) {
      await this.$db.remove('activities', id)
    },
    goTo (item) {
      if (item.service && item.itemId) {
        this.$router.push(`/${item.service}/${item.itemId}`)
      }
    }
  }
}
</script>
