// ** MIDDLEWARE LAYER
//
// injected as inject as $signal in global context
//
// @/store/_signals.js contains the vuex state fields

// import Log from '@/lib/log'

export default ({ app, store, $log }, inject) => {
  const signalBus = {

    // ** user is logged in. Example: if ( this.$signal.isLoggedIn ) {...}
    get isLoggedIn () { return store.getters['_signals/get']('isLoggedIn') },
    set isLoggedIn (value) { store.commit('_signals/set', { isLoggedIn: value }) },

    // visibility changes when browser tab is reopened, see default.vue
    get visibility () { return store.getters['_signals/get']('visibility') },
    set visibility (value) { store.commit('_signals/set', { visibility: value }) },

    // ** Add widgets with this id from WidgetDrawer
    get widgetInsert () { return store.getters['_signals/get']('widget_insert') },
    set widgetInsert (value) { store.commit('_signals/set', { widget_insert: value }) },

    // ** Report status to user with ongoing bar in top
    // Handled by @/components/_default/topbar/ApiStatusBar.vue
    // example for the api backend / server status
    //   this.$signal.apiActionBar = { status: 'loading' }
    //   this.$signal.apiActionBar = { status: 'success' }
    //   this.$signal.apiActionBar = { status: 'error', code: 404, message: 'There are troubles' }
    //   this.$signal.apiActionBar = { status: 'error', code, message }
    //
    //   currentAction = this.$signal.apiActionBar()
    //
    get apiActionBar () { return store.getters['_signals/get']('apiAction') },
    set apiActionBar (value) { store.commit('_signals/set', { apiAction: value }) },

    // ** The right drawer open/closed state of each notification panel in the right drawer
    // Note: To open complete right drawer use $app_rightDrawer = true
    //
    // Examples:
    //   to open a panel this.$signal.openDrawerPanel(panel)
    //   to check if a panel is open:  this.$signal.isOpenDrawerPanel(myPanel)
    //   this.$signal.toggleDrawerPanel(this.panel)
    //
    // Notes:
    // - the opening of the whole drawer is done by $app_rightDrawer (see also ~/plugins/vuex-persist)
    // - $config.DRAWER_PANELS.showOnRoute sets the pages where panels open
    //
    drawerPanels () {
      return store.getters['_signals/get']('open_drawer_panels') || []
    },
    openDrawerPanel (panel) {
      // store.commit('_signals/set', {
      //   open_drawer_panels: Array.from(new Set([...this.drawerPanels(), panel]))
      // })

      // Changed to: one right panel open at a time
      store.commit('_signals/set', { open_drawer_panels: [panel] })
    },
    closeDrawerPanel (panel) {
      store.commit('_signals/set', {
        open_drawer_panels: this.drawerPanels().filter(p => p !== panel)
      })
    },
    // this.$signal.closeAllDrawerPanels()
    closeAllDrawerPanels () {
      store.commit('_signals/set', { open_drawer_panels: [] })
    },
    toggleDrawerPanel (panel) {
      if (this.isOpenDrawerPanel(panel)) {
        this.closeDrawerPanel(panel)
      } else {
        this.openDrawerPanel(panel)
      }
    },
    isOpenDrawerPanel (panel) {
      return store.getters['_signals/get']('open_drawer_panels').includes(panel)
    },

    // Watched by the active Editor component to insert new elements at cursor position
    //   Note: you can watch for changes:
    //    watch: {
    //     '$signal.insertEditor' ({ type, content }) {
    //       Log.i(`Insert ${type} ${content}`)
    //     })
    //    }
    get insertEditor () { return store.getters['_signals/get']('editor_insert') },
    set insertEditor (value) { store.commit('_signals/set', { editor_insert: value }) },

    // Insert new tasks from a pipeline template, right drawer into pipeline
    get pipelineTemplateId () { return store.getters['_signals/get']('insert_pipeline_template_id') },
    set pipelineTemplateId (value) { store.commit('_signals/set', { insert_pipeline_template_id: value }) },

    // ** $signal.timeTrackerRunning   Time Tracker: running or not; DO NOT USE the set
    get timeTrackerRunning () { return !!(this.timeTrackerStartAt && this.timeTrackerStartAt !== null) },
    set timeTrackerRunning(value) { }, // eslint-disable-line 

    // ** activated Time Tracker
    get timeTrackerStartAt () { return store.getters['_signals/get']('timeTrackerStartAt') },
    set timeTrackerStartAt (value) { store.commit('_signals/set', { timeTrackerStartAt: value }) },

    // in seconds: pause counter; time
    get timeTrackerPauseAt () { return store.getters['_signals/get']('timeTrackerPauseAt') },
    set timeTrackerPauseAt (value) { store.commit('_signals/set', { timeTrackerPauseAt: value }) },

    get timeTrackerData () { return store.getters['_signals/get']('timeTrackerData') },
    set timeTrackerData (value) { store.commit('_signals/set', { timeTrackerData: value }) }

  }

  // inject as $signal in global context
  inject('signal', signalBus)
}
