<template>
  <indicator-icon
    icon="folder-multiple-image"
    panel="repository"
    flash
  />
</template>

<script>
import IndicatorIcon from '@/components/_default/topbar/IndicatorIcon.vue'

export default {
  name: 'IndicatorIconRepository',
  components: {
    IndicatorIcon
  }
}
</script>
