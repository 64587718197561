<!-- ** Show a gallery grid with all media from filtered assets.

Popup dialog with Asset Gallery to un/select assets

Events:
  @select contains the selected asset-object

-->

<template>
  <div>
    <list-assets
      v-if="listAssets"
      v-model="selectedIds"
      :width="80"
      :height="100"
    />

    <div class="d-flex justify-end">
      <v-btn small :icon="!label" color="primary" text @click.stop="dialog=true">
        <v-icon v-if="!label" color="primary" small>mdi-pencil</v-icon>
        {{ label }}
      </v-btn>
    </div>

    <v-dialog v-model="dialog" scrollable max-width="770">
      <v-card>
        <v-card-title>
          <v-system-bar absolute window color="secondary" class="white--text" style="cursor: pointer;" @click.stop="dialog=false;">
            <v-btn icon><v-icon color="white">mdi-arrow-left</v-icon></v-btn>
            <v-spacer />
            <span class="caption">{{ $t('assets.select.label') }}</span>
            <v-spacer />
            <v-btn icon class="mr-n2"><v-icon color="white">mdi-close</v-icon></v-btn>
          </v-system-bar>
        </v-card-title>

        <v-card-text style="height: 700px;">
          <asset-table
            v-model="selectedIds"
            selectable
            :select-multiple="multiple"
            :max-height="200"
            show-gallery
            small
            @select="selectItem"
          />
        </v-card-text>

        <v-card-actions>
          <v-btn small color="primary" text @click.stop="selectedIds=[]">
            {{ $t('assets.select.none') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import ListAssets from '@/components/assets/form/ListAssets'
import AssetTable from '@/components/assets/table/AssetTable'

export default {
  components: {
    ListAssets,
    AssetTable
  },
  props: {
    // select option: _id or Array
    value: {
      type: [Array, String],
      default: undefined // set by computed below
    },
    // if multiple, value should be Array, if not, String
    multiple: {
      type: Boolean,
      default: false
    },
    hideAfterSelect: {
      type: Boolean,
      default: false
    },
    //  preview a list of Assets
    listAssets: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: '' // example: 'select image'
    }

  },
  data () {
    return {
      isActive: false,
      dialog: false
    }
  },
  computed: {
    selectedIds: {
      get () { return !this.value ? (this.multiple ? [] : '') : this.value },
      set (value) { this.$emit('input', value) }
    },
    items () {
      // return this.$db.collection('assets')
      return this.$db.collection('assets', { _id: this.selected })
    }
  },
  async mounted () {
    await this.$db.find('assets', { accountId: this.$db.currentAccountId, $cache: true })
    if (this.multiple && !Array.isArray(this.selectedIds)) {
      this.selectedIds = [this.selectedIds]
    }
  },
  methods: {
    selectItem (ev) {
      this.$emit('select', ev)
      if (this.hideAfterSelect) {
        this.dialog = false
      }
    }
  }
}
</script>
