<!-- ** Show file input with drop field and uploader **

 Events:
  v-model: dialog show/hide
  @onLoaded - files loaded and ready to process
  @onFinished - user clicked ok

 Example:
   <file-upload-dialog
      v-model="dialog"
      accept="image/*"
      :max-nr-files="6"
      :max-file-size="$config.ASSETS.MAX_FILE_SIZE"
      :upload-data="{ accountId: $db.doc('users').currentAccountId }"
      hide-after-upload
      @onLoaded="filesUploaded"
      @onFinished="uploadFileDialog=false"
    />

    <c-file-drop-input v-model="myFiles" drop-label="drop here"  />

  Note: Always requires value / v-model with an *Array*, also when not multiple!

-->

<template>
  <div>
    <v-dialog v-model="dialog" width="700" scrollable>
      <v-card class="pa-0" tile>
        <v-system-bar window lights color="info" class="white--text" style="cursor: pointer;" @click.stop="dialog=false;">
          <v-btn icon><v-icon color="white">mdi-arrow-left</v-icon></v-btn>
          <v-spacer />
          <span class="caption">{{ $t('assets.action.upload.label') }} {{ $config.ASSETS.MIME }}</span>
          <v-spacer />
          <v-btn icon class="mr-n2"><v-icon color="white">mdi-close</v-icon></v-btn>
        </v-system-bar>

        <file-upload
          :drop-label="dropLabel"
          :accept-mime="acceptMime"
          :service="service"
          :item-id="itemId"
          :max-nr-files="maxNrFiles"
          :max-file-size="maxFileSize"
          :upload-data="uploadData"
          :drop-zone-height="dropZoneHeight"
          :show-edit-item="showEditItem"
          @editItem="$emit('editItem', $event)"
          @newItem="$emit('newItem', $event)"
          @onLoaded="$emit('onLoaded', $event)"
          @onFinished="uploadFinished"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import Log from '@/lib/log'

import FileUpload from '@/components/_lib/form/file/FileUpload'

export default {
  components: {
    FileUpload
  },

  props: {
    // show hide dialog
    value: {
      type: Boolean,
      default: false
    },
    // pass the asset item if you wan to push the uploaded files into this item. Note: maxNrFiles must be 1
    itemId: {
      type: String,
      default: ''
    },

    dropLabel: {
      type: String,
      default: ''
    },
    service: {
      type: String,
      default: 'assets' // 'assets', 'users'
    },
    // file acceptance
    acceptMime: {
      type: String,
      default: 'image/*' // "image/*", ""image/png, image/jpeg", ...
    },
    maxNrFiles: {
      type: Number,
      default: 1
    },
    maxFileSize: {
      type: Number,
      default: 12 * 1024 * 1024 // 12 MB
    },
    // additional upload data, example: { accountId: $db.doc('users').currentAccountId }
    uploadData: {
      type: Object,
      default: () => {}
    },

    // grid / flex
    dropZoneHeight: {
      type: Number,
      default: 200
    },
    // dialog hide
    hideAfterUpload: {
      type: Boolean,
      default: false
    },
    // show a pencil to directly edit after upload
    showEditItem: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    dialog: {
      get () { return this.value },
      set (value) { this.$emit('input', value) }
    }
  },
  methods: {
    uploadFinished (event) {
      this.$emit('onFinished', event)
      if (this.hideAfterUpload) {
        this.dialog = false
      }
    }
  }
}
</script>
