<!-- ** Show file input with drop field and uploader **

 Uploads one or more data objects with { uri, fileName } to a service

 Note: Except for the users service, when creating a new item, do not forget to include upload-data: { accountId }

 Events:
  @newItem - fired for each uploaded file, returning the the patched/ created asset data
  @editItem - to edit a certain item (pencil clicked from direct upload) after the uploaded list appears
  @onLoaded - emits an array with the files loaded and ready to process ( possible that conversion is still happening on the server)
  @onFinished - user clicked ok. Also emits the first uploaded file response

 Examples:
    <file-upload service="users" :max-nr-files="1" drop-label="drop avatar picture here"  />

    <file-upload
      v-model="myFiles"
      service="assets"
      accept-mime="image/*"
      :max-nr-files="10"
      :max-file-size="10 * 1024 * 1024"  or "$config.ASSETS.MAX_FILE_SIZE"
      :drop-zone-height="200"
      show-edit-item
      :upload-data="{ accountId: $db.doc('users').currentAccountId }"
      @editItem="editItem"
      @onLoaded="filesUploaded"
      @onFinished="dialog=false"
    />

-->

<template>
  <v-card
    class="pa-0 background"
    :class="(cols ? `col col-${cols}`: '') + (md ? ` col-md-${md}`: '') + (sm ? ` col-sm-${sm}`: '')"
    tile
  >
    <div class="d-flex flex-column align-center justify-center pa-1">
      <template v-if="!uploadRunning">
        <div :class="dropZoneOpen ? 'info lighten-3' : 'background darken-1'" :style="`min-height: ${dropZoneHeight}px; width: 100%;`">
          <input
            :style="`min-height: ${dropZoneHeight}px; width: 100%;`"
            type="file"
            multiple
            :accept="acceptMime"
            style="height: 100%; width: 100%; opacity: 0; position: relative; z-index: 5;"
            @dragover="dropZoneOpen=true;"
            @dragleave="dropZoneOpen=false"
            @dragend="dropZoneOpen=false"
            @drop="onDrop($event)"
            @change="onChange($event)"
          >
        </div>

        <div style="position: absolute;" class="mt-n10">
          <div class="d-flex">
            <v-icon x-large color="info" class="mx-auto">mdi-cloud-upload-outline</v-icon>
          </div>

          <div class="d-flex py-3 px-10 ">
            <span class="mx-auto">{{ dropLabel || $tc('form.file_drop_input.drop_here', maxNrFiles, { n: maxNrFiles }) }}</span>
          </div>

          <div v-if="dropErrorMsg" class="d-flex caption error--text align-self-end">
            <span class="mx-auto">{{ dropErrorMsg }}</span>
          </div>
        </div>

        <v-file-input
          v-model="files"
          show-size
          :placeholder="maxHint"
          hide-details="auto"
          :accept="acceptMime"
          multiple
          small-chips
          outlined
          :error-messages="fileTooLarge ? $t('form.too_large', { m: Math.floor(maxFileSize / 1024) }) :''"
          class="mt-2 cursor-pointer"
          @focus="dropErrorMsg=false"
        />

        <!-- actual upload button -->
        <div class="d-flex justify-center pt-2 pb-4">
          <v-btn color="secondary" :disabled="files.length<=0 || fileTooLarge" @click="uploadFiles (files)">
            {{ $t('def.upload') }}
            <c-bytes-to-size :bytes="totalFileSize" />
          </v-btn>
        </div>
      </template>

      <!-- Uploading pane -->

      <template v-else>  <!-- uploadRunning -->
        <v-simple-table dense class="mt-2">
          <template v-slot:default>
            <thead>
              <tr>
                <th :colspan="showEditItem ? 3:2">
                  <span v-if="nrFilesToLoad>0" class="info--text caption">{{ $t('def.uploading') }}… </span>
                  <span v-else-if="error" class="error--text overline">{{ $t('def.upload') }} {{ $t('def.error') }}</span>
                  <span v-else class="success--text overline">{{ $t('def.upload') }} {{ $t('def.success') }}</span>
                </th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(p, index) in uploaded" :key="index" two-line class="cols-12 col-md-6 offset-md-3 " dense>
                <td class="grey--text text--darken-3">
                  {{ p.name }}
                </td>

                <td :width="300">
                  <v-progress-linear
                    :value="p.loaded/p.total*100"
                    :striped="p.ongoing"
                    :color="p.error ? 'error' : ( p.ongoing ? 'info lighten-2': ( p.finished ? 'success': 'grey'))"
                    class="mb-1"
                  />
                </td>

                <td v-if="showEditItem" :width="30">
                  <v-btn icon :disabled="!p.response" @click="$emit('editItem', p.response)">
                    <v-icon :color="p.response ? 'primary' :''" small>mdi-pencil</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <v-btn
          :disabled="nrFilesToLoad>0"
          color="success"
          class="mt-4 mb-2"
          @click="resetUpload()"
        >{{ $t('def.ok') }}</v-btn>

      </template>
    </div>
  </v-card>
</template>

<script>
import Log from '@/lib/log'

function matchRuleShort (str, rule) {
  const escapeRegex = str => str.replace(/([.*+?^=!:${}()|[\]/\\])/g, '\\$1')
  return new RegExp('^' + rule.split('*').map(escapeRegex).join('.*') + '$').test(str)
}

export default {
  props: {
    service: {
      type: String,
      default: 'assets' // 'users', 'assets', ... backend should have hook to catch
    },
    dropLabel: {
      type: String,
      default: ''
    },
    // file acceptance
    acceptMime: {
      type: String,
      default: 'image/*' // "image/*", ""image/png, image/jpeg", ...
    },
    // set only if not patching the current item, but when creating new ones
    maxNrFiles: {
      type: Number,
      default: 1
    },
    maxFileSize: {
      type: Number,
      default: 12 * 1024 * 1024 // 12 MB
    },
    // show a pencil to directly edit after uplaod
    showEditItem: {
      type: Boolean,
      default: false
    },

    // pass the asset item if you wan to push the uploaded files into this item. Note: maxNrFiles must be 1
    itemId: {
      type: String,
      default: ''
    },
    // additional upload data fields, example: { accountId: $db.doc('users').currentAccountId' }
    uploadData: {
      type: Object,
      default: () => {}
    },

    // grid / flex
    dropZoneHeight: {
      type: Number,
      default: 200
    },
    cols: {
      type: String,
      default: ''
    },
    md: {
      type: String,
      default: ''
    },
    sm: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      files: [], // [ {name: 'doggy.jpg', size: '712345', type: 'image/jpeg', lastModifiedDate: ''}, {},..]
      dropZoneOpen: false,
      dropErrorMsg: false,
      uploadRunning: false,
      uploaded: [],
      nrFilesToLoad: 0,
      error: false,
      // response: {},
      editDialog: false,
      editIndex: -1
    }
  },
  computed: {
    maxHint () {
      return this.$i18n.tc('form.file_drop_input.max_hint', this.maxNrFiles, { n: this.maxNrFiles })
    },
    fileTooLarge () {
      return this.files.findIndex((file) => { return file.size > this.maxFileSize }) > -1
    },
    totalFileSize () {
      const fileSize = (this.files && this.files.length > 0) ? this.files.reduce((totalSize, file) => { return totalSize + file.size }, 0) : 0
      return fileSize
    },
    currentItem: {
      get () { return this.editIndex >= 0 ? this.uploaded[this.editIndex].response : {} },
      set (value) { this.uploaded[this.editIndex].response = value }
    }
  },

  methods: {
    onChange (evt) {
      const files = Array.from(evt.target.files).slice(0, this.maxNrFiles)
      this.loadFiles(files)
    },
    onDrop (evt) {
      const files = Array.from(evt.dataTransfer.files).slice(0, this.maxNrFiles)
      this.loadFiles(files)
    },
    loadFiles (files) {
      const filesNotAccepted = files.find(file => !matchRuleShort(file.type, this.acceptMime))
      if (filesNotAccepted) {
        this.dropErrorMsg = this.$i18n.t('error.file_drop.accept', { a: this.acceptMime })
        Log.w(`[file-upload] user dropped ${files.length} files, NOT accepted for "${this.acceptMime}":`, files)
      } else {
        Log.i(`[file-upload] user dropped ${files.length} files, all "${this.acceptMime}" types accepted:`, files)
        this.files = files
      }
      this.dropZoneOpen = false
    },

    async uploadFiles (files) {
      this.uploaded = []
      this.error = false
      this.nrFilesToLoad = files.length
      Log.i(`[file-upload] Upload ${files.length} file(s)`)

      // initialize all the progress bars and show them
      for (let index = 0; index < files.length; index++) {
        this.uploaded[index] = { total: 1, loaded: 0, name: files[index].name }
      }

      this.uploadRunning = true

      // initialize FileReaders, one by one to avoid server timeouts
      for (const [index, file] of files.entries()) {
        Log.i(`  [+] ${index} ${file.name}`)

        this.$api.service(this.service).timeout = (file.size / 1000) + 5000 // 5 seconds + 1 second per MB

        const reader = new FileReader()

        // @NOTE: dirty little trick to abuse FileReader object to store the file index,
        //    so to later get the name & for the uploaded[index] {}
        reader.index = index

        reader.readAsDataURL(file)

        reader.onload = async (event) => {
          const index = event.target.index
          this.uploaded[index].ongoing = true

          const data = {
            fileName: files[index].name || '** unknown_file_name **',
            uri: reader.result,
            ...this.uploadData // additional data we should pass, like accountId on create
          }

          // send file to server and expect an new asset in return
          let response = {}
          if (this.itemId) {
            // patch existing item
            response = (await this.$db.patch(this.service, this.itemId, data)) || { error: true }
          } else {
            // create new item
            response = (await this.$db.create(this.service, data)) || { error: true }
          }
          response = response[0] ? response[0] : response

          // check if we indeed have a new asset
          if (response._id) {
            this.uploaded[index].response = response
            this.$emit('newItem', response)
          } else {
            this.uploaded[index].error = true
            this.error = true
          }

          // store the response to kick the while wait loop
          files[index].response = response
        }

        reader.onprogress = (event) => {
          const index = event.target.index
          const { total, loaded } = event
          // Log.i(index, '..........', total, loaded)
          this.uploaded[index].total = total
          this.uploaded[index].loaded = loaded
        }

        reader.onloadend = (event) => {
          const index = event.target.index
          // Log.i('[file-upload] onloadend ', index)
          this.uploaded[index].finished = true
          this.uploaded[index].ongoing = false
          this.uploaded[index].total = 1
          this.uploaded[index].loaded = 1
        }

        reader.onerror = (event) => {
          const index = event.target.index
          // Log.e('[file-upload] onerror ', index)
          this.error = true
          this.uploaded[index].error = true
          this.$signal.apiActionBar = { message: reader.error }
        }

        // wait for file to load
        await new Promise(function (resolve, reject) {
          (function waitForFileUpload () {
            if (files[index].response) { return resolve() }
            setTimeout(waitForFileUpload, 200)
          })()
        })
        this.nrFilesToLoad -= 1
      }

      // Log.i('###### all uploaded ### ')
      this.$emit('onLoaded', files)
      // await this.$db.find(this.service)
      this.$api.service(this.service).timeout = 5000 // set back to default
    },

    resetUpload () {
      this.uploadRunning = false
      const response = this.files.length > 0 && this.files[0].response
      this.$emit('onFinished', response)
      this.files = []
    }
  }
}
</script>
