/* Tasks */

// import Config from '@/config/constants'

import { initialStateProperties } from '~/plugins/vuex-injects/vuex'

export default initialStateProperties({
  service: 'confirmations',

  channelSubscription: true, // see plugins/feathers/feathers-client.js

  defaultQuery: { $limit: 40, $sort: { updatedAt: -1 } },

  loadCollectionOnStart: true,

  initData: {
    _key: '', // only for admin
    userId: '', // { type: String, ref: 'users', required: true },
    originUrl: '', // { type: String, maxlength: 50 },
    emails: [], // { type: String, required: true, lowercase: true, maxlength: 100 },
    messages: [], // [ { email, templateId, label }],
    expireAt: '',
    timeOutSeconds: 1800,

    execService: '', // { type: String, enum: confirmationServices },
    execItemId: '', // { type: String, ref: confirmationServices.join(',') },
    execMethod: '', // { type: String, enum: [ 'patch', 'create', 'remove', 'update' ] },
    execData: {} // { type: Object, default: () => {} }
  },

  populatedData: {
    user: {}, // '_id', 'displayName', 'email', 'avatarImageSrc', 'avatarPrefix', 'permissions'

    // from hook
    confirmLink: ''
  },

  afterCommitHook: (state, item) => {
    // "confirmLink":"http://localhost:3000/confirm/M_mv8a9kgsrz7an22c3dvovmbf8?_key=woru8"
    item.confirmLink = `${item.originUrl}/confirm/${item._id}?_key=${item._key}`
  }
})
