<template>
  <indicator-icon
    icon="check-box-multiple-outline"
    panel="tasks"
    flash
  />
</template>

<script>
import IndicatorIcon from '@/components/_default/topbar/IndicatorIcon.vue'

export default {
  name: 'IndicatorIconTasks',
  components: {
    IndicatorIcon
  }
}
</script>
