/* Pipelines */

import { initialStateProperties } from '@/plugins/vuex-injects/vuex'

import Log from '@/lib/log' // eslint-disable-line no-unused-vars

const oneYearFromNow = new Date()
oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1)

export default initialStateProperties({
  service: 'pipeline-templates',
  channelSubscription: false, // see plugins/feathers/feathers-client.js

  defaultQuery: { archived: null, template: true, accountId: null }, // remove parameter like archived with null

  initData: {
    accountId: '',
    protected: true, // { type: Boolean, default: true }, // if protected, then can not delete (front end function only)
    archived: false,

    name: '',
    description: '',

    assetIds: [], //  [{ type: String, ref: 'assets' }], // first will be the cover image

    tags: [], // for internal management by user

    workflowIndustries: [], // [{ type: Number, index: true, enum: workflowIndustries.map(i => i.id) }], // [ 104, 105, 106 ]
    workflowTactics: [], // [{ type: Number, index: true, enum: workflowTactics.map(i => i.id) }], // [ 150, 161 ]

    // template = master that users can duplicate the tasks of
    template: false, //  { type: Boolean, default: false }, // will show up in gallery to duplicate set of children-tasks
    public: false, //  { type: Boolean, default: false }, // will show up in gallery of all other users as well; ignoring the accountId filter
    templateOwnerId: '' // { type: String, ref: 'users' }, // only if the pipeline is a template

    // startAt: new Date().toISOString(),
    // endAt: oneYearFromNow.toISOString()
  },

  populatedData: {

    tasks: [],
    templateOwner: {}

  },

  afterCommitHook: (state, item) => {
    item.taskIds = item.tasks.map(i => i._id)
  }
})
