<!-- Show pipeline gallery or table

Slots:
  gallery-card-popup="{ item, gallerySmall }" // dialog appears when clicked on a gallery card and prop: show-gallery-dialog=true
  gallery-card-popup-actions="{ item, gallerySmall }" // the footer in this dialog

 -->

<template>
  <general-table
    v-model="selectedIds"
    :service="service"
    :query="fullQuery"
    :headers="headers"

    :can-delete="(item) => !item.protected"
    :hide-header="hideCampaigns"
    :small="small"
    dialog-wide

    v-bind="$attrs"
    @itemCreated="refreshPipelines"
    @itemRemoved="refreshPipelines"
    @select="$emit('select', $event)"
  >
    <template v-slot:left-toolbar>
      <div v-if="!hideLeftToolbar" class="d-flex flex-wrap align-center">
        <select-archived-query v-model="archivedQuery" />

        <select-scheduled-query v-model="scheduledQuery" class="ml-1 ml-md-4" />

        <select-campaign
          v-if="!hideCampaigns"
          v-model="selectedCampaignId"
          show-icon
          class="ml-4 ml-md-4"
          clear
          dense
          :hide-empty="$app_isMobile"
        />
      </div>
    </template>

    <template v-slot:right-toolbar>
      <c-select
        v-model="industries"
        :title="$t('pipelines.field.industries.label')"
        :placeholder="$t('pipelines.field.industries.select')"
        :items="$db.doc('configuration', null, { $clone: true }).workflowCodes.industries"
        icon="factory"
        :class="{ 'background-2': industries.length>0 }"
        small
        sort
        clearable
      />
      <c-select
        v-model="tactics"
        :title="$t('pipelines.field.tactics.label')"
        :placeholder="$t('pipelines.field.tactics.select')"
        :items="$db.doc('configuration', null, { $clone: true }).workflowCodes.tactics"
        icon="road-variant"
        :class="{ 'background-2': tactics.length>0 }"
        small
        sort
        clearable
      />
    </template>

    <template v-slot:item-table-row="{ item }">
      <gen-td :headers="headers" :item="item" value="status">
        <v-icon v-if="item.template" :color="item.public? 'accent' : 'background-3'" :size="20">
          mdi-{{ $config.SERVICE_ICON['pipelines.templates'] }}
        </v-icon>
        <v-icon v-else :size="20" :color="item.archived ? 'grey':'info'">
          mdi-{{ $config.SELECT_ICON.scheduled[item.status] }}
        </v-icon>
      </gen-td>

      <gen-td :headers="headers" :item="item" value="name">
        <c-link :to="`/tasks?pipelineId=${item._id}`" :class="{'grey--text ':item.archived}">
          <span class="two-lines">{{ item.name }}</span>
        </c-link>
      </gen-td>
      <gen-td :headers="headers" :item="item" value="description" />

      <gen-td v-if="!hideCampaigns" :headers="headers" :item="item" value="campaign">
        <a href="#" class="two-lines" @click.stop="selectedCampaignId = (item.campaign ? item.campaign._id : '')">
          {{ item.campaign ? item.campaign.name : '?' }}
        </a>
      </gen-td>

      <gen-td :headers="headers" :item="item" value="stages">
        <div class="d-flex align-center primary--text">
          <stage-stepper :task-matrix="item.taskMatrix" class="mr-2" />
          <div v-if="!$app_isMobile" class="mr-0 mr-md-4 caption info--text">{{ item.tasks.length }}</div>
        </div>
      </gen-td>

      <gen-td :headers="headers" :item="item" value="storyIds">
        <div v-if="item.storyIds.length>0" class=" mr-sm-1 mr-0 caption grey--text text--darken-2 d-flex">
          <v-icon small class="ml-1">mdi-fountain-pen-tip</v-icon>
          <span>{{ item.storyIds.length }}</span>
        </div>
      </gen-td>

      <gen-td :headers="headers" :item="item" value="tags" />

      <gen-td :headers="headers" :item="item" value="startAt" />
      <gen-td :headers="headers" :item="item" value="endAt" />
    </template>

    <template v-slot:item-expand-pane="{ item }">
      <pipeline-view :item="item" />
    </template>

    <template v-slot:item-edit-form="{ item }">
      <pipeline-edit :item="item" />
    </template>

    <template v-slot:gallery-card="{ item, gallerySmall }">
      <div :class="`${gallerySmall ? 'd-block': 'd-flex'} pa-1`">
        <list-assets
          v-if="item.assetIds && item.assetIds[0]"
          v-model="item.assetIds[0]"
          :width="gallerySmall ? 20 : 35"
          class="mr-1"
        />
        <span class="caption three-lines" style="line-height: 0.9rem;">{{ item.name }}</span>
      </div>

      <v-card-text v-if="!gallerySmall" class="px-2 py-1 background">
        <span class="caption three-lines">{{ item.description }}</span>
      </v-card-text>
    </template>

    <template v-slot:gallery-empty>
      <span class="caption primary--text ">{{ $t('pipelines.label') }}</span>
    </template>

    <template v-slot:gallery-card-popup="{ item, gallerySmall }">
      <slot name="gallery-card-popup" :item="item" :gallerySmall="gallerySmall" />
    </template>

    <template v-slot:gallery-card-popup-actions="{ item, gallerySmall }">
      <slot name="gallery-card-popup-actions" :item="item" :gallerySmall="gallerySmall" />
    </template>
  </general-table>
</template>

<script>
import Log from '@/lib/log' // eslint-disable-line no-unused-vars

import { startDateFunc, endDateFunc } from '@/components/_lib/filters/date-range-classes.js'

import GeneralTable from '@/components/_lib/form/table/GeneralTable'
import GenTd from '@/components/_lib/form/table/GenTd'

import SelectCampaign from '@/components/campaigns/form/SelectCampaign'

import StageStepper from '@/components/tasks/form/progress-status/StageStepper'
import PipelineEdit from '@/components/pipelines/table/PipelineEdit'
import PipelineView from '@/components/pipelines/table/PipelineView'

import SelectArchivedQuery from '@/components/_lib/form/selects/SelectArchivedQuery'
import SelectScheduledQuery from '@/components/_lib/form/selects/SelectScheduledQuery'

import ListAssets from '@/components/assets/form/ListAssets'

const archivedClass = i => i.archived ? ' grey--text ' : ''

export default {
  components: {
    GeneralTable,
    GenTd,
    StageStepper,
    PipelineEdit,
    PipelineView,
    SelectCampaign,
    SelectArchivedQuery,
    SelectScheduledQuery,
    ListAssets
  },
  props: {
    // selected Id(s)
    value: {
      type: [String, Array],
      default: () => []
    },

    service: {
      type: String,
      default: 'pipelines' // 'pipeline-templates'
    },
    query: {
      type: Object,
      default: () => {}
    },
    campaignId: {
      type: String,
      default: ''
    },
    hideLeftToolbar: {
      type: Boolean,
      default: false
    },
    hideCampaigns: {
      type: Boolean,
      default: false
    },

    // open the gallery on first start with small cards
    small: {
      type: Boolean,
      default: false
    }
  },
  data: vm => ({
    headers: [
      { value: 'status', text: '', width: '30', format: 'custom' },
      { value: 'name', text: vm.$i18n.t('pipelines.field.name'), format: 'custom', classFunction: archivedClass },
      // { value: 'description', text: vm.$i18n.t('pipelines.field.description'), responsive: true, format: 'textarea', classFunction: archivedClass },
      // { value: 'campaign', text: vm.$i18n.t('campaigns.label'), responsive: true, format: 'custom', toggleHeader: true }, // toggle-headers prop
      { value: 'campaign', text: vm.$i18n.t('campaigns.field.label'), format: 'custom', toggleHeader: true, responsive: true, classFunction: archivedClass },
      { value: 'stages', text: vm.$i18n.t('pipelines.workflows.label'), width: '120', format: 'custom', to: '/pipeline' },
      { value: 'storyIds', text: '', responsive: true, format: 'custom' }, // toggle-headers prop
      { value: 'tags', text: vm.$i18n.t('def.tags'), align: 'start', format: 'tags' },
      { value: 'startAt', text: 'start at', width: '100', responsive: true, format: 'date', classFunction: startDateFunc },
      { value: 'endAt', text: 'end at', width: '100', responsive: true, format: 'date', classFunction: endDateFunc }
    ],
    selectedCampaignId: '',
    archivedQuery: { archived: false },
    scheduledQuery: {},
    // workflow type filters
    industries: [],
    tactics: []
  }),
  computed: {
    fullQuery () {
      const industriesFilter = this.industries.length > 0 ? { workflowIndustries: { $all: this.industries } } : {}
      const tacticsFilter = this.tactics.length > 0 ? { workflowTactics: { $all: this.tactics } } : {}
      const campFilter = this.hideCampaigns ? {} : (this.selectedCampaignId ? { campaignId: this.selectedCampaignId } : {})
      return Object.assign({},
        this.query,
        campFilter,
        this.scheduledQuery,
        this.archivedQuery,
        tacticsFilter,
        industriesFilter
      )
    },
    selectedIds: {
      get () { return this.value },
      set (value) { this.$emit('input', value) }
    }

    // customActionIcons () {
    //   if (this.$db.isCurrentAccountOwner) {
    //     return ['edit', 'delete']
    //   } else if (this.$db.isCurrentAccountManager) {
    //     return ['edit']
    //   } else {
    //     return []
    //   }
    // }
  },
  watch: {
    campaignId () {
      this.selectedCampaignId = this.campaignId
    }
  },
  mounted () {
    this.selectedCampaignId = this.campaignId
  },
  methods: {
    // to make sure tasks can select this pipeline with selectPipeline
    async refreshPipelines (item) {
      if (item.accountId) {
        await this.$db.get('accounts', item.accountId)
        // children of pipeline should be reloaded , important if pipeline was removed...
        await this.$db.find('tasks', { accountId: item.accountId })
      }
    }
  }
}
</script>
