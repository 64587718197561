 <!--

  Src gets prefix with backend and data service:
   http://localhost:3030/data

  $attrs pass as well

    <c-img
      src="/avatars/U_0.webp"
      contain
      content-class="contain-v-img"
      :max-height="150"
      class="background-1 pa-1"
      :alt="alt"
    />

   @NOTE: See the content-class that fixes a bug scaling in responsive on mobile !

  -->

<template>
  <div style="display: grid;">
    <img v-if="nonResponsive" v-bind="$attrs" :src="url">
    <v-img v-else v-bind="$attrs" :src="url" />
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import Log from '@/lib/log'

export default {
  props: {
    src: {
      type: String,
      default: ''
    },
    nonResponsive: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      accessToken: '',
      parsedSrc: ''
    }
  },
  computed: {
    url () {
      return (this.src.includes('http') ? this.src : `${this.$config.REPO_URL}${this.src}`)
    }
  }
  // async mounted () {
  //   const token = await this.$api.authentication.getAccessToken()
  //   document.cookie = `token=${token};`
  // }
  // async mounted () {
  //   Log.w('srssss', this.src)
  //   let response
  //   try {
  //     this.$signal.apiActionBar = { status: 'loading' }
  //     response = await this.$api.service('/data/avatars/U_0.webp').find()
  //     this.$signal.apiActionBar = { status: 'success' }
  //     Log.w('>>>>>>>>>>>>>>>>>>>>>', response)
  //   } catch ({ code, message }) {
  //     this.$signal.apiActionBar = { status: 'error', code, message }
  //     Log.e(`[D] find failed in data with error: [${code}]: "${message}" and query`)
  //     return []
  //   }
  //   this.parsedSrc = `data:${response.mimeType};base64,${response.base64Content}`
  //   // const token = await this.$api.authentication.getAccessToken()
  //   // this.accessToken = `?access_token=${token}`
  //   // document.cookie = `token=${token};`
  // }
}
</script>

<style lang="scss" scoped >
.contain-v-img {
  width: 100% !important;

}
</style>
