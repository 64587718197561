/* Store: the users collection and the current logged-in user object */

import Config from '@/config/constants'

import setCurrentAccount from '~/plugins/vuex-injects/vuex/users/set-current-account.js'
// import setCurrentCampaign from '~/plugins/vuex-injects/vuex/users/set-current-campaign.js'
// import setCurrentPipeline from '~/plugins/vuex-injects/vuex/users/set-current-pipeline.js'

import { initialStateProperties } from '~/plugins/vuex-injects/vuex'

export default initialStateProperties({
  service: 'users',

  actions: setCurrentAccount,
  // actions: { ...setCurrentAccount, ...setCurrentCampaign, ...setCurrentPipeline },

  initData: {
    email: '',
    emailConfirmed: false, // set to true by /confirmations, can not patch
    emailPublic: false,
    password: '',
    oneTimePassword: false,
    nrUsersInvited: 0, // increased each time a users invites someone new

    permissions: '', // admin | super | user
    displayName: '',

    language: '',
    avatarImageSrc: '',
    avatarPrefix: 1, // 0 = use profile image, or:  1..n use as special avatar prefix to email
    // auth0Id: '',
    // googleId: '',
    termsAgreed: false,
    blocked: false, // { type: Boolean }, // admin can (temporarily) block account
    deleted: false, // { type: Boolean }, // user can delete an account (suggest we only remove after certain amount of time)
    visitedAt: '', // { type: Date }, // user last login

    // business card details (for on the team page)
    company: '', // { type: String, maxlength: 80 },
    title: '', // { type: String, maxlength: 80 },
    showInSpecialistGallery: false, // { type: Boolean, default: false }, // display the profile in teams page
    linkedInUrl: '',
    resume: '', // { type: String, maxlength: 500 },
    certifiedConsultant: '', // { type: String, default: 'none', enum: ['none', 'master', 'senior', 'junior'], },, // admin only field

    industries: [], // [{ type: String, maxlength: 30, default: () => [] }],
    expertise: [], // [{ type: String, maxlength: 30, default: () => [] }],

    address: {}, // { type: locationAddress, default: () => ({}) },
    // company: { type: String, default: '' },
    // department: { type: String, default: '' },
    // firstName: { type: String, default: '' },
    // lastName: { type: String, default: '' },
    // sex: '', // m/f  male / female / undefined
    // tel: [String],
    // email: [String],
    // street: { type: String, default: '' },
    // houseNumber: { type: String, default: '' },
    // city: { type: String, default: '' },
    // state: { type: String, default: '' },
    // zipCode: { type: String, default: '' },
    // countryCode: { type: String, default: '' },
    // homePage: url

    localization: {
      language: 'en',
      currency: 'EUR',
      dateFormat: 'en', // 'int' or 'en'
      time24Hours: true // true, default ="HH:mm"; false: am_pm: 'KK:mm aa'
    },

    subscription: {
      productCode: 'SP_00',
      payMonths: 12,
      unlimitedAccounts: false
    },

    creditCard: {
      fullName: '',
      number: 0 // 12345678912345678
    },
    currentAccountId: '',

    adminNotes: '',

    // upload data for new avatar; pseudo fields !
    uri: '',
    fileName: '',

    // pseudo field
    emailTo: '' // for user invitation email (per confirmation service)
  },

  populatedData: {
    accounts: [],
    subscription: {
      plan: {},
      discountTerm: {}
    }
  },

  afterCommitHook: (state, item) => {
    if (item.subscription) {
      item.maxNrAccounts = item.subscription.unlimitedAccounts
        ? Config.ACCOUNTS.MAX_NR // ~1000
        : item.subscription.plan.accounts
    } else {
      item.maxNrAccounts = 1
    }
  }
})
