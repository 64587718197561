/* Stories */

import { initialStateProperties } from '@/plugins/vuex-injects/vuex'

// import Config from '@/config/constants'
const tenYearsFromNow = new Date()
tenYearsFromNow.setFullYear(tenYearsFromNow.getFullYear() + 10)

export default initialStateProperties({
  service: 'stories',
  channelSubscription: true, // see plugins/feathers/feathers-client.js

  defaultQuery: { accountId: true },

  // Collection: no 'bodyHtml' as it is a bit too large to carry all in client memory
  collectionFields: [
    'accountId',
    'authorIds',
    'featured',
    'nrStars',
    'published',
    'slug',
    'language',
    'title',

    'note',
    'bodySize',

    'excerpt',
    'startAt',
    'endAt',
    'tags',
    'featureAssetId',

    // * populated:
    'account',
    'authors',
    'featureAsset'
    // 'tasks', // @TODO
  ],

  initData: {
    accountId: '', // ref: accounts
    authorIds: [],
    featured: false,
    nrStars: 0, // { type: Number, min: 0, max: 5, default: 0 }, // rating of the quality of this article
    published: false,
    slug: '',
    language: 'en',
    title: '',
    bodyHtml: '<p></p>', // { type: String, minlength: 6, maxlength: 75000, default: '<p></p>' }
    bodySize: 7,
    excerpt: '',
    note: '', // { type: String, maxlength: 2500 },
    featureAssetId: '', // { type: String, ref: 'assets' },
    startAt: new Date().toISOString(), // publish At
    endAt: tenYearsFromNow.toISOString(), // publish Until
    tags: [] // ['marketing', 'sales']
  },

  populatedData: {
    account: {},
    authors: [], // [{ _id: '', email: '', displayName: '' },..]

    assetUrl: '', // { src, alt, title, thumb, variants }
    featureImageSrc: '', // url
    featureImageSrcSm: '', // url

    // @TODO
    //   tasks: [{}]

    // from afterCommitHook below
    searchField: ''
  },

  afterCommitHook: (state, item) => {
  // improve text search for storyTable
    item.searchField = `${item.title} ${item.excerpt} ${item.bodyHtml}`.toLowerCase()
  }
})
