<!--  *** Default Page Template; probably all but home page:

Examples all in one:
  <c-page
    icon="calendar-month-outline"  // should not have 'mdi-'
    i18n-prefix="calendar"
  >
    ...
  </c-page>

Example individual parameters:
  <c-page
    :label="$t('partners.label')"
    label-link="/stories"
    :title="$t('partners.title')"
    :subtitle="$t('partners.subtitle')"
    :intro="$t('partners.intro')"
    info-slug="bligman-introduction"
    hero-color="campaigns"
    hero-text="hallo"
    hero-text-color="white"
  >
    ...
  </c-page>

Note use: info-slug without string ==='' to use url as path

-->

<template>
  <section class="clearfix px-0 px-md-3">
    <v-container fluid>
      <!-- Only show label when leftDrawer does not show as well ?  && ($app_leftMiniDrawer||!$app_leftDrawer)-->

      <!-- Background label; in red for admin -->
      <div v-if="cLabel" class="d-flex align-center my-2" :class="{'warning px-4 py-2 mt-4 mb-8 white--text': admin}">
        <v-icon v-if="icon" small class="mr-2" :color="admin ? 'white': 'primary'">mdi-{{ icon }}</v-icon>

        <nuxt-link v-if="labelLink" :to="labelLink" class="caption text-uppercase ">
          {{ cLabel }}
        </nuxt-link>
        <span v-else class="caption text-uppercase ">{{ cLabel }}</span>

      </div>

      <div class="d-flex align-top">
        <h1 v-if="cTitle" class="pr-3 mb-4 text-capitalize">{{ cTitle }}</h1>
        <v-spacer />
        <!-- help button -->
        <c-info-btn :slug="infoSlug" icon-color="info lighten-2" class="mt-n2" />
      </div>

      <template v-if="cSubtitle">
        <h3 class="pr-3">{{ cSubtitle }}</h3>
      </template>

      <template v-if="heroColor">
        <v-progress-linear
          :color="heroColor"
          :indeterminate="heroLoading"
          rounded
          :value="100"
          height="2"
        />
      </template>

      <template v-if="cIntro">
        <div v-if="introToggle && !readIntro" class="caption primary--text mt-n2" style="cursor: pointer;" @click="readIntro = true">
          {{ $t('def.read') }}
        </div>
        <p v-if="readIntro" v-html="cIntro" />
        <c-spacer half />
      </template>

      <div :class="{'ml-n2 mr-n1': fullWidthSlot}">
        <slot />
      </div>
    </v-container>
    <c-spacer half />
  </section>
</template>

<script>
import Log from '@/lib/log' // eslint-disable-line no-unused-vars

export default {

  props: {
    // little top left corner icon, next to label
    icon: { type: String, default: '' },

    // quick props for label, title, subtitle and intro fields
    i18nPrefix: { type: String, default: 'i18n_empty' },

    // if no i18nPrefix is given, we need the individual i18n parameters
    label: { type: String, default: '' },
    // make the label clickable
    labelLink: { type: String, default: '' },
    title: { type: String, default: '' },
    subtitle: { type: String, default: '' },
    intro: { type: String, default: '' },
    // display "read more…" button to toggle the intro text
    introToggle: { type: Boolean, default: false },
    // signals the user with red label background that she is on an protected admin page
    admin: { type: Boolean, default: false },

    // context sensitive help popup with <c-info-btn>...</c-info-btn>
    // if infoSlug===null do not show info button, even if slug is found in info-articles
    infoSlug: { type: String, default: '' },

    // horizontal full width sheet / bar
    heroText: { type: String, default: '' },
    heroTextColor: { type: String, default: 'white' },
    heroColor: { type: String, default: '' },
    heroLoading: { type: Boolean, default: false },

    // slot margins removed
    fullWidthSlot: { type: Boolean, default: false }
  },
  data () {
    return {
      readIntro: !this.introToggle,
      editDialog: false
    }
  },
  computed: {
    // to allow passing standard i18n parameters
    cLabel () {
      return this.label || (this.$i18n.te(`${this.i18nPrefix}.label`) ? this.$i18n.t(`${this.i18nPrefix}.label`) : '')
    },
    cTitle () {
      return this.title || (this.$i18n.te(`${this.i18nPrefix}.title`) ? this.$i18n.t(`${this.i18nPrefix}.title`) : '')
    },
    cSubtitle () {
      return this.subtitle || (this.$i18n.te(`${this.i18nPrefix}.subtitle`) ? this.$i18n.t(`${this.i18nPrefix}.subtitle`) : '')
    },
    cIntro () {
      return this.intro || (this.$i18n.te(`${this.i18nPrefix}.intro`) ? this.$i18n.t(`${this.i18nPrefix}.intro`) : '')
    }
  },
  async mounted () {
    // passed link such as /pipeline/P_303?accountId=A_100, then we might need to change account
    if (this.$route.query && this.$route.query.accountId && this.$db.currentAccountId !== this.$route.query.accountId) {
      await (this.$db.currentAccountId = this.$route.query.accountId)
      Log.i(`[page] currentAccountId set to: ${this.$route.query.accountId}`)
    }
  }
}
</script>
