<!-- show panels as defined in

  @/config/constants.js DRAWER_PANELS
-->

<template>
  <div style="padding-left: 2px;">
    <v-sheet :height="1" color="primary darken-1" />

    <div class="d-flex px-4 py-2 ">
      <share-link icon-color="white" class="d-block d-md-none" />

      <v-spacer />

      <time-tracker-list service="global" />
    </div>

    <v-sheet :height="2" color="primary darken-1" />

    <div v-for="(drawer,index) in $config.DRAWER_PANELS" :key="index" style="padding-bottom: 2px;">
      <right-drawer-panel v-if="showOnRoute(drawer)" :drawer="drawer" :overflow="drawer.overflow">
        <information-toc v-if="drawer.panel==='info-articles'" />
        <activities-list v-else-if="drawer.panel==='activities'" />
        <widget-drawer v-else-if="drawer.panel==='widgets'" />

        <paste-asset v-else-if="drawer.panel==='assets'" />
        <paste-embed v-else-if="drawer.panel==='embeds'" />
        <paste-snippet v-else-if="drawer.panel==='snippets'" />

        <import-pipeline-tasks v-else-if="drawer.panel==='pipelines.templates'" />
        <v-divider />
      </right-drawer-panel>
    </div>
  </div>
</template>

<script>

import Log from '@/lib/log' // eslint-disable-line no-unused-vars

import RightDrawerPanel from '@/components/_default/drawers/RightDrawerPanel'

import ShareLink from '@/components/_default/topbar/ShareLink'
import TimeTrackerList from '@/components/time-trackers/drawers/TimeTrackerList'

import ActivitiesList from '@/components/activities/drawers/ActivitiesList'
import InformationToc from '@/components/information/drawers/InformationToc'
import WidgetDrawer from '@/components/dashboard/WidgetDrawer'

import PasteAsset from '@/components/assets/drawers/PasteAsset'
import PasteEmbed from '@/components/embeds/drawers/PasteEmbed'
import PasteSnippet from '@/components/snippets/drawers/PasteSnippet'

import ImportPipelineTasks from '@/components/pipelines/drawer/ImportPipelineTasks'

function matchRuleShort (str, rule) {
  const escapeRegex = str => str.replace(/([.*+?^=!:${}()|[\]/\\])/g, '\\$1')
  return new RegExp('^' + rule.split('*').map(escapeRegex).join('.*') + '$').test(str)
}
export default {
  components: {
    ShareLink,
    TimeTrackerList,
    RightDrawerPanel,
    ActivitiesList,
    InformationToc,
    PasteAsset,
    PasteEmbed,
    PasteSnippet,
    ImportPipelineTasks,
    WidgetDrawer
  },
  mounted () {
    // initialize the open state of the default open drawers
    // Can show a panel multiple times for different routes, such as with 'widgets'
    this.$config.DRAWER_PANELS.forEach((drawer) => {
      if (drawer.openByDefault) {
        // Log.w(`OPEN ${drawer.panel}`)
        if (drawer.closeOtherPanels && this.showOnRoute(drawer)) {
          this.$signal.closeAllDrawerPanels()
        }
        this.$signal.openDrawerPanel(drawer.panel)
      }
    })
  },
  methods: {
    showOnRoute (drawer) {
      const show = !!drawer.showOnRoute.find(r => matchRuleShort(this.$nuxt.$route.path, r))
      // Log.w(`SHOW ${drawer.panel} = ${show}`)
      return show
    }
  }
}
</script>
