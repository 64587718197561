// ** Set the currentAccountId and refresh all dependent services
//
// await store.dispatch('users/setCurrentAccount', { id })
//

import Log from '@/lib/log'

export default {
  async setCurrentAccount ({ commit, getters, dispatch, rootGetters }, { id }) {
    //
    // if (id === null || id !== getters.get('currentAccountId')) {
    // check if we just need to reload
    if (id === null) {
      id = getters.get('currentAccountId')
      Log.i(`[set-current-account] first initialization for account ${id}`)
    } else {
      Log.i(`[set-current-account] id = ${id}`)
    }
    //
    // ** Account membership and existence verification
    //
    const accounts = await this.$db.find('accounts', { $cache: true })

    if (accounts.length === 0) {
      // create new account as we do not have any
      const newAccount = await this.$db.create('accounts', {
        name: this.app.i18n.t('accounts.field.new_name'),
        tags: this.app.i18n.t('accounts.field.default_tags').split(',')
      })
      id = newAccount._id
      Log.e(`[set-current-account] User had no accounts yet! This should not have happened! Created new account ${id}`)
    } else {
      // check of the desired id exists
      const index = this.$db.collection('accounts').findIndex(i => i._id === id)

      if (index < 0) {
        Log.w(`[set-current-account] User no longer member of account ${id}: set currentAccount to first from table ${accounts[0]._id}`)
        id = accounts[0]._id
      }
    }

    await this.$db.patch('users', null, { currentAccountId: id })

    // ** Load the accountId as current document
    //
    this.$db.doc('accounts', id)

    // ** Refresh dependents
    //
    const accountIdServices = this.$db.allServices('accountId') // ['campaigns', 'pipelines', 'tasks', 'stories', 'assets']
    Log.i(`[set-current-account] ${id} update: ${accountIdServices}`)
    for (const service of accountIdServices) {
      this.$db.clear(service)
      await this.$db.find(service)
    }
  }
}
