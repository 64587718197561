<!-- Create a 3D lowered effect in a box, top or bottom. Such as in drawer panel

  <c-shadow top />
  <div style="max-height: 400px; overflow-y: auto;">
    <slot />
  </div>
  <c-shadow bottom />

-->

<template>
  <div :class="'in-box-shadow-' + (top || !bottom ? 'top': '') + (bottom ? 'bottom': '')" />
</template>

<script>
export default {
  props: {
    top: {
      type: Boolean,
      default: false
    },
    bottom: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss"  >
.in-box-shadow-top {
  position: relative;
  height: 4px;
  z-index: 10;
  margin-bottom: -4px;
  background: linear-gradient(0deg, rgba(2,0,36,0) 0%, rgba(25,25,43,0.6) 100%);
}
.in-box-shadow-bottom {
  position: relative;
  height: 4px;
  margin-top: -4px;
  background: linear-gradient(180deg, rgba(2,0,36,0) 0%, rgba(25,25,43,0.6) 100%);
}
</style>
