// ** MIDDLEWARE context.$appConf
//
// For application specific settings
//
//   $appConf.darkMode(true)
//   mode = $appConf.darkMode()

// make app settings globally available

import _appSettingsMixin from '@/plugins/mixins/app_settings.js'
import Vue from 'vue'

Vue.mixin(_appSettingsMixin)
