/* Pipelines */

import { initialStateProperties } from '@/plugins/vuex-injects/vuex'

import Log from '@/lib/log' // eslint-disable-line no-unused-vars

const oneYearFromNow = new Date()
oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1)

export default initialStateProperties({
  service: 'pipelines',
  channelSubscription: true, // see plugins/feathers/feathers-client.js

  defaultQuery: { archived: false, accountId: true }, // remove parameter like archived with null

  initData: {
    accountId: '',
    campaignId: '',
    protected: true, // { type: Boolean, default: true }, // if protected, then can not delete (front end function only)
    archived: false,

    name: '',
    description: '',

    assetIds: [], //  [{ type: String, ref: 'assets' }], // first will be the cover image

    tags: [], // for internal management by user

    workflowIndustries: [], // [{ type: Number, index: true, enum: workflowIndustries.map(i => i.id) }], // [ 104, 105, 106 ]
    workflowTactics: [], // [{ type: Number, index: true, enum: workflowTactics.map(i => i.id) }], // [ 150, 161 ]

    // template = master that users can duplicate the tasks of
    template: false, //  { type: Boolean, default: false }, // will show up in gallery to duplicate set of children-tasks
    public: false, //  { type: Boolean, default: false }, // will show up in gallery of all other users as well; ignoring the accountId filter
    templateOwnerId: '', // { type: String, ref: 'users' }, // only if the pipeline is a template

    startAt: new Date().toISOString(),
    endAt: oneYearFromNow.toISOString()
  },

  populatedData: {
    account: {},
    campaign: {},
    tasks: [],
    storyIds: [], // all story ids of the attached Tasks
    templateOwner: {},

    // afterCommitHook below
    taskMatrix: {},
    status: ''
  },

  afterCommitHook: (state, item) => {
    // // fill the taskMatrix to for the drag and drop Canvas / Kanban board
    item.taskMatrix = {
      str: { pln: [], ong: [], fin: [], arch: [] }, // strategy
      con: { pln: [], ong: [], fin: [], arch: [] }, // content
      ver: { pln: [], ong: [], fin: [], arch: [] }, // verify
      pub: { pln: [], ong: [], fin: [], arch: [] }, // publish
      int: { pln: [], ong: [], fin: [], arch: [] } // interact
    }

    item.taskIds = item.tasks.map(i => i._id)

    item.tasks.forEach((task) => {
      item.taskMatrix[task.stage][task.status].push(task._id)
    })

    // to allow easy filter in TaskTable
    if (item.campaign) {
      item.campaignName = item.campaign.name
    }
    if (item.template === true) {
      item.status = 'tpl'
    } else if (new Date(item.startAt) < new Date() && new Date(item.endAt) > new Date()) {
      item.status = 'on'
    } else if (new Date(item.startAt) > new Date()) {
      item.status = 'plan'
    } else if (new Date(item.endAt) < new Date()) {
      item.status = 'fin'
    } else {
      item.status = ''
    }
  }
})
