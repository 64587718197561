<template>
  <div>
    <asset-table
      v-model="itemId"
      selectable
      small
      show-gallery
      hide-gallery-size-toggle
      @select="embedDialog = true"
    />

    <asset-embed-dialog v-model="embedDialog" :item="item" />
  </div>
</template>

<script>
import Log from '@/lib/log' // eslint-disable-line no-unused-vars

import AssetTable from '@/components/assets/table/AssetTable'
import AssetEmbedDialog from '@/components/assets/form/AssetEmbedDialog'

export default {
  components: {
    AssetTable,
    AssetEmbedDialog
  },

  data () {
    return {
      embedDialog: false,
      itemId: ''
    }
  },
  computed: {
    item () {
      return this.$db.doc('assets', this.itemId)
    }
  }
}
</script>
