 <!-- The password reset confirmation warning

 Show if:
  - field in user oneTimePassword === true
  - there is not an outstanding confirmation with 'oneTimePassword' as field

 -->

<template>
  <v-sheet v-if="$db.doc('users').oneTimePassword && !hasOutstandingConfirm" color="warning">
    <div class="col-md-8 offset-md-2 px-10  white--text body-2 d-flex justify-end align-end">
      <div class="mb-2">
        {{ $t('user.field.password.one_time_active') }}
      </div>

      <v-btn small outlined color="white" :to="'/user/profile'" class="mx-auto">
        {{ $t('def.ok') }}
      </v-btn>
    </div>
  </v-sheet>

</template>

<script>
export default {
  computed: {
    // find all confirmations that have at least one message with the relevant field
    hasOutstandingConfirm () {
      return this.$db.collection('confirmations').findIndex(c =>
        c.execData.includes('oneTimePassword')
      ) > -1
    }
  }
}
</script>
