var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('general-table',{attrs:{"service":"snippets","dialog-bar-color":"assets","headers":_vm.headers,"small":_vm.small,"filter-item-id":_vm.filterItemId,"show-tag-select":"","show-expand":"","items-per-page":15,"show-gallery":_vm.showGallery,"show-gallery-toggle":_vm.showGalleryToggle,"hide-gallery-size-toggle":_vm.hideGallerySizeToggle,"show-new-on-empty":_vm.showNewOnEmpty,"selectable":_vm.selectable,"select-multiple":_vm.selectMultiple},on:{"itemCreated":_vm.refreshItem,"itemChanged":_vm.refreshItem,"select":function($event){return _vm.$emit('select', $event)}},scopedSlots:_vm._u([{key:"left-toolbar",fn:function(){return undefined},proxy:true},{key:"right-toolbar",fn:function(){return undefined},proxy:true},{key:"item-table-row",fn:function(ref){
var item = ref.item;
return [_c('gen-td',{attrs:{"headers":_vm.headers,"item":item,"value":"officiallyEndorsed"}},[_c('v-icon',{staticClass:"mx-n1",attrs:{"size":18,"color":item.officiallyEndorsed ? 'info' : 'grey'}},[_vm._v("\n          mdi-police-badge"+_vm._s(item.officiallyEndorsed ? '' : '-outline')+"\n        ")])],1),_vm._v(" "),_c('gen-td',{attrs:{"headers":_vm.headers,"item":item,"value":"title"}}),_vm._v(" "),_c('gen-td',{attrs:{"headers":_vm.headers,"item":item,"value":"tags"}}),_vm._v(" "),_c('gen-td',{attrs:{"headers":_vm.headers,"item":item,"value":"textSnippet","small":_vm.small}})]}},{key:"item-expand-pane",fn:function(ref){
var item = ref.item;
return [_c('snippet-view',{attrs:{"item":item}})]}},{key:"item-edit-form",fn:function(ref){
var item = ref.item;
return [_c('snippet-edit',{attrs:{"item":item}})]}},{key:"gallery-card",fn:function(ref){
var item = ref.item;
var gallerySmall = ref.gallerySmall;
return [_c('v-card-text',{staticClass:"px-2 py-1 background",staticStyle:{"height":"100%"}},[_c('c-field',{class:{'caption':gallerySmall},attrs:{"wrap":""}},[_vm._v(_vm._s(item.title))])],1)]}}]),model:{value:(_vm.selectedIds),callback:function ($$v) {_vm.selectedIds=$$v},expression:"selectedIds"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }