<!-- print the duration between two dates, with start/end date in ISO string Or period in seconds

Examples:

    <c-date-duration start-date="05 October 2020 14:48:12 UTC" end-date="05 October 2020 14:49:12 UTC" />  // one minute
    <c-date-duration start-date="05 October 2020 14:48:12 UTC" end-date="05 October 2020 15:48:12 UTC" />  // one hour
    <c-date-duration start-date="05 October 2020 14:48:12 UTC" end-date="06 October 2020 14:48:12 UTC" />  // one day
    <c-date-duration start-date="05 October 2020 14:48:12 UTC" end-date="21 October 2021 4:19 UTC" />  // several

    Or pass duration in seconds

    <c-date-duration :seconds="32903" hide-seconds hide-zero-hours />  // 9 hours or so

 -->
<template>
  <div>
    <span v-if="days>0">
      {{ `${days}&nbsp;${$te('def.time.days') ? $t('def.time.days') : 'd' }` }}
    </span>

    <span v-if="!hideZeroHours || (days===0 && (hours!==0 || minutes!==0))">
      {{ days>0 ? ', ': '' }}
      {{ `${('00' + hours).slice (-2)}:${('00' + minutes).slice (-2)}${!hideSeconds && sec>0 ? ':'+('00' + sec).slice (-2):''}` }}
    </span>

  </div>
</template>

<script>
export default {
  props: {
    // * Give the dates
    startDate: {
      type: String, // ISOString format: "2011-10-05T14:48:00.000Z"
      default: ''
    },
    endDate: {
      type: String,
      default: ''
    },

    // * OR: time-span
    seconds: {
      type: Number,
      default: 0
    },
    // only show minutes, hours, days.. not seconds
    hideSeconds: {
      type: Boolean,
      default: false
    },
    // do not show "x days, 00:00"  if hours & minutes = 0 unless days is zero
    hideZeroHours: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    sDate () {
      return new Date(this.startDate)
    },
    eDate () {
      return new Date(this.endDate)
    },
    totSeconds () {
      return Math.floor(this.startDate ? (this.eDate - this.sDate) / 1000 : this.seconds)
    },
    totMinutes () {
      return Math.floor(this.totSeconds / 60)
    },
    totHours () {
      return Math.floor(this.totMinutes / 60)
    },
    days () {
      return Math.floor(this.totHours / 24)
    },
    hours () {
      return this.totHours - (this.days * 24)
    },
    minutes () {
      return this.totMinutes - (this.days * 24 * 60) - (this.hours * 60)
    },
    sec () {
      return this.totSeconds - (this.days * 24 * 60 * 60) - (this.hours * 60 * 60) - (this.minutes * 60)
    }
  },
  methods: {
    name () {

    }
  }
}
</script>
