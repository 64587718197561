import io from 'socket.io-client'
import feathers from '@feathersjs/client'

import Log from '@/lib/log'

export default async (context, inject) => {
  const { store, $config, $db, $signal, route } = context // app, redirect

  // initialize feathers and enable global this.$api
  const api = feathers()
  inject('api', api)

  const socket = io($config.API_URL)
  api.configure(feathers.socketio(socket))
  api.configure(feathers.authentication())
  Log.i(`[api] Feathers socketio connected to NODE_ENV= ${$config.NODE_ENV}  version= ${$config.VERSION}  api_url= ${$config.API_URL}`)

  // ** Feathers handlers

  // fired when user logs in or when auto-login / re-authenticated hereunder
  api.on('login', async ({ user }) => {
    $signal.apiActionBar = { status: 'loading' }

    // set the current active user's data object
    store.commit('users/afterCommitHook', user)
    store.commit('users/set', user)

    // signal the state of the authorization directly to the store
    // NOTE: not via $signal inject as it is loaded too late in cycle (?)
    store.commit('_signals/set', { isLoggedIn: true })

    await $db.initCollections() // load the initial data tables
    $signal.apiActionBar = { status: 'success' }

    Log.i('[api] user logged-in:', user.email, user._id)
  })

  api.on('logout', (authResult, params, context) => {
    Log.w(`[api] on log out for user ${$db.doc('users')._id}`)
    store.commit('_signals/set', { isLoggedIn: false })
    $db.clearAll()
    Log.i('Ready on.logout.')
  })

  api.on('disconnect', (authResult, params, context) => {
    Log.w('[api] Server is disconnected so execute logout process.')
    store.commit('_signals/set', { isLoggedIn: false })
    $db.clearAll()
    Log.i('Ready on.disconnect.')
  })

  // ** Subscribe all channel subscripted services
  const channelServices = []
  const patchData = async (action, service, data) => {
    Log.i(`[api] Feathers client "${action}" received for "${service}"; _id="${data._id}"`, data)
    store.commit(`${service}/afterCommitHook`, data)
    store.commit(`${service}/updateCollection`, { id: data._id, data })

    // handle second order reloads
    switch (service) {
      case 'accounts':
        await $db.get('users', $db.currentUserId)
        break
    }
  }
  // subscribe to all events from the services that are marked with "channelSubscription: true" in store files
  Object.keys(store.getters).forEach((storeGetter) => {
    if (storeGetter.includes('/isChannelSubscribed') && store.getters[storeGetter]) {
      const [service] = storeGetter.split('/')
      api.service(service).on('patched', (data, context) => {
        patchData('patched', service, data)
      })
      api.service(service).on('created', (data, context) => {
        patchData('created', service, data)
      })
      channelServices.push(service)
      // Log.i(`[api] "${service}" subscribed.`)
    }
  })
  Log.i(`[api] Feathers subscribed to events for [${channelServices}]`)

  // try to auto-login via existing token
  if (process.browser) {
    try {
      const { user } = await api.reAuthenticate()
      $db.currentAccountId = user.currentAccountId
      Log.i(`[api] Feathers authenticated: ${user._id} ${user.email}  account ${$db.currentAccountId}`)
    } catch ({ code, message }) {
      Log.w('[api] Feathers not authenticated:', code + ':' + message)
      const path = '/' + route.fullPath.split('/')[1] // '/login', '/confirm', '/signup , '/campaigns' ...
      // only redirect if not already on public page
      if (![...$config.publicRoutes, '/login'].includes(path)) {
        window.onNuxtReady(() => { window.$nuxt.$router.push('/login') })
      }
    }
  }
}

// Found on Slack to use cookies instead of local storage (nuxt) to allow SSR and not <client-only></client-only> as we do now
//
// feathers-client.js file
// import feathers from '@feathersjs/feathers';
// import auth from '@feathersjs/authentication-client';
// import socketio from '@feathersjs/socketio-client';
// import io from 'socket.io-client';
// // other imports
// import { CookieStorage } from 'cookie-storage';
// const transport = socketio(io(process.env.API_URL));
// const domain = process.env.NODE_ENV === 'production' ? 'rootdomain.com' : 'localhost';
// const cookieStorage = new CookieStorage({
//   path: '/',
//   domain,
//   secure: domain !== 'localhost',
//   sameSite: 'Lax',
// });
// const feathersClient = feathers()
//   .configure(transport)
//   .configure(auth({
//     storage: cookieStorage,
//     storageKey: 'feathers-jwt',
//   }))
// // other feathers init stuff
