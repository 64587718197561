<!-- Show drop down list with autocomplete / select tag chips

And optional link to set the default account tags

Example:

  <select-tags
    v-model="item.tags"
    dense
    outlined
    :tag-list="$db.doc('configuration').defaultInfoBaseTags"
    hide-edit-account-tags
    label="select tags"
  />
-->

<template>
  <c-field :label="label" :slot-class="slotClass" :label-class="labelClass">
    <v-select
      v-model="tags"
      :items="availableTags"
      :chips="!text"
      :small-chips="!text"
      :solo="!text"
      flat
      :outlined="outlined"
      :background-color="backgroundColor"
      :class="{'select-tags-input': !text}"
      class="pt-1"
      item-color="info"
      hide-details="auto"
      deletable-chips
      :multiple="!single"
      v-bind="$attrs"
      @blur="$emit('changed', tags)"
    />

    <!-- Allow user to edit the Default Account tags -->
    <c-link v-if="!hideEditAccountTags" to="#" class="d-flex">
      <v-spacer />
      <div class="caption" @click="dialog=true">
        {{ $t('accounts.edit_tags').toLowerCase() }}
      </div>
    </c-link>

    <v-dialog
      v-model="dialog"
      color="info"
      :width="600"
    >
      <v-card>
        <v-system-bar window lights color="info" class="white--text" style="cursor: pointer;" @click.stop="dialog=false;">
          <v-btn icon><v-icon color="white">mdi-arrow-left</v-icon></v-btn>
          <span class="caption">{{ $t('accounts.edit_tags') }} - "{{ this.$db.doc('accounts').name }}"</span>
        </v-system-bar>

        <v-card-text class="mt-6 pb-8">
          <list-tags v-model="accountTags" chips clear-chips color="info" />
          <add-tag v-model="accountTags" />
        </v-card-text>
      </v-card>
    </v-dialog>

  </c-field>
</template>

<script>
import ListTags from '@/components/_lib/form/tags/ListTags'
import AddTag from '@/components/_lib/form/tags/AddTag'

export default {
  components: {
    ListTags,
    AddTag
  },
  props: {
    value: {
      type: [Array, String],
      default: () => []
    },
    tagList: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: ''
    },
    labelClass: {
      type: String,
      default: ''
    },
    slotClass: {
      type: String,
      default: ''
    },
    backgroundColor: {
      type: String,
      default: 'background'
    },
    hideEditAccountTags: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: false
    },
    // default multiple
    single: {
      type: Boolean,
      default: false
    },
    text: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dialog: false
    }
  },
  computed: {
    tags: {
      get () { return this.value },
      set (value) { this.$emit('input', this.single ? value : value.sort()) }
    },
    accountTags: {
      get () { return this.$db.doc('accounts').tags },
      set (value) {
        this.$db.patch('accounts', null, { tags: value })
      }
    },
    availableTags () {
      if (this.tagList.length > 0) {
        return this.tagList
      } else {
        return Array.from(new Set([...this.accountTags, ...this.tags])).sort()
      }
    }
  }
}
</script>

<style lang="scss" >
 .select-tags-input .v-input__slot{
  padding-top: 4px !important;
}
</style>
