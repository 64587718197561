import { render, staticRenderFns } from "./Avatar.vue?vue&type=template&id=531fed72&"
import script from "./Avatar.vue?vue&type=script&lang=js&"
export * from "./Avatar.vue?vue&type=script&lang=js&"
import style0 from "./Avatar.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/@nuxtjs/vuetify/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VDialog } from 'vuetify/lib/components/VDialog';
installComponents(component, {VBadge,VDialog})
