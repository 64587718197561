<!-- *** Show Timer-buttons: start/pause and stop.

Emits the following events:
  @start
  @stop

Uses global $signal parameters:  $signal.timeTrackerStartAt,...

-->

<template>
  <div class="d-flex">

    <c-tooltip bottom>
      <template #act>
        <v-btn
          v-if="!$signal.timeTrackerRunning"
          fab
          color="white"
          style="height: 34px; width: 34px;"
          @click="$emit('start')"
        >
          <v-btn fab x-small :color="buttonColor">
            <v-icon
              color="white"
              fab
              small
              style="margin-top: -1px;"
            >mdi-{{ $config.SERVICE_ICON['time-trackers'] }}</v-icon>
          </v-btn>
        </v-btn>

        <v-btn v-else fab small :color="buttonColor" @click="$emit('stop')">
          <v-progress-circular
            :size="40"
            :width="2"
            color="white"
            :value="100"
          >
            <!-- indeterminate -->
            <v-icon>mdi-pause</v-icon>
          </v-progress-circular>
        </v-btn>
      </template>

      <span v-if="!$signal.timeTrackerRunning">{{ $t('time-trackers.start') }}</span>
      <span v-else> {{ $t('time-trackers.stop') }}</span>
    </c-tooltip>

  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import Log from '@/lib/log'

export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    buttonColor () {
      return this.$signal.timeTrackerData.service || this.item.service || 'primary'
    }
  }
}
</script>
