//
// ** REGISTER AND LOAD THE DASHBOARD WIDGETS **
//
// To import:
//   import { Widgets = {}, Icons ={} WidgetTypes = [] }  from '@/components/_lib/widgets/widget-loader'
//   export default {
//    components: Widgets,
//    ...
//   }
//
// And then in templates fore example:
//    <component is="information_random" />
//
// To get all imported widget types:
//   data () {
//     return {
//       widgetTypes: Object.keys(Widgets)
//     }
//   },
//
// ****** NOTE - IMPORTANT**
//
//  To adjust height to 'auto' from 200px, after lazy loading, all widgets MUST include:
//    mounted () { this.$emit('widgetLoaded')}
//
// ******
import CONFIG from '@/config/constants.js'

export const Widgets = {

  // *** REGISTER YOUR WIDGETS HERE UNDER ***
  //
  // Note:
  //   also add text in i18n(assets / lang / en.coffee) widgets[type].label and .intro
  //   order determines also order in WidgetDrawer

  accounts_all: () => import('@/components/accounts/widget/AccountsAll'),
  user_business_card: () => import('@/components/users/widget/UserBusinessCard'),

  campaigns_all: () => import('@/components/campaigns/widget/CampaignsAll'),
  campaign_details: () => import('@/components/campaigns/widget/CampaignDetails'),
  campaign_pipelines: () => import('@/components/campaigns/widget/CampaignPipelines'),

  pipelines_all: () => import('@/components/pipelines/widget/PipelinesAll'),
  pipeline_details: () => import('@/components/pipelines/widget/PipelineDetails'),

  information_random: () => import('@/components/information/widget/InformationRandom'),

  stories_recent: () => import('@/components/stories/widget/StoriesRecent'),
  stories_direct_create: () => import('@/components/stories/widget/StoryDirectCreate'),

  task_direct_create: () => import('@/components/tasks/widget/TaskDirectCreate'),
  task_list: () => import('@/components/tasks/widget/TaskList'),

  user_account_team: () => import('@/components/users/widget/AccountUsers'),
  user_onboarding: () => import('@/components/users/widget/WelcomeOnboarding')

  // *** FUTURE

  // tasks_ongoing: () => import('@/components/tasks/widget/TasksOngoing'),
  // analytics_visitors: () => import('@/components/analytics/widget/AnalyticsVisitors')
  // deadlines_upcoming: ''
}

export const Icons = {
  accounts_all: CONFIG.SERVICE_ICON.accounts,

  campaigns_all: CONFIG.SERVICE_ICON.campaigns,
  campaign_details: CONFIG.SERVICE_ICON.campaigns,
  campaign_pipelines: CONFIG.SERVICE_ICON.campaigns,

  pipelines_all: CONFIG.SERVICE_ICON.pipelines,
  pipeline_details: CONFIG.SERVICE_ICON.pipelines,

  task_direct_create: CONFIG.SERVICE_ICON.tasks,
  task_list: CONFIG.SERVICE_ICON.tasks,

  stories_recent: CONFIG.SERVICE_ICON.stories,
  stories_direct_create: CONFIG.SERVICE_ICON.stories,

  information_random: CONFIG.SERVICE_ICON.information,

  user_account_team: 'account-group',
  user_business_card: 'account',
  user_onboarding: 'account-heart-outline'
}

export const WidgetTypes = Object.keys(Icons)
