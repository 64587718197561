var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('general-table',{attrs:{"service":"assets","headers":_vm.headers,"small":_vm.small,"items-per-page":15,"filter-item-id":_vm.filterItemId,"hide-new":"","show-tag-select":"","show-expand":"","show-gallery":_vm.showGallery,"show-gallery-toggle":_vm.showGalleryToggle,"hide-gallery-size-toggle":_vm.hideGallerySizeToggle,"selectable":_vm.selectable,"select-multiple":_vm.selectMultiple},on:{"select":function($event){return _vm.$emit('select', $event)}},scopedSlots:_vm._u([{key:"left-toolbar",fn:function(){return undefined},proxy:true},{key:"right-toolbar",fn:function(){return [_c('c-icon-btn',{attrs:{"icon":"plus","color":"primary","i18n-tooltip-text":"assets.action.upload.tooltip"},on:{"click":function($event){_vm.uploadFileDialog=true}}})]},proxy:true},{key:"item-table-row",fn:function(ref){
var item = ref.item;
return [_c('gen-td',{staticClass:"d-flex justify-start align-center",attrs:{"headers":_vm.headers,"item":item,"value":"thumb"}},[_c('list-assets',{attrs:{"width":40,"height":35,"max-width":40,"max-height":35},model:{value:(item._id),callback:function ($$v) {_vm.$set(item, "_id", $$v)},expression:"item._id"}})],1),_vm._v(" "),_c('gen-td',{attrs:{"headers":_vm.headers,"item":item,"value":"title"}}),_vm._v(" "),_c('gen-td',{attrs:{"headers":_vm.headers,"item":item,"value":"description","small":_vm.small}}),_vm._v(" "),_c('gen-td',{attrs:{"headers":_vm.headers,"item":item,"value":"totalStorage","small":_vm.small}},[(item.totalStorage > 500)?_c('c-bytes-to-size',{staticClass:"info--text caption",attrs:{"bytes":item.totalStorage,"decimal-basis":""}}):_vm._e()],1)]}},{key:"item-expand-pane",fn:function(ref){
var item = ref.item;
return [_c('asset-view',{attrs:{"item":item}})]}},{key:"item-edit-form",fn:function(ref){
var item = ref.item;
return [_c('asset-edit',{attrs:{"item":item}})]}},{key:"gallery-card",fn:function(ref){
var item = ref.item;
return [_c('list-assets',{model:{value:(item._id),callback:function ($$v) {_vm.$set(item, "_id", $$v)},expression:"item._id"}}),_vm._v(" "),_c('c-field',{staticClass:"caption pl-1 text-lowercase "},[_vm._v(_vm._s(item.title))])]}},{key:"gallery-empty",fn:function(){return [_c('c-icon-btn',{attrs:{"icon":"plus","color":"primary","i18n-tooltip-text":"table.tooltip.add_new"},on:{"click":function($event){_vm.uploadFileDialog=true}}})]},proxy:true}]),model:{value:(_vm.selectedIds),callback:function ($$v) {_vm.selectedIds=$$v},expression:"selectedIds"}}),_vm._v(" "),_c('file-upload-dialog',{attrs:{"accept-mime":_vm.$config.ASSETS.MIME,"max-nr-files":10,"max-file-size":_vm.$config.ASSETS.MAX_FILE_SIZE,"drop-zone-height":200,"upload-data":{ accountId: _vm.$db.doc('users').currentAccountId },"hide-after-upload":""},on:{"onFinished":function($event){_vm.uploadFileDialog=false}},model:{value:(_vm.uploadFileDialog),callback:function ($$v) {_vm.uploadFileDialog=$$v},expression:"uploadFileDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }