import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _dea85034 = () => interopDefault(import('../pages/analytics.vue' /* webpackChunkName: "pages/analytics" */))
const _23b7690e = () => interopDefault(import('../pages/calendar.vue' /* webpackChunkName: "pages/calendar" */))
const _57062649 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _e542f99c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _7d07a8f1 = () => interopDefault(import('../pages/pipelines.vue' /* webpackChunkName: "pages/pipelines" */))
const _53cee030 = () => interopDefault(import('../pages/signup.vue' /* webpackChunkName: "pages/signup" */))
const _23e50033 = () => interopDefault(import('../pages/stories.vue' /* webpackChunkName: "pages/stories" */))
const _8d20773c = () => interopDefault(import('../pages/test.vue' /* webpackChunkName: "pages/test" */))
const _2f675016 = () => interopDefault(import('../pages/accounts/activities.vue' /* webpackChunkName: "pages/accounts/activities" */))
const _60bab008 = () => interopDefault(import('../pages/accounts/integrations.vue' /* webpackChunkName: "pages/accounts/integrations" */))
const _af70ee80 = () => interopDefault(import('../pages/accounts/members.vue' /* webpackChunkName: "pages/accounts/members" */))
const _29d7a83c = () => interopDefault(import('../pages/accounts/overview.vue' /* webpackChunkName: "pages/accounts/overview" */))
const _253e4ea2 = () => interopDefault(import('../pages/accounts/time-trackers.vue' /* webpackChunkName: "pages/accounts/time-trackers" */))
const _56795276 = () => interopDefault(import('../pages/admin/accounts.vue' /* webpackChunkName: "pages/admin/accounts" */))
const _297fb326 = () => interopDefault(import('../pages/admin/analytics.vue' /* webpackChunkName: "pages/admin/analytics" */))
const _0df454ea = () => interopDefault(import('../pages/admin/database.vue' /* webpackChunkName: "pages/admin/database" */))
const _3439ec33 = () => interopDefault(import('../pages/admin/settings.vue' /* webpackChunkName: "pages/admin/settings" */))
const _7c90377e = () => interopDefault(import('../pages/admin/tests.vue' /* webpackChunkName: "pages/admin/tests" */))
const _1c4bd8a8 = () => interopDefault(import('../pages/admin/users.vue' /* webpackChunkName: "pages/admin/users" */))
const _5d37ef77 = () => interopDefault(import('../pages/admin/information/_id.vue' /* webpackChunkName: "pages/admin/information/_id" */))
const _0c3f6ef3 = () => interopDefault(import('../pages/repository/assets/_id.vue' /* webpackChunkName: "pages/repository/assets/_id" */))
const _3541612a = () => interopDefault(import('../pages/repository/embeds/_id.vue' /* webpackChunkName: "pages/repository/embeds/_id" */))
const _7df83426 = () => interopDefault(import('../pages/repository/snippets/_id.vue' /* webpackChunkName: "pages/repository/snippets/_id" */))
const _4055635d = () => interopDefault(import('../pages/achievers/_id.vue' /* webpackChunkName: "pages/achievers/_id" */))
const _74acef54 = () => interopDefault(import('../pages/audits/_pipeline_audit_id.vue' /* webpackChunkName: "pages/audits/_pipeline_audit_id" */))
const _47711b6e = () => interopDefault(import('../pages/campaigns/_id.vue' /* webpackChunkName: "pages/campaigns/_id" */))
const _dceaa7ac = () => interopDefault(import('../pages/confirm/_confirm_id.vue' /* webpackChunkName: "pages/confirm/_confirm_id" */))
const _6fe57654 = () => interopDefault(import('../pages/experiences/_id.vue' /* webpackChunkName: "pages/experiences/_id" */))
const _6b47a8c7 = () => interopDefault(import('../pages/information/_slug.vue' /* webpackChunkName: "pages/information/_slug" */))
const _4f708b1d = () => interopDefault(import('../pages/pipeline/_id.vue' /* webpackChunkName: "pages/pipeline/_id" */))
const _5b00e500 = () => interopDefault(import('../pages/story/_id.vue' /* webpackChunkName: "pages/story/_id" */))
const _396a82ce = () => interopDefault(import('../pages/tasks/_id.vue' /* webpackChunkName: "pages/tasks/_id" */))
const _46f68cfa = () => interopDefault(import('../pages/user/_tab.vue' /* webpackChunkName: "pages/user/_tab" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/analytics",
    component: _dea85034,
    name: "analytics___en"
  }, {
    path: "/calendar",
    component: _23b7690e,
    name: "calendar___en"
  }, {
    path: "/login",
    component: _57062649,
    name: "login___en"
  }, {
    path: "/nl",
    component: _e542f99c,
    name: "index___nl"
  }, {
    path: "/pipelines",
    component: _7d07a8f1,
    name: "pipelines___en"
  }, {
    path: "/signup",
    component: _53cee030,
    name: "signup___en"
  }, {
    path: "/stories",
    component: _23e50033,
    name: "stories___en"
  }, {
    path: "/test",
    component: _8d20773c,
    name: "test___en"
  }, {
    path: "/accounts/activities",
    component: _2f675016,
    name: "accounts-activities___en"
  }, {
    path: "/accounts/integrations",
    component: _60bab008,
    name: "accounts-integrations___en"
  }, {
    path: "/accounts/members",
    component: _af70ee80,
    name: "accounts-members___en"
  }, {
    path: "/accounts/overview",
    component: _29d7a83c,
    name: "accounts-overview___en"
  }, {
    path: "/accounts/time-trackers",
    component: _253e4ea2,
    name: "accounts-time-trackers___en"
  }, {
    path: "/admin/accounts",
    component: _56795276,
    name: "admin-accounts___en"
  }, {
    path: "/admin/analytics",
    component: _297fb326,
    name: "admin-analytics___en"
  }, {
    path: "/admin/database",
    component: _0df454ea,
    name: "admin-database___en"
  }, {
    path: "/admin/settings",
    component: _3439ec33,
    name: "admin-settings___en"
  }, {
    path: "/admin/tests",
    component: _7c90377e,
    name: "admin-tests___en"
  }, {
    path: "/admin/users",
    component: _1c4bd8a8,
    name: "admin-users___en"
  }, {
    path: "/nl/analytics",
    component: _dea85034,
    name: "analytics___nl"
  }, {
    path: "/nl/calendar",
    component: _23b7690e,
    name: "calendar___nl"
  }, {
    path: "/nl/login",
    component: _57062649,
    name: "login___nl"
  }, {
    path: "/nl/pipelines",
    component: _7d07a8f1,
    name: "pipelines___nl"
  }, {
    path: "/nl/signup",
    component: _53cee030,
    name: "signup___nl"
  }, {
    path: "/nl/stories",
    component: _23e50033,
    name: "stories___nl"
  }, {
    path: "/nl/test",
    component: _8d20773c,
    name: "test___nl"
  }, {
    path: "/nl/accounts/activities",
    component: _2f675016,
    name: "accounts-activities___nl"
  }, {
    path: "/nl/accounts/integrations",
    component: _60bab008,
    name: "accounts-integrations___nl"
  }, {
    path: "/nl/accounts/members",
    component: _af70ee80,
    name: "accounts-members___nl"
  }, {
    path: "/nl/accounts/overview",
    component: _29d7a83c,
    name: "accounts-overview___nl"
  }, {
    path: "/nl/accounts/time-trackers",
    component: _253e4ea2,
    name: "accounts-time-trackers___nl"
  }, {
    path: "/nl/admin/accounts",
    component: _56795276,
    name: "admin-accounts___nl"
  }, {
    path: "/nl/admin/analytics",
    component: _297fb326,
    name: "admin-analytics___nl"
  }, {
    path: "/nl/admin/database",
    component: _0df454ea,
    name: "admin-database___nl"
  }, {
    path: "/nl/admin/settings",
    component: _3439ec33,
    name: "admin-settings___nl"
  }, {
    path: "/nl/admin/tests",
    component: _7c90377e,
    name: "admin-tests___nl"
  }, {
    path: "/nl/admin/users",
    component: _1c4bd8a8,
    name: "admin-users___nl"
  }, {
    path: "/nl/admin/information/:id?",
    component: _5d37ef77,
    name: "admin-information-id___nl"
  }, {
    path: "/nl/repository/assets/:id?",
    component: _0c3f6ef3,
    name: "repository-assets-id___nl"
  }, {
    path: "/nl/repository/embeds/:id?",
    component: _3541612a,
    name: "repository-embeds-id___nl"
  }, {
    path: "/nl/repository/snippets/:id?",
    component: _7df83426,
    name: "repository-snippets-id___nl"
  }, {
    path: "/admin/information/:id?",
    component: _5d37ef77,
    name: "admin-information-id___en"
  }, {
    path: "/nl/achievers/:id?",
    component: _4055635d,
    name: "achievers-id___nl"
  }, {
    path: "/nl/audits/:pipeline_audit_id?",
    component: _74acef54,
    name: "audits-pipeline_audit_id___nl"
  }, {
    path: "/nl/campaigns/:id?",
    component: _47711b6e,
    name: "campaigns-id___nl"
  }, {
    path: "/nl/confirm/:confirm_id?",
    component: _dceaa7ac,
    name: "confirm-confirm_id___nl"
  }, {
    path: "/nl/experiences/:id?",
    component: _6fe57654,
    name: "experiences-id___nl"
  }, {
    path: "/nl/information/:slug?",
    component: _6b47a8c7,
    name: "information-slug___nl"
  }, {
    path: "/nl/pipeline/:id?",
    component: _4f708b1d,
    name: "pipeline-id___nl"
  }, {
    path: "/nl/story/:id?",
    component: _5b00e500,
    name: "story-id___nl"
  }, {
    path: "/nl/tasks/:id?",
    component: _396a82ce,
    name: "tasks-id___nl"
  }, {
    path: "/nl/user/:tab?",
    component: _46f68cfa,
    name: "user-tab___nl"
  }, {
    path: "/repository/assets/:id?",
    component: _0c3f6ef3,
    name: "repository-assets-id___en"
  }, {
    path: "/repository/embeds/:id?",
    component: _3541612a,
    name: "repository-embeds-id___en"
  }, {
    path: "/repository/snippets/:id?",
    component: _7df83426,
    name: "repository-snippets-id___en"
  }, {
    path: "/achievers/:id?",
    component: _4055635d,
    name: "achievers-id___en"
  }, {
    path: "/audits/:pipeline_audit_id?",
    component: _74acef54,
    name: "audits-pipeline_audit_id___en"
  }, {
    path: "/campaigns/:id?",
    component: _47711b6e,
    name: "campaigns-id___en"
  }, {
    path: "/confirm/:confirm_id?",
    component: _dceaa7ac,
    name: "confirm-confirm_id___en"
  }, {
    path: "/experiences/:id?",
    component: _6fe57654,
    name: "experiences-id___en"
  }, {
    path: "/information/:slug?",
    component: _6b47a8c7,
    name: "information-slug___en"
  }, {
    path: "/pipeline/:id?",
    component: _4f708b1d,
    name: "pipeline-id___en"
  }, {
    path: "/story/:id?",
    component: _5b00e500,
    name: "story-id___en"
  }, {
    path: "/tasks/:id?",
    component: _396a82ce,
    name: "tasks-id___en"
  }, {
    path: "/user/:tab?",
    component: _46f68cfa,
    name: "user-tab___en"
  }, {
    path: "/",
    component: _e542f99c,
    name: "index___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
