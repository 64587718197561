// Check if user is on a private page and authorized to access, if not redirect to /login
//
// Public pages are allowed if configured in nuxt.config.js :
//
//    publicRuntimeConfig: {
//      publicRoutes: ['/login', '/signup', '/confirm']
//    },
//
// Note: See ~/plugins/feathers/feathers-client.js  initializes login middle ware
//

import Log from '@/lib/log'

export default async function ({ $api, redirect, store, route, $config }) {
  // Log.w('[auth] Try log in on', route.fullPath, publicRoutes)
  try {
    await $api.authenticate()
  } catch (error) {
    // we use local storage so initial SSR will always fail to authenticate
    // so do not redirect if we are on server, as that will force a flash load of the Login screen
    if (process.browser) {
      let path = '/' + route.fullPath.split('/')[1] // '/login', '/confirm', '/signup , '/campaigns' ...
      path = path.split('?')[0] // '/login', '/confirm', '/signup , '/campaigns' ...
      Log.w(`[auth] route.fullPath=${route.fullPath} PATH = `, path)
      // only redirect if we are not on public path
      if (!$config.publicRoutes.includes(path) && !path.includes('/login')) {
        const PATH = encodeURIComponent(route.fullPath) || '/'
        const REDIRECT = `/login?path=${PATH}`
        Log.w(`[auth] Not authorized and "${path.substring(0, 120)}…" in ${route.fullPath} not public: ${error.code} ${error.message}. Redirect to /login`)
        return redirect(REDIRECT)
      }
    }
  }
}
