<!-- *** Show a badge with the level of consultant

<certified-consultant-badge :item="user" /> -->

<template>

  <v-icon
    v-if="item.certifiedConsultant"
    :small="small"
    :color="expert.color"
    class="mt-n1"
  >mdi-{{ expert.icon }}
  </v-icon>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    expert () {
      return (this.item.certifiedConsultant && this.$config.USERS.EXPERT_LEVELS[this.item.certifiedConsultant])
        ? this.$config.USERS.EXPERT_LEVELS[this.item.certifiedConsultant]
        : this.$config.USERS.EXPERT_LEVELS.none
    }
  }
}
</script>
