<template>
  <div class="d-flex flex-column pt-2" style="height: 100%;">

    <!-- width="300" -->
    <v-list dark>
      <nuxt-link to="/">
        <v-img
          v-if="$app_leftMiniDrawer"
          alt="Bligson Icon"
          :max-height="28"
          contain
          content-class="contain-v-img"
          :src="require('@/assets/logo/bligman-icon-white-v12.svg')"
        />
        <v-img
          v-else
          alt="Bligson Logo"
          :max-height="28"
          contain
          content-class="contain-v-img"
          :src="require('@/assets/logo/bligson-white-txt.svg')"
        />
      </nuxt-link>

      <v-divider class="mt-4" />

      <c-list-item
        to="/"
        :label="$t('dashboard.label')"
        :icon="$config.SERVICE_ICON.dashboard"
        class="pt-1"
        show-tooltip
      />

      <!-- Accounts sub menu -->
      <!-- <v-list-group color="info" class="" group="/accounts" append-icon="">
        <template v-slot:activator>
          <c-tooltip :max-width="300" :open-delay="600">
            <template #act>
              <div class="d-flex align-end mr-4">
                <v-icon color="white" class="">mdi-factory</v-icon>
                <v-icon small color="white">mdi-menu-down</v-icon>
              </div>
            </template>
            {{ $t('accounts.title') }}: {{ $t('accounts.subtitle') }}
          </c-tooltip>

          <v-list-item-title class="nav-item white--text" outlined>
            {{ $t('accounts.labels') }}
          </v-list-item-title>
        </template>

        <div class="info lighten-1 pl-3 body-2">
          <c-list-item
            to="/accounts/overview"
            :label="$t('accounts.overview')"
            :tooltip="$t('accounts.title') + ': ' + $t('accounts.subtitle')"
            :icon="$config.SERVICE_ICON.accounts"
            dense
          />
          <c-list-item
            to="/accounts/members"
            :label="$t('accounts.member.labels')"
            :tooltip="$t('accounts.member.title') + ': ' + $t('accounts.member.subtitle')"
            :icon="$config.SERVICE_ICON.users"
            dense
          />

          <c-list-item
            to="/accounts/time-trackers"
            :label="$t('time-trackers.labels')"
            :tooltip="$t('time-trackers.title') + ': ' + $t('time-trackers.subtitle')"
            :icon="$config.SERVICE_ICON['time-trackers']"
            dense
          />
          <c-list-item
            to="/accounts/activities"
            :label="$t('activities.labels')"
            :tooltip="$t('activities.title') + ': ' + $t('activities.subtitle')"
            :icon="$config.SERVICE_ICON.activities"
            dense
          />
          <c-list-item
            to="/accounts/integrations"
            :label="$t('integrations.labels')"
            :tooltip="$t('integrations.title') + ': ' + $t('integrations.subtitle')"
            :icon="$config.SERVICE_ICON.integrations"
            dense
          />
        </div>
      </v-list-group> -->

      <v-divider />
      <c-list-item
        to="/achievers"
        :label="$t('achievers.labels')"
        :tooltip="$t('achievers.title') + ': ' + $t('achievers.subtitle')"
        :icon="$config.SERVICE_ICON.achievers"
      />
      <c-list-item
        to="/experiences"
        :label="$t('experiences.labels')"
        :tooltip="$t('experiences.title') + ': ' + $t('experiences.subtitle')"
        :icon="$config.SERVICE_ICON.experiences"
      />
      <c-list-item
        to="/stories"
        :label="$t('stories.labels')"
        :tooltip="$t('stories.title') + ': ' + $t('stories.subtitle')"
        :icon="$config.SERVICE_ICON.stories"
      />
      <!-- Repository / Database sub-menu -->
      <v-list-group color="info" class="" group="/repository" append-icon="">
        <!-- group="^(/stories|/assets|/achievements)" -->
        <template v-slot:activator>
          <c-tooltip :max-width="300" :open-delay="600">
            <template #act>
              <div class="d-flex align-end mr-4">
                <v-icon color="white" class="">mdi-database-outline</v-icon>
                <v-icon small color="white">mdi-menu-down</v-icon>
              </div>
            </template>
            {{ $t('repository.title') }}: {{ $t('repository.subtitle') }}
          </c-tooltip>

          <v-list-item-title class="nav-item white--text" outlined>
            {{ $t('repository.label') }}
          </v-list-item-title>
        </template>

        <div class="info lighten-1 pl-3 body-2">
          <c-list-item
            to="/repository/assets"
            dense
            :label="$t('assets.labels')"
            :tooltip="$t('assets.title') + ': ' + $t('assets.subtitle')"
            :icon="$config.SERVICE_ICON.assets"
          />
          <c-list-item
            to="/repository/embeds"
            dense
            :label="$t('embeds.labels')"
            :tooltip="$t('embeds.title') + ': ' + $t('embeds.subtitle')"
            :icon="$config.SERVICE_ICON.embeds"
          />
          <c-list-item
            to="/repository/snippets"
            dense
            :label="$t('snippets.labels')"
            :tooltip="$t('snippets.title') + ': ' + $t('snippets.subtitle')"
            :icon="$config.SERVICE_ICON.snippets"
          />
        </div>
      </v-list-group>

      <template v-if="$db.doc('accounts').marketingMenuActivated">
        <v-divider />

        <c-list-item
          to="/calendar"
          :label="$t('calendar.label')"
          :tooltip="$t('calendar.title') + ': ' + $t('calendar.subtitle')"
          :icon="$config.SERVICE_ICON.calendar"
        />
        <c-list-item
          to="/campaigns"
          :label="$t('campaigns.labels')"
          :tooltip="$t('campaigns.title') + ': ' + $t('campaigns.subtitle')"
          :icon="$config.SERVICE_ICON.campaigns"
        />
        <c-list-item
          to="/pipelines"
          :label="$t('pipelines.labels')"
          :tooltip="$t('pipelines.title') + ': ' + $t('pipelines.subtitle')"
          :icon="$config.SERVICE_ICON.pipelines"
        />
        <c-list-item
          to="/tasks"
          :label="$t('tasks.labels')"
          :tooltip="$t('tasks.title') + ': ' + $t('tasks.subtitle')"
          :icon="$config.SERVICE_ICON.tasks"
        />

        <c-list-item
          to="/audits"
          :label="$t('audits.label')"
          :tooltip="$t('audits.title') + ': ' + $t('audits.subtitle')"
          :icon="$config.SERVICE_ICON.audits"
        />
      </template>

      <v-divider />

      <c-list-item
        to="/information"
        :label="$t('info-articles.label')"
        :tooltip="$t('info-articles.title') + ': ' + $t('info-articles.subtitle')"
        :icon="$config.SERVICE_ICON['info-articles']"
      />
      <!-- <c-list-item to="/specialists" :label="$t('specialists.label')" icon="head-check-outline" show-tooltip /> -->

      <!-- ** Admin only menu -->
      <v-list-group v-if="$db.isAdmin" class="mt-6 info darken-1" color="Juneberry" group="/admin" append-icon="">
        <template v-slot:activator>
          <div class="d-flex align-end mr-4">
            <v-icon color="grey" class="">mdi-head-cog-outline</v-icon>
            <v-icon small color="grey">mdi-menu-down</v-icon>
          </div>

          <v-list-item-title class="nav-item grey--text" outlined>
            {{ $t('admin.label') }}
          </v-list-item-title>
        </template>

        <div class="Juneberry darken-1 body-2 pl-3">
          <c-list-item to="/admin/users" :label="$t('user.labels')" :icon="$config.SERVICE_ICON.users" color="primary" dense show-tooltip />
          <c-list-item to="/admin/accounts" :label="$t('accounts.labels')" :icon="$config.SERVICE_ICON.accounts" color="primary" dense show-tooltip />
          <c-list-item to="/admin/database" :label="$t('table.databases')" :icon="$config.SERVICE_ICON.database" color="primary" dense show-tooltip />
          <c-list-item to="/admin/information" :label="$t('info-articles.table')" :icon="$config.SERVICE_ICON.information" color="primary" dense show-tooltip />
          <c-list-item to="/admin/analytics" :label="$t('analytics.label')" :icon="$config.SERVICE_ICON.analytics" color="primary" dense show-tooltip />
          <c-list-item to="/admin/settings" :label="$t('admin.settings.label')" :icon="$config.SERVICE_ICON.settings" color="primary" dense show-tooltip />
        </div>

        <!-- <c-list-item to="/admin/tests" label="test" show-tooltip /> -->

      </v-list-group>
    </v-list>

    <!-- ** (c) copyright and panel minimize drawer footer  -->
    <v-list class="mt-auto">
      <v-divider />

      <v-list-item class="align-center pt-3 ml-n2 mr-n3">
        <!-- Copyright note -->
        <template v-if="!$app_leftMiniDrawer">
          <copyright-footer />
          <v-spacer />
        </template>

        <!-- miniDrawer setting button -->
        <v-btn icon @click.stop="$app_leftMiniDrawer = !$app_leftMiniDrawer; $app_leftDrawer = true">
          <v-icon color="grey lighten-1">mdi-arrow-expand-{{ $app_leftMiniDrawer ? 'right': 'left' }}</v-icon>
        </v-btn>
      </v-list-item>
    </v-list>

  </div>

</template>

<script>
import CopyrightFooter from '@/components/_default/drawers/CopyrightFooter'

export default {
  components: {
    CopyrightFooter
  },
  data (vm) {
    return {
      adminColor: 'white'
    }
  }
}
</script>
