// https://github.com/thecodealer/vue-panzoom
// https://github.com/anvaka/panzoom

// @NOTE: v1.1.3 is essential, see https://github.com/thecodealer/vue-panzoom/issues/27
//   npm install vue-panzoom@1.1.3
//   Otherwise "Object not a function" errors

import Vue from 'vue'
import VuePanZoom from 'vue-panzoom'

Vue.use(VuePanZoom, { componentName: 'v-panzoom' })
