/* Campaigns */

import { initialStateProperties } from '@/plugins/vuex-injects/vuex'

const oneYearFromNow = new Date()
oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1)

export default initialStateProperties({
  service: 'campaigns',
  channelSubscription: true, // see plugins/feathers/feathers-client.js

  defaultQuery: { archived: false, accountId: true },
  // Note: to override / remove a defaultQuery key, you can set to null: { archived: null }

  initData: {
    name: '',
    accountId: '',
    protected: true, // { type: Boolean, default: true }, // if protected, then can not delete (front end function only)
    protect: false, // if true can not by accident delete this campaign
    archived: false,
    tags: [],
    targetAudience: '',
    description: '',
    workflowStrategies: [], // [{ type: Number, index: true, enum: workflowStrategies.map(i => i.id) }], // [ 107, 108 ]
    startAt: new Date().toISOString(),
    endAt: oneYearFromNow.toISOString()
  },

  populatedData: {
    account: {},
    pipelines: [],

    // afterCommitHook below
    status: ''
  },

  afterCommitHook: (state, item) => {
    if (new Date(item.startAt) < new Date() && new Date(item.endAt) > new Date()) {
      item.status = 'on'
    } else if (new Date(item.startAt) > new Date()) {
      item.status = 'plan'
    } else if (new Date(item.endAt) < new Date()) {
      item.status = 'fin'
    } else {
      item.status = ''
    }
  }
})
