/* Tasks */

import { initialStateProperties } from '~/plugins/vuex-injects/vuex'

const halfYear = new Date()
halfYear.setMonth(new Date().getMonth() + 6)

export default initialStateProperties({
  service: 'tasks',
  channelSubscription: true, // see plugins/feathers/feathers-client.js

  defaultQuery: { accountId: true, $sort: { createdAt: -1 } },

  initData: {
    accountId: '',
    pipelineId: '', // { type: String, ref: 'pipelines', required: true, index: 1},

    stage: 'str', // { type: String, enum: [ 'str', 'con', 'ver', 'pub', 'int' ] },
    status: 'pln', // { type: String, enum: ['pln', 'ong', 'fin', 'arch'] },
    listIndex: 0, // { type: Number }, sort order in each Task Colum on Canvas board

    name: '',
    description: '',
    infoSlug: '', // { ...slug, required: false } // optional link to a "more info" page
    color: '#aaaaaa',

    ownerId: '',
    memberIds: [],

    storyIds: [],
    embedIds: [], // [{ type: String, ref: 'embeds' }],
    snippetIds: [], // [{ type: String, ref: 'snippets' }],
    assetIds: [],

    // checklist: see populated
    // caching totals after TaskEdit so pipeline also sees them
    checklistStats: {
      total: 0, // length of checklist
      select: 0 // nr selected
    },

    tags: [],
    dueAt: halfYear.toISOString(), // Date when task should be ready,
    updatedAt: null // to force update checklist, see TaskEdit
  },

  populatedData: {
    account: {},
    pipeline: {},
    pipelineName: '' // provided by afterCommitHook

    // populated from checklist-items service
    // checklist: [
    //   // { taskId, select, text, audit: { type, value, factor, infoSlug } } // type: enum: ['', 'progress', 'switch', 'stepper']
    // ]
  },

  afterCommitHook: (state, item) => {
    // to allow easy filter in TaskTable
    item.pipelineName = item.pipeline ? item.pipeline.name : ''
  }
})
