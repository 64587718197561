/* Currencies and rates */

import { initialStateProperties } from '~/plugins/vuex-injects/vuex'

export default initialStateProperties({
  service: 'currency',
  loadCollectionOnStart: true,

  initData: {
    currency: 'EUR',
    symbol: '€',
    label: 'Euro',
    rate: 1.00
  }
})
