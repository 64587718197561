<template>
  <v-row>
    <v-col cols="8" md="4">
      <v-text-field
        v-model="item.title"
        outlined
        hide-details="auto"
        :label="$t('snippets.field.title')"
        :rules="[$validRule.required, $validRule.maxLength(100).val ]"
        @input="item.title = item.title.trim()"
      />
    </v-col>

    <v-col cols="4" md="3">
      <v-checkbox
        v-model="item.officiallyEndorsed"
        dense
        :label="$t('snippets.field.officially_endorsed')"
        hide-details
      />
    </v-col>

    <v-col cols="12" md="5">
      <select-tags
        v-model="item.tags"
        :label="$t('def.tags')"
        dense
        outlined
      />
    </v-col>

    <v-col cols="12">
      <v-textarea
        v-model="item.textSnippet"
        outlined
        :label="$t('snippets.field.text_snippet')"
        auto-grow
        :rows="4"
        counter
        :rules="[$validRule.maxLength(10000).val ]"
      />
    </v-col>

  </v-row>
</template>

<script>
import Log from '@/lib/log' // eslint-disable-line no-unused-vars

import SelectTags from '@/components/_lib/form/tags/SelectTags'

export default {
  name: 'SnippetEdit',
  components: {
    SelectTags
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  data (vm) {
    return {
      editSnippetDialog: false
    }
  }

}
</script>
