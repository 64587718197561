<!-- ** Show button with (i) button and context sensitive information

Examples:

  <c-info-btn slug="camp-examples" button-class="ml-2" />

Show info button if there is an article with slug matching current path. Example: path=  /admin/users  will find slug 'admin-users'
  <c-info-btn icon-color="info lighten-2"/>

Show title instead of icon:
  <c-info-btn slug="camp-examples" show-title />

Show alert/tip that can be dismissed

  <c-info-btn
    slug="camp-intro"
    alert
    alert-class="float-left"
    alert-dismissible
    alert-button="open"
    @alertAction="$app_rightDrawer=true"
  />
-->

<template>
  <div :style="wide ? 'width: 100% !important;' : ''" class="no-print">
    <div v-if="realSlug">
      <!-- Tip that shows always, until dismissed -->
      <template v-if="alert && article._id">
        <v-expand-transition>
          <v-card v-if="showAlert" class="background mt-2" :class="alertClass">
            <v-system-bar :color="alertColor" :dark="dark" class="py-4 pr-0">
              <v-spacer />
              <v-btn icon @click="showAlert=false"><v-icon>mdi-close</v-icon></v-btn>
            </v-system-bar>

            <v-card-text>
              <span class="font-weight-bold mr-8">{{ article.title }}</span>

              <div v-if="showExcerpt" class="my-4" v-html="article.excerpt" />

              <div v-if="showBodyHtml" class="my-4" v-html="articleBody" />

              <div v-if="alertButton" class="d-flex justify-end">
                <v-btn text class="mr-4" @click="$emit('alertAction')">{{ alertButton }}</v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-expand-transition>
      </template>

      <v-btn v-if="!showAlert" icon @click="showAlert=true; loadBody()">
        <v-icon :color="iconColor" :small="iconSmall">mdi-{{ icon }}</v-icon>
      </v-btn>

      <!-- Show (i) button and dialog when clicked -->
      <c-dialog-btn
        v-else-if="!alert"
        :button-label="showTitle ? article.title : ''"
        :title="article.title"
        :icon-color="iconColor"
        icon-super-script
        :icon="icon"
        :small="iconSmall"
      >
        <div class="my-4 blog-post-view info-card" v-html="article.excerpt" />

        <template #left-actions>
          <c-link v-if="$db.isAdmin" :to="`/admin/information/${article._id }`">
            <span class="caption">{{ article.slug }}</span>
          </c-link>
          <div v-else class="info--text caption ml-2">
            {{ article.slug }}
          </div>
        </template>

        <template #right-actions>
          <c-nav-btn color="info" :to="`/information/${realSlug}`" text>
            {{ $t('def.read') }}
          </c-nav-btn>
        </template>
      </c-dialog-btn>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import Log from '@/lib/log'

export default {
  props: {
    slug: {
      type: String,
      default: '' // true: will set the slug to the 'camp-intro', 'pipe-tutorial',..
    },
    icon: {
      type: String,
      default: 'information-outline'
    },
    iconColor: {
      type: String,
      default: 'grey'
    },
    iconSmall: {
      type: Boolean,
      default: false
    },
    // show the title instead of an icon show the icon that displays a popup dialog
    wide: {
      type: Boolean,
      default: false
    },
    showTitle: {
      type: Boolean,
      default: false
    },
    // shows the full text of the article under the excerpt
    showBodyHtml: {
      type: Boolean,
      default: false
    },
    showExcerpt: {
      type: Boolean,
      default: true
    },

    // if alert then show sticky message that show immediately, until dismissed
    dark: {
      type: Boolean,
      default: false
    },
    alert: {
      type: Boolean,
      default: false
    },
    alertDismissible: {
      type: Boolean,
      default: false
    },
    alertButton: {
      type: String,
      default: ''
    },
    alertColor: {
      type: String,
      default: 'grey'
    },
    // the class of the v-card that appears when clicking on the icon
    alertClass: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      articleBody: ''
    }
  },
  computed: {
    infoArticlesSlugs () {
      return this.$db.reduceCollection('info-articles', 'slug')
    },
    realSlug () {
      let realSlug = 'not-found'
      if (this.slug !== '') {
        realSlug = this.slug
        // Log.i('[i] infoButton slug from prop = ', realSlug)
      } else {
        // remove leading '/'  and replace all '/' with '-'
        const slug = this.$router.currentRoute.path.substr(1).replace('/', '-').replace('/', '-').replace('/', '-')
        if (this.infoArticlesSlugs.includes(slug)) {
          realSlug = slug
          // Log.i('[i] infoButton slug from ', this.$router.currentRoute.path, ' set to:', slug)
        } else {
          // Log.i(`[i] no article found for infoButton slug "${slug}"`)
          realSlug = ''
        }
      }
      return realSlug
    },
    showAlert: {
      get () {
        // In $app_infoTipsSeen we store all the slugs that the user decided to never see again
        return !this.$app_infoTipsSeen.includes(this.realSlug)
      },
      set (value) {
        if (this.$app_infoTipsSeen.includes(this.realSlug)) {
          // we want to see the tip again please
          this.$app_infoTipsSeen = this.$app_infoTipsSeen.replace(`,${this.realSlug}`, '')
        } else {
          // do not show this tip again
          this.$app_infoTipsSeen = this.$app_infoTipsSeen += `,${this.realSlug}`
        }
      }
    },
    article () {
      const article = this.$db.collection('info-articles', { $limit: 1, slug: this.realSlug })
      return article || {
        title: this.$i18n.t('info-articles.not_found.title'),
        excerpt: this.$i18n.t('info-articles.not_found.html', { slug: this.realSlug })
      }
    }
  },
  watch: {
    'article._id' () {
      this.loadBody()
    }
  },
  async mounted () {
    await this.loadBody()
  },
  methods: {
    async loadBody () {
      // Log.w(`[info-button] showBodyHtml ${this.showBodyHtml} alert= ${this.showAlert} article id =${this.article._id}`)
      if (this.showBodyHtml && this.showAlert && this.article._id) {
        Log.i(`Loading body ${this.slug} ${this.article._id}`)
        this.articleBody = (await this.$db.get('info-articles', this.article._id, { $cache: true })).bodyHtml || ''
      }
    }
  }
}
</script>
