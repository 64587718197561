<!--

  <c-date-picker v-model="startAt" />

  <c-date-picker
      v-model="startAt"
      :dialog-width="500"
      time-picker
      :error="invalidDateRange"
      outlined
      init-date="2030-01-02T03:04:00.000+00:00"
    />
 -->

<template>
  <div class="mb-2">
    <div class="d-flex align-center">
      <v-btn v-if="outlined" :color="error ? 'error': 'foreground-2'" outlined class="px-2" style="min-width: 80px;" @click="openPicker()">
        <c-date :date="value" :date-only="!timePicker" class="body-2 text-capitalize" />
      </v-btn>

      <a v-else href="#" @click="openPicker()">
        <c-date :date="value" :date-only="!timePicker" :class="`body-2 ${error ? 'error--text font-weight-medium': ''}`" />
      </a>

      <v-btn v-if="clearable" icon small @click="clearDate()">
        <v-icon small color="primary">mdi-close</v-icon>
      </v-btn>
    </div>

    <!-- hidden input to enable validation -->
    <v-input
      :error="error"
      :value="value"
      readonly
      hide-details="auto"
      :rules="rules"
      style="display: hidden;"
    />

    <v-dialog v-model="dialog" :width="dialogWidth">
      <v-card>
        <v-row v-if="timePicker" no-gutters>
          <v-col class="d-flex align-center justify-center py-2">
            <v-icon :color="tab===0 ? 'primary': 'background-2'" @click="tab=0">mdi-calendar</v-icon>
          </v-col>

          <v-col class="d-flex align-center justify-center py-2">
            <v-icon :color="tab===1 ? 'primary': 'background-2'" @click="tab=1">mdi-clock</v-icon>
          </v-col>
        </v-row>

        <v-date-picker
          v-if="tab===0"
          :value="dateStr"
          :disabled="disabled"
          color="primary"
          full-width
          class="mb-4"
          @input="setDateValue"
        />

        <v-time-picker
          v-else
          :value="timeStr"
          color="primary"
          :disabled="disabled"
          full-width
          :format="$db.currentUser.localization.time24Hours ? '24hr':'ampm' "
          @input="setTimeValue"
        />

        <v-card-actions class="mt-0 py-0">
          <v-btn color="info" text @click="resetDate()">{{ $t('def.reset') }}</v-btn>
          <v-spacer />
          <span v-if="dialogWidth >= 300" class="caption background-2--text">{{ dateStr }} {{ timeStr }}</span>
          <v-spacer />
          <v-btn color="info" text @click="dialog=false">{{ $t('def.ok') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import Log from '@/lib/log'

export default {
  props: {
    // v-model prop in ISOString format: "2011-10-05T14:48:00.000Z"
    value: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    timePicker: {
      type: Boolean,
      default: false
    },
    // Example:  :rules="[$validRule.required]"
    rules: {
      type: Array,
      default: () => []
    },
    dialogWidth: {
      type: Number,
      default: 300
    },
    outlined: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    // show button to set date to null
    clearable: {
      type: Boolean,
      default: false
    }

  },
  data () {
    return {
      dialog: false,
      previousValue: '',
      tab: 0
    }
  },
  computed: {
    // Return str from value, such as '2021-03-31'
    dateStr () {
      const d = new Date(this.value || new Date().toISOString())
      return `${d.getFullYear()}-${(d.getMonth() + 1).toString().padStart(2, '0')}-${d.getDate().toString().padStart(2, '0')}`
    },
    // Return str from value, such as '03:45'
    timeStr () {
      const d = new Date(this.value || new Date().toISOString())
      return `${d.getHours().toString().padStart(2, '0')}:${d.getMinutes().toString().padStart(2, '0')}`
    },
    viewStr () {
      return `${this.dateStr} ${this.timePicker ? this.timeStr : ''}`
    }
  },
  mounted () {
    this.previousValue = this.value
    if (this.$refs.form) {
      this.$refs.form.validate()
    }
  },
  methods: {
    setDateValue (dateStr) {
      this.setValue(dateStr, this.timeStr)
      if (this.timePicker) { this.tab = 1 }
    },
    setTimeValue (timeStr) {
      this.setValue(this.dateStr, timeStr)
    },
    setValue (dateStr, timeStr) {
      const d = new Date()

      // the date
      const year = Number(dateStr.substring(0, 4))
      const month = Number(dateStr.substring(5, 7))
      const day = Number(dateStr.substring(8, 10))
      d.setFullYear(year)
      d.setMonth(month - 1)
      d.setDate(day)

      // the time
      const timeArr = timeStr.split(':')
      const hour = parseInt(timeArr[0])
      const minutes = parseInt(timeArr[1])
      d.setHours(hour)
      d.setMinutes(minutes)
      d.setSeconds(0)

      // Log.i('[c-date-picker] date set to: ', d)
      this.$emit('input', d.toISOString())
    },
    resetDate () {
      this.dialog = false
      this.$emit('input', this.previousValue)
    },
    clearDate () {
      this.dialog = false
      this.$emit('input', '')
    },
    openPicker () {
      this.dialog = true
      this.tab = 0
      this.previousValue = this.value
    }

  }
}

</script>
