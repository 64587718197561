
 <!-- Show a button group
  - items show all the buttons,  [ { id: '', value: {}, icon: '' },...]
  - id + i18nPrefix needed for dynamic labels and tooltips:
     prefix for $t(`${i18nPrefix}.${item.id}.label`) and .tooltip

Examples:

  <c-btn-select-object v-model="provider" :items="auditTypes" color="primary" />

   auditTypes: [
    { id: 'progress', value: 'progress', icon: 'progress-check' },
    { id: 'switch', value: 'switch', icon: 'toggle-switch-outline' },
    { id: 'stepper', value: 'stepper', icon: 'electric-switch-closed' },
    { id: 'none', value: '', icon: 'clear' }
  ]

   <c-btn-select-object
      v-model="archivedQuery"
      :items="selectOptions"
      i18n-prefix="pipelines.select"
   />
   selectOptions: [
        { id: 'archived.all', value: { archived: null }, icon: vm.$config.SELECT_ICON.all },
        { id: 'archived.hide', value: { archived: false }, icon: vm.$config.SELECT_ICON.archived.false },
        { id: 'archived.act', value: { archived: true }, icon: vm.$config.SELECT_ICON.archived.true }
      ]

-->

<template>
  <v-btn-toggle v-model="buttonValue" :class="notSelectedClass" style="display: inline-table;">
    <v-btn
      v-for="(item,index) in items"
      :key="index"
      small
      :text="showLabels"
      :icon="!showLabels"
      :class="`${ JSON.stringify(item.value) === JSON.stringify(value) ? selectedClass: ''}`"
    >
      <c-tooltip>
        <template #act>
          <v-icon small :color="`${ (JSON.stringify(item.value) === JSON.stringify(value)) ? color : `grey ${$app_darkMode ? 'lighten-1':'darken-1'}`}`">
            mdi-{{ item.icon }}
          </v-icon>

          <span
            v-if="showLabels"
            class="ml-1 hidden-sm-and-down"
            :class="JSON.stringify(item.value) === JSON.stringify(value) ? selectedClass: ''"
          >
            {{ $t(`${i18nPrefix}.${item.id}.label`) }}
          </span>
        </template>

        <span class="text-lowercase">{{ $t(`${i18nPrefix}.${item.id}.tooltip`) }}</span>
      </c-tooltip>
    </v-btn>
  </v-btn-toggle>
</template>

<script>
export default {
  props: {
    value: {
      type: [Object, String],
      default: () => {}
    },
    // array with all possible values definitions:  { id: '', value: {}, icon: '' }
    items: {
      type: Array,
      default: () => []
    },
    showLabels: {
      type: Boolean,
      default: false
    },
    // prefix for $t( ) used in combination with item.id and .label / .tooltip
    i18nPrefix: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'background-1'
    },
    selectedClass: {
      type: String,
      default: 'foreground-3'
    },
    notSelectedClass: {
      type: String,
      default: 'background'
    }

  },
  computed: {
    itemIndex () {
      return this.items.findIndex(i => JSON.stringify(i.value) === JSON.stringify(this.value))
    },
    buttonValue: {
      get () { return this.value },
      set (value) { this.$emit('input', this.items[value].value) }
    }
  },
  methods: {
    selected () {

    }
  }
}
</script>
