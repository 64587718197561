<!-- ** Generic Table component **

Show a table for a specified service

Slots:
  left-toolbar // for example for additional filters
  right-toolbar  // for example for additional buttons / icons
  left-footer // in custom footer
  right-footer

  item-table-row // each specific row of the table, use <gen-td>...</gen-td> for each column/field
  item-edit-form // popup dialog
  item-expand-pane // for the view form, between each row; set prop :show-expand="true"
  item-edit-actions // additional button possible in the middle of the action bar of the edit popup

  gallery-card="{ item, gallerySmall }" // the gallery card contents
  gallery-empty // to show when no items
  gallery-card-popup="{ item, gallerySmall }" // dialog appears when clicked on a gallery card and prop: show-gallery-dialog=true
  gallery-card-popup-actions="{ item, gallerySmall }" // the footer in this dialog

Events:
  @action click on one of the row icons
  @select click on a row or  the result of one or more selects in the gallery
  @filter change of the table filter
  @itemCreated emits a newly created item;
  @itemChanged item that was changed and saved to the database
  @itemRemoved emits after removing an item

 -->

<template>
  <div class="general-data-table">
    <!-- *** TOP BAR *** with filters, search, refresh,.. buttons -->
    <table-top-bar
      :filter-list="filterList"
      :title="title"
      :hide-new="hideNew"
      :hide-tag-select="!showTagSelect"
      :hide-search="hideSearch"
      :hide-filter="hideFilter"
      :disabled-new="!hasRights(null,'create')"
      :filter-placeholder="filterPlaceholder"
      @updateQuery="tagQuery=$event; reloadItems();"
      @updateFilter="tableFilter=$event"
      @updateSearch="tableSearch=$event"
      @new="newItem"
      @refresh="reloadItems()"
    >
      <!-- * TOOLBAR LEFT & RIGHT * -->
      <template v-slot:left-toolbar>
        <c-icon-btn
          v-if="showGalleryToggle "
          icon="table"
          :color="galleryView ? 'primary' : 'foreground-3'"
          i18n-tooltip-text="table.tooltip.gallery"
          @click="galleryView = !galleryView"
        />

        <c-icon-btn
          v-if="!hideGallerySizeToggle && galleryView"
          icon="resize"
          :small="!gallerySmall"
          color="primary"
          i18n-tooltip-text="table.tooltip.resize"
          @click="gallerySmall = !gallerySmall"
        />

        <slot name="left-toolbar" :hideFilter="filterOnItemId" />

        <!-- Clear filer: User can override the filter to only show one record -->
        <v-btn
          v-if="filterItemId && filterOnItemId"
          small
          outlined
          color="primary"
          class="my-n1"
          @click="filterOnItemId=false"
        >
          {{ filterItemId }}
          <v-icon small color="primary" class="ml-2 mr-n1">mdi-close</v-icon>
        </v-btn>
      </template>

      <template v-slot:right-toolbar>
        <!-- Download button if so required -->
        <c-alert-btn
          v-if="showDownload"
          :tooltip="$t('table.download.tooltip')"
          icon-color="primary"
          icon="download"
          type="info"
          small
          @ok="download()"
        >
          {{ $t('table.download.sure') }}
        </c-alert-btn>

        <slot name="right-toolbar" />
      </template>
    </table-top-bar>

    <!-- *** GALLERY *** (instead of the default table) -->
    <template v-if="galleryView">
      <v-divider class="mb-2" />

      <div class="flex-grid" :style="`--card_width: ${gallerySmall ? 78 : 106}px; --gap: ${gallerySmall ? 2 : 6}px;`">
        <template v-if="items.length>0">
          <gallery-card
            v-for="item in items"
            :key="item._id"
            v-model="selectedIds"
            :item="item"
            :hide-edit="hideEdit"
            :selectable="selectable"
            :select-multiple="selectMultiple"
            :small="gallerySmall"
            @edit="editItem"
            @select="$emit('select', $event);"
            @click="galleryClickDialog=showGalleryDialog; currentItem=$event"
          >
            <slot name="gallery-card" :item="item" :gallerySmall="gallerySmall" />
          </gallery-card>
        </template>

        <!-- the table is empty -->
        <v-card v-else tile flat elevation="2" height="80" class="d-flex align-center" style="overflow:hidden;">
          <div style="width: 100%;">
            <div class="d-flex justify-center">
              <c-icon-btn
                v-if="showNewOnEmpty"
                icon="plus"
                color="primary"
                i18n-tooltip-text="table.tooltip.add_new"
                @click="newItem ()"
              />
            </div>
            <div class="d-flex justify-center px-1">
              <slot name="gallery-empty" />
            </div>
          </div>
        </v-card>
      </div>
    </template>

    <!-- *** no GalleryView so TABLE *** @NOTE: use <client-only>: https://github.com/vuetifyjs/vuetify/issues/7410 -->
    <template v-else>
      <v-data-table
        :headers="tableHeader"
        :items="items"
        :items-per-page="setItemsPerPage"
        :page.sync="page"
        hide-default-footer
        item-key="_id"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        :search="tableSearch || (filterCustom ? '' : tableFilter)"
        :mobile-breakpoint="200"
        :footer-props="{'items-per-page-text': $t('table.footer.items_per_page')}"
        @page-count="pageCount = $event"
      >
        <!-- *** ROW *** -->
        <template v-slot:item="{ item }">
          <tr
            :class="{'cursor-pointer': !hideRowClick}"
            @click.stop="if (!hideRowClick) {toggleExpand(item)}"
            @click="$emit('select',item)"
          >
            <slot name="item-table-row" :item="item" />

            <!--  the buttons, delete, edit, drop  down -->
            <td>
              <div class="d-flex justify-end align-center">
                <template v-if="!smallScreen || !showExpand">
                  <v-btn
                    v-for="(icon,index) in actionIcons"
                    :key="index"
                    :disabled="!hasRights(item, icon)"
                    icon
                    small
                    @click.stop="customAction({ icon, item })"
                  >
                    <v-icon color="primary" small>mdi-{{ icon === 'edit' ? 'pencil': icon }}</v-icon>
                  </v-btn>
                </template>

                <!-- The drop down expand button -->
                <v-btn v-if="showExpand" small icon @click.stop="toggleExpand(item)">
                  <v-icon>mdi-chevron-{{ expanded.includes(item._id)? 'up' :'down' }}</v-icon>
                </v-btn>
              </div>
            </td>
          </tr>

          <!-- * VIEW PANE *  -->
          <tr v-if="showExpand && expanded.includes(item._id)" class="view-pane background-1">
            <td :colspan="tableHeader.length">
              <!-- the view contents -->
              <div
                class="pt-2"
                :class="[{'background-1': !$app_darkMode, }, 'pb-4 view-inset']"
                style="width: 100% !important;"
                @click="toggleExpand(item)"
              >
                <c-spacer clear />
                <slot name="item-expand-pane" :item="item" />
              </div>

              <!-- Buttons in the view pane if there is no space in the table rows -->
              <div v-if="smallScreen" class="d-flex mr-2">
                <v-spacer />
                <v-btn
                  v-for="(icon,index) in actionIcons"
                  :key="index"
                  icon
                  :disabled="!hasRights(item, icon)"
                  @click.stop="customAction({ icon, item })"
                >
                  <v-icon color="primary" small>mdi-{{ icon === 'edit' ? 'pencil': icon }}</v-icon>
                </v-btn>
              </div>
            </td>
          </tr>
        </template>
      </v-data-table>

      <!-- Custom footer -->
      <div class="d-flex align-center pt-2 pb-1 v-data-footer">
        <slot name="left-footer" />
        <v-spacer />

        <v-select
          v-model="setItemsPerPage"
          :items="itemsPerPageOptions"
          dense
          hide-details
          style="max-width: 60px;"
          class="mt-n1 "
        />

        <v-pagination v-model="page" :length="pageCount" dense />

        <span class="body-2">{{ items.length }}</span>

        <v-spacer />
        <slot name="right-footer" />
      </div>
    </template>

    <!-- *** EDIT ITEM popup ***-->
    <edit-item-dialog
      v-model="editDialog"
      :service="service"
      :width="dialogWide ? 1280 : 1000"
      :item-id="currentItem._id"
      :title-bar-color=" ((typeof dialogBarColor === 'function') ? dialogBarColor(currentItem) : dialogBarColor) || service"
      :title="dialogTitle"
      :title-text-color="dialogTitleTextColor"
      :hide-activity-drawer="!currentItem._id"
      :hide-delete="!hasRights(currentItem, 'delete')"
      :hide-edit="!hasRights(currentItem, 'edit')"
      :info-slug="infoSlug"
      @closeDialog="editDialog= false"
      @deleteDialog="deleteDialog=$event"
      @itemCreated="$emit('itemCreated', $event)"
      @itemChanged="$emit('itemChanged', $event)"
    >
      <template v-slot:item-edit-form="{ item }">
        <slot name="item-edit-form" :item="item" />
      </template>
      <template v-slot:item-edit-actions="{ item }">
        <slot name="item-edit-actions" :item="item" />
      </template>
    </edit-item-dialog>

    <!-- GALLERY CLICK POPUP -->
    <v-dialog
      v-if="showGalleryDialog"
      v-model="galleryClickDialog"
      :fullscreen="$app_isMobile"
      :width="dialogWide ? 1280 : 1000"
      scrollable
      @click:outside="galleryClickDialog=false"
    >
      <v-card elevation="4" style="width: 100%;" :tile="$app_isMobile">
        <v-system-bar class="elevation-1" :color="((typeof dialogBarColor === 'function') ? dialogBarColor(currentItem) : dialogBarColor) || service" :height="40">
          <span class="ml-2 white--text text-capitalize">{{ $t(`${service}.label`) }}</span>
          <v-spacer />
          <v-btn icon class="mx-2" @click.stop="galleryClickDialog=false">
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </v-system-bar>

        <v-card-text style="height: 80%;">
          <slot name="gallery-card-popup" :item="currentItem" :gallerySmall="gallerySmall" />
        </v-card-text>

        <v-divider />

        <v-card-actions @click="galleryClickDialog=false">
          <v-spacer />

          <v-btn
            v-if="!hideGalleryPopupCancel"
            :small="$app_isMobile"
            color="info"
            text
          >{{ $t('def.cancel') }}</v-btn>

          <!-- use click.stop on action buttons if you do not want galleryDialog to close -->
          <slot name="gallery-card-popup-actions" :item="currentItem" :gallerySmall="gallerySmall" />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ** CONFIRM DELETE popup -->
    <c-alert-btn
      v-model="deleteDialog"
      hide-button
      :ok-label="$t('def.delete')"
      @cancel="deleteDialog=false"
      @ok="deleteItem(currentItem)"
    >
      <p>{{ $t('table.alert.delete',{ d: currentItem.name || service } ) }}</p>
      <p>{{ $t('table.alert.no_revert' ) }}</p>
      <p class="caption info--text">item: {{ currentItem._id }}</p>
    </c-alert-btn>
  </div>
</template>

<script>
import FileSaver from 'file-saver' // for download()

import Log from '@/lib/log' // eslint-disable-line no-unused-vars

import TableTopBar from '@/components/_lib/form/table/TableTopBar'
import GalleryCard from '@/components/_lib/form/table/GalleryCard'
import EditItemDialog from '@/components/_lib/form/data/EditItemDialog'

export default {
  components: {
    TableTopBar,
    GalleryCard,
    EditItemDialog
  },
  props: {
    // **** SELECTABLE ITEMS ***
    value: {
      type: [String, Array],
      default: ''
    },
    selectable: {
      type: Boolean,
      default: false
    },
    selectMultiple: {
      type: Boolean,
      default: false
    },

    // **** THE SERVICE ~/store/.. / feathers ***
    service: {
      type: String,
      default: ''
    },

    //
    // **** APPEARANCE ****
    //
    // force screen to think it is xs wide breakpoint, so that all responsive columns <get-td small ... />  will be hidden
    small: {
      type: Boolean,
      default: false
    },
    itemsPerPage: {
      type: Number,
      default: 0
    },

    //
    // **** PER ROW ***
    //
    // action icons, per row except for edit/delete all are custom $emit @action
    customActionIcons: {
      type: Array,
      default: () => [] // set default by mounted, based on mobile or not
    },

    // do not show the trash-icon
    hideDelete: {
      type: Boolean,
      default: false
    },
    // do not show the pencil-icon
    hideEdit: {
      type: Boolean,
      default: false
    },
    // real-time check if item can be deleted; example: :can-delete="(item) => item._id !== $db.currentAccountId"
    canDelete: {
      type: Function,
      default: () => true
    },
    canEdit: {
      type: Function,
      default: () => true
    },
    // Show view-expansion pane on each row
    showExpand: {
      type: Boolean,
      default: false
    },
    // no click on outer div
    hideRowClick: {
      type: Boolean,
      default: false
    },

    // **** TOP/FOOTER ***
    //
    // Title to show in left of table top-header
    title: {
      type: String,
      default: ''
    },
    hideSearch: {
      type: Boolean,
      default: false
    },
    hideFilter: {
      type: Boolean,
      default: false
    },
    // tag select: backend service must have 'tags' field  and $all whitelisted
    showTagSelect: {
      type: Boolean,
      default: false
    },
    // show the new button
    hideNew: {
      type: Boolean,
      default: false
    },
    // disable but show the newRecord button, for example if maximum reached or no permissions
    disableNew: {
      type: Boolean,
      default: false
    },
    // show a download button at footer
    showDownload: {
      type: Boolean,
      default: false
    },

    //
    // **** GALLERY ****
    //
    // show a gallery; if not show table
    showGallery: {
      type: Boolean,
      default: false
    },
    // allow user to toggle gallery/table view
    showGalleryToggle: {
      type: Boolean,
      default: false
    },
    // hide icon button to toggle card sizes; Note: use prop 'small' to mount size at first in small thumbs
    hideGallerySizeToggle: {
      type: Boolean,
      default: false
    },
    // show add new + when gallery is empty
    showNewOnEmpty: {
      type: Boolean,
      default: false
    },
    // click on gallery item to open
    showGalleryDialog: {
      type: Boolean,
      default: false
    },
    // do not show footer action button to close dialog
    hideGalleryPopupCancel: {
      type: Boolean,
      default: false
    },

    //
    // **** HEADER ROW
    //
    // the v-data-table header definition, expanded with properties:
    //   format: '' // currency, check, date, number, textarea = show 2 lines
    //   responsive: false // do show on mobile
    //   hide: false // do not show at all
    headers: {
      type: Array,
      default: () => []
    },
    // if header contains { toggleHeader: true} then this prop will show/hide that header.
    // The column you should take care of show/hide with <gen-td v-if="...">
    hideHeader: {
      type: Boolean,
      default: false
    },

    //
    // *** FILTER, SORT AND QUERY the DATA SET ***
    //
    // The query object to to find the items at the service backend
    // @NOTE: does obviously not work on populated fields, use filterQuery instead for local filtering
    query: {
      type: Object,
      default: () => {}
    },
    // field name to sort on mount, default latest at the top
    sortBy: {
      type: String,
      default: 'updatedAt'
    },
    // sort direction
    sortDesc: {
      type: Boolean,
      default: true
    },
    // set to true to use filter() => {} in header row:
    //  {..., filter: (value) => { if (!vm.fieldFilter) { return true }; return value.productCode === vm.fieldFilter }}
    filterCustom: {
      type: Boolean,
      default: false
    },
    // local front-end filter. Example: { pipelineId: 'P_400' }
    filterQuery: {
      type: Object,
      default: () => {}
    },
    // to only show one document with the specified id; remove this filter with filterOnItemId=false
    filterItemId: {
      type: String,
      default: ''
    },
    // provide select list for TopBar user filter
    filterList: {
      type: Array,
      default: () => []
    },
    filterPlaceholder: {
      type: String,
      default: 'filter something'
    },

    //
    // **** EDIT DIALOG popup ***
    //
    // The color of the edit window bar and window drawer
    //   default === service, see nuxt.config.js vuetify/themes/theme
    //   :dialog-bar-color="primary"
    //   :dialog-bar-color="(item) => item.color" // for example for Tasks
    //
    dialogBarColor: {
      type: [String, Function],
      default: ''
    },
    // Dialog title;  default:  "${$t('def.edit')} ${$t(service + '.label')}"
    dialogTitle: {
      type: String,
      default: ''
    },
    dialogTitleTextColor: {
      type: String,
      default: 'white'
    },
    dialogWide: {
      type: Boolean,
      default: false
    },
    // possible help button in edit dialog
    infoSlug: {
      type: String,
      default: ''
    }
  },
  data (vm) {
    return {
      currentItem: {},
      page: 1,
      pageCount: 0,
      galleryView: true,
      gallerySmall: false,
      galleryClickDialog: false,
      setItemsPerPage: 0, // set by mounted
      itemsPerPageOptions: [5, 10, 15, 20, 25, 50, 500],

      tagQuery: {},
      tableSearch: '',
      tableFilter: '',
      filterOnItemId: true, // to allow hiding the filter on ItemId

      editDialog: false,
      deleteDialog: false,
      expanded: [],
      alignRight: ['currency', 'euro', 'euro_3', 'number', 'percentage']
    }
  },
  computed: {
    // filtered table content
    items () {
      const itemIdFilter = (this.filterOnItemId && this.filterItemId) ? { _id: this.filterItemId } : {}
      return this.$db.collection(this.service, { ...this.filterQuery, ...itemIdFilter })
    },
    fullQuery () {
      return Object.assign({}, this.query, this.tagQuery)
    },

    smallScreen () {
      return this.$vuetify.breakpoint.xs || this.small
    },
    // the icons we should show for certain actions
    actionIcons () {
      const actionIcons = []
      if (!this.hideEdit) { actionIcons.push('edit') }
      if (!this.hideDelete) { actionIcons.push('delete') }
      return Array.from(new Set([...this.customActionIcons, ...actionIcons]))
    },
    nrActionsInHeader () {
      return (this.smallScreen ? 0 : this.actionIcons.length) + (this.showExpand ? 1 : 0)
    },

    // add the actions buttons to the header
    tableHeader () {
      const responsiveHeaders = this.headers.filter(th => (
        !th.hide && (!th.responsive || !this.smallScreen) && (!th.toggleHeader || !this.hideHeader)
      ))
      responsiveHeaders.forEach((h) => {
        if (!h.align && h.format && this.alignRight.includes(h.format)) {
          h.align = 'right'
        }
      })

      // now add space for the icons, width = 30 per icon, on wide screens only
      responsiveHeaders.push(
        { text: '', value: 'actions', align: 'right', width: 25 * this.nrActionsInHeader, sortable: false }
      )
      // add space for the view-chevron (down arrow)
      // if (this.showExpand) {
      //   responsiveHeaders.push(
      //     { value: 'data-table-expand', text: '', width: '40' })
      // }

      // make sure at least one header is without a width, otherwise formatting of the header is not dynamic
      const nrWidth = (responsiveHeaders.filter(i => i.width && i.width > 0)).length
      if (nrWidth === responsiveHeaders.length) {
        Log.e(`[general-table] all ${nrWidth} table items for ${this.service} have width property. Header will not be responsive.`, responsiveHeaders)
      }
      return responsiveHeaders
    },
    selectedIds: {
      get () { return this.value },
      set (value) { this.$emit('input', value) }
    }
  },
  watch: {
    // if the parent changes the query, we need to reload
    query: {
      deep: true,
      handler (newQuery, oldQuery) {
        if (JSON.stringify(newQuery) !== JSON.stringify(oldQuery)) {
          Log.i('[general-table] query changed to', newQuery)
          this.reloadItems() // refresh cache
        }
      }
    }
  },
  mounted () {
    // number of items on one page to show
    this.setItemsPerPage = this.itemsPerPage || (
      this.smallScreen
        ? this.itemsPerPageOptions[1] || 10
        : this.itemsPerPageOptions[3] || 20
    )
    // for gallery view initialization
    this.gallerySmall = this.small
    this.galleryView = this.showGallery
  },
  async created () {
    this.currentItem = this.$db.initData(this.service)
    await this.reloadItems(true)

    // immediately view an item if there is only one record
    if (this.items.length === 1) {
      this.expanded.push(this.items[0]._id)
    }
  },
  methods: {
    async reloadItems ($cache = false) {
      // Log.w('Loading', this.service)
      await this.$db.find(this.service, { $cache, ...this.fullQuery })
      // Log.i('[general-table] Reloaded', this.service, $cache)
    },

    customAction ({ icon, item }) {
      // Log.i('Action', icon)
      switch (icon) {
        case 'edit':
          this.editItem(item)
          break
        case 'delete':
          this.confirmDelete(item)
          break
        default:
          this.$emit('action', { icon, item })
      }
    },
    hasRights (item, action) {
      switch (action) {
        case 'delete':
          return this.canDelete(item) && this.$db.hasRights('remove', this.service)
        case 'edit':
          return this.canEdit(item) && this.$db.hasRights('edit', this.service)
        case 'create':
          return !this.disableNew && this.$db.hasRights('create', this.service)
        default:
          // Log.e('[general-table ] rights handled by parent via @action event', action)
          return true
      }
    },
    toggleExpand (item) {
      const { _id: key } = item
      if (this.showExpand) {
        if (this.expanded.includes(key)) {
          this.expanded = this.expanded.filter(item => item !== key)
        } else {
        // this.expanded.push(key)
          this.expanded = [key]
        }
      } else {
        this.editItem(item)
      }
    },
    // updateTagQuery (value) {
    //   Log.w(`[gt] updateTagQuery ${value}`)
    // },

    // Edit item from database to ensure we have all fields, not only in collection
    editItem (item) {
      this.currentItem = item
      this.expanded = []
      this.editDialog = true
    },
    // Prepare to create a new item in the collection
    newItem () {
      this.currentItem = {}
      this.editDialog = true
    },

    // Show dialog to confirm Deletion of selected item from collection
    confirmDelete (item) {
      this.currentItem = JSON.parse(JSON.stringify(item)) // clone to avoid mutation store.__collection
      this.deleteDialog = true
    },
    async deleteItem (item) {
      this.editDialog = false
      this.deleteDialog = false
      await this.$db.remove(this.service, item._id)
      this.$emit('itemRemoved', item)
    },

    // Download of this table in csv format
    // Make sure server has @/config/download-fields.json
    async download () {
      const url = `${this.$config.API_URL}/download/${this.service}?accountId=${this.$db.currentAccountId}`
      const shortISODate = (new Date()).toISOString().split('.')[0] // without the milliseconds
      const fileName = `bligson-${this.$db.currentAccountId}-${this.service}-${shortISODate}.csv`
      // FileSaver.saveAs(url, fileName)
      const { accessToken } = await this.$api.get('authentication')

      Log.w(`[general-table] Download: ${url} to ${fileName} `) // with JWT=${accessToken}
      const res = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: accessToken
          // 'Access-Control-Allow-Origin': 'http://localhost:3000'
        }
      })
      if (res.ok) {
        const blob = await res.blob()
        FileSaver.saveAs(blob, fileName)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.view-inset {
  border-bottom: 1px solid #999;
  padding: 0 16px;
}
.view-compact {
  max-height: 200px;
  overflow-y: scroll;

  // bottom shadow line
  background: linear-gradient( 180deg,
    rgba($color: #f5f5f5, $alpha: 1.0) 0%,
    rgba($color: #f5f5f5, $alpha: 0) 98%,
    rgba($color: #c9c9c9, $alpha: 1) 100%
  );
}
</style>
