<template>
  <c-page
    icon="alert-circle-outline"
    :title="$t('error.subtitle')"
    :label="$t('error.title')"
    hero-color="error"
  >
    <v-app dark>
      <v-container fluid class="mt-12">
        <h1>{{ $t('error.other') }}</h1>

        <v-divider class="my-4" />

        <h3 v-if="error.statusCode === 404">
          "{{ pageNotFound }}"
        </h3>

        <h3 v-else>
          {{ otherError }}
        </h3>

        <v-divider class="my-4" />

        <nuxt-link to="/">
          {{ $t('error.back_home') }}
        </nuxt-link>
      </v-container>
    </v-app>
  </c-page>
</template>

<script>
export default {
  name: 'LayoutError',
  props: {
    error: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      pageNotFound: '404 Not Found',
      otherError: 'An error occurred'
    }
  },
  head () {
    const title =
      this.error.statusCode === 404 ? this.pageNotFound : this.otherError
    return {
      title
    }
  }
}
</script>
