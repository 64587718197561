/* Assets */

import { initialStateProperties } from '@/plugins/vuex-injects/vuex'

// import Config from '@/config/constants' // NOTE >> ******* : .env parameters are not valid here yet !!!!

import Log from '@/lib/log' // eslint-disable-line no-unused-vars

export default initialStateProperties({
  service: 'assets',
  channelSubscription: true, // see plugins/feathers/feathers-client.js

  defaultQuery: { accountId: true },

  initData: {
    // _id will be same as the filename == fileId; without the file extension

    accountId: '',
    title: '',
    description: '',
    tags: [],

    fileName: '', // { type: String, maxlength: 200 }, // original upload file name 'my_house.jpg'
    alt: '', // alternative info for image; eg. when is broken; default = originalFileName

    variants: {
      lg: {
        // src: server populatedData, see below
        // width: { type: Number }, // width in pixels
        // height: { type: Number }, // height in pixels
        // size: { type: Number }, // image size in bytes,
        // format: { type: String, enum: ['jpeg', 'jpg', 'png', 'svg', 'gif', 'webp'], default: 'webp' },
        // quality: { type: Number, max: 100 }, // quality conform jpeg, usually 80 is ok
        // fit: { type: String, enum: ['cover'] }, // see sharp module
      },
      md: {},
      sm: {},
      th: {}
    },

    uri: '', // ** Temporary field, used to to upload new files to server
    totalStorage: 0
  },

  populatedData: {
    url: '' // '{uploadPath}/assets/A_100' // uploadPath example: http://localhost:3030/data/
    //  variants.lg.src // = 'assets/A_100/lg/123.png'  // relative url = '{uploadPath}/{variant.lg.src}'
    //  variants.md.src
    //  ...
  },

  // collectionFields: [...Object.keys(initData),...], // all

  afterCommitHook: (state, item) => {

  }
})
