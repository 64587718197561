<!-- Show an selected un selected icon, based on constants

  SELECT_ICON: {
    published: { true: 'eye-outline', false: 'pencil-outline', color: 'success' },
    ...
  },

color = color of NOT selected icon if object has color definition, otherwise it takes color for selected
  <c-select-icon small color="grey" :selected="pipelines.archived" type="archived" />

-->
<template>
  <div>
    <v-icon v-bind="$attrs" :color="iconColor">
      mdi-{{ $config.SELECT_ICON[type][selected === true] }}
    </v-icon>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'archived'
    },
    color: {
      type: String,
      default: 'archived'
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    iconColor () {
      if (!('color' in this.$config.SELECT_ICON[this.type])) { return this.color }
      return this.selected ? this.$config.SELECT_ICON[this.type].color : 'grey'
    }
  }
}
</script>
