
// Note: !!!  Disabled in nuxt.config.js

import Log from '@/lib/log'
export default function ({ store, $db }) {
  Log.i('[i] Middleware: initialization.')
  if (store.state._app_settings.__right_drawer_open_panel) {
    store.commit('_app_settings/set', ['__right_drawer_open_panel', ''])
  }
}
