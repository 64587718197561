<!-- Mount a drawer panel

To open/close a panel use:
  this.$signal.openDrawerPanel(myPanel)
  this.$signal.closeDrawerPanel(myPanel)

 NOTE:
   @/middleware/initialize-ui.js closes panels first, Initiated from @/layouts/default.vue

-->

<template>
  <div
    tile
    :dark="drawer.dark"
    :flat="panelIsOpen"
  >
    <v-system-bar
      :height="40"
      :color="drawer.color + (panelIsOpen ? ' lighten-1' : '') "
      class="white--text text-uppercase body-2 px-4 py-0"
      style="min-height: 40px; cursor: pointer;"
      @click="$signal.toggleDrawerPanel(drawer.panel)"
    >
      {{ $t(drawer.panel + '.label') }}
      <v-spacer />
      <v-icon color="white">mdi-chevron-down</v-icon>
    </v-system-bar>

    <v-expand-transition>
      <div v-if="panelIsOpen" class="background drawer-panel-content" :class="{'overflow': overflow}">
        <slot />
      </div>
    </v-expand-transition>

    <v-sheet :height="1" color="primary darken-1" />
  </div>

</template>

<script>

// eslint-disable-next-line no-unused-vars
import Log from '@/lib/log'

export default {
  name: 'RightDrawerPanel',
  props: {
    //  { panel: 'notifications', color: 'info', dark:true, showOnRoute: ['/admin'] },
    drawer: {
      type: Object,
      default: () => {}
    },
    overflow: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      panelOpenIdx: null
    }
  },

  computed: {
    panelIsOpen () {
      return this.$signal.isOpenDrawerPanel(this.drawer.panel)
    }
  }
}
</script>

<style lang="scss" scoped>
.drawer-panel-content {
  max-height: 421px;
  overflow-x: hidden;
  overflow-y: auto !important;
  &.overflow {
    max-height:initial;
  }
}
</style>
