<!-- Select an account from the list with possible accounts

Examples

  <select-account v-model="myAccountId" :accounts="..." show-manager-link show-badge default-current />
  <select-account v-model="$db.currentAccountId" show-manager-link show-owner-badge />

Unlisted props as Attributes bound to <v-select />
 -->

<template>
  <c-field :label="value ? '' : $t('accounts.label')" wrap class="mb-0">
    <p v-if="items.length <1" class="error--text" style="cursor: pointer;">{{ $t('accounts.none') }}</p>

    <!-- class="mt-n2 pb-0" -->
    <v-select
      v-else
      v-bind="$attrs"
      :value="value"
      :items="items"
      :item-text="itemName"
      item-value="_id"
      hide-details
      :clearable="clearable"
      dense
      class="ml-n2"
      solo
      flat
      :background-color="backgroundColor"
      @input="$emit('input', $event)"
      @change="$emit('change')"
    >
      <!-- :style="wide ? '' : 'max-width: 200px;'" -->
      <!-- Display the selected item; default blurred state -->
      <template v-slot:selection="data">
        <a href="#" style="overflow: hidden; width: 100%;">
          <c-field :label="$t('accounts.label')" dense wrap>
            <span class="two-lines">
              <span v-if="showOwnerBadge" :class="`font-weight-bold ${accountColor( $db.doc('accounts') )}--text`">
                &blacktriangleright;
              </span>
              {{ data.item.name }}
            </span>
          </c-field>
        </a>
      </template>

      <template v-if="showManagerLink" v-slot:append-item>
        <v-divider />
        <c-link to="/accounts/overview">
          <span class="caption ml-4">{{ $t('accounts.manage.title').toLowerCase() }}</span>
        </c-link>
      </template>
    </v-select>
  </c-field>

</template>

<script>

// eslint-disable-next-line no-unused-vars
import Log from '@/lib/log'

export default {
  props: {
    // use v-model is accountId
    value: {
      type: String,
      default: ''
    },
    backgroundColor: {
      type: String,
      default: 'background'
    },
    // the accounts to select from
    accounts: {
      type: Array,
      default: () => []
    },
    // link to account profile
    showManagerLink: {
      type: Boolean,
      default: false
    },
    // set the initial value on current if value is empty
    defaultCurrent: {
      type: Boolean,
      default: false
    },
    // @TODO: show icons in front of items
    showIcon: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    // show indicator for the user as owner or manager of that account
    showOwnerBadge: {
      type: Boolean,
      default: false
    },
    wide: {
      type: Boolean,
      default: false
    },
    // show [id ]name  instead of name; see ~/store/accounts.js afterCommitHook
    idName: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    items () {
      if (this.accounts.length > 0) {
        return [...this.accounts].sort((a, b) => {
          const fa = a.name.toLowerCase()
          const fb = b.name.toLowerCase()
          if (fa < fb) { return -1 }
          if (fa > fb) { return 1 }
          return 0
        })
      } else {
        // sorted on server side in join
        return this.$db.doc('users', null, { $clone: true }).accounts || []
      }
    },
    itemName () {
      return this.idName ? 'idName' : 'name'
    }
  },
  mounted () {
    if (this.defaultCurrent && !this.value) {
      this.$emit('input', this.$db.currentAccountId)
      this.$emit('change')
    }
  },
  methods: {
    accountColor (account) {
      const userId = this.$db.currentUserId
      if (account.ownerIds.includes(userId)) { return 'primary' }
      if (account.managerIds.includes(userId)) { return 'info' }
      return ''
    }
  }
}
</script>
