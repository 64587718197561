// ** CREATE - STATE CRUD DATABASE ACTIONS
//
// Create / insert one ore more items to the database and __collection
// Set current data to newly created item or the first record of newly created items
//
// If admin wants to create accounts they do not own, they should set params.query = {$limit -1}
//
// data may also be an array; but in the feathers service the "multi" option has to be set
//
//    await store.dispatch(`${service}/create`, { data, params })
//

import Log from '@/lib/log'

export default {
  async create ({ commit, getters, dispatch, rootGetters }, { data, params = {} }) {
    const service = getters.getService

    // For all "accountId services", backend-create demands an data.accountId
    if (('accountId' in getters.getInitData) && !data.accountId) {
      data.accountId = rootGetters['users/get']('currentAccountId')
    }

    // preform the actual create
    let results
    try {
      this.$signal.apiActionBar = { status: 'loading' }
      results = await this.$api.service(service).create(data, params)
      this.$signal.apiActionBar = { status: 'success' }
    } catch ({ code, message }) {
      this.$signal.apiActionBar = { status: 'error', code, message }
      Log.e(`[S] create failed in "${service}" with error: [${code}]: "${message}"`, data)
      return {}
    }

    // we could get a multi create data object back
    const resultArray = Array.isArray(results) ? results : [results]
    for (const result of resultArray) {
      commit('afterCommitHook', result)

      // shall we let the feathers.js channel subscription handle the update?
      if (service !== 'activities') {
        commit('appendItemCollection', result)
      }
      // check for a difference: probably means that a field was seized in awaiting of confirmation email link
      const resultDiffers = Object.keys(data).find(field => data[field] !== result[field])
      if (resultDiffers) {
        // inform ConfirmationsNotifier.vue
        await dispatch('confirmations/find', {}, { root: true })
      }
    }

    Log.i(`[S] create("${service}")`, data, params, results)
    return results
  }
}
