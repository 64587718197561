<!-- **  Select one campaign or more campaign ids

  To select current campaign:
    <select-campaign v-model="campaignId" :hide-empty="$app_isMobile" />

  To select an campaign id, and update current if not empty:
    <select-campaign v-model="selectedCampaignId"  hide-label show-icon :rules="[$validRule.required]" />

  To select campaign not changing the current, also allows empty; so can use option to clear:
    <select-campaign v-model="selectedCampaignId" hide-label show-icon clear />

  autofocus only when using the clear option

-->

<template>
  <div>
    <c-link v-if="campaigns.length <1" to="/campaigns" class="body-2">
      {{ $t('campaigns.none') }}
    </c-link>

    <div v-else class="d-flex align-center" style="overflow: hidden;">
      <c-tooltip v-if="showIcon">
        <template #act>
          <v-btn :small="!outlined" icon :class="{'mr-2': outlined}" @click="inputShowToggle=!inputShowToggle">
            <v-icon :small="!outlined" color="primary">mdi-bullseye-arrow</v-icon>
          </v-btn>
        </template>
        {{ $t('campaigns.field.select_filter') }}
      </c-tooltip>

      <!-- Show pull down menu -->
      <v-select
        v-if="showInput"
        v-model="campaignId"
        :items="campaigns"
        item-text="name"
        item-value="_id"
        auto-select-first
        :dense="dense"
        solo
        flat
        :outlined="outlined"
        background-color="background"
        :rules="rules"
        hide-no-data
        hide-details="auto"
        full-width
        style="min-width: 200px; max-width:400px;"
        :placeholder="$t('campaigns.field.select_filter')"
        @change="$emit('change',$event)"
      >
        <!-- :allow-overflow="false" overflow hidden removes the line -->
        <template v-slot:selection="data">
          <div class="d-flex">
            <c-field v-if="!hideLabel" :label="$t('campaigns.field.label')" :dense="dense" wrap>
              <span class="primary--text two-lines body-2" style="cursor: pointer;">{{ data.item.name }}</span>
            </c-field>

            <span v-else class="primary--text two-lines body-2" style="cursor: pointer; line-height: 1.25em;">{{ data.item.name }}</span>
          </div>
        </template>
      </v-select>

      <v-btn v-if="clear && campaignId" icon small @click="campaignId=''; inputShowToggle=!hideEmpty;">
        <v-icon small color="primary">mdi-close</v-icon>
      </v-btn>

    </div>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import Log from '@/lib/log'

export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    account: {
      type: Object,
      default: () => {}
    },
    rules: {
      type: Array,
      default: () => []
    },
    hideLabel: {
      type: Boolean,
      default: false
    },
    // set to true to auto select last campaign used , if any
    autoSelect: {
      type: Boolean,
      default: false
    },
    // hide Empty only works when showing Icon, otherwise no way to show the selection
    hideEmpty: {
      type: Boolean,
      default: false
    },
    showIcon: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    // show icon to clear input
    clear: {
      type: Boolean,
      default: false
    }
  },
  data (vm) {
    return {
      inputShowToggle: false
    }
  },
  computed: {
    showInput () {
      return this.campaignId || !this.showIcon || !this.hideEmpty || this.inputShowToggle
    },
    campaigns () {
      return this.$db.doc('accounts', null, { $clone: true }).campaigns.sort((a, b) => {
        const fa = a.name.toLowerCase()
        const fb = b.name.toLowerCase()
        if (fa < fb) { return -1 }
        if (fa > fb) { return 1 }
        return 0
      })
    },
    campaignId: {
      get () { return this.value },
      set (value) { this.$emit('input', value) }
    }
  },
  mounted () {
    // if auto-select has been set, try to initialize the value to the last used campaign or the first in the array
    if (this.autoSelect && !this.campaignId) {
      this.campaignId = this.$db.doc('campaigns')._id ||
       this.$db.collection('campaigns').length > 0 ? this.$db.collection('campaigns')[0]._id : ''
    }
  }
}
</script>
