<!-- *** Search button and field in TableTopBar.vue

Events:
  @update: search changed, emits new search string, also emitted when filter cleared

Example:
 <table-search
   v-model="tableSearch"
   :placeholder="$t('def.search')"
   @update="$emit('updateSearch',tableSearch)"
/>

-->

<template>
  <div>
    <!-- style="max-width: 250px;" -->
    <!-- warp d-flex in component with div otherwise server side rendering issue in Firefox -->
    <div class="d-flex align-center">
      <template v-if="showSearch">
        <v-text-field
          v-model="localSearch"
          dense
          single-line
          :placeholder="placeholder || $t('def.search')"
          hide-details
          class="ml-2"
          @change="$emit('update', localSearch)"
        />

        <v-btn icon class="ml-n2" @click="clearSearch()">
          <v-icon small color="primary">mdi-close</v-icon>
        </v-btn>
      </template>

      <c-icon-btn
        v-else
        icon="magnify"
        class="ml-0 ml-md-1"
        color="primary"
        small
        i18n-tooltip-text="table.tooltip.search"
        @click="showSearch=true;"
      />

    </div>
  </div>
</template>

<script>
export default {
  value: {
    type: String,
    default: ''
  },
  props: {
    search: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    showSearch: false
  }),
  computed: {
    localSearch: {
      get () { return this.value },
      set (value) { this.$emit('input', value) }
    }
  },
  methods: {
    clearSearch () {
      this.localSearch = ''
      this.showSearch = false
      this.$emit('update', this.localSearch)
    }
  }
}
</script>
