<!-- Display row with tag chips with optional delete

Examples:

  <list-tags :tags="item.tags" item-class="two-lines" />

You can use v-model and clear-chips to delete tags:

  <c-field cols="12" md="6" label="delete your tags">
    <list-tags v-model="item.tags" chips clear-chips  />
  </c-field>

And for tags like '101# en:: banking & finance | fr:: Banque et Finance | nl:: bank en financieel'

   <list-tags
      :tags="user.industries"
      class="two-lines"
    />

-->

<template>
  <div class="d-flex flex-wrap">
    <template v-if="chips">
      <v-chip
        v-for="tag in allTags"
        :key="tag"
        small
        :close="clearChips"
        :color="color"
        outlined
        class="mb-1 mr-1"
        @click:close="clearChips ? deleteTag(tag) : null"
      >
        {{ tag }}
      </v-chip>
    </template>

    <span v-else :class="itemClass">
      {{ allTags.join(', ') }}
    </span>
  </div>
</template>

<script>
import Log from '@/lib/log' // eslint-disable-line no-unused-vars

export default {
  model: {
    prop: 'tags'
  },
  props: {
    tags: {
      type: Array,
      default: () => []
    },

    //  show list of chips, default comma-separated names
    chips: {
      type: Boolean,
      default: false
    },
    // show a cross to delete chip
    clearChips: {
      type: Boolean,
      default: false
    },
    itemClass: {
      type: String,
      default: 'caption narrow-line'
    },
    // chip color
    color: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      allTags: []
    }
  },

  // to enable deep reactivity on place A when deleting tags on place B: use deep watch
  watch: {
    tags: {
      deep: true,
      handler () {
        this.setTagList()
      }
    }
  },
  mounted () {
    this.setTagList()
  },
  methods: {
    setTagList () {
      this.allTags = [...this.tags].sort()
    },
    deleteTag (tag) {
      const index = this.allTags.findIndex(t => t === tag)
      if (index >= 0) {
        this.allTags.splice(index, 1)
      }
      this.$emit('input', this.allTags)
      // Log.w('[list-tags] delete tag', tag, this.allTags)
    }
  }
}
</script>

<style lang="scss" scoped>
  .narrow-line {
    line-height: 1.1em;
  }
</style>
