  <!-- IndicatorBadges: icons with counters that show in the top bar on each page -->

<template>
  <div id="indicator-row" class="d-flex align-center">

    <time-tracker-indicator v-if="$signal.timeTrackerRunning" />

    <!-- badges to only show on wide screen -->
    <div class="d-none d-sm-flex">
      <activities-indicator />
    </div>

    <confirmations-indicator-icon />

    <repository-indicator v-if="panelContext('stories-id')" />

    <tasks-indicator v-if="panelContext('tasks')" />

  </div>
</template>

<script>
import ActivitiesIndicator from '@/components/activities/ActivitiesIndicator.vue'
import RepositoryIndicator from '@/components/assets/IndicatorIcon.vue'
import TasksIndicator from '@/components/tasks/IndicatorIcon.vue'
import TimeTrackerIndicator from '@/components/time-trackers/TimeTrackerIndicator.vue'
import ConfirmationsIndicatorIcon from '@/components/confirmations/ConfirmationsIndicatorIcon'

export default {
  components: {
    ActivitiesIndicator,
    RepositoryIndicator,
    TasksIndicator,
    TimeTrackerIndicator,
    ConfirmationsIndicatorIcon
  },
  methods: {
    panelContext (showContext) {
      return showContext === '' || (this.$route.name && this.$route.name.includes(showContext))
    }
  }
}
</script>
