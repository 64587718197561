/* Subscription plan definitions */

import { initialStateProperties } from '~/plugins/vuex-injects/vuex'

export default initialStateProperties({
  service: 'subscription-plans',
  loadCollectionOnStart: true,

  initData: {
    productCode: 'SP_00',
    name: 'New Plan',
    color: '',

    // service limits:
    accounts: 1,
    campaigns: 1,
    pipelines: 1,

    // service options:
    canUnlimitedAccounts: false,

    // service prices
    priceEur: 0,
    unlimitedAccountPrice: 0

  }
})
