export default

  home:
    label: 'Home'
    company: 'Bligson'
    title_IS: 'Achievement Recognition Framework'
    title_FB: 'Happiness is a continuous flow of recognized achievements'
    subtitle: 'Tell their story'
    description: 'BLIGMAN - .'

  dashboard:
    label: 'Dashboard'
    title: 'Dashboard'
    subtitle: 'Bligman Dashboard'
    
  user:
    title: 'User'
    titles: 'Users'
    subtitle: 'Appearance, profile, settings'
    label: 'user'
    labels: 'users'
    profile:
      label: 'Profile'
      title: 'User Profile'
    field:
      email:
        label: 'email'
        confirm: 'Are you sure you like to change your email from "{e}" to "{n}"?'
        tt_resend: 'Resend the email to confirm the email address change to "{e}". Please check your spam box'
        tt_reset: 'Retract the change to a new email address and keep the current one ({e})'
        public: 'email visible to other users'
      password:
        label: 'password'
        repeat: 'Repeat password'
        confirm: 'Are you sure you would like to update your password?'
        one_time_active: 'One time password is still active. As you have just reset your password, for maximal security, you should NOW choose a new password…'
        reset:
          label: 'reset password'
          tooltip: 'if you forgot your password, you can reset it here'
          confirm: 'Would you like to reset your password for {e}?'
          info: 'We will send an email to {e}. In this email you will find a link that enables you to activate a temporary password.'
          email_required: 'To reset your password, you first need to enter your email address so we can send you a password reset link.'
          sent: 'We sent you a message by email to {e}. Please allow for up to 15 minutes to receive this confirmation. If you can not find it also check your spam-folder'
      displayName:
        label: 'your name'
        placeholder: 'display name'
      title:
        label: 'title'
      avatar:
        upload_here: 'upload your image'
        upload: 'Upload a new profile image to set as your avatar. Please note that others will be able to see this picture.'
        select_img: 'Set this uploaded photo as your profile image. Click again to upload a new image.'
        select_grv: 'Set this gravatar as your profile image'
      company:
        label: 'company name'
      expertise:
        label: 'expertise'
        select: 'choose your main field of expertise'
    login:
      label: 'Login'
      page: 'login page'
      hint: 'At least {n} characters'
      required: 'Required'
      failed: 'Login failed. Please check your email and password.'
      agree: 'agree with our'
      t_c: 'terms and conditions'
      no_agree: 'You must agree to continue!'
      no_match: 'email & password do not match'
    logout:
      label: 'Logout'
    signup:
      title: 'Sign up'
      label: 'sign up'
      confirm_password: 'Confirm password'
      confirm_no_match: 'Passwords do not match'
      invite:
        intro: 'For now, you need to enter an invite code here as we are still in Beta-mode. Please contact us on www.bligson.com'
        enter: 'your invite code'
        invalid: 'Invalid code'
      free: 'FREE registration - no credit card required'
      register: 'Sign up'
      success: 'Thank you. Registration was successful.'
      failed: 'Registration failed: "{m} ({c})"'
    role:
      admin: 'admin'
      super: 'super admin'
    settings:
      subtitle: 'Configure your profile and user details'
    appearance:
      label: 'appearance'
      title: 'Appearance'
    localization:
      label: 'localization'
      title: 'Localization settings'
    interface:
      label: 'Interface'
      title: 'Local browser/app interface settings'
      reset:
        alert: 'Are you sure you want to clear all your local app settings?'
        label: 'clear settings'
      dark_mode:
        label: 'dark mode'
      twenty_four_hour:
        label: '24 hour time format'
      currency:
        label: 'Currency'
      language:
        label: 'Language'
      date_format:
        label: 'Date format'
    plan:
      title: 'Subscription plan'
      label: 'plan'
      select:
        label: 'Select your plan'
        unlimited: 'Unlimited accounts'
        one: 'One account only'
        sure: 'Are you sure you want to transfer your plan to this one?'
        new_monthly: 'New monthly price:'
      cost:
        title: 'Your current plan and invoices'
        average: 'average per month'
        toPay: 'to pay each {m} months'
        total:
          gross: 'Gross total'
          net: 'Net total'
      card:
        title: 'Subscribe or upgrade a plan'
        details: 'Enter your card details.'
        now: 'Your subscription will automatically be renewed each term, until you cancel your account with us or downgrade to a free plan'
        due: 'Total due now'
        required: 'Required information'
        name:
          fullName: 'Full name'
          placeholder: 'First and last name'
        code:
          number: 'Card'
          placeholder: 'Card number'
          valid: 'Please enter valid code'
        expire: 'MM/DD'
        cvc: 'CVC'
        pay: 'Process payment'
    invoices:
      label: 'invoices'
      title: 'Invoices and payments'
    expert:
      label: 'expertise'
      title: 'Resume & Expertise'
      resume: 'About'
      linkedIn: 'URL of your linkedIn profile page...'
      listed:
        ok: 'You are listed as an expert !'
        not: 'NOTE: You are NOT yet listed as an expert; please apply below'
      apply:
        title: 'Bligson Expert - application'
        info: 'You can apply at Bligson to offer your Employer Branding expertise to other Bligson members. This is particularly useful if you are a freelance consultant or marketeers. After we approve your application, other members will see your business card in the Account / Specialist tab and can easily send you an invitation to become a team member of their account.'
        info_note: 'Please allow us a couple of days to review your application. We might contact your per email, chat or phone per your convenience and to learn a bit more about your background and level of expertise.'
        tooltip: 'You can offer your expertise to other Bligson members: click here to apply.'
        alert: 'Send a request to the Bligson team to review your application?'
        alert_note: 'If you feel you would like to contact us later, you can find our email address by clicking on the envelope at the bottom of the left drawer.'
        pending: 'Your request is pending approval'
      retract:
        info: 'You are listed as an Expert. You can retract your listing if you no longer wish to be listed as a Bligson Workflow specialist.'
        tooltip: 'Inform us that you want to remove your Bligson Expert listing.'
        alert: 'Are you sure and like to remove your listing from the Specialists? Other users will no longer be able to invite you to their accounts'
      business_card: 'Business Card Preview'
      industry: "Major fields of expertise, maximal {n}"
      invite:
        join: 'Invite this expert to join your current account "{a}"'
        send: 'Send an invitation by email to "{n}" to join the current account "{a}"?'
    accounts:
      title: 'Accounts'

  subscription:
    label: 'Plan'
    title: 'Subscription plan'
    plan:
      compare: 'Bligson plan comparison'
      name:
        0: 'Free plan',
        10: 'Standard'
        20: 'Professional'
        30: 'Enterprise'
    payment:
      term: 'Payment term'
    unlimited: 'unlimited'
    category:
      label: 'category'
      accounts: 'accounts'
      campaigns: 'campaigns'
      pipelines: 'pipelines'
      priceEur: 'price'
      canUnlimitedAccounts: 'optional unlimited accounts'
      unlimitedAccountPrice: 'unlimited accounts price'

  accounts:
    label: 'account'
    labels: 'accounts'
    title: 'Account management'
    subtitle: 'Clients, projects, team, experts, history and details'
    none: 'Click here to reload the page and refresh the accounts.'
    edit_tags: 'Edit default account tags'
    contents: 'account contents'
    overview: 'overview'
    field:
      label: 'account'
      name: 'account name'
      new_name: 'My new Account'
      department: 'departments'
      add_department: 'add new department'
      achiever_tags: 'achiever tags'
      add_achiever_tag: 'add new achiever tag'
      tags: 'default content tags'
      add_tag: 'add new tag'
      edit_tags: 'edit the default tags'
      default_tags: 'marketing,sales,business development'
      integration: 'Custom Integration'
      marketing_menu: 'Show marketing menu\'s & campaign planning functionality'
    manage:
      title: 'Account Management'
      nr: 'Your current subscription allows for "{n}" account(s).'
      upgrade: 'Upgrade your plan '
      more: 'if you need to manage more accounts.'
    multiple:
      select: 'Select account to manage'
      manage: 'Manage multiple accounts'
    member:
      title: 'Member management'
      subtitle: 'Add and remove other users to the current account'
      label: 'member'
      labels: 'members'
      regular: 'regular'
      rights: 'Access & rights'
      invite:
        label: 'invite'
        title: 'invite a member by email'
        hint: 'enter email address of new or existing user'
        already: 'Can not add this member: "{e}" is already a member of the "{a}" account team'
        existing:
          tooltip: 'Invite this existing member to join account.'
          label: 'Invite {e} to account'
          join_account: 'Send "{e}" an invitation by email to join the "{a}" account team as a new member?'
          sent:
            ok: 'Sent "{e}" an email to ask to participate in account "{a}"'
            error: 'Sorry, the invitation to "{e}" to join account "{a}", did not send out correctly.'
        new:
          tooltip: 'Invite new member to sign up to Bligson.'
          label: 'Invite {e} to join Bligson'
          join_bligson: '"{e}" is not yet a subscriber of Bligson. Do you want us to send an email to invite her/him to join Bligson?'
          join_note: 'Please note: after the user signed up to Bligson you will need to return here and send another invitation to the user´s sign-up email address to join the account.'
          sent:
            ok: 'Sent "{e}" an email to ask to participate in account "{a}"'
            error: 'Sorry, the invitation to "{e}" to become member of Bligson did not send out correctly.'
      remove:
        tooltip: 'Remove this user from this account'
        message: 'Remove {n} from this team? Please note there should always remain at least one owner per account. Be careful, if you like to add this user again, you would need to send a new invitation!'
    owner:
      label: 'owner'
      labels: 'owners'
      not: 'no owner rights'
      set: 'Make an owner'
      remove: 'Retract owner rights & make manager.'
    manager:
      label: 'manager'
      labels: 'managers'
      not: 'no manager rights'
      set: 'Make a manager'
      remove: 'Retract management rights & make ordinary member'

  bligsy:
    label: 'Bligsy'
    title: 'Bligsy Happy chat Bot'
    field:
      enabled: 'Enabled'
      schedule: 'Bligsy Alert Schedule'
      time: 'Time'
      local_time: 'local time'
      timezone: 'Timezone'

  integrations:
    label: 'integration'
    labels: 'integrations'
    title: 'Custom Integrations'
    subtitle: 'serve your stories as posts to other websites'
    description: 'You can add REST api integrations here. For example to load and display stories in your blog/website. Ask your web-developer for assistance.'
    field:
      disabled: 'Disabled / blocked'
      title: 'Integration title'
      tags: 'Optional filter-tags, comma separated'
      hosts: 'Allowed hosts, comma separated, no http(s)'
    populated: 'Populated fields'
    url: 'Integrations url'
    delete:
      label: 'Delete this integration'
      sure: 'Are you sure to delete this integration from this account? You will loose the key. If you like to disable this integration temporarily, you can edit to mark it as ’disabled’.'

  widgets:
    label: 'widget'
    labels: 'widgets'
    title: 'Dashboard widgets'
    settings: 'settings'
    open: 'Show widget drawer to add new widgets'
    add: 'click on a title to add it to the top left column'
    no_longer: 'Widget no longer exists!'
    bar:
      refresh: 'Refresh widget'
      settings: 'Widget settings'
      delete: 'Delete this widget from the dashboard?'
    collapse:
      open_compact: 'open widget in compact mode'
      open_expanded: 'open widget in maximal mode'
      close: 'close: hide widget content'

    all:
      select_account: 'Please first select an account in the settings above.'
      select_campaign: 'Please first select a campaign in the settings above.'
      select_pipeline: 'Please first select a pipeline in the settings above.'

    # one set per widget
    accounts_all:
      label: 'Accounts - all'
      show_nr: 'Number of accounts to show'
      intro: 'Show table with accounts and their objects'
    campaigns_all:
      label: 'Campaigns - all'
      intro: 'List the most recent edited campaigns'
      show_archived: 'also show archived'
      show_nr: 'Number of campaigns to show'
    campaign_details:
      label: 'Campaign details'
      intro: 'Show details, such as status and description, of a selected campaign, .'
    campaign_pipelines:
      label: 'Campaign pipelines'
      intro: 'Show a list with the pipelines and task status of the active campaign'
    pipelines_all:
      label: 'Pipelines - all'
      show_nr: 'Number of pipelines to show'
      intro: 'Show all pipelines of a selected account'
      show_archived: 'archived'
    pipeline_details:
      label: 'Pipeline details'
      intro: 'Show some fields of a selected pipeline'
    task_list:
      label: 'Tasks list'
      intro: 'List all tasks in an account, filtered on status, sorted by due-date'
    task_direct_create:
      label: 'Task direct create'
      intro: 'Immediately create and insert a new task in a new pipeline'
    stories_recent:
      label: 'Stories - recent',
      intro: 'A list with an account´s most recent stories: select "published", "draft" or "all"'
    stories_direct_create:
      label: 'Story direct create',
      intro: 'Immediately create and insert a new story'

    user_account_team:
      label: 'Team - all account users'
      show_nr: 'Number of users to show'
      intro: 'Show list with all users of a certain account'
    user_business_card:
      label: 'Business card',
      intro: 'Your business card shows who are you and how you might appear to other users'
      no_settings: '...'
    user_onboarding:
      label: 'Welcome & Quick Start',
      intro: 'First time introduction to the system and the way to work'
      no_settings: '...'
      step:
        1:
          title: 'Welcome'
          subtitle: 'Start by renaming your account'
          text: 'The default name of the account is “My company”. Use the name of the Company you cooperate with to rename the account.'
        2:
          title: 'Choose workflow'
          subtitle: 'Start by renaming your account'
          text: 'Workflow is a set of actions that should be done to reach a goal. Opt-in the scope and load into. Filter by type and industry Pick up the workflow you like'
          link: 'load a workflow'
      
    deadlines_upcoming:
      label: 'Upcoming deadlines'
      intro: 'A list with deadlines that soon will expire.'
    analytics_visitors:
      label: 'Visitor audits'
      intro: 'Number of visitors to your stories.'
    information_random:
      label: 'Random tip'
      intro: 'Show a random tip about Employer Branding or Bligson.'

  calendar:
    label: 'Calendar'
    title: 'Planning'
    subtitle: 'Start moments and deadlines of tasks, pipelines and campaigns.'
    intro: ''

  campaigns:
    label: 'campaign'
    labels: 'campaigns'
    title: 'Campaigns'
    subtitle: 'Achievement Recognition Strategy'
    edit: 'Edit campaign'
    none: 'No campaigns in this account. Please create one first.'
    select:
      label: 'select campaign'
      all: 'All campaigns'
    field:
      actions: 'actions'
      description: 'description'
      end_at: 'end at'
      label: 'campaign'
      members: 'members'
      name: 'name'
      owner: 'owner'
      select_filter: 'select a campaign'
      start_at: 'start at'
      status: 'stat'
      strategies:
        label: 'strategies'
        select: 'please select one or more strategies'
      target_audience: 'target audience'

  pipelines:
    label: 'pipeline'
    labels: 'pipelines'
    label_id: '"{c}"'
    title: 'Pipelines'
    subtitle: 'Tactics & Workflow management'
    title_id: 'Campaign for "{t}"'
    edit: 'Edit pipeline'
    for: 'For '
    none: 'no pipelines yet available'
    status:
      x_archived: 'archived'
    field:
      actions: 'actions'
      campaign: 'campaign'
      description: 'description'
      end_at: 'end at'
      end: 'end'
      industries:
        label: 'applicable industries'
        select: 'please select one or more industries'
      label: 'pipeline'
      name: 'name'
      owner: 'template owner'
      template: 'template',
      public: 'public'
      select_filter: 'select a pipeline'
      stages: 'task stages'
      start_at: 'start at'
      start: 'start'
      status: 'stat'
      tactics:
        label: 'tactical goals'
        select: 'please select one or more tactics'
      target_audience: 'target audience'
    select:
      label: 'select pipeline'
      archived:
        act:
          label: 'archived'
          tooltip: 'archived; inactive'
        hide:
          label: 'active'
          tooltip: 'active; non-archived'
        all:
          label: 'all'
          tooltip: 'Show all '
    stages:
      label: 'Active stage template'
      load: 'Load new template'
      alert: 'Are you sure you want to load a new stages template? This will overwrite the current stages. It will not remove any tasks in the pipeline.'
      yes_button: 'Delete old stages & overwrite'
      none: 'no tasks / stages'
      field:
        name: 'pipeline stage'
        hours: 'hours'
        completed: 'completed'
    templates:
      label: 'templates'
      title: ' Pipeline Template'
      titles: ' Pipeline Templates'
      import: 'import'
      description: 'Import the tasks of this pipeline-template into the current pipeline.'
    workflows:
      label: 'workflow'
  'pipeline-templates':
    label: 'Pipeline Template'
    
  tasks:
    label: 'task'
    labels: 'tasks'
    title: 'Tasks'
    subtitle: 'Tasks & to-do’s'
    delete_all: 'Delete all {i} cards from archive? Warning: you can not undo this action!'
    add: 'new task'
    export: 'Insert {t} tasks'
    save_first: 'You need to save the task first, before you can add any checklist items.'
    actions:
      add: 'create new/planned task'
      drawer: 'load batch of tasks from a template'
      archive: 'show archive list: drag & drop tasks to archive'
    drawer:
      label: 'Card loader'
    field:
      label: 'task'
      name: 'name'
      status: 'status'
      stage: 'stage'
      color: 'task color'
      checklist: 'checklist'
      stories: 'stories'
      members: 'members'
      description: 'description'
      due_at: 'due at'
    status:
      pln: 'plan',
      ong: 'ongoing',
      fin: 'finished',
      arch: 'archived'
    stage:
      str: 'strategy'
      con: 'content'
      ver: 'verify'
      pub: 'publish'
      int: 'interact'
    agile:
      label: 'workflow'
      title: 'Workflow'
      plan:
        label: 'plan'
      ongoing:
        label: 'ongoing'
      done:
        label: 'done'
      archive:
        label: 'archive'
    group:
      blog: 'blog'
      webinar: 'webinar'
      conference: 'conference'
      video: 'video'
    template:
      select:
        label: 'pipeline card template'
      blog: 'Standard blog'
      webinar: 'Informative Webinar'
      video: 'YouTube infomercial'
      presentation: 'Corporate presentation'
    
  audits:
    label: 'audits'
    title: 'Employer Branding: Pipeline Audit'
    subtitle: 'Track the results of your pipelines'
    intro: 'Various monitoring of your Employer Branding progress'
    task: 'Task {t}'
    field:
      text: 'Checklist item'
      info: 'Additional information about this audit'
    types:
      none:
        label: 'Off'
        tooltip: 'No audit for this checklist item'
      progress:
        label: 'Progress'
        tooltip: 'Progress slider'
      switch:
        label: 'Switch'
        tooltip: 'Boolean value: on/off'
    total: 'Total score for {n} audits'
    results:
      no_tasks: ' Please note: There are no tasks in this pipeline, and therefore no active audits. Audits are associated with *checked* checklist in tasks. You can activate an audit by adding checklist(s) to task(s) in this pipeline, and mark  the items as "done".'
      no_items: 'There are no checked-off checklist items in this task.'
      select_pipeline: 'Please select a pipeline first.'
    level:
      title: 'Explaining the scores'
      red: 'Poor; most likely you are loosing business'
      orange: 'Fair; you are advised to improve'
      blue: 'Good; however improvements still recommended'
      green: 'Excellent!'

  stories:
    label: 'story'
    labels: 'stories'
    title: 'Achievement Stories'
    subtitle: 'Achievements, stories, posts, blogs, articles,…'
    edit: 'Edit story'
    type:
      blog: 'blog'
      webinar: 'webinar'
      presentation: 'presentation'
      script: 'script'
      other: 'other'
      'undefined': 'undefined'
    field:
      label: 'Story'
      title: 'title'
      slug:
        label: 'slug'
        exist: 'slug already exists'
      authors:
        by: 'by: '
        label: 'author(s)'
        hint: 'select authors: first is the "primary" author'
        primary: 'set self as primary author'
      featured: 'featured'
      draft: 'draft'
      published: 'published'
      campaign_id: 'campaign'
      feature_image:
        label: 'feature image'
        select: 'Set feature image'
      excerpt:
        label: 'excerpt / summary'
        placeholder: 'Excerpt of your post'
      note:
        label: 'note'
        placeholder: 'Notes / Ideas (hidden from public)'
      html:
        label: 'Html view'
        size: 'size'
        placeholder: 'View html'
        escape_toggle: 'escape quotes'
      start_at: 'start at'
      end_at: 'end at'
      created_at: 'created at'
      updated_at: 'updated at'
      type: 'type'
      tags:
        hint: 'We suggest to select from 2 to 4 tags.'
        placeholder: 'Select a tag'
    select:
      published:
        pub:
          label: 'published'
          tooltip: 'published '
        draft:
          label: 'draft'
          tooltip: 'drafts'
        all:
          label: 'all'
          tooltip: 'published and drafts '
      featured:
        feat:
          label: 'featured'
          tooltip: 'featured '
        reg:
          label: 'regular'
          tooltip: 'non-featured '
        all:
          label: 'all'
          tooltip: 'Show all featured and non-featured '
      scheduled:
        fin:
          label: 'finished'
          tooltip: 'Finished'
        plan:
          label: 'not started'
          tooltip: 'planned, scheduled '
        on:
          label: 'ongoing'
          tooltip: 'ongoing'
        all:
          label: 'all'
          tooltip: 'Show all'

  repository:
    title: 'Repository'
    label: 'repository'
    subtitle: 'media, embeds, snippets'

  assets:
    label: 'asset'
    labels: 'assets'
    title: 'Assets'
    subtitle: 'File and Media storage'
    gallery: 'gallery'
    select:
      label: 'Select asset'
      all: 'all'
      none: 'deselect all assets'
    insert: 'Insert asset'
    field:
      label: 'asset'
      title: 'title'
      alt: 'alt'
      campaign_ids: 'campaigns'
      description: 'description'
      type: 'type'
      filename: 'filename'
      org_filename: 'original filename'
      id: 'file id'
      sizes: 'file format & image sizes'
      total_size: 'total size'
      total_storage: 'total storage'
      src: 'src / href / url'
      thumb_src: 'thumbnail'
      org_src: 'original source'
      parameters: 'optional parameters'
    action:
      select:
        tooltip: 'Select "{name}"'
      delete:
        label: 'Delete uploaded file'
        sure: 'Are you sure to delete this file from the Bligson cloud?'
        tooltip: 'Delete the uploaded file and it’s thumbnails from the Bligson repository'
      upload:
        label: 'upload new image'
        tooltip: 'Create multiple assets at once by uploading several images to the repository'
      # ext:
      #   label: 'external provider'
      #   tooltip: 'Items from external asset providers'
      #   upload:
      #     label: 'add external hosted image'
      #     tooltip: 'Create new external-hosted asset. Supply a link to an image file, Youtube video,…'
      # snippet:
      #   label: 'snippet'
      #   tooltip: 'Snippets / standard clips of text'
      #   upload:
      #     label: 'edit snippet'
      #     tooltip: 'A snippet is a piece of text you like to re-use more often in stories and on other places.'
      # all:
      #   label: 'all'
      #   tooltip: 'All assets; from every provider'
    type:
      animation: 'animation'
      artwork: 'artwork'
      embed: 'embed'
      html: 'html'
      image: 'image'
      interaction: 'interaction'
      presentation: 'presentation'
      social: 'social'
      text: 'text'
      video: 'Video'
      vimeo: 'Vimeo'
      youtube: 'YouTube'
      'undefined': 'undefined'

  snippets:
    labels: 'snippets'
    label: 'snippet'
    title: 'Text snippets'
    subtitle: 'Clipboard with standard pieces of text'
    field:
      title: 'title'
      text_snippet: 'snippet text'
      officially_endorsed: 'officially endorsed'
    
  embeds:
    labels: 'embeds'
    label: 'embed'
    title: 'Embed links'
    subtitle: 'Links to various third party assets'
    gallery: 'embed gallery'
    select:
      label: 'Select embed'
      all: 'all'
      none: 'deselect all embeds'
    type:
      code: 'software code'
      image: 'image'
      vimeo: 'Vimeo'
      youtube: 'YouTube'

  achievers:
    label: 'achiever'
    labels: 'achievers'
    title: 'Achiever'
    titles: 'Achievers'
    active: 'Active achievers'
    subtitle: 'Colleagues, employees, team members who achieve daily'
    invite: 'invite'
    invite_tooltip: 'Copy a "Telegram invite link" to the clipboard. You can then paste this link, in an  email or chat, to send to new achievers. The link enables the achiever to install Telegram (if needed) and start a chat with Bligsy Bot.'
    select:
      blocked:
        lock:
          label: 'blocked'
          tooltip: 'achiever is not enabled and can not submit experiences'
        unlocked:
          label: 'active'
          tooltip: 'achiever is active, not blocked'
        all:
          label: 'all'
          tooltip: 'blocked and active'
    field:
      chatId: 'chatId'
      chatId_format: 'format TG#123456'
      telegram:
        username: 'username'
        first_name: 'first name'
        last_name: 'last name'
        lang: 'language'
      blocked: 'blocked'
      invitationsEnabled: 'invitations enabled'
      invitations: 'invitations'
      description: 'description'
      full_name: 'full name'

  experiences:
    label: 'experience'
    labels: 'experiences'
    title: 'Experience'
    titles: 'Experiences'
    subtitle: 'Their happy moments'
    field:
      alias: 'alias'
      description: 'comments / description'
      promotable: 'suitable for public promotion'
      rating: 'experience rating'
      sphere: 'sphere'
      level: 'level'
      text: 'experience, text'
      full_name: 'full name'
      language: 'language'
    review: 'Review'
    sphere:
      P: 'private'
      W: 'professional'
    level:
      0: 'none'
      1: 'ok'
      2: 'good'
      3: 'great'
      4: 'excellent'
      5: 'perfect'
    select:
      promotable:
        pub:
          label: 'promotable'
          tooltip: 'authorized for promotion'
        private:
          label: 'private'
          tooltip: 'not (yet) suitable to show to the outside world'
        all:
          label: 'all'
          tooltip: 'promotable and hidden'
      sphere:
        personal:
          label: 'personal'
          tooltip: 'personal, private sphere, not work related'
        work:
          label: 'work'
          tooltip: 'related to work, job, career, employer'
        all:
          label: 'all'
          tooltip: 'work and private'


  currency:
    label: 'Currency'
    
  'subscription-plans':
   label: 'Subscription Plans'

  'discount-terms':
   label: 'Discount Terms'

  checklists:
    label: 'checklists'
    field:
      text: 'text'

  analytics:
    label: 'analytics'
    title: 'Statistics & monitoring'
    subtitle: 'System usage'
    intro: 'Various monitoring of the application'

  team:
    label: 'Team'
    labels: 'Teams'
    title: 'Staff & specialists'
    subtitle: 'Organize your team, their roles and status.'
    intro: '',
    contacts: 'contacts'

  specialists:
    label: 'Specialist'
    labels: 'Specialists'
    title: 'Freelance Editors, Translators, Workflow Managers'
    subtitle: 'Invite specialists to join "{a}"'
    intro: ''

  'info-articles':
    label: 'Information'
    title: 'Help & Information'
    table: 'Information base'
    index: 'index'
    not_found:
      title: 'No article found',
      html: '<b>No article content found for "{slug}".</b>'

  activities:
    title: 'Activities'
    subtitle: 'User actions on the various data objects'
    label: 'activity'
    labels: 'activities'
    filter:
      no_found: 'No activities found.'
      try_reset: 'To show all activities, you can try to '
      messages_only: 'hide activities that do not contain a message'
      including_messages: 'show all activities: both with and without messages'
      others_only: 'hide your own activities'
      all_members: 'show activities from all members'
      urgent_only: 'hide activities that have an urgency level below {l}'
    send:
      label: 'Write a new comment…'
      message: 'Send message to this activity-stream'
    seen_by: 'Seen by'
    act:
      # {m} = method, {s} = service (plural) {u} service singular
      archived_off: '{u} no longer in Archive'
      archived_on: 'archived {u}'
      assets: 'changed assets for {u}'
      checklist: 'edited checklist in {u}'
      commented: 'comment:'
      created: 'created a new {u}'
      published_off: 'switched "published" off in {u}'
      published_on: '{u} set to "published"'
      ended: 'set a new end date for {u}'
      featured_off: '{u} to no longer "Featured"'
      featured_on: '{u} set to "Featured"'
      manager: 'changed the list of managers for an {u}'
      member: 'changed membership of {s}'
      moved: 'moved a {u} item'
      owner: 'changed ownership of {u}'
      protected_off: 'removed protection from a {u}'
      protected_on: 'protected {u}'
      removed: 'removed {u}'
      renamed: 'renamed a {u}'
      started: 'set a new start date for {u}'
      tagged: 're-tagged a {u} item'

  'time-trackers':
    title: 'Time Trackers'
    subtitle: 'Time registration of your account activities'
    label: 'tracker'
    labels: 'trackers'
    paused: 'paused'
    start: 'Start a new timer'
    stop: 'Stop (or pause) the timer'
    continue: 'Continue the timer'
    table: 'time tracker table'
    visit_service: 'visit tracked service'
    field:
      start_at: 'Timer started'
      end_at: 'Timer stopped'
      pause: 'Pause'
      run_time: 'run time'
      passed_time: 'time passed'

  confirmations:
    label: 'Email confirmation'
    labels: 'Email confirmations'
    await: 'awaiting email confirm to change "{f}"'
    signup: 'To verify your email address, click here to request an email with confirmation link:'
    notifier:
      outstanding: 'You have no outstanding, valid confirmations | You have an outstanding confirmation. Please check your email. | You have {nr} outstanding confirmations.  Please check your email.'
      outdated: 'Note: you may have {o} unused but outdated confirmation emails. You can delete these from your email Inbox.'
      check: 'Did not find your email?'
      note: 'If you can not find the email in your inbox wait some minutes for it to arrive. Do not forget to check your spam box as well. Note that confirmation-links are only valid for {m} minutes unless refreshed.'
    email_sent: 'Email sent'
    resend: 'renew confirmation(s)'
    reset: 'Confirmations reset'
    reset_all: 'Reset: we have cleared and invalidated all confirmations. You can discard any emails you might still have in your inbox.'
    error:
      404: 'This confirmation link (no longer) exists. Update is not applied.'
      406: 'Missing confirmation id'
      422: 'This link is outdated and can no longer be processed.'

  editor:
    placeholder:
      title: 'Post Title'
      excerpt: 'Your excerpt, summary or description…'
      content: 'Begin writing your post…'
    button:
      save:
        tooltip:
          title: 'save Document'
          text: 'You can also press Ctrl-S. Please note that we automatically save if you pause typing for {sec} seconds or when your mouse leaves the editor area.'
      zoom:
        tooltip:
          title: 'zoom in/out'
          text: 'Enlarge or reduce the document text'
    words: 'words'
    reading:
      words: '{w} words'
      time: ' done | one minute reading | {m} minutes reading'
    link:
      title: 'Link'
      valid: 'Please enter a valid url, leave empty to delete'
    image:
      title: 'Image'
      insert:
        title: 'Insert new image'
        post: 'Set post image'
      alt: 'Alt'
      caption: 'Caption/title'
      delete:
        alert: 'Are you sure you want to delete this image?'
    embed:
      link: 'Parsed embed link'
      src: 'Original link'

  admin:
    label: 'admin'
    title: 'Administration'
    settings:
      label: 'settings'
      reset:
        alert: 'Are you sure you want to clear ALL data?'
        label: 'Reset data'
      contacts:
        title: 'Contact us'
    database:
      show_current_only: 'Only show current account’s documents instead of complete database'

  invoices:
    label: 'invoices'

  address:
    company: 'company',
    department: 'department',
    firstName: 'first name',
    lastName: 'last name',
    sex:
      label: 'sex'
      options: 'female|male|other'  # order is important! f/m/o; separate with one space
    tel: 'telephone nr(s)',
    tel_inp: 'telephone nrs (comma separated)',
    email: 'email(s)',
    email_inp: 'emails (comma separated)',
    street: 'street',
    houseNumber: 'nr.',
    city: 'city',
    state: 'state',
    zipCode: 'zip code',
    country: 'country'

  # table & database stuff
  table:
    label: 'table'
    title: 'My {f}'
    data: 'Data'
    database: 'Database'
    databases: 'Databases'
    download:
      tooltip: 'download table'
      sure: 'Download this table as .csv file?'
    nr_page: '{f} per page:'
    no_results: 'no {f} found'
    no_name: '(no name/title yet)'
    no_items_found: 'no items found'
    unknown: '*unknown*'
    no_image: 'no image set'
    add_first: 'no "{f}" found, click [+] to add new'
    footer:
      items_per_page: '#/page'
    tooltip:
      filter: 'filter the table to show reduced set of items'
      search: 'free text search'
      tag_query: 'select a tag to filter'
      refresh: 'refresh the table and reset all filters'
      add_new: 'create a new item'
      size_toggle: 'thumbnail size: small/medium'
      gallery: 'toggle table/gallery view'
      resize: 'toggle item small/medium size'

    alert:
      delete: 'Are you sure you want to delete this item "{d}"? '
      cancel: 'Do you want to discard all changes?'
      no_revert: 'You can not revert this action!'

  share:
    label: 'share'
    title: 'Share a link'
    tooltip: 'Share the link of this page with other apps such as email'
    
  form:
    file_drop_input:
      drop_here: '| drop one file in this area | drop maximal {n} files in this area'
      max_hint: '| …or select a file here | …or select here'
    too_large: 'File is too large. The maximal size is {m} kilobytes'
  
  error:
    title: 'Oops...'
    subtitle: 'We are sorry'
    page_not_found: '404 Page not found'
    other: 'An error occurred'
    back_home: 'Back to home page'
    file_drop:
      accept: 'Only attach "{a}" file types.'
    api_response_code:
      0: 'Server api is idle.'
      # Bligman specific success codes
      201: 'Successful signup'
      #  Feathers error codes  https://github.com/feathersjs/feathers/blob/master/packages/errors/lib/index.js
      400: 'Failed to update user field(s).'
      401: 'Not Authenticated'
      402: 'Payment Error'
      403: 'Forbidden'
      404: 'Not Found'
      405: 'Method is not allowed'
      406: 'Not acceptable'
      408: 'Server timeout. We could not connect to the database'
      409: 'Conflict'
      410: 'Gone'
      411: 'Length is required'
      422: 'Un-processable'
      429: 'Too Many Requests'
      500: 'General Error'
      501: 'Not Implemented'
      502: 'Bad Gateway'
      503: 'Unavailable'
      # By Lorinthe:
      599: 'Unknown Error'
      undefined: 'Server informed an unknown error'

  # for v-form validation, see @/plugins/validation-rules/index.js
  validation_rules:
    field: 'field can not be empty'
    includes: 'must be included in'
    contains: 'should include "{s}"'
    equals: 'should be equal'
    not_includes: 'should not already be included'
    not_include_spaces: 'no spaces allowed'
    max_includes: 'to be included maximal {i} times'
    letters: 'expecting letters only; a-z'
    slug: 'lowercase letters, numbers and dashes only'
    digits: 'expecting digits only'
    number: 'expected numeric value'
    credit_card: 'please enter a valid credit card number'
    email: 'valid email address is required'
    url: 'valid url = https address is required'
    min_value: 'value should be minimal {n}'
    max_value: 'value should be maximal {n}'
    exact_length: 'please enter {n} characters.'
    minimal_length: 'minimal length of {n} characters'
    maximal_length: 'maximal length of {n} characters'
    exact_length_array: 'please enter {n} items.'
    minimal_length_array: 'minimal {n} items'
    maximal_length_array: 'maximal {n} items'
    range_invalid: 'invalid range'

  def:
    add: 'add'
    all: 'all'
    api: 'api'
    apply: 'apply'
    archive: 'archive'
    archived: 'archived'
    attachments: 'attachments'
    attach: 'attach'
    cancel: 'cancel'
    cancelled: 'cancelled'
    change: 'change'
    clear: 'clear'
    close: 'close'
    comment: 'comment'
    continue: 'continue'
    created: 'created'
    delete: 'delete'
    details: 'details'
    discard: 'discard'
    download: 'download'
    done: 'done'
    drafts: 'drafts'
    edit: 'edit'
    error: 'error'
    existing: 'existing'
    filter: 'filter'
    go_to: 'go to'
    help: 'help'
    hosts: 'hosts'
    insert: 'insert'
    invoice: 'invoice'
    item: 'item'
    label: 'label'
    more_info: 'more info…'
    more: 'more…'
    new: 'new'
    no: 'no'
    not_set: 'not set'
    note: 'note'
    ok: 'ok'
    open: 'open'
    page: 'page'
    price: 'price'
    print: 'print this page'
    protected: 'protected'
    protection: 'protection'
    rating: 'rating'
    read: 'read more…'
    refresh: 'refresh'
    reset: 'reset'
    resend: 'resend'
    resume: 'resume'
    retract: 'retract'
    revert: 'revert'
    save: 'save'
    search: 'search'
    select: 'select'
    send: 'send'
    share: 'Share'
    submit: 'submit'
    success: 'success'
    tags: 'tags'
    tag: 'tag'
    type: 'type'
    update: 'update'
    upload: 'upload'
    uploading: 'uploading'
    yes: 'yes',
    zoom: 'zoom'
    # cut and paste
    clipboard:
      copy:
        label: 'Copy to clipboard'
        ok: 'Content copied to clipboard'
        error: 'Copy to clipboard failed'
    size:
      label: 'size'
      th: 'thumbnail'
      sm: 'small'
      md: 'medium'
      lg: 'large'
    # pipeline, campaign, story status
    status:
      label: 'status'
      active: 'ongoing'
      planned: 'planned'
      resolved: 'finished'
      stored: 'archived'
      unresolved: 'unresolved'
      'undefined': 'undefined'

    # time and periods
    time:
      month: 'month'
      quarter: 'quarter'
      year: 'year'
      months: 'months'
      quarters: 'quarters'
      years: 'years'
      hours: 'hours'
      days: 'days'
    term:
      1: 'monthly'
      2: 'bi-monthly'
      3: 'quarterly'
      6: 'half-yearly'
      12: 'yearly'
    today: 'Today'