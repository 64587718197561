/* Help information articles */

import { initialStateProperties } from '~/plugins/vuex-injects/vuex'

export default initialStateProperties({
  service: 'info-articles',
  loadCollectionOnStart: true,

  // Note: __currentUserLanguage__ is replaced by global app before/hook languageQuery in backend
  defaultQuery: {
    $sort: { fallBack: 1 },
    language: ['__currentUserLanguage__', 'en'],
    $unique: 'slug' // we do not want more than one doc with same slug
  },

  // fields to reduce the size of collection; default to the initData fields
  collectionFields: [
    'slug',
    'language',
    'fallBack',
    'openToPublic',
    'nrStars',
    'showInTips',
    'label',
    'title',
    'excerpt',
    'tags',
    'bodySize',
    'hasBodyHtml' // bodyHtml is not empty
    // 'bodyHtml', // too much data to keep in collection; fetch when $db.doc() misses it
  ],

  initData: {
    slug: '', // 'introduction'
    openToPublic: false,
    nrStars: 0, // { type: Number, min: 0, max: 5, default: 0 }, // rating of the quality of this article
    twoColumnLayout: false,
    showInTips: false,
    language: 'en', // 'nl' | ...
    label: '',
    title: '',
    excerpt: '',
    hasBodyHtml: false,
    bodyHtml: '<h1>A new chapter</h1><p>The main <b>article</b> comes here...</p>',
    bodySize: 0,
    tags: []
  },

  // fields not to send to server
  populatedData: {
    fallBack: false
  },

  afterCommitHook: (state, item) => {
  }
})
