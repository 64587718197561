<!-- Edit array with tags: add/remove

  <add-tag v-model="item.tags" />

  <list-tags v-model="item.tags" chips clear-chips color="info" />
  <add-tag v-model="item.tags" class="mt-n1 ml-1" />

-->

<template>
  <v-form
    v-model="valid"
    class="d-flex align-center"
    @submit.prevent=""
    @keydown.prevent.enter
  >
    <v-text-field
      v-model="addTag"
      :label="tagLabel"
      class="mr-2"
      style="max-width: 200px;"
      :rules="[ $validRule.minLength(2).val, $validRule.notIncludes(tags).val ]"
      @change="addNewTag()"
    />
    <!-- hide-details="auto" -->

    <c-tooltip>
      <template #act>
        <v-btn small icon :disabled="!valid || !addTag " @click="addNewTag()">
          <v-icon color="primary">mdi-tag-plus-outline</v-icon>
        </v-btn>
      </template>
      <span>{{ $t('def.add') }}</span>
    </c-tooltip>
  </v-form>
</template>

<script>

export default {

  props: {
    value: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      valid: false,
      addTag: ''
    }
  },
  computed: {
    tags: {
      get () { return this.value },
      set (value) { this.$emit('input', value) }
    },
    tagLabel () {
      return this.label || this.$t('accounts.field.add_tag')
    }
  },
  watch: {
    // force each tag to be lower case while editing
    addTag () {
      this.addTag = this.addTag.toLowerCase()
    }
  },
  methods: {
    addNewTag () {
      if (this.valid && this.addTag) {
        this.tags = [...this.tags, this.addTag].sort()
        this.addTag = ''
      }
    }
  }
}
</script>
