<template>
  <div>
    <p class="text-center mt-3 body-2 foreground-2--text">{{ $t('widgets.add') }}</p>

    <v-divider />

    <v-list dense class="pa-0 mr-n6">
      <v-list-item-group class="info">
        <v-list-item
          v-for="widgetType in widgetTypes"
          :key="widgetType"
          class="background py-0"
          style="margin-bottom: 1px; cursor: copy; height: 50px;"
          @click="$signal.widgetInsert=widgetType"
        >
          <v-list-item-icon>
            <v-icon color="grey">mdi-{{ icons[widgetType] }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="primary--text mt-0 mb-0">
              {{ $t(`widgets.${widgetType}.label`) }}
            </v-list-item-title>

            <v-list-item-subtitle
              class="pr-4 two-lines"
              style="white-space: normal;"
            >
              {{ $t(`widgets.${widgetType}.intro`) }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import Log from '@/lib/log' // eslint-disable-line no-unused-vars

import { Icons, WidgetTypes } from '@/components/_lib/widgets/widget-loader' // Widgets

export default {
  data () {
    return {
      icons: Icons,
      widgetTypes: WidgetTypes
    }
  },
  mounted () {
    Log.i('[widget-drawer] widget types:', this.widgetTypes)
  }
}
</script>
