<!-- ** Show various stage steps.

 <stage-stepper v-model="selectedStage" :pipeline="selectedPipeline"  large />

NOTE: Careful to change! Took long fidel-ling to get right on every browser!!

-->
<template>
  <div>
    <!-- the stepper line -->
    <div :style="`height: ${height}px; width: 100%; min-width: ${30+ height*5}px;`" class=" d-flex align-center">
      <v-divider style="border: 1px solid #99999988;" />
    </div>

    <!-- the "chevron" arrows in the line -->
    <div
      v-if="large && !$app_isMobile"
      class="d-flex align-center justify-space-around "
      :style="`height: ${height}px; margin-top: -${height}px; width: 100%; margin-left: 0%;`"
    >
      <v-icon v-for="index in stages.length-1" :key="index" color="grey">mdi-chevron-right</v-icon>
    </div>

    <!-- the progress buttons -->
    <div class="d-flex align-center justify-space-between" :style="`height: ${height}px; margin-top: -${height}px;`">
      <div
        v-for="(st,key) in taskMatrix"
        :key="key"
        :style="key !== stage ? 'cursor: pointer;' : ''"
        @click="stage=key"
      >
        <!-- class="flex-grow-1 flex-shrink-0 d-flex justify-space-between " -->
        <stage-progress
          :nr-plan="taskMatrix[key].pln.length"
          :nr-ongoing="taskMatrix[key].ong.length"
          :nr-finished="taskMatrix[key].fin.length"
          :height="height"
          :droppable="key !== stage"
          :selected="large && key===stage"
          @drop="$emit('stageDrop',key)"
        />
      </div>
    </div>

    <!-- large stepper shows: all labels or just one label on small screen -->
    <!-- class="d-flex justify-space-between mt-1" style="margin-left: -1%; width: 102%;" -->
    <v-row v-if="large" class="mt-1 ml-n4 mr-n6 mx-md-n10">
      <v-col
        v-for="(st,key) in taskMatrix"
        :key="key"
        class="body-2 d-flex justify-center pt-1"
      >
        <span v-if="key===stage" class="font-weight-medium">{{ $t(`tasks.stage.${key}`) }}</span>
        <span v-else class="d-none d-md-block">{{ $t(`tasks.stage.${key}`) }}</span><!-- {{ stageLength (taskMatrix[key] ) }}x  -->
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Log from '@/lib/log' // eslint-disable-line no-unused-vars

import StageProgress from '@/components/tasks/form/progress-status/StageProgress.vue'

export default {
  components: {
    StageProgress
  },

  props: {
    // v-model selector for the stage
    value: {
      type: String, //  'str', 'con', 'ver', 'pub', 'int'
      default: ''
    },
    taskMatrix: {
      type: Object,
      default: () => ({})
    },
    large: {
      type: Boolean,
      default: false
    }
  },
  data: vm => ({
    stages: Object.keys(vm.$config.TASKS.STAGE) // [ 'str', 'con', 'ver', 'pub', 'int' ]
  }),
  computed: {
    stage: {
      get () { return this.value || this.stages[0] },
      set (value) { this.$emit('input', value) }
    },
    height () {
      return this.large ? 34 : (this.$app_isMobile ? 15 : 21)
    }
  },
  methods: {
    stageLength (stage = { pln: [], ong: [], fin: [], arch: [] }) {
      return Object.keys(stage).reduce((totalLen, status) => {
        totalLen += stage[status].length; return totalLen
      }, 0)
    }
  }
}
</script>
