<!-- ** Allow user to edit few embed fields and then insert via state\

-->
<template>
  <v-dialog v-model="dialog" width="680">
    <v-card>
      <v-system-bar
        window
        lights
        color="assets"
        class="white--text"
        style="cursor: pointer;"
        @click.stop="closeDialog()"
      >
        <v-btn icon><v-icon color="white">mdi-arrow-left</v-icon></v-btn>
        {{ $t('assets.insert') }}

        <v-spacer />

        <v-btn icon @click="closeDialog()"><v-icon color="white">mdi-close</v-icon></v-btn>
      </v-system-bar>

      <v-card-text class="pt-4">
        <!-- Bligson cloud assets -->
        <v-row>
          <v-col cols="4">
            <v-hover v-slot:default="{ hover }">
              <v-card
                class="pa-1"
                :color="hover ? 'grey': 'grey darken-1'"
                max-width="200"
                :elevation="hover ? 12 : 2"
                @click="sendMediaToEditor()"
              >
                <slot />
              </v-card>
            </v-hover>
          </v-col>

          <v-col>
            <slot name="description" />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn text color="info" @click="closeDialog()">{{ $t('def.close') }}</v-btn>
        <v-btn text color="primary" @click="sendMediaToEditor()">{{ $t('def.insert') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import Log from '@/lib/log'

export default {

  props: {
    value: {
      type: Boolean,
      default: true
    },
    item: {
      type: Object,
      default: () => {}
    },
    mediaType: {
      type: String,
      default: ''
    }
  },

  computed: {
    dialog: {
      get () { return this.value },
      set (value) { this.$emit('input', value) }
    },

    // fields the user can still last-minute-edit for this asset
    title: {
      get () { return this.item.title },
      set (title) { this.$db.patch('assets', this.item._id, { title }, { $debounce: 2000 }) }
    },
    alt: {
      get () { return this.item.alt },
      set (alt) { this.$db.patch('assets', this.item._id, { alt }, { $debounce: 2000 }) }
    }
  },

  methods: {
    closeDialog () {
      if (this.$app_isMobile) {
        this.$app_rightDrawer = false
      }
      this.dialog = false
    },
    sendMediaToEditor () {
      this.closeDialog()

      // "Pipe" via Vuex state: Editor should watch and handle the inserts depending on the type of action
      this.$signal.insertEditor = {
        type: this.mediaType,
        content: this.item
      }
    }
  }
}
</script>
