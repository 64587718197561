<template>
  <div>
    <indicator-icon
      v-if="totalConfirms>0"
      icon="email-outline"
      :total="totalConfirms"
      :unread="nrValidConfirmations"
      flash
      @click="showConfirmInfo=true"
    />

    <v-dialog v-model="showConfirmInfo" :width="600">
      <v-card>
        <v-system-bar class="d-flex align-center pl-4" color="success lighten-2" :height="32">
          <span class="white--text">{{ $t('confirmations.labels') }}</span>
          <v-spacer />
          <v-btn icon @click="showConfirmInfo=false"><v-icon color="white">mdi-close</v-icon></v-btn>
        </v-system-bar>

        <v-card-text class="pt-6">
          <template v-if="nrValidConfirmations>0">
            <h3 class="font-weight-medium">
              {{ $tc('confirmations.notifier.outstanding', nrValidConfirmations, { nr: nrValidConfirmations }) }}
            </h3>
            <p class="body-2 foreground-2--text mt-2 mb-6">
              {{ $t('confirmations.notifier.note', { m: timeoutMinutes }) }}
            </p>
          </template>

          <p v-if="nrOutDatedConfirmations>0" class="info--text mt-2">
            {{ $t('confirmations.notifier.outdated', { o: nrOutDatedConfirmations }) }}
          </p>

        </v-card-text>

        <v-card-actions>
          <!-- <v-btn
              v-if="nrValidConfirmations>0 || nrOutDatedConfirmations>0"
              small
              text
              color="error"
              @click="resetAll()"
            >
              {{ $t('def.clear') }}
            </v-btn> -->

          <v-btn small text color="warning" @click="refreshAll()">{{ $t('confirmations.resend') }}</v-btn>
          <v-spacer />
          <v-btn small text color="info" @click="showConfirmInfo=false">{{ $t('def.close') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- message after remove all confirmations -->
    <v-snackbar v-model="showResetAlert" top color="background" multi-line timeout="10000">
      <v-btn icon class="float-right mt-n2 mr-n4" @click="showResetAlert=false;">
        <v-icon color="info">mdi-close</v-icon>
      </v-btn>

      <div class="foreground--text">
        <h3 class="font-weight-bold mt-2">{{ $t('confirmations.reset') }}</h3>
        <v-sheet :height="3" color="primary" class="mb-4" />
        <p class="my-4">{{ $t('confirmations.reset_all') }}</p>
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import IndicatorIcon from '@/components/_default/topbar/IndicatorIcon.vue'

export default {
  name: 'IndicatorIconConfirmations',
  components: {
    IndicatorIcon
  },
  data () {
    return {
      showResetAlert: false,
      showConfirmInfo: false
    }
  },
  computed: {
    timeoutMinutes () {
      return Math.floor(this.$config.CONFIRMATIONS.TIME_OUT_SECONDS / 60)
    },
    nrValidConfirmations () {
      // find all confirmations that have at least one message send to the current user's email address
      const now = new Date()
      // c.userId === this.$db.currentUserId &&
      const confirms = this.$db.collection('confirmations').filter(c =>
        c.messages.reduce((total, m) => total + (m.email === this.$db.currentUser.email ? 1 : 0), 0) > 0 &&
        new Date(c.expireAt) >= now
      )
      return confirms.length
    },
    nrOutDatedConfirmations () {
      const now = new Date()
      const outDatedConfirmations = this.$db.collection('confirmations').filter(c =>
        c.userId === this.$db.currentUserId &&
        new Date(c.expireAt) < now
      )
      return outDatedConfirmations.length
    },
    totalConfirms () {
      return this.nrValidConfirmations + this.nrOutDatedConfirmations
    }
  },
  methods: {
    resetAll () {
      this.showConfirmInfo = false
      this.showResetAlert = true
      this.$db.collection('confirmations', { userId: this.$db.currentUserId }).forEach(async (confirmation) => {
        await this.$db.remove('confirmations', confirmation._id)
      })
    },
    refreshAll () {
      this.showConfirmInfo = false
      this.$db.collection('confirmations', { userId: this.$db.currentUserId }).forEach(async (confirmation) => {
        // updatedAt field should be changed
        // as we patch the email, patch after hook should also resend the email with the new address
        await this.$db.patch('confirmations', confirmation._id, {
          timeOutSeconds: confirmation.timeOutSeconds + 1
        })
      })
    }

  }
}
</script>
