<!-- Insert button with link. Conforming the nuxt and locale property

Example:
  <c-nav-btn outlined color="white" to="/pricing/standard">{{ $t('pricing.label') }}</c-nav-btn>
  <c-nav-btn small text to="/contact">{{ $t('contact.label') }}</c-nav-btn>
-->

<template>
  <v-btn
    nuxt
    exact
    :color="color"
    :rounded="rounded"
    :small="small"
    :outlined="outlined"
    :text="text"
    :to="to | i18nLink()"
  >
    <slot />
  </v-btn>
</template>

<script>
export default {
  props: {
    text: {
      type: Boolean,
      default: false // Makes the background transparent.
    },
    outlined: {
      type: Boolean,
      default: false // Makes the background transparent and applies a thin border.
    },
    rounded: {
      type: Boolean,
      default: false // Makes the background transparent and applies a thin border.
    },
    small: {
      type: Boolean,
      default: false // Makes the component small.
    },
    color: {
      type: String,
      default: '' // Applies specified color to the control - it can be the name of material color
    },
    to: {
      type: String,
      default: null // Denotes the target route of the link.
    }
  }
}
</script>
