const middleware = {}

middleware['old.admin'] = require('../middleware/old.admin.js')
middleware['old.admin'] = middleware['old.admin'].default || middleware['old.admin']

middleware['old.auth'] = require('../middleware/old.auth.js')
middleware['old.auth'] = middleware['old.auth'].default || middleware['old.auth']

middleware['old.initialize-ui'] = require('../middleware/old.initialize-ui.js')
middleware['old.initialize-ui'] = middleware['old.initialize-ui'].default || middleware['old.initialize-ui']

export default middleware
