<!-- **  View fields of a specific asset

  <asset-view :item="item"  />

-->

<template>
  <v-row no-gutters>
    <v-col cols="12" md="9">
      <v-row no-gutters>
        <c-field cols="12" md="6" :label="$t('assets.field.title')" wrap>{{ item.title }}</c-field>

        <c-field cols="12" md="6" :label="$t('def.tags')">
          <list-tags v-model="item.tags" chips />
        </c-field>

        <c-field cols="12" md="6" :label="$t('assets.field.alt')" wrap>{{ item.alt }}</c-field>

        <c-field cols="12" md="6" :label="$t('assets.field.description')" wrap>{{ item.description }}</c-field>
      </v-row>
    </v-col>

    <v-col cols="12" md="3">
      <list-assets v-model="item._id" variant="md" max-width="400" max-height="400" />
    </v-col>

    <v-col cols="12" class="mt-4">
      <asset-file-details :item="item" cols="12" md="4" />
    </v-col>
  </v-row>
</template>

<script>

import ListTags from '@/components/_lib/form/tags/ListTags'
import ListAssets from '@/components/assets/form/ListAssets'
import AssetFileDetails from '@/components/assets/form/AssetFileDetails'

export default {
  components: {
    ListTags,
    ListAssets,
    AssetFileDetails
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  }
}
</script>
