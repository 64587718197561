/* Accounts: companies or projects that users belong to */
// import Config from '@/config/constants'

import { initialStateProperties } from '~/plugins/vuex-injects/vuex'

const timeZoneMin = (new Date()).getTimezoneOffset()

export default initialStateProperties({
  service: 'accounts',
  channelSubscription: true, // see plugins/feathers/feathers-client.js

  loadCollectionOnStart: false,

  // fields to send to server
  initData: {
    name: '',
    departments: [], // { type: Array, default: [] }, // Default department options for Achievers

    protected: true, // { type: Boolean, default: true }, // if protected, then can not delete (front end function only)
    // primary: false, // every new user will get ONE primary account they can not delete
    tags: [], // Default tags to choose from for stories etc.

    achieverTags: [], // { type: Array, default: defAchieverTags || [] },    // List of tags to define achievers

    // access rights for users to this account
    memberIds: [],
    managerIds: [],
    ownerIds: [],

    bligsyAlertSchedule: {
      enabled: false, // { type: Boolean, default: false }
      // Alert every work day default, // Javascript: d.getDay()
      dayOfWeek: {
        0: false, // { type: Boolean, default: false }, // Sunday;
        1: true, // { type: Boolean, default: true }, // Monday
        2: true, // { type: Boolean, default: true },
        3: true, // { type: Boolean, default: true },
        4: true, // { type: Boolean, default: true },
        5: true, // { type: Boolean, default: true },
        6: false // { type: Boolean, default: false }, // Saturday
      },
      // Alert every day at 20:30 default
      timeOfDay: {
        hours: 20, // { type: Number, min: 0, max: 23, default: 20 }, // d.getHours()
        minutes: 30 // { type: Number, min: 0, max: 59, default: 30 }, // d.getMinutes()
      },
      timezoneOffset: timeZoneMin === 0 ? 0 : timeZoneMin / 60, // { type: Number, min: -12, max: 12, default: -2 } // d.getTimezoneOffset()/60
      utcTime: '' // { type: String, maxlength: 5 } // populated with create/update/patch hook, '20:15', time of day normalized to UTC
    },

    marketingMenuActivated: false, // show the marketing functionality

    // pseudo field
    emailTo: ''
  },

  currentIdUserKey: 'currentAccountId',

  // fields not to send to server, but sent by server to client, **if**:
  //  no query.$select or $select includes the field, such as $select['achievers']
  // so populatedData should include ALL fields we expect by default (unless in normal initData/collection above)
  populatedData: {
    // related collections
    achievers: [],
    users: [],
    owners: [],
    managers: [],
    campaigns: [],
    pipelines: [],
    stories: [],
    assets: [],
    tasks: [],
    size: 0,
    integrations: [
      // title: { type: String, default: '', maxlength: 100 }
      // type: { type: String, enum: ['rest' ], default: 'rest'},
      // key: { type: String, default: () => 'CI_' + customNanoId(20) },
      // hosts: [ url ], // origin hosts  ["localhost:3000", "www.volvo.com"]
      // tags: { type: Array, default: [] }, // some filtering: for "rest" it means: "auto add these tags"
      // params: { type: Object, default: () => {} } // optional integrated related info
    ],

    bligsyActivated: false // set to true by accounts.hooks if enabled && selected at least one day, see accounts.hook
  },

  afterCommitHook: (state, item) => {
    item.idName = `[ ${item._id} ] ${item.name}`
  }
})
