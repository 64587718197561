<!-- Show the image of one or more assets

$attrs attributes passed to the image: <c-img ../>  => <v-img ../>

Events:
  @select  clicked on the image

@TODO: Drag and drop reorder if value is Array and prop allow-reorder=true
-->

<template>
  <!-- Only show if the variant desired indeed exists -->
  <div
    v-if="hasAssetVariant"
  >
    <div
      v-if="!carousel"
      :class="{ 'flex-grid': width>0 }"
      :style="width>0 ? `--card_width: ${width-2}px; --gap: 2px; padding: 1px;`: ''"
    >
      <div v-for="(iId,idx) in itemIdList" :key="idx+'_'+iId">
        <image-view
          v-if="iId"
          :src="formatSrc(iId, variant)"
          :lazy-src="formatSrc(iId, $config.ASSETS.DEFAULT_VARIANT.thumb)"
          :full-src="formatSrc(iId, $config.ASSETS.DEFAULT_VARIANT.maximal)"
          :hide-dialog="hideDialog"
          v-bind="$attrs"
          @click="$emit('select',iId)"
        />
      </div>
    </div>

    <v-carousel v-else v-model="slideNr" style="height: 300px;">
      <v-carousel-item v-for="(iId,idx) in itemIdList" :key="idx+'_'+iId" style="height: 300px;">
        <v-sheet color="foreground-2" height="100%" tile style="height: 300px;" class="d-flex align-center justify-center">
          <image-view
            v-if="iId"
            :src="formatSrc(iId, variant)"
            :lazy-src="formatSrc(iId, $config.ASSETS.DEFAULT_VARIANT.thumb)"
            :full-src="formatSrc(iId, $config.ASSETS.DEFAULT_VARIANT.maximal)"
            :hide-dialog="hideDialog"
            v-bind="$attrs"
            @click="$emit('select',iId)"
          />
        </v-sheet>
      </v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>
import Log from '@/lib/log' // eslint-disable-line no-unused-vars

import ImageView from '@/components/_lib/form/image/ImageView'

export default {
  components: {
    ImageView
  },
  props: {
    value: {
      type: [String, Array],
      default: ''
    },
    // use md, lg, sm or th to show
    variant: {
      type: String,
      default: 'th'
    },
    carousel: {
      type: Boolean,
      default: false
    },
    hideDialog: {
      type: Boolean,
      default: false
    },
    // @TODO: allow drag and drop replacement; note: only useful if value isArray
    allowReorder: {
      type: Boolean,
      default: false
    },
    // the size of the image; width=0 no grid
    width: {
      type: Number,
      default: 0
    }
    // Note: Use v-bind=$attrs for other image,: max-width, max-height and so on

  },
  data () {
    return {
      slideNr: 0,
      colors: [
        'primary',
        'secondary',
        'yellow darken-2',
        'red',
        'orange'
      ]
    }
  },
  computed: {
    itemIdList: {
      get () { return Array.isArray(this.value) ? this.value : [this.value] },
      set (value) {
        if (Array.isArray(this.value)) {
          this.$emit('input', value)
        } else {
          Log.e(`[list-assets] reordering of assets only on array, now we have ${this.value}`)
        }
      }
    },
    hasAssetVariant () {
      if (this.$db.doc('configuration').assetVariants[this.variant]) {
        return true
      } else {
        Log.e(`[list-assets] variant ${this.variant} does not exist in configuration.`)
        return false
      }
    }
  },
  methods: {
    formatSrc (itemId, variant) {
      const url = this.$db.doc('configuration').assetUrl // https://api.bligson.com/data/assets
      const ext = this.$db.doc('configuration').assetVariants[variant].forceFormat || 'webp'
      const src = `${url}/${this.$db.currentAccountId}/${variant}/${itemId}.${ext}`
      // Log.i(`[list-assets]  formatSrc(${itemId}, ${variant}) = ${src}`)
      return src
    }
  }

}
</script>
