// ** FIND - STATE CRUD DATABASE ACTIONS
//
// Load a document set from the database to the state's __collection.
// Current document is set to first document of the returned array
// Optional query can filter the loaded set
//
// const data = await store.dispatch(`${service}/find`, { query })
//
// With pagination enabled, to just get the number of available records set $limit to 0.
// This will only run a(fast) counting query against the database and return a page object with the total and an empty data array.
//
// Special Query parameters:
//   for services with an accountId:  accountId === null  -do not automatically apply find filter on currentAccountId
//   for account-service:  memberIds===null - do not filter on memberIds (admin only function!)
//   $limit: 0  - return number of records
//   $noCommit: false - to not commit the result to the __collection, so just return the data set
//

import Log from '@/lib/log'

// import Config from '@/config/constants'

export default {

  async find ({ commit, getters, dispatch, rootGetters }, { query }) {
    const service = getters.getService

    // ** Build the Query
    query = { ...getters.getDefaultQuery, ...query }

    // to limit query to only currentAccounts, you can set { accountId: true }
    const currentAccountId = rootGetters['users/get']('currentAccountId')
    if ('accountId' in query && typeof query.accountId === 'boolean') { query.accountId = currentAccountId }

    // to override / remove a defaultQuery key, you can set to null: { archived: null }
    for (const field of Object.keys(query)) {
      if (query[field] === null) { delete query[field] }
    }

    query.$select = query.$select || getters.getCollectionFields

    // ** See if we need commit to state/store or just return data (for example in widgets)
    const noCommit = query.$noCommit
    delete query.$noCommit

    // ** Execute the find
    let response
    try {
      this.$signal.apiActionBar = { status: 'loading' }
      response = await this.$api.service(service).find({ query })
      this.$signal.apiActionBar = { status: 'success' }
    } catch ({ code, message }) {
      this.$signal.apiActionBar = { status: 'error', code, message }
      Log.e(`[S] find failed in "${service}" with error: [${code}]: "${message}" and query`, query)
      return []
    }

    // ** If records needed not to be fetched but only counted, return total now
    if (query.$limit === 0) {
      Log.i(`[$db] find query limit = ${query.$limit}`)
      return response.total
    }

    // if service is paginated, we get a data property instead of array
    const data = response.data || response

    const { $select, ...queryNoSelect } = query
    const queryTxt = Object.keys(query).length > 0 ? `, { query:${JSON.stringify(queryNoSelect)} }` : ''

    if (data && noCommit) {
      Log.i(`[S] find("${service}"${queryTxt}): NO commit; ${data.length} document${data.length > 1 ? 's' : ''}`)
    } else if (data) {
      // we have data AND we need to commit
      // run hook on each item
      data.forEach((item) => {
        commit('afterCommitHook', item)
      })
      commit('setCollection', data)
      // commit('incNrFindActions')
      Log.i(`[S] find("${service}"${queryTxt}) + commit ${data.length} doc${data.length > 1 ? 's' : ''}`)
    } else {
      Log.i(`[S] find("${service}"${queryTxt}) no records.`)
    }

    return data
  }
}
