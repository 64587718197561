/* Checklist Items belonging to tasks */

import { initialStateProperties } from '~/plugins/vuex-injects/vuex'

export default initialStateProperties({
  service: 'checklist-items',
  channelSubscription: true, // see plugins/feathers/feathers-client.js

  defaultQuery: { accountId: true },

  initData: {
    accountId: '',
    taskId: '', // { type: String, ref: 'tasks' },
    listIndex: 0, // { type: Number }, // // the sort order in each task colum in the list
    select: false, // { type: Boolean, default: false },
    text: '', // { type: String, maxlength: 80 },
    audit: {
      // type: { type: String, enum: ['', 'progress', 'switch', 'stepper'], default: 'none' }, // '' = this audit is not used
      // value: { type: Number, default: 0 },
      // factor: { type: Number, default: 100 }, // weighing factor
      // info: {  type: String, maxlength: 200  }, // will use excerpt to explain this audit
    }
  },

  populatedData: {
    task: {} // name
  },

  afterCommitHook: (state, item) => {
  }
})
