/* Assets */

import { initialStateProperties } from '@/plugins/vuex-injects/vuex'

// import Config from '@/config/constants' // NOTE >> ******* : .env parameters are not valid here yet !!!!

import Log from '@/lib/log' // eslint-disable-line no-unused-vars

export default initialStateProperties({
  service: 'experiences',
  channelSubscription: false, // see plugins/feathers/feathers-client.js

  defaultQuery: { accountId: true },

  initData: {
    accountId: '', // { type: String, ref: 'accounts', required: true, maxlength: 20, index: 1 },

    // fields supplied by Bligsy, external REST
    chatId: '', // { type: String, maxlength: 80, ref: 'achievers', required: true }, // Telegram chatId "TG#123456abcd"
    text: '', // { type: String, maxlength: 500, required: true }, // Telegram message

    level: 0, // { type: Number, min: 0, max: 5, required: true, default: 0 },
    // 1: 'ok'
    // 2: 'good'
    // 3: 'great'
    // 4: 'excellent'
    // 5: 'perfect'
    sphere: '', // { type: String, enum: ['P', 'W'], maxlength: 1, required: true, default: 'P' },

    // fields manually by account manager in Bligman
    description: '', // { type: String, maxlength: 500 },
    promotable: false, // { type: Boolean, default: false },
    nrStars: 0, // { type: Number, min: 0, max: 5, default: 0 }, // rating of the quality of this article
    tags: [] // [{ type: String, maxlength: 50, index: 1 }],

  },

  populatedData: {
    // from experiences.hook fastJoin
    achiever: {},

    // from afterCommitHook below
    username: '',
    firstName: '', // { type: String, maxlength: 50 }, // From Telegram user profile
    lastName: '', // { type: String, maxlength: 80 },
    language: '' // { type: String, maxlength: 2, required: true, default: 'en' },
  },

  // collectionFields: [...Object.keys(initData),...], // all

  afterCommitHook: (state, item) => {
    // some fields that will ease search and filter // alterItems(async (item) => {
    if (item.achiever) {
      item.username = item.achiever.username
      item.language = item.achiever.language
      item.lastName = item.achiever.lastName
      item.firstName = item.achiever.firstName
    }
  }
})
