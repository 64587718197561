<!-- ** Allow user to edit few embed fields and then insert via state\

-->
<template>
  <v-dialog v-model="dialog" width="680">
    <v-card>
      <v-system-bar
        window
        lights
        color="assets"
        class="white--text"
        style="cursor: pointer;"
        @click.stop="closeDialog()"
      >
        <v-btn icon><v-icon color="white">mdi-arrow-left</v-icon></v-btn>
        {{ $t('assets.insert') }}

        <v-spacer />

        <v-btn icon @click="closeDialog()"><v-icon color="white">mdi-close</v-icon></v-btn>
      </v-system-bar>

      <v-card-text class="pt-4">

        <!-- Bligson cloud assets -->
        <div class="d-flex flex-wrap mx-n2">
          <v-hover v-for="(size,key,index) in item.variants" v-slot:default="{ hover }" :key="index">
            <v-card
              class="ma-2 pa-1"
              width="200"
              :color="hover ? 'grey': 'grey darken-2'"
              :elevation="hover ? 12 : 2"
              @click="sendMediaToEditor(size.type, size.src)"
            >

              <c-img contain :max-height="40+ size.height / 18" :src="size.src" />

              <p class="caption text-center white--text">
                {{ size.width }} x {{ size.height }}px, <c-bytes-to-size :bytes="size.size" />
              </p>
            </v-card>
          </v-hover>

          <!-- Alt and Title fields; no use for snippets -->
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field v-model="title" :label="$t('editor.image.caption')" />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field v-model="alt" :label="$t('editor.image.alt')" class="pr-4" />
            </v-col>
          </v-row>

        </div></v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import Log from '@/lib/log'

export default {

  props: {
    value: {
      type: Boolean,
      default: true
    },
    item: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    dialog: {
      get () { return this.value },
      set (value) { this.$emit('input', value) }
    },

    // fields the user can still last-minute-edit for this asset
    title: {
      get () { return this.item.title },
      set (title) { this.$db.patch('assets', this.item._id, { title }, { $debounce: 2000 }) }
    },
    alt: {
      get () { return this.item.alt },
      set (alt) { this.$db.patch('assets', this.item._id, { alt }, { $debounce: 2000 }) }
    }
  },

  methods: {
    closeDialog () {
      if (this.$app_isMobile) {
        this.$app_rightDrawer = false
      }
      this.dialog = false
    },
    sendMediaToEditor (type = 'image', src = '') {
      this.closeDialog()

      // "Pipe" via Vuex state: Editor should watch and handle the inserts depending on the type of action
      this.$signal.insertEditor = {
        type,
        content: {
          src,
          alt: this.item.alt,
          title: this.item.title,
          htmlSnippet: this.item.htmlSnippet
        }
      }
    }
  }
}
</script>
