<template>
  <div>
    <c-tooltip bottom>
      <template #act>
        <v-btn
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
          v-clipboard:copy="shareValue.url"
          icon
        >
          <!-- @click.stop="shareUrl()" -->
          <v-icon :color="iconColor">mdi-share-variant-outline</v-icon>
        </v-btn>
      </template>

      <template>
        <h4 class="font-weight-medium">{{ $t('share.title') }}</h4>
        <span>{{ $t('share.tooltip') }}</span>
      </template>
    </c-tooltip>

    <v-snackbar v-model="snackbarCopied" :timeout="4000" top absolute>
      {{ $t('def.clipboard.copy.ok') }}:
      <p class="body-2 secondary--text mt-2">{{ shareValue.url }}</p>
      <v-btn color="secondary" text class="float-right" @click="snackbarCopied = false">{{ $t('def.close') }}</v-btn>
    </v-snackbar>

    <v-snackbar v-model="snackbarError" :timeout="4000" color="warning" top absolute>
      {{ $t('def.clipboard.copy.error') }}
      <v-btn color="white" text class="float-right" @click="snackbarError = false">{{ $t('def.close') }}</v-btn>
    </v-snackbar>
  </div>
</template>

<script>

// eslint-disable-next-line no-unused-vars
import Log from '@/lib/log'

export default {
  props: {
    iconColor: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      snackbarCopied: false,
      snackbarError: false
    }
  },
  computed: {
    shareValue () {
      return {
        title: 'Bligson',
        text: 'Achievement Recognition Framework',
        url: window.location
      }
    }
  },
  methods: {
    async onCopy (e) {
      if (navigator.share) {
        try {
          await navigator.share(this.shareValue)
          Log.w('[share-link] shared successfully')
        } catch (err) {
          Log.e('[share-link] Error: ' + err)
        }
      } else {
        // show popup if on desktop (where we not have official share)
        this.snackbarCopied = true
      }
    },
    onError (e) {
      this.snackbarError = true
    }
  }
}
</script>
