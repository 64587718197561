 <!-- Generate a <td></td> field for the slot of a data table; used in @/components/_lib/form/table/GeneralTable

The headers or header + field property are comparable to v-data-table, but with added formatting fields:
   [{
     value: 'createdAt',
     text: 'created',
     width: '100',
     align: 'right',
     class: 'warning', // pl-2 does not work, use style with !important
     style: 'padding-left: 8px !important;'
     responsive: true, // if true do not show on mobile
     format: 'date' // options see @/components/_lib/form/table/GenTdField.vue
    }

 Note:
 - For example for cellClass, see @/components/_lib/form/table/GenTdField.vue
 - Format can also auto set headers align right in GeneralTable.vue)
 - do not forget to pass small property to this component as well if used in table!

-->

<template>
  <td
    v-if="hdr && !hdr.hide && (!($vuetify.breakpoint.xs || small) || !hdr.responsive)"
    :class="(hdr.align ? `text-${hdr.align}`: '') + (hdr.class? ` ${hdr.class}` : '')"
    :style="hdr.style ? hdr.style : ''"
  >
    <slot name="td-prefix" />

    <!-- field with link -->
    <c-link v-if="hdr.to" :to="hdr.to + '/' + item._id">
      <slot v-if="hdr.format==='custom'" />
      <gen-td-field v-else :hdr="hdr" :item="item" :value="value" />
    </c-link>

    <!-- no linked field -->
    <template v-else>
      <gen-td-field v-if="hdr.format==='custom'" :hdr="hdr" :item="item" :value="value">
        <slot />
      </gen-td-field>
      <gen-td-field :hdr="hdr" :item="item" :value="value" />
    </template>

    <slot name="td-postfix" />
  </td>
</template>

<script>

import GenTdField from '@/components/_lib/form/table/GenTdField'

export default {
  components: {
    GenTdField
  },
  props: {
    header: {
      type: Object,
      default: () => {}
    },
    headers: {
      type: Array,
      default: () => []
    },
    value: {
      type: String,
      default: ''
    },
    item: {
      type: Object,
      default: () => {}
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hdr () {
      return this.header || this.headers.find(item => item.value === this.value)
    }
  }
}

</script>
