<!-- Notify the user about all outstanding confirmations -->

<template>
  <div>
    <request-confirm-email />
    <reset-one-time-password />
  </div>
</template>

<script>

// eslint-disable-next-line no-unused-vars
import Log from '@/lib/log'

import ResetOneTimePassword from '@/components/_default/topbar/confirmations/ResetOneTimePassword'
import RequestConfirmEmail from '@/components/_default/topbar/confirmations/RequestConfirmEmail'

export default {
  components: {
    ResetOneTimePassword,
    RequestConfirmEmail
  }
}
</script>
