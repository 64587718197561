/* Assets */

import { initialStateProperties } from '@/plugins/vuex-injects/vuex'

// import Config from '@/config/constants' // NOTE >> ******* : .env parameters are not valid here yet !!!!

import Log from '@/lib/log' // eslint-disable-line no-unused-vars

export default initialStateProperties({
  service: 'achievers',
  channelSubscription: false, // see plugins/feathers/feathers-client.js

  defaultQuery: { accountId: true },

  initData: {
    accountId: '', // { type: String, ref: 'accounts', required: true, maxlength: 20, index: 1 },

    chatId: '', //  { type: String, maxlength: 80, required: true }, // ref. by experiences.model.js // Telegram chatId "TG#123456abcd"
    blocked: false, // { type: Boolean }, // admin can (temporarily) block account
    invitationsEnabled: true,

    telegram: {
      username: '', // { type: String, maxlength: 80, required: true },
      firstName: '', // { type: String, default: '', maxlength: 40 },
      lastName: '', // { type: String, default: '', maxlength: 60 }
      lang: 'en' // { type: String, default: 'en', length: 2 },
    },

    address: {
      company: '', // { type: String, default: '' },
      department: '', // { type: String, default: '' },
      firstName: '', // { type: String, default: '' },
      lastName: '', // { type: String, default: '' },
      sex: '', // m/f  male / female / undefined
      tel: [], // [String],
      email: [], // [String],
      street: '', // { type: String, default: '' },
      houseNumber: '', // { type: String, default: '' },
      city: '', // { type: String, default: '' },
      state: '', // { type: String, default: '' },
      zipCode: '', // { type: String, default: '' },
      countryCode: '', // { type: String, default: '' },
      homePage: ''
    },

    tags: [], // ['black', 'lgbtq', 'minority' ]

    description: '' // { type: String, maxlength: 500 }, // some additional notes from account managers about this person

  },

  populatedData: {
    account: {}, // { _id, name},
    experiences: [], // [ {_id, level, sphere, promotable, nrStars} ]

    username: '',
    firstName: '', // { type: String, maxlength: 50 }, // From Telegram user profile
    lastName: '', // { type: String, maxlength: 80 },
    language: '' // { type: String, maxlength: 2, required: true, default: 'en' },
  },

  // collectionFields: [...Object.keys(initData),...], // all

  afterCommitHook: (state, item) => {
    // some fields that will ease search, sort and filter in tables
    item.sex = item.address.sex
    item.department = item.address.department
  }
})
