<!-- ** SHOW PROGRESS BAR AT TOP OF SCREEN WITH API STATUS

Appearance depends on _signals apiAction state

 - loader for minimal amount of time to make sure even fast database shows some interaction
 - colors depending on status code kept by apiAction.status
 - alert pop up if apiAction.status === 'error'

Example:

  <api-api-status-bar position="position: absolute; top: 0;" />

Start the loader with:

   this.$signal.apiActionBar = { status: 'loading' }
   this.$signal.apiActionBar = { status: 'success' }
   this.$signal.apiActionBar = { status: 'error', code: 404, message: 'There are troubles' }
   this.$signal.apiActionBar = { status: 'error', code, message }

* After timeout this component will reset apiAction.status === 'done'
-->

<template>
  <div>
    <v-progress-linear
      v-if="apiAction.status !== 'done'"
      indeterminate
      :style="position + ' z-index: 10;'"
      class="pr-4"
      :height="3"
      :color="barColor"
      value="100"
    />

    <v-snackbar v-model="showAlert" top color="white" multi-line :timeout="loaderTimeOut.error">
      <v-btn icon class="float-right mt-n2 mr-n4" @click="showAlert=false; resetLoader();">
        <v-icon color="info">mdi-close</v-icon>
      </v-btn>

      <p class="body-1 error--text">{{ $t(`error.api_response_code.${lastAction.code}`) }}</p>
      <p class="caption error--text">{{ lastAction.code }}: {{ lastAction.message }} {{ additionalInfo() }}</p>
    </v-snackbar>
  </div>
</template>

<script>
import Log from '@/lib/log'

import debounceService from '@/lib/debounce/debounce-service.js'

export default {
  props: {
    position: {
      type: String,
      default: 'position: absolute; bottom: 0;'
    }
  },
  data () {
    return {
      showLoading: false,
      debounceLoading: null,
      debounceReset: null,
      lastAction: {},
      showAlert: false,
      statusColor: {
        loading: 'info',
        success: 'success',
        error: 'error'
      },
      loaderTimeOut: {
        loading: 20000, // waiting for server to respond
        success: 700, // signal user action was a success
        error: 12000 // signal user action was failure
      }
    }
  },

  computed: {
    apiAction () {
      return this.$signal.apiActionBar
    },
    apiStatus () {
      return this.apiAction.status
    },
    apiError () {
      return this.apiAction.code
    },
    barColor () {
      return this.statusColor[this.apiAction.status]
    }
  },
  watch: {
    apiStatus () {
      if (this.apiStatus !== 'done') {
        this.debounceLoading = debounceService('api-status-bar', this.resetLoader, this.loaderTimeOut[this.apiStatus])()

        if (this.apiAction.log) {
          if (this.apiStatus === 'error') {
            Log.e(this.apiAction.log)
          } else {
            Log.i(this.apiAction.log)
          }
        }

        if (this.apiStatus === 'error') {
          this.lastAction = JSON.parse(JSON.stringify(this.apiAction))
          if (this.apiError === 401) {
            // not authenticated
            this.lastAction.message = 'Authentication expired, please login again'
            this.$router.push('/login')
          }
          this.showAlert = true
        }
      }
    }
  },
  mounted () {
    this.resetLoader()
  },
  methods: {
    resetLoader () {
      this.$signal.apiActionBar = { status: 'done', code: 0, message: '' }
    },
    // show the item's service in case an id is not found (usually on 404)
    additionalInfo () {
      let addStr = ''
      if (this.lastAction.message) {
        const allServices = this.$config.SERVICES.ITEM_PREFIX
        Object.keys(allServices).forEach((key) => {
          if (this.lastAction.message.includes(allServices[key])) {
            addStr += `- ${key}`
          }
        })
      }
      return addStr
    }
  }
}

</script>
