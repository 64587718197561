<!-- Select from array with id / language pairs

    <c-select
      v-model="strategies"
      :title="$t('pipelines.field.strategies.label')"
      :placeholder="$t('pipelines.field.strategies.select')"
      :items="$db.doc('configuration', null, { $clone: true }).workflowCodes.strategies"
      sort
      clearable
      class="mt-4 mb-2"
    />

    <c-select
      :value="strategies"
      :title="$t('pipelines.field.strategies.label')"
      :items="[{id: 100, en: 'hello', nl: 'hallo'}, {id:120, en: 'there', nl:'daar'}]"
      list-class="body-2 three-lines info--text"
      readonly
      icon="filter"
    />

  Events
   @change: user clicked "ok"
 -->

<template>
  <div :class="icon? 'rounded-circle':''">
    <!-- just an icon with tooltip -->
    <c-icon-btn
      v-if="icon"
      :icon="icon"
      :color="color || 'primary'"
      :tooltip="placeholder"
      :small="small"
      @click="openDialog()"
    />

    <!-- outlined field with the selected contents -->
    <v-card
      v-else
      :outlined="!readonly "
      flat
      class="pa-1 "
      :class="{'cursor-pointer': !readonly, 'transparent': readonly}"
      @click="openDialog()"
    >
      <div :class="listClass">
        <span v-if="itemIds.length>0">{{ itemList.join(', ') }}</span>
        <span v-else-if="placeholder && !readonly" class="foreground-3--text">{{ placeholder }}</span>
      </div>
    </v-card>

    <v-dialog
      v-if="!readonly"
      v-model="dialog"
      :width="wide ? 800: 400"
      @click:outside="resetFilter()"
    >
      <v-system-bar
        window
        lights
        :color="color || 'foreground-2'"
        class="white--text"
        style="cursor: pointer;"
      >
        <v-btn icon @click.stop="save()"><v-icon color="white">mdi-arrow-left</v-icon></v-btn>
        <v-spacer />
        <span class="caption">{{ title }} </span>
        <v-spacer />
        <v-btn icon class="mr-n2" @click.stop="resetFilter(); dialog=false;"><v-icon color="white">mdi-close</v-icon></v-btn>
      </v-system-bar>

      <v-card>
        <v-card-text :class="{ 'two-column': wide }" style="max-height: 550px;" class="overflow-auto pt-4">
          <v-checkbox
            v-for="item in sortedItems"
            :key="item.id"
            v-model="itemIds"
            :value="item.id"
            hide-details
            multiple
            class="mt-0"
          >
            <template v-slot:label>
              <div class="body-2 text-truncate" style="overflow:hidden;">
                {{ item[$db.currentUserLanguage] }}
              </div>
            </template>
          </v-checkbox>

          <!-- Even out the item number in the columns to avoid Chrome overflow bug -->
          <div v-if="wide && sortedItems.length % 2 !== 0" style="height: 28px;" />
        </v-card-text>
        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            v-if="clearable"
            :disabled="Object.keys(itemIds).length===0"
            small
            text
            class="mr-2"
            color="warning"
            @click="clearFilter()"
          >
            {{ $t('def.clear') }}
          </v-btn>

          <v-btn
            :disabled="JSON.stringify(itemIds) === JSON.stringify(originalList)"
            small
            text
            class="mr-2"
            color="info"
            @click="resetFilter()"
          >
            {{ $t('def.revert') }}
          </v-btn>

          <v-btn color="success" text small @click="save()">{{ $t('def.ok') }} </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Log from '@/lib/log' // eslint-disable-line no-unused-vars

export default {
  props: {
    value: {
      type: Array,
      default: () => []
    },
    items: {
      type: Array,
      default: () => []
    },
    itemValue: {
      type: String,
      default: 'industries'
    },
    // dialog title
    title: {
      type: String,
      default: ''
    },
    // text for empty field Or for icon tooltip
    placeholder: {
      type: String,
      default: 'select...'
    },
    listClass: {
      type: String,
      default: 'body-2 three-lines'
    },
    // do not show the card, but show an icon-button to click on
    // the icon's tooltip will be the placeholder
    icon: {
      type: String,
      default: '' // e.g. 'filter'
    },
    // icon small size
    small: {
      type: Boolean,
      default: false
    },
    // icon color, default 'primary' *and* color for dialog system ar, default 'foreground-2')
    color: {
      type: String,
      default: '' // e.g. 'filter'
    },
    clearable: {
      type: Boolean,
      default: false
    },
    // only show the list with items
    readonly: {
      type: Boolean,
      default: false
    },
    sort: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dialog: false,
      originalList: []
    }
  },
  computed: {
    wide () {
      return this.items.length > 10 && !this.$app_isMobile
    },
    itemIds: {
      get () { return this.value },
      set (value) { this.$emit('input', value) }
    },
    itemList () {
      const list = (this.itemIds && this.itemIds.length > 0)
        ? this.items.filter(i => this.itemIds.includes(i.id)).map(s => s[this.$db.currentUserLanguage])
        : []
      return this.sort ? this.listSort(list) : list
    },
    sortedItemList () {
      return this.sort ? this.listSort(this.itemList) : this.itemList
    },
    sortedItems () {
      return this.sort ? this.listSort(this.items) : this.items
    }
  },

  methods: {
    openDialog () {
      this.dialog = true
      this.originalList = [...this.itemIds]
    },
    save () {
      this.dialog = false
      this.$emit('change', this.itemIds)
    },
    resetFilter () {
      // Log.i('reset to ', this.originalList)
      this.itemIds = [...this.originalList]
    },
    clearFilter () {
      this.itemIds = []
    },
    saveTags () {
      this.dialog = false
      this.$emit('input', this.itemIds)
    },
    listSort (list) {
      const sortedList = [...list].sort((a, b) => {
        const fa = a[this.$db.currentUserLanguage]
        const fb = b[this.$db.currentUserLanguage]
        if (fa < fb) { return -1 }
        if (fa > fb) { return 1 }
        return 0
      })
      return sortedList
    }
  }
}
</script>
