<!-- Select multiple users

Example:

  <select-users v-model="item.authorIds" multiple :users="item.authors" :label="$t('stories.field.authors.label')" />

  <select-users v-model="oneUser" label="filter one user" />

  -->

<template>
  <c-field :label="label">
    <!-- <p class="caption info--text">{{ allUserIds }}</p> -->
    <v-select
      v-model="allUserIds"
      :items="items"
      item-text="displayName"
      item-value="_id"
      chips
      small-chips
      :class="outlined ? '' : 'ml-n4'"
      :outlined="outlined"
      solo
      flat
      :background-color="backgroundColor"
      hide-details="auto"
      :multiple="multiple"
      v-bind="$attrs"
    >
      <template v-slot:selection="data">
        <div v-if="!multiple" class="d-flex overflow-hidden align-center">
          <avatar :size="22" :user="data.item" class="mr-2" />
          <span class="two-lines ">{{ data.item.displayName || data.item.email }}</span>
        </div>

        <v-chip
          v-else
          :disabled="currentUserDisabled && data.item._id === currentUserId"
          :input-value="data.selected"
          color="info"
          outlined
          small
          class="mt-1 mb-1"
          v-bind="data.attrs"
          @click="data.select"
        >
          <avatar :size="18" :user="data.item" class="mr-2" />
          {{ data.item.displayName || data.item.email }}&nbsp;&nbsp;
        </v-chip>
      </template>

      <template v-slot:item="data">
        <template v-if="(typeof data.item !== 'object')">
          <v-list-item-content v-text="data.item" />
        </template>

        <template v-else>
          <v-list-item-avatar>
            <avatar :size="30" :user="data.item" />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>
              {{ data.item.displayName }}
            </v-list-item-title>

            <v-list-item-subtitle>
              {{ data.item.email }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </template>

    </v-select>
    <c-link v-if="setPrimaryUserLink" to="#" class="d-flex">
      <v-spacer />
      <div class="caption" @click="setCurrentUserAsPrimary()">
        {{ $t('stories.field.authors.primary').toLowerCase() }}
      </div>
    </c-link>
  </c-field>
</template>

<script>
import Log from '@/lib/log'// eslint-disable-line

import Avatar from '@/components/users/profile/Avatar'

export default {
  components: {
    Avatar
  },

  props: {
    // pass an v-model with array of user ids
    value: {
      type: [Array, String],
      default: () => []
    },
    // Array with user objects to choose from, defaults to account.users
    users: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: ''
    },
    backgroundColor: {
      type: String,
      default: 'background'
    },
    hint: {
      type: String,
      default: ''
    },
    outlined: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    setPrimaryUserLink: {
      type: Boolean,
      default: false
    },
    // disable the selection of the current user
    currentUserDisabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    currentUserId () {
      return this.$db.doc('users')._id
    },
    items () {
      return this.users.length > 0 ? this.users : this.$db.doc('accounts').users
    },
    allUserIds: {
      get () {
        return this.value
      },
      set (value) {
        // const unique = Array.from(new Set([this.$db.doc('users')._id, ...value]))
        this.$emit('input', value)
      }
    }
  },
  methods: {
    setCurrentUserAsPrimary () {
      Log.i(`Primary authors "${this.currentUserId}"  in`, this.allUserIds)
      this.allUserIds.unshift(this.currentUserId)
      this.allUserIds = Array.from(new Set(this.allUserIds))
    }
  }
}
</script>
