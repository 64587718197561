<!-- Insert formatted date string, using formats of https://date-fns.org/v2.12.0/docs/format

Examples:
  <c-date :date="new Date().toISOString()" time-only not-set />
  <c-date :date="new Date().toISOString()" long date-only long />
  <c-date :date="new Date().toISOString()" date-format="d MMM yyyy" />
-->

<template>
  <span @click="$emit('click')">{{ formattedDate }}</span>
</template>

<script>
import { format } from 'date-fns'
import Log from '@/lib/log' // eslint-disable-line no-unused-vars

// import { format, parse } from 'date-fns'

export default {
  props: {
    date: {
      type: String, // ISOString format: "2011-10-05T14:48:00.000Z"
      default: ''
    },
    dateFormat: {
      type: String,
      default: 'dd MMM yyyy'
    },
    // expect long format
    dateOnly: {
      type: Boolean,
      default: false
    },
    timeOnly: {
      type: Boolean,
      default: false
    },
    long: {
      type: Boolean,
      default: false
    },
    // show $t('def.not_set') if date is invalid or empty
    notSet: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    formatTemplate () {
      const userLocDateFormat = this.$db.doc('users').localization ? this.$db.doc('users').localization.dateFormat : 'int'
      const userDateFormat = this.$config.DATE_FORMAT.find(item => item.value === userLocDateFormat)

      const dateFormat = this.dateFormat || (this.long ? userDateFormat.long : userDateFormat.short)
      //  { value: 'int', text: 'International: "16 may 2020"', short: 'd MMM yyyy', long: 'iiii, d MMMM yyyy' },
      //  { value: 'en', text: 'English: "May 16, 2020"', short: 'MMM d, yyyy', long: 'iiii, MMMM d, yyyy' }
      if (this.dateOnly) { return dateFormat }

      const userTimeFormat = (this.$db.doc('users').localization && this.$db.doc('users').localization.time24Hours)
        ? this.$config.TIME_FORMAT.default // 'HH:mm'
        : this.$config.TIME_FORMAT.am_pm // 'KK:mm aaa'

      if (this.timeOnly) {
        return userTimeFormat
      }

      // both time and date
      return `${dateFormat} ${userTimeFormat}`
    },
    formattedDate () {
      if (!this.date) { return '…' }

      const initDate = new Date(this.date)
      if (this.date && isValidDate(initDate)) {
        try {
          return format(initDate, this.formatTemplate)
        } catch (error) {
          Log.e(`[!] Date parse format error with "${this.formatTemplate}" for `, initDate)
        }
      } else if (this.notSet) {
        return this.$i18n.t('def.not_set')
      }
      return ''
    }
  }
}
// *** HELPER
function isValidDate (d) {
  return d instanceof Date && !isNaN(d)
}
</script>
