/* Currencies and rates */

import { initialStateProperties } from '~/plugins/vuex-injects/vuex'

export default initialStateProperties({
  service: 'integrations',

  loadCollectionOnStart: false,

  defaultQuery: { accountId: true, $sort: { disabled: 1, title: 1 } },

  initData: {
    accountId: '', // { type: String, ref: 'accounts', index: 1 },
    disabled: false, // { type: Boolean, default: false },
    title: '', // { type: String, default: '', maxlength: 100 }, // 'Blog for our website',
    type: 'rest', // { type: String, enum: ['rest' ], default: 'rest'},
    tags: [], // { type: Array, default: [], index: 1 }, // some filtering: for "rest" it means: "auto add these tags"
    hosts: [] // [ url ], // origin hosts  ["localhost:3000", "www.volvo.com"]
  },

  populatedData: {
    key: '', // { type: String, default: () => 'CI_' + customNanoId(20) },
    params: {} // { type: Object, default: () => {} } // optional integrated related info
  }
})
