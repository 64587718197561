<!-- **  Show button group to select provider between 'youtube', 'vimeo,...

Events:

  @change emits new type

  <select-embed-type v-model="embedType" class="ml-1 ml-md-4" show-labels color="info" />
  <select-embed-type v-model="embedType" hide-all radio label="select type to embed" />

-->

<template>
  <div>
    <c-field v-if="radio" :label="label" wrap>
      <v-radio-group v-model="embedType" row hide-details="auto" class="mt-0 mb-2">
        <v-radio
          v-for="p in embedTypes"
          :key="p"
          :label="$t(`embeds.type.${p}`)"
          :value="p"
          @change="$emit('change', p)"
        />
      </v-radio-group>
    </c-field>

    <v-btn-toggle v-else v-model="embedType" dense :color="color">
      <v-btn
        v-for="p in embedTypes"
        :key="p"
        :text="showLabels"
        small
        :icon="!showLabels"
        :value="p"
        @click="$emit('change', p)"
      >
        <c-tooltip>
          <template #act>
            <v-icon small :color="color" :class="p===value ? '' : notSelectedColor">mdi-{{ $config.EMBEDS.TYPE[p].icon }}</v-icon>
            <span v-if="showLabels" class="ml-1 hidden-sm-and-down" :class="p===value ? '': `${notSelectedColor}`">
              {{ $t(`assets.provider.${p}.label`) }}
            </span>
          </template>

          <span class="text-lowercase">{{ $t(`assets.provider.${p}.tooltip`) }}</span>
        </c-tooltip>
      </v-btn>
    </v-btn-toggle>
  </div>

</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    // label only shown if radio
    label: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'grey'
    },
    notSelectedColor: {
      type: String,
      default: 'grey--text lighten-1'
    },
    // show radio instead of button group
    radio: {
      type: Boolean,
      default: false
    },
    // hide the 'all' selection from the provider
    hideAll: {
      type: Boolean,
      default: false
    },
    // show labels instead of only icon
    showLabels: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    embedTypes () {
      const keys = Object.keys(this.$config.EMBEDS.TYPE)
      return this.hideAll ? keys.filter(p => p !== 'all') : keys
    },
    embedType: {
      get () { return this.value },
      set (value) { this.$emit('input', value) }
    }
  }
}
</script>
