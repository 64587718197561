// ** PATCH - UPDATE STATE CRUD DATABASE ACTIONS
//
// Patch / update / edit one item in the database, the store data (current doc) and the store __collection
//  @TODO: provide id for one item or params for multiple
//
//  await store.dispatch(`${service}/patch`, { id, data, params })
//

import Log from '@/lib/log'

export default {
  async patch ({ commit, getters, dispatch, rootGetters }, { id, data, params = {} }) {
    const service = getters.getService
    if (id === undefined) {
      throw new Error(`[S] patch("${service}") action failed: id missing.`)
    }

    // Limit the patch for account related services if non-admin user
    if ('accountId' in getters.getInitData) {
      const isSuperAdmin = rootGetters['users/get']('permissions').includes('super')
      const isAdmin = isSuperAdmin || rootGetters['users/get']('permissions').includes('admin')
      if (!isAdmin) {
        // ... limit the patch to accounts they are member of
        params.query = params.query || {}
        params.query.accountId = rootGetters['users/get']('currentAccountId')
      }
    }

    const $noCommit = params.$noCommit
    delete params.$noCommit

    let result
    try {
      this.$signal.apiActionBar = { status: 'loading' }
      result = await this.$api.service(service).patch(id, data, params)
      this.$signal.apiActionBar = { status: 'success' }
    } catch ({ code, message }) {
      this.$signal.apiActionBar = { status: 'error', code, message }
      Log.e(`[S] patch "${service}" failed for id=${id}. Error = "${code}: ${message}". Passed data and params: `, data, params)
      return {}
    }

    // @TODO: patch multiple if we have a result array
    // only commit to current doc if not blocked by $noCommit parameter AND ..
    // ... there is specific accountId active: not the case when admin browses user records
    if (!$noCommit && result && result._id) {
      commit('afterCommitHook', result)
      commit('updateCollection', { id: result._id, data: result }) // also patches current document if we have match
    }

    // check for a difference: probably means that a field was seized in awaiting of confirmation email link
    // objects we dont compare
    const resultDiffers = Object.keys(data).find(field =>
      data[field] !== result[field] && typeof data[field] !== 'object' ? field : undefined
    )
    Log.i(`[S] patch("${service}", ${id}) ${$noCommit ? '' : '& commit '}`, data, params, result)

    if (resultDiffers) {
      // inform ConfirmationsNotifier.vue
      Log.i(`[S] patch field difference we need find confirmations ${service}`, resultDiffers)
      await dispatch('confirmations/find', {}, { root: true })
    }

    return result
  }
}
