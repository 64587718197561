<!-- Allow user to enter a message  and dens it

Events:
  @sent: message

-->

<template>
  <div class="d-flex align-end white">

    <v-textarea
      v-model="message"
      :label="$t('activities.send.label')"
      outlined
      full-width
      :rows="1"
      auto-grow

      hide-details="auto"
    />

    <c-tooltip>
      <template #act>
        <v-btn small icon :disabled="!message" class="ml-2 " @click.stop="saveMessage()">
          <v-icon color="primary">mdi-send-circle-outline</v-icon>
        </v-btn>
      </template>

      <span>{{ $t('activities.send.message') }}</span>
    </c-tooltip>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import Log from '@/lib/log'

export default {

  props: {
    itemId: {
      type: String,
      default: ''
    },
    currentMessage: {
      type: String,
      default: ''
    },
    service: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      message: ''
    }
  },
  mounted () {
    this.message = this.currentMessage
  },
  methods: {
    async saveMessage () {
      if (this.currentMessage) {
        await this.patchMessage()
      } else {
        await this.createMessage()
      }
      this.$emit('sent', this.message)
    },
    async createMessage () {
      await this.$db.create('activities', {
        itemId: this.itemId,
        service: this.service,
        userId: this.$db.currentUserId,
        method: 'comment',
        message: this.message,
        seenByUserIds: [this.$db.currentUserId],
        actions: ['commented']
      })
      this.message = ''
    },
    async patchMessage () {
      Log.w('send message', this.message, this.$db.currentUserId)
      await this.$db.patch('activities', this.itemId, {
        message: this.message
      })
      this.message = ''
    }
  }
}
</script>
