<template>
  <client-only>

    <!--
       We render client-only as ~/middleware/auth.js forwards on process browser only.
       Which is because JWT is not shared with backend... @TODO
    -->

    <v-app v-if="$signal.isLoggedIn">
      <!-- :hide-on-scroll="screenWidth < 800"-->
      <v-app-bar
        app
        fixed
        :hide-on-scroll="zenScreen"
        elevate-on-scroll
        class="no-print background-1"
      >

        <api-status-bar />

        <v-app-bar-nav-icon
          v-if="!$app_leftMiniDrawer"
          class="ml-n4 ml-md-n2"
          :class="{'rotate-button': $app_leftDrawer}"
          aria-label="top menu"
          @click.stop="$app_leftDrawer = !$app_leftDrawer"
        />

        <!-- <c-link to="/" class="ml-n2 ml-md-0 mr-1 mr-md-4">
            <logo class="d-none d-sm-flex" style="max-height: 44px;" />
            <logo class="d-flex d-sm-none" style="max-height: 32px;" />
          </c-link> -->

        <!-- Drop down with all accounts available to this user -->
        <select-account
          v-model="$db.currentAccountId "
          show-manager-link
          show-owner-badge
          wide
          class="mt-n2"
          append-icon=""
          style="max-height: 56px; overflow:hidden;"
          background-color="background-1"
        />
        <v-spacer />

        <!-- the right buttons -->
        <div class="d-flex align-center">
          <indicator-bar class="ml-n4" />

          <share-link class="d-none d-md-flex" />

          <user-drop-menu />

          <account-drop-menu />

          <v-btn
            icon
            :class="{'rotate-ninety-right': $app_rightDrawer}"
            small
            class="ml-0 ml-md-2 mr-n2 mr-md-2"
            @click.stop="$app_rightDrawer = !$app_rightDrawer"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </div>
      </v-app-bar>

      <v-navigation-drawer
        v-model="$app_leftDrawer"
        fixed
        app
        touchless
        :width="220"
        :mini-variant="$app_leftMiniDrawer"
        :permanent="$app_leftMiniDrawer"
        class="Oceanside no-print"
      >
        <left-drawer />
      </v-navigation-drawer>

      <v-navigation-drawer
        v-model="$app_rightDrawer"
        app
        right
        touchless
        :width="$vuetify.breakpoint.mdAndDown ? 250 : 380"
        fixed
        class="Juneberry no-print"
      >
        <right-drawer />
      </v-navigation-drawer>

      <v-main v-if="$db.currentAccountId ">
        <confirmations-notifier class="no-print" />
        <nuxt />
      </v-main>
    </v-app>
  </client-only>
</template>

<script>
import Log from '@/lib/log' // eslint-disable-line no-unused-vars

import ApiStatusBar from '@/components/_default/topbar/ApiStatusBar.vue'
import ConfirmationsNotifier from '@/components/_default/topbar/ConfirmationsNotifier'

import ShareLink from '@/components/_default/topbar/ShareLink'
import UserDropMenu from '@/components/_default/topbar/UserDropMenu.vue'
import AccountDropMenu from '@/components/_default/topbar/AccountDropMenu.vue'

import SelectAccount from '@/components/accounts/form/SelectAccount'

import LeftDrawer from '@/components/_default/drawers/LeftDrawer.vue'
import RightDrawer from '@/components/_default/drawers/RightDrawer.vue'
import IndicatorBar from '@/components/_default/topbar/IndicatorBar.vue'

function matchRuleShort (str, rule) {
  const escapeRegex = str => str.replace(/([.*+?^=!:${}()|[\]/\\])/g, '\\$1')
  return new RegExp('^' + rule.split('*').map(escapeRegex).join('.*') + '$').test(str)
}

export default {
  name: 'DefaultLayout',
  components: {
    ApiStatusBar,
    ConfirmationsNotifier,
    ShareLink,
    AccountDropMenu,
    UserDropMenu,
    SelectAccount,
    LeftDrawer,
    RightDrawer,
    IndicatorBar
  },
  computed: {
    theme () {
      return (this.$vuetify.theme.dark) ? 'dark' : 'light'
    },
    // zenMode for some routes such as the Editor
    zenScreen () {
      const okRoute = this.$config.ZEN_ROUTES.find(r => matchRuleShort(this.$nuxt.$route.path, r))
      return okRoute !== undefined
    }
  },

  async mounted () {
    // load user local preferences
    await this.$app_storeLoaded
    this.$vuetify.theme.dark = this.$app_darkMode
    // whenever the user goes to other tab and returns, we like to start with re-authentication
    document.addEventListener('visibilitychange', this.authenticateOnVisible, true)
  },
  beforeDestroy () {
    Log.i('[default-visibility] event listener removed')
    document.removeEventListener('visibilitychange', this.authenticateOnVisible, true)
  },
  methods: {
    async authenticateOnVisible () {
      this.$signal.visibility = false
      if (document.visibilityState === 'visible' && process.browser) {
        try {
          const { user } = await this.$api.reAuthenticate()
          // $db.currentAccountId = user.currentAccountId
          Log.i(`[default-visibility] ${document.visibilityState} re-authenticated: ${user._id} ${user.email}  account ${this.$db.currentAccountId}`)
          this.$signal.visibility = true
        } catch ({ code, message }) {
          Log.w('[default-visibility] not authenticated:', code + ':' + message)
          const path = '/' + this.$route.fullPath.split('/')[1] // '/login', '/confirm', '/signup , '/campaigns' ...
          // only redirect if not already on public page
          if (![...this.$config.publicRoutes, '/login'].includes(path)) {
            window.onNuxtReady(() => { window.$nuxt.$router.push('/login') })
          }
        }
      }
    }
  }

  // middleware: 'authenticated'  // moved to nuxt.config.js: router: {... }
}
</script>
