// Generate classes based on item.startAt and item.endAt parameters
//
// Example:
//
//   import { startDateFunc, endDateFunc } from '@/components/_lib/filters/date-range-classes.js'
//
//    headers: [
//        { value: 'startAt', text: 'start', responsive: true, width: '100', format: 'date', classFunction: startDateFunc },
//        { value: 'endAt', text: 'end', responsive: true, width: '100', format: 'date', classFunction: endDateFunc }
//    ]

const bothOkFunc = item => (
  (new Date(item.startAt) < new Date()) && (new Date(item.endAt) > new Date()) ? ' background-1 ml-n2 pl-2 py-1' : ` ${item.startAt} / ${item.endAt}`
)

module.exports = {
  startDateFunc: item => (new Date(item.startAt) < new Date() ? 'success--text mr-0 mr-md-n2' : 'primary--text') + bothOkFunc(item),
  endDateFunc: item => (new Date(item.endAt) > new Date() ? 'success--text' : 'primary--text') + bothOkFunc(item)
}
