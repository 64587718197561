
import Log from '@/lib/log'

const setData = (state, data) => {
  const keys = Object.keys(data)
  keys.forEach((key) => { // loop for Vue reactivity
    if (typeof data[key] === 'object' && !Array.isArray(data[key])) {
      // keep the remainder of the key,values in the object
      state[key] = { ...state[key], ...data[key] }
    } else {
      state[key] = data[key]
    }
  })
}

export default {
  //
  // Store one or more parameters: this.$store.commit('user/set', { email: 't@j.com, name: 'Tom'})
  //
  set (state, data) {
    setData(state, data)
  },

  // @NOTE
  // clear (state) you will find in plugins/vuex-injects/vuex/index.js

  setCollection (state, data) {
    state.__collection = data
    // also patch current document, if we have any
    data.forEach((item) => {
      if (item._id === state._id) {
        setData(state, item)
      }
    })
  },

  // After each find action: commit('user/setLastQuery', query), to enable $cache
  setLastQuery (state, query) {
    state.__lastQuery = query
  },

  //
  // Patch item in the __collection store array with data or add new item if id not found
  //
  updateCollection (state, { id, data }) {
    const service = state.__service
    // Log.i(`CALL updateCollection ${service}`)
    if (!id) { throw new Error(`[S] updateCollection: missing id in ${service}`) }

    // update current document as well
    if (state._id === id) {
      setData(state, data)
    }

    const newStateCollection = [...state.__collection]
    if (newStateCollection) {
      const index = state.__collection.findIndex(item => item._id === id)
      if (index >= 0) {
        newStateCollection[index] = data
        // Log.i(`[S] updateCollection ${service} patch index ${index} to`, state.__collection[index])
      } else {
        newStateCollection.push(data)
        // Log.i(`[S] updateCollection ${service} append new document`)
      }
      state.__collection = [...newStateCollection]
    }
  },

  //
  // Remove item from the __collection store array
  //
  removeItemCollection (state, id) {
    if (state.__collection) {
      const index = state.__collection.findIndex(item => item._id === id)
      if (index >= 0) {
        state.__collection.splice(index, 1)
        // Log.i(`[S] removeItemCollection "${state.__service}" [${index}] _id="${id}"`)
      } else {
        Log.w(`[S] removeItemCollection with _id="${id}" not found in ${state.__service}.__collection`)
      }
    }
  },

  //
  // Append a new item to the end of the __collection
  //
  appendItemCollection (state, data) {
    if (!state.__collection) {
      state.__collection = data
    } else {
      state.__collection.push(data)
    }
  }
}
