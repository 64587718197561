var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('general-table',{attrs:{"service":"embeds","dialog-bar-color":"assets","headers":_vm.headers,"small":_vm.small,"items-per-page":15,"show-tag-select":"","show-expand":"","show-gallery":_vm.showGallery,"show-gallery-toggle":_vm.showGalleryToggle,"hide-gallery-size-toggle":_vm.hideGallerySizeToggle,"show-new-on-empty":_vm.showNewOnEmpty,"selectable":_vm.selectable,"select-multiple":_vm.selectMultiple,"query":_vm.totQuery,"filter-item-id":_vm.filterItemId,"filter-list":_vm.types,"filter-placeholder":((_vm.$t('def.select')) + " " + (_vm.$t('def.type')))},on:{"itemCreated":_vm.refreshItem,"itemChanged":_vm.refreshItem,"select":function($event){return _vm.$emit('select', $event)}},scopedSlots:_vm._u([{key:"left-toolbar",fn:function(){return undefined},proxy:true},{key:"right-toolbar",fn:function(){return undefined},proxy:true},{key:"item-table-row",fn:function(ref){
var item = ref.item;
return [_c('gen-td',{staticClass:"d-flex justify-start align-center",attrs:{"headers":_vm.headers,"item":item,"value":"type"}},[_c('list-embeds',{attrs:{"item-id":item._id,"width":44,"max-height":40}})],1),_vm._v(" "),_c('gen-td',{attrs:{"headers":_vm.headers,"item":item,"value":"title"}}),_vm._v(" "),_c('gen-td',{attrs:{"headers":_vm.headers,"item":item,"value":"tags"}}),_vm._v(" "),_c('gen-td',{attrs:{"headers":_vm.headers,"item":item,"value":"description","small":_vm.small}})]}},{key:"item-expand-pane",fn:function(ref){
var item = ref.item;
return [_c('embed-view',{attrs:{"item":item}})]}},{key:"item-edit-form",fn:function(ref){
var item = ref.item;
return [_c('embed-edit',{attrs:{"item":item}})]}},{key:"gallery-card",fn:function(ref){
var item = ref.item;
return [_c('list-embeds',{attrs:{"item-id":item._id}}),_vm._v(" "),_c('c-field',{staticClass:"caption pl-1 text-lowercase "},[_vm._v(_vm._s(item.title))])]}}]),model:{value:(_vm.selectedIds),callback:function ($$v) {_vm.selectedIds=$$v},expression:"selectedIds"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }