/* Debounce a function.

Optional several debounce functions in parallel with the optional service parameter

Example in vuex actions:

  import debounceService from '@/lib/debounce/debounce-service.js'
  ...

  export default {
    async patch ({ commit, getters, dispatch }, props ) {
    ....
    },
    patchDocument ({ commit, getters, dispatch }, props) {
      const debFunc = debounceService('my-service', dispatch, 2000)
      debFunc('patch', props)
    },
  }
*/

const debounceTimerIds = {} // keep one debounce timer per service

const debounceService = (service = 'global', func, delay = 1500, immediate = false) => {
  return (...args) => {
    const boundFunc = func.bind(this, ...args)
    clearTimeout(debounceTimerIds[service])
    if (immediate && !debounceTimerIds[service]) {
      boundFunc()
    }
    const calleeFunc = immediate ? () => { debounceTimerIds[service] = null } : boundFunc
    debounceTimerIds[service] = setTimeout(calleeFunc, delay)
  }
}

export default debounceService
