// *** CONFIGURATION CONSTANTS ***
//
// Loaded in nuxt.config.js which will make it available as $config.
//   Careful to not use already existing params (!)
//
// To use in vuex
//  import CONFIG from '@/config/constants.js'
//
//   Example
//     Log.i(`Version = ${$config.VERSION}`)

// ** Use REV for revisions that do *not* need new npm install
//    (which occur after changes in package.json)
//
const REV = "a";
//

const serialVersion = version => {
  // Calculate a serial version to compare versions if needed when migrating
  // for example, 4.1.56 = 40156
  const v = version.split(".");
  return parseInt(v[0]) * 10000 + parseInt(v[1]) * 100 + parseInt(v[2]);
};

const apiUrls = {
  development: "http://localhost:3030",
  test: "https://bapi.nuricon.net",
  staging: "https://bapi.nuricon.net",
  production: "https://api.bligson.com"
};
const apiUrl = apiUrls[process.env.NODE_ENV];

const bligsyBots = {
  development: "Bligsy_Dev_bot",
  test: "Bligsy_Test_bot",
  staging: "Bligsy_Test_bot",
  production: "Bligsy_bot"
};
const bligsyBot = bligsyBots[process.env.NODE_ENV];

export default {
  NODE_ENV: process.env.NODE_ENV,
  VERSION: (process.env.npm_package_version || "0.0.0") + REV,
  SERIAL_VERSION: serialVersion(process.env.npm_package_version || "0.0.0"),

  BLIGSY_BOT_USERNAME: bligsyBot,
  API_URL: apiUrl,
  REPO_URL: apiUrl + "/data", // Note: no trailing '/' So you can use use: `${this.$config.REPO_URL}/A_100/lg/1234abc.jpg`

  // Locales: See nuxt.config.js
  LANGUAGES: [
    { value: "en", text: "English" },
    { value: "nl", text: "Nederlands" },
    { value: "de", text: "Deutsch" }
  ],
  FALL_BACK_LANGUAGE: "en",

  // zenMode for some routes such as the Editor, to reduce screen clutter
  ZEN_ROUTES: [
    // matches wildcard of $route.path see @/layouts/default
    "/stories/*",
    "/admin/information/*"
  ],

  // Determines on what pages and how to show right drawer panels
  DRAWER_PANELS: [
    // Open by default state initializes by components/_default/drawers/RightDrawer.vue
    //   showOnRoute can use wild card '*admin' or '/*', without wild card match exactly
    {
      panel: "activities",
      color: "Juneberry",
      showOnRoute: ["/*"],
      openByDefault: true
    },
    {
      panel: "assets",
      color: "secondary",
      showOnRoute: ["/story/*", "/admin/information/*"],
      openByDefault: true,
      closeOtherPanels: true
    },
    {
      panel: "embeds",
      color: "secondary",
      showOnRoute: ["/story/*", "/admin/information/*"],
      openByDefault: false,
      closeOtherPanels: true,
      overflow: true
    },
    {
      panel: "snippets",
      color: "secondary",
      showOnRoute: ["/story/*", "/admin/information/*"],
      openByDefault: false,
      closeOtherPanels: true,
      overflow: true
    },
    {
      panel: "pipelines.templates",
      color: "info",
      showOnRoute: ["/pipeline/*"],
      openByDefault: true
    },
    {
      panel: "widgets",
      color: "Oceanside",
      showOnRoute: ["/"],
      openByDefault: false
    },
    {
      panel: "widgets",
      color: "Oceanside",
      showOnRoute: ["/admin/settings"],
      openByDefault: false
    },
    {
      panel: "info-articles",
      color: "Oceanside",
      showOnRoute: ["/information*", "/admin/information"],
      openByDefault: false
    }
  ],

  // Dashboard widgets
  WIDGETS: {
    colors: [
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "purple darken-2",
      "grey darken-3"
    ]
  },

  // ** For Date/Time we use dfns library, see: https://date-fns.org/v2.12.0/docs/format
  DATE_FORMAT: [
    {
      value: "int",
      text: 'International: "16 may 2020"',
      short: "d MMM yyyy",
      long: "iiii, d MMMM yyyy"
    },
    {
      value: "en",
      text: 'English: "May 16, 2020"',
      short: "MMM d, yyyy",
      long: "iiii, MMMM d, yyyy"
    }
  ],
  TIME_FORMAT: {
    default: "HH:mm",
    am_pm: "KK:mm aaa"
  },

  // note Widget icons in components/_lib/widgets/widget-loader.js
  // $config.SERVICE_ICON.embeds
  SERVICE_ICON: {
    "info-articles": "information-outline",
    "pipelines.templates": "plus-box-multiple-outline", // $config.SERVICE_ICON['pipelines.templates']
    "time-trackers": "timer-outline",
    accounts: "office-building-outline",
    achievers: "seal-variant",
    activities: "bell-outline",
    analytics: "chart-line",
    assets: "image-multiple-outline",
    audits: "gauge",
    calendar: "calendar-month-outline",
    campaigns: "bullseye-arrow",
    checklists: "order-bool-ascending-variant",
    dashboard: "monitor-dashboard",
    database: "database", // ** admin
    embeds: "application-brackets-outline",
    experiences: "card-account-details-star-outline",
    information: "folder-information-outline", // ** admin
    integrations: "puzzle",
    invoices: "cash-multiple",
    pipelines: "electric-switch-closed",
    settings: "cogs", // ** admin
    snippets: "clipboard-text-outline",
    stories: "fountain-pen-tip",
    tasks: "checkbox-multiple-blank-outline",
    users: "account-multiple",
    workflow: "flower"
  },

  // Icons for table filters, see components/stories/table/StoryTable.vue
  SELECT_ICON: {
    all: "check-all",
    achiever_blocked: {
      true: "lock",
      false: "run",
      color: "grey",
      colorFalse: "success"
    },
    archived: {
      true: "archive-arrow-down",
      false: "archive-arrow-up-outline",
      color: "background-4"
    }, // negate color
    bligsy_activated_icon: {
      true: "robot-love",
      false: "robot-off-outline",
      color: "secondary"
    },
    featured: { true: "star", false: "star-outline", color: "secondary" },
    published: { true: "earth", false: "pencil-outline", color: "info" },
    promotable: {
      true: "account-voice",
      false: "account-voice-off",
      color: "accent"
    },
    scheduled: {
      all: "check-all",
      plan: "calendar-clock-outline",
      on: "run",
      fin: "flag-checkered"
    },
    sphere: {
      true: "home-heart",
      false: "account-hard-hat",
      color: "success",
      offColor: "primary"
    }
  },

  // *** SERVICES
  // The _id prefix opf the various item ids, used in error message apiStatusBar
  SERVICES: {
    ITEM_PREFIX: {
      accounts: "A_",
      embeds: "E_",
      campaigns: "C_",
      pipelines: "P_",
      tasks: "T_",
      assets: "M_",
      story: "S_",
      widgets: "W_",
      integrations: "CI_"
    }
  },

  ACCOUNTS: {
    MAX_NR: 1000,
    BLIGSY_ACTIVATED_ICON: {
      true: "robot-love",
      false: "robot-off-outline",
      color: "secondary"
    }
  },

  USERS: {
    MIN_PASSWORD_LENGTH: 6,
    USER_ROLES: {
      PERMISSIONS: {
        super: "super_admin",
        admin: "admin",
        user: "user"
      },
      COLORS: {
        super: "secondary",
        admin: "primary",
        user: "foreground-2"
      }
    },
    AVATAR_PATH: "/avatars",
    EXPERT_LEVELS: {
      none: { icon: "", color: "" },
      master: { icon: "shield-star", color: "secondary" },
      senior: { icon: "shield-star-outline", color: "success" },
      junior: { icon: "shield-star-outline", color: "grey" }
    }
  },

  INVOICES: {
    STATUS: {
      sent: { text: "Invoice has been sent to the customer", color: "blue" },
      paid: { text: "Invoice has been paid by the customer", color: "green" },
      overdue: {
        text:
          "Invoice has past the payment date and the customer hasn’t paid yet",
        color: "red"
      },
      void: {
        text:
          "You will void an invoice if it has been raised incorrectly. Customers cannot pay for a voided invoice.",
        color: "grey"
      },
      write_off: {
        text:
          "You can Write Off an invoice only you’re sure that the amount the customer owes is un-collectible.",
        color: "grey darken-1"
      },
      published: {
        text:
          "You’ve created an incomplete invoice and it hasn’t been sent to the customer.",
        color: "warning"
      }
    }
  },

  CAMPAIGNS: {},

  PIPELINES: {},

  TASKS: {
    STAGE: {
      str: { icon: "strategy", color: "info" },
      con: { icon: "image", color: "info" },
      ver: { icon: "order-bool-ascending-variant", color: "info" },
      pub: { icon: "publish", color: "info" },
      int: { icon: "swap-vertical-circle-outline", color: "info" }
    },
    STATUS: {
      pln: { icon: "calendar-clock", color: "info" }, // planned
      ong: { icon: "run", color: "secondary" }, // ongoing
      fin: { icon: "flag-checkered", color: "background" }, // finished
      arch: { icon: "archive-arrow-down-outline", color: "grey" } // archived:
    },
    AUDIT: {
      grade_levels: {
        grey: 0,
        red: 1,
        orange: 55,
        blue: 70,
        green: 85
      }
    },
    TYPE: {
      animation: { icon: "reload", color: "accent" },
      artwork: { icon: "lead-pencil", color: "secondary" },
      embed: { icon: "video", color: "primary" },
      youtube: { icon: "youtube", color: "primary" },
      image: { icon: "image-outline", color: "info" },
      interaction: { icon: "gesture-tap", color: "info" },
      presentation: { icon: "presentation", color: "info" },
      social: { icon: "account-multiple-outline", color: "info" },
      text: { icon: "clipboard-text-outline", color: "secondary" },
      // indicating a system issue:
      undefined: { icon: "help", color: "grey" }
    }
  },

  STORIES: {
    TYPE: {
      blog: { icon: "post-outline", color: "accent" },
      webinar: { icon: "message-video", color: "primary" },
      presentation: { icon: "presentation", color: "secondary" },
      script: { icon: "script-text-outline", color: "success" },
      other: { icon: "alpha-b-box-outline", color: "info" }
    }
  },

  EMBEDS: {
    TYPE: {
      image: { icon: "image-outline", color: "secondary" },
      youtube: { icon: "youtube", color: "red" },
      vimeo: { icon: "vimeo", color: "blue" },
      code: { icon: "code-braces", color: "primary" }
    }
  },

  ASSETS: {
    THUMB_SIZE: {
      sm: 78,
      md: 106
    },
    MAX_FILE_SIZE: 1024 * 1024 * 20, // MB
    MIME: "image/*",
    // default variants sizes
    DEFAULT_VARIANT: {
      thumb: "th",
      feature: "md",
      maximal: "lg"
    },
    // number of items in the media gallery: repository page and drawer
    ITEMS_PAGE: {
      SM: 12, // use an even number & dividable by 3, as on mobile we have width = 33%
      MD: 16
    }
  },

  INFO_ARTICLES: {
    DEFAULT_SLUG: "welcome"
  },

  ACTIVITIES: {
    urgency: {
      0: { icon: "", color: "" },
      1: { icon: "information-outline", color: "success" },
      2: { icon: "lightbulb-on-outline", color: "secondary" },
      3: { icon: "alert-outline", color: "warning" },
      4: { icon: "alert-octagram", color: "error" }
    }
  },

  CONFIRMATIONS: {
    TIME_OUT_SECONDS: 1800
  },
  EDIT_SETTINGS: {
    // to determine reading time
    charactersPerWord: 5.8,
    wordsPerMinute: 275,
    maxLengthExcerpt: 500,
    maxLengthNote: 2500
  }
};
