<template>
  <time-tracker-panel style="height: 46px;" />
</template>

<script>
import Log from '@/lib/log' // eslint-disable-line no-unused-vars

import TimeTrackerPanel from '@/components/time-trackers/form/TimeTrackerPanel'

export default {
  components: {
    TimeTrackerPanel
  }
}
</script>
