<template>
  <v-btn text color="info" @click="logout()">
    <v-icon small>mdi-logout</v-icon>
    {{ $t('user.logout.label') }}
  </v-btn>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import Log from '@/lib/log'

export default {

  methods: {
    async logout () {
      // Note: state clearance and other maintenance is done by
      //    plugins/feathers/feathers-client.js
      try {
        this.$signal.apiActionBar = { status: 'loading' }
        // await this.$api.authentication.removeAccessToken()
        // Log.i('[<] Logging out...')
        await this.$api.logout()
        this.$signal.apiActionBar = { status: 'success' }
      } catch ({ code, message }) {
        this.$signal.apiActionBar = { status: 'error', code, message }
        Log.e(`[!] Logout failed  ${code} ${message}`)
      }
      this.$router.push('/login')
    }
  }
}
</script>
