<!-- Show preview of embed and Dialog with iFrame

  Note: $attrs such as contain, height and max-width etc. are all Vuetify <v-img> attributes
-->

<template>

  <div class="" @click="showPreview">
    <c-img
      :src="thumbSrc"
      :alt="title"
      :style="thumbStyle"
      :content-class="thumbContentClass"
    />
    <!-- v-bind="$attrs" -->

    <!-- Optional Play button over the thumbnail
    <v-icon
      v-if="!hideDialog"
      class=" grey darken-3 semi-transparent cursor-pointer mt-n12 ml-2"
      color="white"
    >mdi-play</v-icon> -->

    <!-- Embed preview dialog -->
    <v-dialog v-if="dialog" v-model="dialog" width="1000">
      <v-system-bar window lights style="cursor: pointer;" @click="dialog=false;">
        <v-btn icon><v-icon>mdi-arrow-left</v-icon></v-btn>
        <v-spacer />
        <span class="caption">{{ title }}</span>
        <v-spacer />
        <v-btn icon class="mr-n2"><v-icon>mdi-close</v-icon></v-btn>
      </v-system-bar>

      <div class="repo-dialog-embed">
        <iframe :src="src" allowfullscreen />
      </div>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    // the iframe page link
    src: {
      type: String,
      default: ''
    },
    // link to the thumbnail preview image
    thumbSrc: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },

    thumbContentClass: {
      type: String,
      default: ''
    },
    thumbStyle: {
      type: String,
      default: ''
    },
    hideDialog: {
      type: Boolean,
      default: false
    }

  },
  data () {
    return {
      dialog: false
    }
  },
  methods: {
    showPreview (event) {
      if (!this.hideDialog) {
        this.dialog = true
        event.stopPropagation()
      }
    }
  }
}

</script>
