 <!-- Generate show the field for components/_lib/form/table/GenTd.vue

Header parameters:

  - value: the item field
  - text: the header label
  - width: width of column in pixels
  - align: 'center'
  - responsive: if true do not show this column on mobile
  - to: 'url' // make whole field clickable and link to that to:${url}/{item_id}
  - class: margin to add to whole td content
  - cellClass: 'warning--text caption'
  - classFunction
  - format:
      'check'
      'currency'
      'custom': do not apply any format
      'date_time'
      'date'
      'euro_3'
      'euro'
      'info'
      'link'
      'number'
      'percentage'
      'radio'
      'stars'
      'tags'
      'textarea'

 Example:

   headers: [
      { value: 'showInTips', text: 'tip', width: '80', responsive: true, format: 'radio', class: 'ml-2' },
      { value: 'label', text: 'label', width: '140', to: '/admin/information/' },
      { value: 'title', text: 'title', width: '220' },
      { value: 'slug', text: 'slug', width: '140', format: 'info'  },
      { value: 'excerpt', text: 'excerpt', format: 'textarea' },
      { value: 'language', text: 'lang', width: '80' }
      { value: 'tags', text: 'tags', align: 'start', format: 'tags', responsive: true },
      { value: 'nrStars', text: 'rating', format: 'stars' },
      { value: 'updatedAt', text: 'updated', width: '110', responsive: true, format: 'date' }
      { value: 'startAt', text: vm.$i18n.t('stories.field.start_at'), width: '90', format: 'date_time',
        classFunction: i => new Date(i.endAt) < new Date()) ? 'info--text' : 'primary--text'  },
    ]
  -->

<template>
  <div>
    <!-- class function -->

    <!-- date field -->
    <c-date v-if="hdr.format==='date'" :date="item[hdr.value]" date-format="d MMM yyyy" date-only class="two-lines caption" :class="hdrClass" />
    <c-date v-else-if="hdr.format==='date_time'" :date="item[hdr.value]" date-format="d MMM yyyy" class="two-lines caption" :class="hdrClass" />

    <!-- money fields -->
    <cur v-else-if="hdr.format==='currency'" :value="item[hdr.value]" :class="hdrClass" />
    <cur v-else-if="hdr.format==='euro'" :value="item[hdr.value]" currency="EUR" :class="hdrClass" />
    <cur v-else-if="hdr.format==='euro_3'" :value="item[hdr.value]" currency="EUR" :digits="3" :class="hdrClass" />

    <!-- number fields -->
    <span v-else-if="hdr.format==='percentage'" :class="hdrClass">{{ item[hdr.value] }}%</span>
    <span v-else-if="hdr.format==='number'" class="float-right" :class="hdrClass">{{ item[hdr.value] || 0 }}</span>

    <!-- text fields -->
    <span v-else-if="hdr.format==='info'" class="info--text caption" :class="hdrClass">{{ item[hdr.value] }}</span>

    <!-- selection symbols -->
    <v-icon v-else-if="hdr.format==='check'" small :class="hdrClass">
      mdi-{{ item[hdr.value] ? 'checkbox-marked-outline': 'checkbox-blank-outline' }}
    </v-icon>
    <v-icon v-else-if="hdr.format==='radio'" small>mdi-radiobox-{{ item[hdr.value] ? 'marked': 'blank' }}</v-icon>

    <!-- links -->
    <c-link v-else-if="hdr.format==='link'" :to="`${hdr.to}/${item._id}`" :class="hdrClass">
      {{ item[hdr.value] }}
    </c-link>

    <!-- textarea fields: two lines if needed with nice ellipse if even more, class from ~/assets/styles/typography.scss -->
    <div v-else-if="hdr.format==='textarea'" class="two-lines body-2" :class="hdrClass">
      {{ item[hdr.value] }}
    </div>

    <!-- tags listing -->
    <c-tooltip v-else-if="hdr.format==='tags'" :max-width="300" show-mobile>
      <template #act>
        <list-tags :tags="item[hdr.value]" item-class="caption info--text two-lines" />
      </template>
      <list-tags :tags="item[hdr.value]" item-class="caption" chips :class="hdrClass" />
    </c-tooltip>

    <!-- yellowish stars -->
    <v-rating
      v-else-if="hdr.format==='stars'"
      :class="hdrClass"
      class="ml-n1"
      :value="item[hdr.value]"
      readonly
      half-increments
      dense
      background-color="foreground-3"
      color="secondary"
      :length="5"
      :size="16"
    />

    <div v-else-if="hdr.format==='custom'" :class="hdrClass">
      <slot />
    </div>

    <!-- just show value without format -->
    <span v-else :class="hdrClass">{{ item[hdr.value] }}</span>
  </div>

</template>

<script>
import Log from '@/lib/log' // eslint-disable-line no-unused-vars

import ListTags from '@/components/_lib/form/tags/ListTags'

export default {
  components: {
    ListTags
  },
  props: {
    header: {
      type: Object,
      default: () => {}
    },
    hdr: {
      type: Object,
      default: () => ({})
    },
    value: {
      type: String,
      default: ''
    },
    item: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    hdrClass () {
      return this.hdr.classFunction ? this.hdr.classFunction(this.item) : '' + ' ' + (this.hdr.cellClass || '')
    }
  }
}

</script>
