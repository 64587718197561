import locale1f17bd0a from '../../assets/lang/en.coffee'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":"en","silentTranslationWarn":true},
  vueI18nLoader: false,
  locales: [{"name":"English","code":"en","iso":"en-US","file":"en.coffee"},{"name":"Nederlands","code":"nl","iso":"nl-NL","file":"nl.coffee"}],
  defaultLocale: "en",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "/usr/src/app/assets/lang",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":true,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"site_language","cookieSecure":false,"fallbackLocale":"en","onlyOnNoPrefix":false,"onlyOnRoot":false,"useCookie":true},
  differentDomains: false,
  seo: false,
  baseUrl: "",
  vuex: {"moduleName":"__bligman-i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  beforeLanguageSwitch: () => null,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"name":"English","code":"en","iso":"en-US","file":"en.coffee"},{"name":"Nederlands","code":"nl","iso":"nl-NL","file":"nl.coffee"}],
  localeCodes: ["en","nl"],
}

export const localeMessages = {
  'en.coffee': () => Promise.resolve(locale1f17bd0a),
  'nl.coffee': () => import('../../assets/lang/nl.coffee' /* webpackChunkName: "lang-nl.coffee" */),
}
