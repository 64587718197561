<!-- **  View fields of a specific snippet

  <snippet-view :item="item"  />

-->

<template>
  <v-row>
    <c-field cols="12" md="4" :label="$t('snippets.field.title')" wrap>{{ item.title }}</c-field>

    <c-field cols="12" md="4" :label="$t('def.tags')">
      <list-tags v-model="item.tags" chips />
    </c-field>

    <v-col cols="12" md="4" class="d-flex justify-end">
      <c-check-field
        hide-false
        class="mt-4 mr-2"
        :value="item.officiallyEndorsed"
        small
        :label="$t('snippets.field.officially_endorsed')"
      />
    </v-col>

    <c-field cols="12" :label="$t('snippets.field.text_snippet')" wrap>{{ item.textSnippet }}</c-field>
  </v-row>
</template>

<script>

import ListTags from '@/components/_lib/form/tags/ListTags'

export default {
  components: {
    ListTags
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  }
}
</script>
