<template>

  <v-treeview
    ref="tree"
    :items="tocList"
    dense
    item-children="toc"
    item-text="label"
    item-key="slug"
    class="mt-4 info-tree"
  >
    <template v-slot:label="{ item }">
      <!-- index, leaf -->
      <div
        class="tree-label cursor-pointer"
        :class="{'info lighten-4': value === item.slug}"
        @click="select ? $emit('input',item.slug) : goToLink(item)"
      >
        {{ item.paragraphNumber }}  {{ article(item.slug).label }}
      </div>
    </template>
  </v-treeview>

</template>

<script>

// eslint-disable-next-line no-unused-vars
import Log from '@/lib/log'

export default {
  name: 'TestPage',
  props: {
    value: {
      type: String,
      default: ''
    },
    // false: GoToLink, true: return selected
    select: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      tocList: []
    }
  },
  computed: {
    toc () {
      return this.$db.doc('configuration', null, { $clone: true }).tocInfoBase
    },
    infoBase () {
      return this.$db.collection('info-articles', { $clone: true })
    }
  },
  watch: {
    toc: {
      immediate: true,
      deep: true,
      handler () {
        this.changeList()
      }
    }
  },
  mounted () {
    this.changeList()
  },
  methods: {
    changeList () {
      // set new paragraph numbers
      this.tocList = this.toc
      this.numberParagraphs(this.tocList)
      if (this.$refs.tree) {
        this.$refs.tree.updateAll(true)
        this.$refs.tree.updateAll(false)
      }
    },
    article (slug) {
      return this.infoBase.find(i => i.slug === slug) || { label: 'not found' }
    },
    numberParagraphs (list, { itemChildren = 'toc', itemText = 'paragraphNumber' } = {}, prefix = '', depth = 1) {
      list.forEach((item, index) => {
        item[itemText] = prefix + (index + 1).toString()
        if (item[itemChildren] && item[itemChildren].length > 0) {
          this.numberParagraphs(item[itemChildren], { itemChildren, itemText }, item[itemText] + '.', depth + 1)
        }
      })
    },
    goToLink (item) {
      this.$router.push(`/information/${item.slug}`)
    }
  }

}
</script>

<style lang="scss" >

.info-tree {
  .v-treeview-node {
    margin-top: -4px;
    .tree-label {
      font-weight: 600 !important;
      text-transform: uppercase;
      font-size: 0.95rem;
      color: $info !important;
    }

    .v-treeview-node {
      margin-top: -8px;
      .tree-label{
        color: #333 !important;
        text-transform: capitalize;
        font-size: 0.9rem;
      }

      .v-treeview-node {
        margin-top: -16px;
        .tree-label{
          // color: #666 !important;
          font-weight: 400 !important;
          text-transform: capitalize;
          // font-size: 0.7em;
        }
      }
    }
  }
}
</style>
