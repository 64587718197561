<template>
  <activities-list />
</template>

<script>
// eslint-disable-next-line no-unused-vars
import Log from '@/lib/log'

import ActivitiesList from '@/components/activities/form/ActivitiesList'

export default {
  components: {
    ActivitiesList
  }
}
</script>
