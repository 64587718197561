<!-- Show an icon with a badge with a counter

Example:
   <indicator-icon
      icon="email-outline"
      :total="userTotalNr"
      :unread="emailTotal"
      :flash="emailTotal>0"
      @click="showConfirmInfo=true"
    />

Events:
  @click  click the button
  @select  the drawer panel is selected / open

-->

<template>
  <v-btn
    :id="'notify-badge-' + panel"
    icon
    :class="`${flash ? 'flash-me': ''} ${ blink ? 'blink-me': ''} mr-${iconBadgeContent.length}`"
    @click="togglePanel(); $emit('click')"
  >
    <v-badge
      v-if="total || unread"
      :color="unread >0 ? (dark ? 'info': 'primary') :'grey  darken-1'"
      :content="iconBadgeContent"
      overlap
    >
      <v-icon :class="{ 'white--text': dark }" :color="iconColor">mdi-{{ icon }}</v-icon>
    </v-badge>

    <v-icon v-else :class="{ 'white--text': dark }" :color="iconColor">mdi-{{ icon }}</v-icon>
  </v-btn>
</template>

<script>

// eslint-disable-next-line no-unused-vars
import Log from '@/lib/log'

export default {

  props: {
    dark: { type: Boolean, default: false },
    panel: {
      type: String,
      default: ''
    },
    icon: { type: String, default: 'bell' },
    iconColor: { type: String, default: '' },
    total: { type: Number, default: 0 },
    unread: { type: Number, default: 0 },
    flash: {
      type: Boolean,
      default: false
    },
    blink: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    iconBadgeContent () {
      return (this.unread && this.total) ? `${this.total}/${this.unread}` : (this.unread || this.total)
    }
  },
  methods: {
    togglePanel () {
      if (this.panel) {
        this.$signal.toggleDrawerPanel(this.panel)
        this.$app_rightDrawer = true
        if (this.$signal.isOpenDrawerPanel(this.panel)) {
          this.$emit('select')
        }
      }
    }
  }
}

</script>
