<template>
  <div>
    <general-table
      v-model="selectedIds"
      service="snippets"
      dialog-bar-color="assets"
      :headers="headers"
      :small="small"
      :filter-item-id="filterItemId"
      show-tag-select
      show-expand
      :items-per-page="15"

      :show-gallery="showGallery"
      :show-gallery-toggle="showGalleryToggle"
      :hide-gallery-size-toggle="hideGallerySizeToggle"
      :show-new-on-empty="showNewOnEmpty"
      :selectable="selectable"
      :select-multiple="selectMultiple"

      @itemCreated="refreshItem"
      @itemChanged="refreshItem"
      @select="$emit('select', $event)"
    >
      <template v-slot:left-toolbar />

      <template v-slot:right-toolbar />

      <template v-slot:item-table-row="{ item }">
        <gen-td :headers="headers" :item="item" value="officiallyEndorsed">
          <v-icon
            :size="18"
            class="mx-n1"
            :color="item.officiallyEndorsed ? 'info' : 'grey'"
          >
            mdi-police-badge{{ item.officiallyEndorsed ? '' : '-outline' }}
          </v-icon>
        </gen-td>
        <gen-td :headers="headers" :item="item" value="title" />

        <gen-td :headers="headers" :item="item" value="tags" />

        <gen-td :headers="headers" :item="item" value="textSnippet" :small="small" />
      </template>

      <template v-slot:item-expand-pane="{ item }"> <!-- headers, -->
        <snippet-view :item="item" />
      </template>

      <template v-slot:item-edit-form="{ item }">
        <snippet-edit :item="item" />
      </template>

      <template v-slot:gallery-card="{ item, gallerySmall }">
        <v-card-text class="px-2 py-1 background" style="height: 100%;">
          <c-field wrap :class="{'caption':gallerySmall}">{{ item.title }}</c-field>
        </v-card-text>
      </template>
    </general-table>

  </div>
</template>

<script>

import GeneralTable from '@/components/_lib/form/table/GeneralTable'
import GenTd from '@/components/_lib/form/table/GenTd'

import SnippetView from '@/components/snippets/table/SnippetView'
import SnippetEdit from '@/components/snippets/table/SnippetEdit'

export default {
  components: {
    GeneralTable,
    GenTd,
    SnippetView,
    SnippetEdit
  },
  props: {
    // selected Id(s)
    value: {
      type: [String, Array],
      default: () => []
    },
    selectable: {
      type: Boolean,
      default: false
    },
    selectMultiple: {
      type: Boolean,
      default: false
    },
    // do not allow user to select table view
    showGallery: {
      type: Boolean,
      default: false
    },
    showGalleryToggle: {
      type: Boolean,
      default: false
    },
    showNewOnEmpty: {
      type: Boolean,
      default: false
    },
    // use prop 'small' to mount size at first in small
    hideGallerySizeToggle: {
      type: Boolean,
      default: false
    },
    query: {
      type: Object,
      default: () => {}
    },
    filterItemId: {
      type: String,
      default: ''
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  data: vm => ({
    headers: [
      { value: 'officiallyEndorsed', text: '+', width: '25', format: 'custom' },
      { value: 'title', text: 'title', width: '150', format: 'textarea' },
      { value: 'tags', text: vm.$i18n.t('def.tags'), format: 'tags' },
      { value: 'textSnippet', text: vm.$i18n.t('snippets.label'), format: 'textarea', responsive: true }
    ]
  }),
  computed: {
    selectedIds: {
      get () { return this.value },
      set (value) { this.$emit('input', value) }
    }
  },
  methods: {
    async refreshItem (item) {
      await this.$db.get('snippets', item._id)
    }
  }
}
</script>
