/* Tasks */

// import Config from '@/config/constants'

import { initialStateProperties } from '~/plugins/vuex-injects/vuex'

export default initialStateProperties({
  service: 'activities',
  channelSubscription: true, // see plugins/feathers/feathers-client.js

  defaultQuery: { accountId: true, $limit: 40, $sort: { createdAt: -1 } }, //  seenByUserIds: 1,

  loadCollectionOnStart: false,

  initData: {
    userId: '', // : { type: String, ref: 'users', required: true },
    accountId: '', // : { type: String, ref: 'accounts' },
    service: '', // : { type: String, enum: activityServices },
    itemId: '', // : { type: String, ref: activityServices.join(',') },
    method: '', // : { type: String, enum: ['comment', 'get', 'find', 'create', 'update', 'patch', 'remove'] },
    fields: [], // : [{ type: String, maxlength: 30 }],
    actions: [], // : [{ type: String, enum: ['renamed', 'created', ...] }],
    seenByUserIds: [], // : [{ type: String, ref: 'users' }], // the users that viewed this activity
    urgency: 0, // { type: Number, default:0, min: 0, max: 4 }, // alert level 0=trivial, 1=info, 2=warn, 3=alert, 4=escalation
    message: '' // : { type: String, maxlength: 1000 } // for activities users created as a message
  },

  populatedData: {
    user: {} // { _id, displayName }
  },

  afterCommitHook: (state, item) => {
  }
})
