<!-- Load a time tracker panel. PRessing start will set itemId, service etc.

<time-tracker-panel service="campaigns" item-id="hallo" />
 -->

<template>
  <div class="d-flex align-center">
    <!-- *** Display the timer buttons + expanding info if it is running-->
    <time-tracker-buttons
      :item="$signal.timeTrackerData"
      @start="startTimer"
      @stop="stopTimer"
    />

    <v-expand-x-transition>
      <time-tracker-info
        v-if="$signal.timeTrackerRunning"
        class="ml-2"
        :item="$signal.timeTrackerData"
      />
    </v-expand-x-transition>

    <!-- *** The stop dialog -->
    <v-dialog v-model="dialog" :width="800">
      <v-card>
        <v-system-bar window lights color="grey" class="white--text pr-0" style="cursor: pointer;" @click.stop="dialog=false;">
          <span class="body-2 font-weight-medium">{{ $t('time-trackers.label') }}</span>
          <v-spacer />
          <v-btn icon><v-icon color="white">mdi-close</v-icon></v-btn>
        </v-system-bar>

        <!-- ** The edit form -->
        <v-card-text class="mt-6 pb-8">
          <edit-timer v-model="$signal.timeTrackerData" @valid="valid=$event" />
        </v-card-text>
        <v-divider />

        <!-- Action buttons -->
        <v-card-actions>
          <v-btn text color="warning" @click="resetTimer()">{{ $t('def.reset') }}</v-btn>
          <v-spacer />
          <!-- <c-link to="/accounts/time-trackers">
            {{ $t('time-trackers.table') }}
          </c-link>
          <v-spacer /> -->
          <template v-if="service && itemId">
            <c-link :to="`/${service}/${itemId}`">
              {{ $t('time-trackers.visit_service') }}
            </c-link>
            <v-spacer />
          </template>
          <v-btn text color="info" @click="dialog = false">{{ $t('def.resume') }}</v-btn>
          <v-btn text color="success" :disabled="!valid" @click="saveTimeTracker()">{{ $t('def.save') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Log from '@/lib/log' // eslint-disable-line no-unused-vars

import TimeTrackerButtons from '@/components/time-trackers/form/TimeTrackerButtons'
import TimeTrackerInfo from '@/components/time-trackers/form/TimeTrackerInfo'
import EditTimer from '@/components/time-trackers/form/EditTimer'

function secondsPassed (ds, de) {
  if (!ds || ds === null || !de || de === null) { return 0 }
  const passSeconds = (de.getTime() - ds.getTime()) / 1000
  return passSeconds
}
export default {
  components: {
    EditTimer,
    TimeTrackerButtons,
    TimeTrackerInfo
  },
  props: {
    accountId: {
      type: String,
      default: ''
    },
    service: {
      type: String,
      default: ''
    },
    itemId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      dialog: false,
      valid: false,
      endAt: '',
      // timer: {}, // object to store in the backend time-trackers service
      runTimer: null, // the interval timer, updating time active
      pauseTimer: null // count down seconds, in $signal.timeTrackerPauseTime
    }
  },
  computed: {
    runTime: {
      get () { return this.$signal.timeTrackerData.runTime },
      set (value) { this.$signal.timeTrackerData = Object.assign({}, this.$signal.timeTrackerData, { runTime: value }) }
    }
  },
  watch: {
    '$signal.timeTrackerRunning' (value) {
      if (value) {
        // Log.i('[timer-tracker-panel] RUNNING', this.service)
        this.runTimer = setInterval(() => {
          // Log.i('run time', this.runTime)
          this.runTime = secondsPassed(this.$signal.timeTrackerStartAt, new Date()) - this.$signal.timeTrackerPauseTime
        }, 1000)
      } else {
        this.clearTimerIntervals()
      }
    },
    dialog () {
      if (this.dialog) {
        this.pauseTimer = setInterval(() => {
          this.$signal.timeTrackerPauseTime += 1
        }, 1000)
      } else {
        clearInterval(this.pauseTimer)
      }
    }
  },
  // mounted () {
  //   Log.w('[time-tracker-panel] $signal.timeTrackerStartAt =', this.$signal.timeTrackerStartAt)
  //   Log.w('[time-tracker-panel] $signal.timeTrackerRunning =', this.$signal.timeTrackerRunning)
  // },
  beforeDestroy () {
    this.clearTimerIntervals()
  },
  methods: {
    startTimer () {
      if (!this.$signal.timeTrackerRunning) {
        this.resetTimer()
      }
      this.$signal.timeTrackerStartAt = new Date()
      this.$signal.timeTrackerData = JSON.parse(JSON.stringify(this.$signal.timeTrackerData))
    },
    stopTimer () {
      this.$signal.timeTrackerData = Object.assign({}, this.$signal.timeTrackerData, {
        startAt: this.$signal.timeTrackerStartAt.toISOString(),
        endAt: (new Date()).toISOString()
      })
      this.dialog = true
    },
    resetTimer () {
      this.dialog = false
      this.$signal.timeTrackerStartAt = null
      this.$signal.timeTrackerPauseTime = 0
      this.$signal.timeTrackerData = {
        userId: this.$db.currentUserId,
        accountId: this.accountId || this.$db.currentAccountId,
        service: this.service,
        itemId: this.itemId,
        runTime: 0
      }
      this.runTime = 0
      this.clearTimerIntervals()
    },

    async saveTimeTracker () {
      this.dialog = false
      await this.$db.create('time-trackers', this.$signal.timeTrackerData)
      this.resetTimer()
    },
    clearTimerIntervals () {
      Log.i('[timer-tracker-panel] clear intervals', this.service)
      clearInterval(this.runTimer)
      clearInterval(this.pauseTimer)
    }
  }
}
</script>
