<!-- Quick select of a color from a few buttons

<c-color-select v-model="myColor" small default-color="grey" :colors="['grey', 'primary]" />

<c-color-select v-model="newColor" small :color-append="$app_darkMode ? 'darken-3': 'lighten-3'" />

Note:
- append is not shown on default color
- if user selects defaultColor, return empty value

 -->
<template>
  <div>
    <v-btn
      v-for="color in colorList"
      :key="color"
      fab
      class="mr-1"
      :height="size"
      :width="size"
      :color="`${color} ${color!== defaultColor ? colorAppend : ''}`"
      :elevation="6"
      @click="$emit('input', color !== defaultColor ? color: ''); $emit('change', color)"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: 'grey'
    },
    defaultColor: {
      type: String,
      default: ''
    },
    colors: {
      type: Array,
      default: () => [] // default set from this.$config.WIDGETS.colors
    },
    small: {
      type: Boolean,
      default: false
    },
    xSmall: {
      type: Boolean,
      default: false
    },
    iconClass: {
      type: String,
      default: 'mr-1'
    },
    // append to the color
    //   example :color-append="$app_darkMode ? 'darken-3': 'lighten-3'"
    colorAppend: {
      type: String,
      default: ''
    }

  },
  computed: {
    colorList () {
      const colorSet = this.colors.length > 0 ? this.colors : this.$config.WIDGETS.colors
      return this.defaultColor ? [this.defaultColor, ...colorSet] : colorSet
    },
    size () {
      return this.small ? 16 : (this.xSmall ? 12 : 30)
    }
  }
}
</script>
