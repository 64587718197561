/* Tasks */

// import Config from '@/config/constants'

import { initialStateProperties } from '~/plugins/vuex-injects/vuex'

export default initialStateProperties({
  service: 'widgets',

  channelSubscription: false, // see plugins/feathers/feathers-client.js

  defaultQuery: { $sort: { location: 1, index: 1 } },

  loadCollectionOnStart: false,

  initData: {
    userId: '', // { type: String, ref: 'users', required: true },
    type: '', // { type: String, required: true, maxlength: 30 }, // 'onboarding', 'campaigns_all' ,...

    // where to show
    location: 'L', // { type: String, enum: ['L', 'M', 'R'], default: 'L'  }, // column on dashboard used to be 'dbL',...
    index: 0, // { type: Number },

    // Parameters how to show
    barColor: '', // color, // { ...color, default: '#50598e' },
    visibility: 'L', // { type: String, enum: ['S', 'M', 'L'], default: 'L' }, // S,M,L = closed, compact, open
    limit: 5, // { type: Number }, // nr records to show (not for all widgets)

    // additional settings
    settings: {} // { type: Object, default: () => ({})}
  },

  populatedData: {
  },

  afterCommitHook: (state, item) => {
  }
})
