<!-- list stories

Events:
  @deleteIndex  user clicks on an index to delete; if not hide-delete prop

-->

<template>
  <div :style="` max-height: ${maxHeight}px; overflow-y: auto; overflow-x: hidden;`">
    <div v-for="(item, idx) in items" :key="idx" class="d-flex align-center">
      <v-icon small class="mr-2" :color="item.published ? 'grey': 'success'">
        mdi-{{ $config.SELECT_ICON.published[item.published] }}
      </v-icon>

      <div class="two-lines">
        <c-link :to="`/story/${item._id}?accountId=${item.accountId}`" class="caption">
          {{ item.title }}
        </c-link>
      </div>

      <v-btn v-if="!hideDelete" small icon @click="$emit('deleteIndex',idx)">
        <v-icon small color="primary">mdi-close</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
// import Log from '@/lib/log'

export default {

  props: {
    itemIds: {
      type: Array,
      default: () => ([])
    },
    maxHeight: {
      type: Number,
      default: 220
    },
    hideDelete: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      storyIdToAdd: ''
    }
  },
  computed: {
    items () {
      return this.$db.collection('stories')
        .filter(item => this.itemIds.includes(item._id))
        .sort((a, b) => {
          const fa = a.title.toLowerCase()
          const fb = b.title.toLowerCase()
          if (fa < fb) { return -1 }
          if (fa > fb) { return 1 }
          return 0
        })
    }
  }
}
</script>
