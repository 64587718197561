/* Assets */

import { initialStateProperties } from '@/plugins/vuex-injects/vuex'

// import Config from '@/config/constants' // NOTE >> ******* : .env parameters are not valid here yet !!!!

import Log from '@/lib/log' // eslint-disable-line no-unused-vars

export default initialStateProperties({
  service: 'embeds',
  channelSubscription: true, // see plugins/feathers/feathers-client.js

  defaultQuery: { accountId: true },

  initData: {
    // general embed information
    accountId: '', //  { type: String, ref: 'accounts', required: true, maxlength: 20, index: 1 },
    type: '', // { type: String, enum: ['image', 'youtube', 'vimeo', 'code'] },
    title: '', // { type: String, maxlength: 100  },
    description: '', // { type: String, maxlength: 1000  },
    tags: [], // [{ type: String, maxlength: 50, index: 1 }],
    orgSrc: '', // url, // original url if src was parsed, such as in YouTube
    parameters: '' // { type: Object, default: () => ({}) } // optional external server parameters
  },

  populatedData: {
    // by service afterHook
    src: '', // url, // full url , example after YouTube parse
    thumbSrc: '' // url, // if user leaves this field empty, thumbnail url can also be parsed, for example with YouTube

    // @TODO
    //   tasks: [{}]
  },

  // collectionFields: [...Object.keys(initData),...], // all

  afterCommitHook: (state, item) => {

  }
})
