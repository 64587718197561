<!-- Show one of more Embed images based on their id

Attributes passed to the image: <c-img ../>  => <v-img ../>

Events:
  @select  clicked on the image

Examples:
  <list-embeds :item-id="item._id" width="80" />

-->

<template>
  <div>
    <!-- show one  -->
    <embed-link-preview
      v-if="itemId"
      thumb-style="padding: 1px;"
      :thumb-src="item(itemId).thumbSrc"
      :src="item(itemId).src"
      :width="width"
      v-bind="$attrs"
      @click="$emit('select',itemId)"
    />

    <!-- show a gallery -->
    <div
      v-else-if="itemIds"
      class="flex-grid"
      :style="`--card_width: ${width-2}px; --gap: 2px;`"
    >

      <embed-link-preview
        v-for="(iId,idx) in itemIds"
        :key="idx+'_'+iId"
        :src="item(iId).src"
        :thumb-src="item(iId).thumbSrc"
        thumb-style="padding: 1px;"
        contain
        :width="width"
        v-bind="$attrs"
        @click="$emit('select',iId)"
      />
    </div>
  </div>
</template>

<script>
import Log from '@/lib/log' // eslint-disable-line no-unused-vars

import EmbedLinkPreview from '@/components/embeds/form/EmbedLinkPreview'

export default {
  components: {
    EmbedLinkPreview
  },
  props: {
    itemId: {
      type: String,
      default: ''
    },
    itemIds: {
      type: Array,
      default: () => []
    },
    hideDialog: {
      type: Boolean,
      default: false
    },
    // the size of the image
    width: {
      type: Number,
      default: 80
    }
    // Note: Use v-attrs for other image,: max-width, max-height and so on

  },

  methods: {
    item (itemId) {
      return this.$db.doc('embeds', itemId)
    }
  }

}
</script>
