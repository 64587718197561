<!-- ** Show a text field:

    label: above the text
    wrap: truncated or wrapped
    not-set: display "not set" message if slot is *completely* empty
    error: display field in RED
    slot-class: class around the slot

    allow direct input of cols/md/sm

 Example:

   <c-field cols="6" md="8" sm=-"12" label="nice field" not-set wrap>
      <span v-if="fieldText">{{ fieldText}}</span>
   </c-field>

   <c-field label="Key for REST integration" wrap slot-class="d-flex align-end">
     ...
   </c-field>

  <c-field cols="12" md="4" class="offset-md-1" :label="$t('campaigns.field.description')" wrap>{{ item.description }}</c-field>

  -->
<template>
  <div :class="(cols ? `col col-${cols}`: '') + (md ? ` col-md-${md}`: '') + (sm ? ` col-sm-${sm}`: '')">
    <label
      v-if="label"
      class="caption text-truncate info--text"
      :class="labelClass"
    >
      <!-- :class=" { 'secondary--text': $vuetify.theme.dark, 'info--text': !$vuetify.theme.dark, [labelClass]: labelClass }" -->
      {{ label.toLowerCase() }}&nbsp;
      <a v-if="more && !showMore &&!slotEmpty()" href="#" class="primary--text" @click.stop.prevent="showMore=true">{{ $t('def.more') }}…</a>
    </label>

    <div
      v-if="!more || showMore"
      :class="{'text-truncate': !wrap, 'red--text': error, 'body-2 mr-n1 mt-n1': dense, '': !dense, [slotClass]: slotClass}"
      style="word-wrap: anywhere; line-height: 1.2em;"
    >
      <!-- note: word-wrap anywhere for very long pieces of text that have no spaces -->
      <slot />
      <span v-if="slotEmpty() && notSet" class="info--text overline">{{ $t('def.not_set') }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    cols: {
      type: String,
      default: ''
    },
    md: {
      type: String,
      default: ''
    },
    sm: {
      type: String,
      default: ''
    },
    labelClass: {
      type: String,
      default: ''
    },
    slotClass: {
      type: String,
      default: ''
    },

    dense: {
      type: Boolean,
      default: false
    },
    wrap: {
      type: Boolean,
      default: false
    },
    // display 'show…' link to show
    more: {
      type: Boolean,
      default: false
    },
    // show "not set" if no value
    notSet: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showMore: false
    }
  },
  methods: {
    slotEmpty () {
      const noSlot = this.$slots && this.$slots.default && !this.$slots.default[0].text && !this.$slots.default[0].tag
      return noSlot === undefined
    }
  }
}
</script>
