<!--
  Display name of a country

  Model is a 2-character country code

  <list-country code="NL" />

-->

<template>
  <span>{{ country }}</span>
</template>
<script>

import Countries from '@/config/countries.json'

export default {
  props: {
    // country code
    code: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      countries: Countries
    }
  },
  computed: {
    country () {
      if (!this.code) { return '' }
      const item = this.countries.find(item => item.code === this.code)
      return item ? item.name : '??'
    }
  }
}
</script>
