<!-- ** Show button that when clicked showing a standard dialog

 Note:
 - The @click allows another action to be performed in the parent
   It will fire when user clicks on button that will also open dialog
 - You can force dialog to close with closeDialog property

 Example:
  <c-dialog-btn
    icon-super-script
    button-class="px-20"
    icon="help"
    icon-color="white"
    wide
    :title="myTitle"
    title-color="info"
    title-text-color="white"
    @click="createNewItem()"
    @close="dialogClosed()"
  >
    <template #left-title-bar>
      <span>
        Some text instead of :title="myTitle"
      </span>
    </template>

    Hello dialog

    <template #left-actions>
      <c-nav-btn color="info" text :to="`/information/${slug}`">
        {{ $t('def.read') }}
      </c-nav-btn>
    </template>
  </c-dialog-btn>
-->

<template>
  <v-dialog v-model="dialog" :width="wide ? 1200: 800" scrollable @click:outside="closeDialog()">
    <template v-slot:activator="{ on }">
      <v-btn
        :style="buttonStyle"
        :color="iconColor"
        :icon="!buttonLabel "
        :text="!!buttonLabel "
        :small="iconSmall"
        :outlined="buttonOutlined"
        :class="buttonClass + (iconSuperScript ? ' icon-super-script':' ')"
        @click="$emit('click')"
        v-on="on"
      >
        <v-icon :color="iconColor" :small="iconSmall">mdi-{{ icon }}</v-icon>
        <span v-if="buttonLabel" class="body-2 ml-2">{{ buttonLabel }}</span>
      </v-btn>
    </template>

    <v-card elevation="4">
      <v-system-bar class="body-1 flex-row-reverse pl-4" :class="`${titleColor} ${titleTextColor}--text`" primary-title height="36">
        <v-btn icon class="mr-n2" @click="closeDialog()"><v-icon :color="titleTextColor">mdi-{{ titleCloseIcon }}</v-icon></v-btn>
        <v-spacer />
        {{ title }}
        <slot name="left-title-bar" />
      </v-system-bar>

      <v-card-text class="pt-2">
        <slot />
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <slot name="left-actions" />

        <v-spacer />

        <slot name="right-actions" />

        <v-btn color="info" text @click="closeDialog()">{{ closeButtonTxt }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {

  props: {

    // ** The dialog toggle: button
    buttonLabel: {
      type: String,
      default: ''
    },
    buttonOutlined: {
      type: Boolean,
      default: false
    },
    buttonClass: {
      type: String,
      default: ''
    },
    buttonStyle: {
      type: String,
      default: ''
    },

    // ** The dialog toggle: icon
    icon: {
      type: String,
      default: 'help' // information-variant or information-outline
    },
    iconSuperScript: {
      type: Boolean,
      default: false
    },
    iconColor: {
      type: String,
      default: 'info'
    },
    iconSmall: {
      type: Boolean,
      default: false
    },

    // ** Dialog title bar
    titleColor: {
      type: String,
      default: 'info'
    },
    titleTextColor: {
      type: String,
      default: 'white'
    },
    title: {
      type: String,
      default: ''
    },
    titleCloseIcon: {
      type: String,
      default: 'close'
    },

    // ** Dialog pane total width
    wide: {
      type: Boolean,
      default: false
    },
    // the button text to show in the lower right
    closeLabel: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      dialog: false,
      closeButtonTxt: this.closeLabel || this.$i18n.t('def.close')
    }
  },
  methods: {
    closeDialog () {
      this.dialog = false
      this.$emit('close')
    }
  }
}
</script>
