 <!-- Edit the fields of a specific pipeline -->

<template>
  <v-row>
    <!-- ** Top Row -->
    <v-col cols="12" md="4">
      <v-text-field
        v-model="item.name"
        :label="$t('pipelines.field.name')"
        hide-details="auto"
        outlined
        dense
        :counter="100"
        :rules="[$validRule.required, $validRule.minLength(5).val, $validRule.maxLength(100).val ]"
        @input="item.name = item.name.trim()"
      />
    </v-col>

    <v-col cols="8" md="3">
      <select-campaign
        v-model="item.campaignId"
        :label="$t('pipelines.label')"
        auto-select
        outlined
        dense
        :rules="[$validRule.required]"
        @change="loadCampaign()"
      />
    </v-col>

    <v-col cols="10" md="5">
      <div class="d-flex align-top mt-n2 mt-md-n5">
        <c-field :label="$t('pipelines.field.start_at')">
          <c-date-picker
            v-model="item.startAt"
            outlined
            time-picker
            :error="invalidDateRange"
          />
        </c-field>
        <v-spacer />
        <c-field :label="$t('pipelines.field.end_at')">
          <c-date-picker
            v-model="item.endAt"
            time-picker
            outlined
            :error="invalidDateRange"
          />
        </c-field>
      </div>
      <v-sheet v-if="invalidDateRange" color="background-1" class="mt-n2 caption error--text text-center">
        {{ $t('validation_rules.range_invalid') }}
      </v-sheet>
    </v-col>

    <!-- ** Middle area -->
    <v-col cols="12" md="7" class="mb-n4">
      <v-textarea
        v-model="item.description"
        outlined
        :label="$t('pipelines.field.description')"
        auto-grow
        :rows="7"
        :counter="1000"
        :rules="[$validRule.maxLength(1000).val ]"
      />
    </v-col>

    <v-col cols="12" md="5">
      <select-tags
        v-model="item.tags"
        :label="$t('def.tags')"
        dense
        outlined
        class="mt-n6"
      />

      <v-row no-gutters>
        <v-col cols="4">
          <v-checkbox v-model="item.archived" dense :label="$t('def.archive')" hide-details />

          <v-checkbox v-model="item.protected" dense :label="`${$t('def.delete')} ${$t('def.protection')}`" hide-details />
        </v-col>

        <v-col cols="4">
          <v-checkbox v-model="item.template" dense :label="$t('pipelines.field.template')" hide-details />

          <v-checkbox v-model="item.public" :disabled="!item.template" dense color="accent" :label="$t('pipelines.field.public')" hide-details />
        </v-col>

        <v-col cols="4">
          <select-users
            v-if="item.template"
            v-model="item.templateOwnerId"
            outlined
            dense
            icon
            :label="$t('pipelines.field.owner')"
          />
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="6" md="4">
      <c-field :label="$t('pipelines.field.industries.label')" wrap>
        <c-select
          v-model="item.workflowIndustries"
          :title="$t('pipelines.field.industries.label')"
          :placeholder="$t('pipelines.field.industries.select')"
          :items="$db.doc('configuration', null, { $clone: true }).workflowCodes.industries"
          sort
          clearable
        />
      </c-field>
    </v-col>

    <c-field cols="6" md="4" :label="$t('pipelines.field.tactics.label')" wrap>
      <c-select
        v-model="item.workflowTactics"
        :title="$t('pipelines.field.tactics.label')"
        :placeholder="$t('pipelines.field.tactics.select')"
        :items="$db.doc('configuration', null, { $clone: true }).workflowCodes.tactics"
        sort
        clearable
      />
    </c-field>

    <c-field cols="12" md="4" :label="$t('assets.labels')">
      <v-card class="pa-2" min-height="100" outlined>
        <select-assets
          v-model="item.assetIds"
          list-assets
          multiple
          :label="`${$t('def.select')} ${$t('assets.labels')}`"
          style="max-height: 274px; overflow-y: auto;"
        />
      </v-card>
    </c-field>
  </v-row>
</template>

<script>
import Log from '@/lib/log' // eslint-disable-line no-unused-vars

import SelectCampaign from '@/components/campaigns/form/SelectCampaign'
import SelectUsers from '@/components/users/form/SelectUsers'
import SelectTags from '@/components/_lib/form/tags/SelectTags'
import SelectAssets from '@/components/assets/form/SelectAssets'

export default {
  components: {
    SelectCampaign,
    SelectUsers,
    SelectTags,
    SelectAssets
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    invalidDateRange () {
      return (new Date(this.item.endAt)) < (new Date(this.item.startAt))
    }
  },
  watch: {
    invalidDateRange () {
      if (this.invalidDateRange) {
        Log.w('not valid form', this.$refs.form)
      }
    },
    // pre-fill template owner  if empty
    'item.template' () {
      if (this.item.template && !this.item.templateOwnerId) {
        this.item.templateOwnerId = this.$db.currentUserId
      }
    }
  },
  async mounted () {
    await this.loadCampaign()
  },
  methods: {
    trimSpaces (name) {
      Log.i('trim')
      return name.trim()
    },
    // if not already set, we like to take the start and endAt from the campaign
    async loadCampaign () {
      if (this.item.campaignId && !this.item.startAt && !this.item.endAt) {
        const campaign = await this.$db.get('campaigns', this.item.campaignId, { $noCommit: true })
        this.item.startAt = campaign.startAt || new Date().toISOString
        this.item.endAt = campaign.endAt || new Date().toISOString
      }
    }
  }
}
</script>
