<template>
  <div style="min-height: 200px;" class="px-2 pb-1">
    <v-switch
      v-model="publicTemplate"
      color="primary"
      dense
      hide-details
      :label="$t('pipelines.field.public') + ' ' + $t('pipelines.templates.label')"
    />

    <pipeline-table
      service="pipeline-templates"
      dialog-bar-color="accent"
      hide-left-toolbar
      hide-download
      hide-new
      hide-edit
      hide-search
      hide-gallery-size-toggle
      show-gallery
      show-gallery-dialog
      dialog-wide
      :query="query"
    >
      <template v-slot:gallery-card-popup="{ item }">
        <pipeline-template-view :item="item" />
      </template>

      <template v-slot:gallery-card-popup-actions="{ item }">
        <c-tooltip>
          <template #act>
            <v-btn
              class="ml-4"
              label="import"
              :small="$app_isMobile"
              color="primary"
              text
              @click="$signal.pipelineTemplateId = item._id"
            >{{ $t('pipelines.templates.import') }}</v-btn>
          </template>
          {{ $t('pipelines.templates.description') }}
        </c-tooltip>
      </template>
    </pipeline-table>
  </div>
</template>

<script>
import Log from '@/lib/log' // eslint-disable-line no-unused-vars

import PipelineTable from '@/components/pipelines/table/PipelineTable'
import PipelineTemplateView from '@/components/pipelines/form/PipelineTemplateView'

export default {
  components: {
    PipelineTable,
    PipelineTemplateView
  },
  data () {
    return {
      publicTemplate: true
    }
  },
  computed: {
    query () {
      const publicQ = this.publicTemplate ? { public: true } : {}
      return Object.assign({}, publicQ)
    }
  },
  mounted () {
    this.$signal.closeDrawerPanel('activities')
  }
}
</script>
