<!-- Show a draggable and nested tree

  <c-nested-draggable :items="list" item-key="items" label-key="name" />

  // ...
  data () {
    return {
      list: [
        { name: 'task 1',
          items: [
            { name: 'task 2', items: [] }
          ]
        },
        { name: 'task 3',
          items: [
            { name: 'task 4' items: [] }
          ]
        },
        { name: 'task 5', items: [] }
      ]
    }
  }
-->

<template>

  <draggable
    :list="items"
    tag="div"
    :group="{ name: 'g1' }"
    class="nd-item-container grey lighten-2 ml-7 mb-1"
    ghost-class="nd-ghost"
    chosen-class="nd-chosen"
    handle=".nd-drag-handle"
  >
    <div
      v-for="(el,index) in items"
      :key="index"
      class="nd-item-group grey lighten-4 pb-1 mb-3"
    >
      <div class="d-flex nd-item">
        <v-icon class="nd-drag-handle mr-1" color="grey">mdi-drag-horizontal-variant</v-icon>
        <div>
          {{ el[labelKey] }}
        </div>
      </div>
      <nested-draggable :items="el[itemKey]" :item-key="itemKey" :label-key="labelKey" />
    </div>
  </draggable>

</template>
<script>

export default {
  name: 'NestedDraggable',
  props: {
    items: {
      required: true,
      type: Array,
      default: () => []
    },

    itemKey: {
      type: String,
      default: 'items'
    },
    labelKey: {
      type: String,
      default: 'name'
    }
  },
  computed: {
    // items: {
    //   get () { return this.value },
    //   set (value) { this.$emit('input', value) }
    // },
    // this.value when input = v-model
    // this.list  when input != v-model
    // realValue () {
    //   return this.value ? this.value : this.items
    // }
  },
  methods: {
    // emitter (value) {
    //   this.$emit('input', value)
    // }
    // updateBranch (value) {
    //   this.$emit('input', value)
    // }
  }
}
</script>

<style lang="scss" scoped>

.nd-item-container {
  // border: 1px solid #333;
  overflow-x: visible;
  min-height: 14px;
  width: 100px;
}
// .nd-item-group {
// }
.nd-item {
  width: 200px;
}
.nd-drag-handle {
  cursor: pointer;
}
.nd-chosen {
  color: $primary;
}
.nd-item-sub {
  margin: 0 0 0 1rem;
}

</style>
