<template>
  <indicator-icon
    v-if="timerRunning &&!$app_rightDrawer"
    :icon="$config.SERVICE_ICON['time-trackers']"
    blink
  />
</template>

<script>
import IndicatorIcon from '@/components/_default/topbar/IndicatorIcon.vue'

export default {
  name: 'IndicatorIconInformation',
  components: {
    IndicatorIcon
  },
  computed: {

    timerRunning () {
      return this.$signal.timeTrackerStartAt !== null
    }
  }
}
</script>
