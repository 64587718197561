// ** DELETE - STATE CRUD DATABASE ACTIONS
//
// Delete / remove one ore more items from the database and __collection
// Reset current data in store to init data if item deleted._id === current _id
//
//  await store.dispatch(`${service}/remove`, { _id, params })
//

import Log from '@/lib/log'

export default {
  async remove ({ commit, getters, dispatch, rootGetters }, { id, params = {} }) {
    const service = getters.getService
    if (id === undefined) {
      throw new Error(`[S] remove("${service}") action failed: id missing.`)
    }

    // Limit the remove for account related services if non-admin user
    if ('accountId' in getters.getInitData) {
      const isSuperAdmin = rootGetters['users/get']('permissions').includes('super')
      const isAdmin = isSuperAdmin || rootGetters['users/get']('permissions').includes('admin')
      if (!isAdmin) {
        // ... limit the patch to accounts they are member of
        params.query = params.query || {}
        params.query.accountId = rootGetters['users/get']('currentAccountId')
      }
    }

    let response
    try {
      this.$signal.apiActionBar = { status: 'loading' }
      response = await this.$api.service(service).remove(id, params)
      this.$signal.apiActionBar = { status: 'success' }
    } catch ({ code, message }) {
      this.$signal.apiActionBar = { status: 'error', code, message }
      Log.e(`[S] remove("${service}", ${id !== null ? id : JSON.stringify(params)}) failed:  ${code} ${message}, params:`, params)
      return {}
    }

    commit('removeItemCollection', id)
    Log.i(`[S] remove("${service}", ${id !== null ? id : JSON.stringify(params)})`, JSON.parse(JSON.stringify(response)))
    return response
  }
}
