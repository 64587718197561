<template>
  <div>
    <snippet-table
      v-model="itemId"
      selectable
      small
      show-gallery
      hide-gallery-size-toggle
      @select="embedDialog = true"
    />

    <story-embed-dialog v-model="embedDialog" media-type="snippet" :item="item">
      <c-field :label="$t('snippets.field.text_snippet')" wrap class="caption pa-1 background-2">
        {{ item.textSnippet }}
      </c-field>

      <template v-slot:description>
        <c-field cols="12" md="6" :label="$t('snippets.field.title')" wrap>
          {{ item.title }}
        </c-field>

        <list-tags v-model="item.tags" color="info" chips class="mt-2" />
      </template>
    </story-embed-dialog>
  </div>
</template>

<script>
import Log from '@/lib/log' // eslint-disable-line no-unused-vars

import SnippetTable from '@/components/snippets/table/SnippetTable'
import ListTags from '@/components/_lib/form/tags/ListTags'
import StoryEmbedDialog from '@/components/stories/form/StoryEmbedDialog'

export default {
  components: {
    SnippetTable,
    ListTags,
    StoryEmbedDialog
  },

  data () {
    return {
      embedDialog: false,
      itemId: ''
    }
  },

  computed: {
    item () {
      return this.$db.doc('snippets', this.itemId)
    }
  }
}
</script>
