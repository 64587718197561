<template>
  <!-- <v-btn
    large
    rounded
    outlined
    color="white darken-1"
    :to="item.service && item.itemId ? `/${item.service}/${item.itemId}`: '/'"
  > -->
  <!-- :to="`/`" -->
  <div>
    <div class="d-flex justify-center white--text">
      <c-date-duration :seconds="item.runTime" />
    </div>

    <template v-if="item.service">
      <v-sheet :height="1" color="grey lighten-1" />
      <div class="d-flex justify-center align-center white--text">
        <v-icon small color="white">mdi-{{ $config.SERVICE_ICON[item.service] }}</v-icon>
        <span class="caption text-lowercase ml-1 " style="line-height: 1em;">{{ item.service }}</span>
        <span v-if="item.itemId" class="caption ml-1 ">{{ item.itemId }}</span>
      </div>
    </template>
  </div>
  <!-- </v-btn> -->
</template>

<script>

// eslint-disable-next-line no-unused-vars
import Log from '@/lib/log'

export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  }
}
</script>
