<!-- *** Filter button and select popup in TableTopBar.vue

Select a filter string from a list of {text,value} pairs

Events:
  @update: filter changed, emits new filter string, also emitted when filter cleared

Example:
  <table-filter
     v-model="tableFilter"
     :select-list="[ { value: 'S_01', text: 'ongoing'}, {...} ,.. ]"
     placeholder="pick me please" />
     @update="$emit('updateFilter',tableFilter)"
  />
-->

<template>
  <div>
    <div class="d-flex align-center">
      <template v-if="showFilter">
        <v-select
          v-model="localFilter"
          dense
          hide-details
          :placeholder="placeholder || $t('def.filter')"
          :items="selectList"
          class="ml-2"
          @change="$emit('update', localFilter)"
        >
          <template v-slot:item="{item}">
            <div class="body-2 text-truncate" style="overflow:hidden;">
              {{ label(item) }}
            </div>
          </template>
        </v-select>

        <v-btn icon class="ml-n1" @click="clearFilter()">
          <v-icon small color="primary">mdi-close</v-icon>
        </v-btn>
      </template>

      <c-icon-btn
        v-else-if="selectList.length>0"
        icon="filter-outline"
        class="ml-0 ml-md-1"
        color="primary"
        small
        i18n-tooltip-text="table.tooltip.filter"
        @click="showFilter=true;"
      />
    </div>
  </div>
</template>

<script>
export default {

  props: {
    value: {
      type: String,
      default: ''
    },
    selectList: { // [ { value: 'S_01', text: 'ongoing'}, {...} ,.. ]
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    showFilter: false
  }),
  computed: {
    localFilter: {
      get () { return this.value },
      set (value) { this.$emit('input', value) }
    }
  },
  mounted () {
    if (this.value) {
      this.showFilter = !!this.selectList.find(f => f.value === this.value)
    }
  },
  methods: {
    // make item labels look nice
    label (item) {
      return (typeof item === 'string' ? item : item.text).replace(/_/g, '') // remove any "_"
    },
    clearFilter () {
      this.localFilter = ''
      this.showFilter = false
      this.$emit('update', this.localFilter)
    }
  }
}
</script>
