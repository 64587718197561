<!--  Show nicely formatted checkbox

@TODO: readonly & v-model

  <c-check-field cols="12" md="2" sm="4" :value="item.archived" :label="$t('def.archived')" hide-false />
  <c-check-field :value="item.featured" label="Featured" on-icon="star" on-color="secondary" />

 -->
<template>
  <div :class="(cols ? `col col-${cols}`: '') + (md ? ` col-md-${md}`: '') + (sm ? ` col-sm-${sm}`: '')">

    <div v-if="!hideFalse || value" class="d-flex align-center">
      <v-icon class="mr-1" :small="small" :color="disabled ? 'grey' : (value ? onColor : offColor)">
        mdi-{{ value ? onIcon: offIcon }}
      </v-icon>

      <label
        v-if="label"
        :class="[
          {'text-truncate': !wrap},
          disabled ? 'grey--text' : ($vuetify.theme.dark ? 'secondary--text' : 'info--text'),
          'caption']"
        style="word-wrap: anywhere; line-height: 1.2em;"
      >
        {{ label.toLowerCase() }}
      </label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    hideFalse: {
      type: Boolean,
      default: false
    },
    // force show in grey
    disabled: {
      type: Boolean,
      default: false
    },
    // icon
    small: {
      type: Boolean,
      default: false
    },
    wrap: {
      type: Boolean,
      default: false
    },
    onIcon: {
      type: String,
      default: 'check-underline'
    },
    onColor: {
      type: String,
      default: 'success'
    },
    offIcon: {
      type: String,
      default: 'checkbox-blank-outline'
    },
    offColor: {
      type: String,
      default: 'grey'
    },
    readonly: {
      type: Boolean,
      default: false
    },
    cols: {
      type: String,
      default: ''
    },
    md: {
      type: String,
      default: ''
    },
    sm: {
      type: String,
      default: ''
    }
  }
}
</script>
