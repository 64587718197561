//
// Save store state to localStorage so to remember user's settings
//
// See https://github.com/championswimmer/vuex-persist

import VuexPersist from 'vuex-persist'

export default ({ store }) => {
  window.onNuxtReady(() => {
    new VuexPersist({
      supportCircular: true,
      // only store these module's objects
      // NOTE: please check no console warnings when using the the vuex-tab in vue-devtools
      modules: ['_app_settings']
    }).plugin(store)
  })
}
