/** STORE INITIALIZATION MODULE **

In each store.js file:

  import { initialStateProperties } from '@/lib/store'

  // For a complete collection
    export default initialStateProperties({
      service: 'users',

      // provide all state reactive fields:
      initData: {
        email: '',
        displayName: ''
      }
      // fields that are in collection, defaults to all fields from initData, populatedData
      collectionFields: ['label','title'],
      // or
      collectionFields: [...Object.keys(initData), 'description']  // use initData by predefined const

      // a hook to run after each commit of data to the store by api-service
      // useful to populate additional pseudo fields
      afterCommitHook: (state, item) => {
        item.hello = 'Hi there people'
      }
    })
*/

import sharedGetters from './_shared/getters'
import sharedMutations from './_shared/mutations'

import findActions from './_shared/actions/find.js'
import getActions from './_shared/actions/get.js'
import createActions from './_shared/actions/create.js'
import removeActions from './_shared/actions/remove.js'
import patchActions from './_shared/actions/patch.js'

import Log from '@/lib/log' // eslint-disable-line no-unused-vars

// fields not given by collectionFields or in initData, but still will be in collection
const defaultMongoObj = {
  _id: null,
  updatedAt: null,
  createdAt: null
}
const defaultMongoFields = Object.keys(defaultMongoObj)

const initialStateProperties = function ({
  service, // name of service equal to Feathersjs service
  channelSubscription, // true|false if service should react on chan
  initData = {}, // initial default data object

  // Query to run on finding default data:  { $limit: 120, $sort: { createdAt: -1 }, archived: false, accountId: true }
  // Special cases in ~/plugins/vuex-injects/vuex/_shared/actions/find.js
  //  - { accountId: true } - will replace find.js query to { accountId: currentAccountId },
  //  - { [key]: null} - will override a defaultQuery key, for example: { archived: null }
  //
  defaultQuery = { $sort: { createdAt: -1 } },

  populatedData = {}, // automatically filled fields by backend
  collectionFields, // array with fields that should be kept in collection, default all fields from initData
  loadCollectionOnStart = false, //  false or true or { id: '1' } to also set current document; see $db.initCollection()
  currentIdUserKey, // the field name OR _id in user record to store the id of the current document user is working on

  // service specific, see for example the user service
  mutations = {},
  actions = {},
  getters = {},

  // mutation called before committing data to the store, for example for pseudo fields
  afterCommitHook = () => { }
}) {
  // ** Perform some parameter checks
  if (!service) {
    Log.e('[!] Parameter "service" undefined in initialStateProperties.')
  }
  if (Object.keys(initData).length < 1) {
    Log.e(`[!] No initData fields passed in service "${service}", but required.`)
  }

  const collectionInitFields = collectionFields || Object.keys({ ...initData, ...populatedData })

  const dataStructure = {
    __service: service, // example: 'users'
    __loadCollectionOnStart: loadCollectionOnStart, // if to load the complete collection into the database on startup
    __currentIdUserKey: currentIdUserKey, // example: 'currentAccountId', or 1 to load exact that record with that _id ; _id must be number for that to work
    __initData: initData,
    __defaultQuery: defaultQuery, // the default query from each service
    __lastQuery: { is_first_query: true }, // the last query executed (without __defaultQuery), required for find $cache
    __channelSubscription: channelSubscription,

    // actual state data
    __collection: [], // all records with document objects (exclude large fields)
    __collectionFields: [...defaultMongoFields, ...collectionInitFields], // all fields to be stored in the __collection
    ...defaultMongoObj
  }

  // ** Build and return the state object
  return {
    namespaced: true,

    getters: {
      ...getters,
      ...sharedGetters
    },

    mutations: {
      ...mutations,
      ...sharedMutations,

      //
      // User must be empty after logout or fail auth.js
      //
      clear (state) {
        // Merge rather than replace so we don't lose observers
        // So use (state,...) otherwise clear does not work at all!
        state = Object.assign(state, dataStructure, initData, populatedData)
        // Log.i(`[S] service clear/${state.__service}`)
      },

      // Mutation Hook to run before committing the response/data to the store (collection and document)
      //   Example:
      //     afterCommitHook: (state, item) => { item.myStatus = 'hello there' }
      //
      // NOTE: the newly populated fields must also be listed in populatedData: { myStatus: '' }
      //
      afterCommitHook
    },

    // Load *state related* actions
    // If no state mutation needed, use without actions by $api in code, see @/plugins/feathers/feathers-client.js
    actions: {
      ...actions,
      ...findActions,
      ...getActions,
      ...createActions,
      ...removeActions,
      ...patchActions
    },

    state: () => ({
      ...dataStructure, // the settings & meta data
      ...initData, // default fields and content in case of document
      ...populatedData // fields from the database, we can not change in the client
    })
  }
}

export {
  initialStateProperties
}
