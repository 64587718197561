/** DATABASE API-SERVICE FUNCTION CONTEXT

## PRINCIPLE OF OPERATION

- Complete MIDDLEWARE between store mutations, getters and actions.
- ONLY use this $db when you need to interact with services
- avoid using getters/mutations/actions directly
- For not by $db supported feathersjs $api calls you can call $api directly
- $db Functions are supposed to be lightweight; api calls, cache refresh and commits all handled in actions.
- Exported as global context.$db at the end of @/plugins/vuex-injects/db-api-service.js

## SYNTAX

> Api CRUD syntax tries to follow <https://docs.feathersjs.com/api/services.html#service-methods>

> Query follows: <https://docs.feathersjs.com/api/databases/querying.html>. Most commonly used:
  - ...fields: set of key/value pairs that all should match
  - $limit: maximal number of documents to return
  - $select: array with field names to return
  - $debounce: value in ms to wait before preforming the action

## NOTES
  - The `__collection` object caches the last set of documents loaded with most recent $db.find().
  - Root of `state` caches the *current* document. This enables fully reactive fields.
  - use $signal.isLoggedIn to check if the user is logged-in. Do NOT use this $db
  - use $signal.isAccountLoaded to know if there is an account actively loaded
  - @TODO: use feathersjs channel-subscriptions for automatic refresh after changes in backend
*/

import Log from '@/lib/log'
import debounceService from '@/lib/debounce/debounce-service.js'
// import sanitizeQuery from '@/plugins/vuex-injects/lib/sanitize-query'

function shuffleArray (array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]]
  }
}
export default ({ $api, app, store, $log, $signal }, inject) => {
  // Return a list with all service names
  const serviceList = () => {
    // stores that we should not include in our list
    const sl = []
    Object.keys(store.getters).forEach((storeGetter) => {
      const [service, thisGetter] = storeGetter.split('/')
      //  also skip stores names that start with '_'
      // only take teh stores we defined for db services, recognize by getCurrentDocument
      if (thisGetter === 'getCurrentDocument' && !sl.includes(service) && service[0] !== '_') {
        sl.push(service)
      }
    })
    return sl
  }
  // Throw an error if the service does not exist
  const existService = (service, apiCall = '') => {
    if (!serviceList().includes(service)) {
      throw new Error(`[$db] ${apiCall} "${service}" does not exist. Did you define it in @/store ?`)
    }
    return true
  }
  // Throw an error if data is not an object or if it contains an invalid field (initData top level only)
  const dataIsValidObject = (service, apiCall, data) => {
    // check if object
    if (typeof data !== 'object' || data === null) {
      throw new Error(`[$db] ${apiCall} "${service}" does not pass data as object: data=${data}`)
    }
    // check if not empty
    if (Object.keys(data).length === 0) {
      // object is valid, but no need to process further
      return false
      // throw new Error(`[$db] ${apiCall} "${service}" data object is empty.`)
    }
    // check if no invalid fields
    const initData = store.getters[`${service}/get`]('__initData')
    const errorField = Object.keys(data).find(field => !(field in initData) && !field.startsWith('$'))
    if (errorField) {
      throw new Error(`[$db] ${apiCall} "${service}" contains invalid field "${errorField}". Use the "$select" parameter to limit to valid keys only: ${Object.keys(initData)}.`)
    }
    return true
  }
  // Throw an error if _id undefined
  const idDefined = (service, apiCall, value) => {
    if (value && typeof value === 'object' && !value._id) {
      throw new Error(`[$db] ${apiCall} "${service}" data object is missing an _id:`, value)
    } else if (!value) {
      throw new Error(`[$db] ${apiCall} "${service}" _id is undefined.`, value)
    }
    return true
  }

  const dbApiService = {
    // SERVICES: return array with all services we use in the system: ['users', 'accounts', 'campaigns',..]
    //  Example: $db.allServices('accountId')  or $db.allServices()
    allServices (field) {
      // see if we only need to return services that contain a certain field
      if (field) {
        const allServices = []
        for (const service of serviceList()) {
          if (field in store.getters[`${service}/getCurrentDocument`]) {
            allServices.push(service)
          }
        }
        return allServices
      } else {
        return serviceList()
      }
    },

    // USER services helpers : types, permissions, rights & identity
    get isSuperAdmin () { return (store.getters['users/get']('permissions').includes('super')) },
    get isAdmin () { return (this.isSuperAdmin || store.getters['users/get']('permissions').includes('admin')) },
    // const userId = $db.currentUserId is same as:  $db.currentUser._id
    get currentUser () { return store.getters['users/getCurrentDocument'] },
    get currentUserId () { return store.getters['users/getCurrentDocument']._id },

    // const lang=$db.currentUserLanguage // 'en'
    get currentUserLanguage () { return store.getters['users/getCurrentDocument'].localization.language },

    // *** Bligman specific $db.currentAccountId
    get currentAccount () { return store.getters['accounts/getCurrentDocument'] },
    get currentAccountId () { return store.getters['users/get']('currentAccountId') },
    // Example: $db.currentAccountId = 'A_100'
    set currentAccountId (value) { store.dispatch('users/setCurrentAccount', { id: value }) },

    get isCurrentAccountMember () { return store.getters['accounts/get']('memberIds').includes(this.currentUserId) },
    get isCurrentAccountManager () { return store.getters['accounts/get']('managerIds').includes(this.currentUserId) },
    get isCurrentAccountOwner () { return store.getters['accounts/get']('ownerIds').includes(this.currentUserId) },

    // Bligson application rights, example $db.hasRights('edit','tasks')
    hasRights (right, service) {
      // Follows:
      //   get: { default: 'memberIds'},
      //   find: { default: 'memberIds' },
      //   patch: { default: 'managerIds' },
      //   remove: { default: 'ownerIds'},
      //   create: { default: 'ownerIds', tasks: 'managerIds' }, // but for service 'tasks' also managers can create
      //
      switch (right) {
        case 'remove':
          return this.isAdmin || this.isCurrentAccountOwner || service === 'accounts'
        case 'edit': // patch
          return this.isAdmin || this.isCurrentAccountManager || service === 'accounts'
        case 'create':
          return this.isAdmin || this.isCurrentAccountOwner ||
            (this.isCurrentAccountManager && ['tasks', 'stories', 'assets'].includes(service))
        default:
          break
      }
    },

    // ***

    // DOC: get current reactive document
    //   Params:
    //     $clone: if true return a cloned, non-reactive, copy of the data
    //     $noCommit = true: do not commit response to vuex store
    //
    //   Examples:
    //     const currentUser = $db.doc('users', { $clone: true })
    //     const allAvailableTags = $db.doc('accounts').tags
    //     const id = $db.doc('campaigns')._id
    //     const widget = this.$db.doc('widgets', id, { $noCommit: true })
    //
    //   Reactive input field:
    //     <v-text-field v-model="vatPercentage" type="number" label="VAT percentage" />
    //
    //     computed: {
    //       vatPercentage: {
    //         get () { return this.$db.doc('configuration').financial.vatPercentage },
    //         set (vatPercentage) {
    //           this.$db.patch('configuration', null, {
    //             financial: { ...this.$db.doc('configuration').financial, vatPercentage }
    //           }, { $debounce: 2500 })
    //         }
    //       },
    //
    doc (serviceField = '', id = '', { $clone = false, $noCommit = false } = {}) {
      const [service, field] = serviceField.split('.')
      if (!existService(service, 'doc()')) { return }

      if (field) {
        Log.e(`[$db] doc() Depreciated ${serviceField}`)
      }

      if (!id || id === null) {
        // no id so simply return current document
        const data = store.getters[`${service}/getCurrentDocument`]
        const response = field ? data[field] : data
        if ($clone) {
          return JSON.parse(JSON.stringify(response))
        } else {
          return response
        }
      } else {
        // we have id, so try to find a document in the collection, set that as current and return it

        const newCurrDoc = this.collection(service, { $limit: 1, _id: id })
        if (!$noCommit) {
          store.commit(`${service}/set`, newCurrDoc)
        }

        if ($clone) {
          return JSON.parse(JSON.stringify(newCurrDoc))
        } else {
          return newCurrDoc
        }
      }
    },

    // ## CRUD ## //

    // GET: get current reactive document from database and set as current document
    //      if no id, will try to use id from user [ currentUserKey ] if any
    //   Params:
    //     $cache = true  allow document to load from collection if present with all initData fields
    //     $clone = true: return non-reactive copy
    //     $noCommit = true: do not commit response to vuex store
    //
    //   Examples:
    //     let editPipelineId = $db.get('pipelines', 1234, { $cache, true })._id
    //     let unlinkedAccount = $db.get('accounts', { $clone: true })
    //
    async get (service, id, { $clone = false, $cache = false, ...query } = {}) {
      if (!existService(service, 'get')) { return }

      if (!id) {
        const currentUserKey = store.getters[`${service}/getCurrentIdUserKey`]
        id = store.getters['users/get'](currentUserKey)
      }
      if (!idDefined(service, 'get()', id)) { return }

      let response
      if ($cache) {
        // load from __collection, unless collection does not have same fields stored as initData
        const currDoc = store.getters[`${service}/getCollection`].find(i => i._id === id)
        if (currDoc) {
          // check if we have all initData fields in the document we found from the collection
          const documentFields = Object.keys(currDoc)
          const initFields = Object.keys(store.getters[`${service}/get`]('__initData'))
          const cacheMissField = initFields.find(i => !documentFields.includes(i))
          if (!cacheMissField) {
            Log.i(`[$db] get("${service}", "${id}") retrieved from $cache.`)
            response = currDoc
          } else {
            Log.i(`[$db] get("${service}") cache miss on field "${cacheMissField}". Load document.`)
          }
        }
      }

      if (!response) {
        response = await store.dispatch(`${service}/get`, { id, params: query })
      }
      if ($clone) {
        return JSON.parse(JSON.stringify(response))
      } else {
        return response
      }
    },

    // FIND and return a set of documents in the __collection
    //   Params
    //     $cache: true   only load new set of documents if collection is empty or accountId has changed for
    //     $clone: return not reactive duplicate
    //     $noCommit:true do not update vuex store
    //     $limit: 1..n   retrieve max nr of documents.
    //                      If $limit 1 : return first document as an *object* instead of array
    //     query:         all other parameters, see feathersjs
    //                    to limit query to only currentAccounts, you can set { accountId: true }
    //
    //                    to override / remove a defaultQuery key, you can set to null, example: { archived: null }
    //
    //   let myCollection = await this.$db.find('accounts', { $cache: true })
    //   let myCollection = await this.$db.find('campaigns', { $limit: 1, archived: false })
    //
    async find (service, { $clone, $cache, ...query } = {}) {
      if (!existService(service)) { return }
      let collection
      // const nrFinds = store.getters[`${service}/getNrFindActions`] // sequential number
      const lastQueryStr = store.getters[`${service}/getLastQuery`]
      const newQueryStr = JSON.stringify(query)

      if (!$cache || lastQueryStr !== newQueryStr) {
        collection = await store.dispatch(`${service}/find`, { query })
        store.commit(`${service}/setLastQuery`, newQueryStr)
        // Log.i(`[$db] find("${service}"): ${collection.length} docs retrieved. Cache refreshed.`)
      } else {
        collection = store.getters[`${service}/getCollection`]
        Log.i(`[$db] find("${service}"): ${collection.length} documents retrieved from $cache`)
      }

      return $clone ? [...collection] : collection
    },

    // COUNT the total number of documents in database table
    async findTotalNrDocuments (service, query = {}) {
      const nrDocs = await store.dispatch(`${service}/find`, { query: { ...query, $limit: 0, $noCommit: true } })
      if (nrDocs === undefined) {
        Log.e(`[$db] Could not get nrDocs. Is pagination enabled for "${service}"?`)
      }
      return nrDocs
    },
    // FIND MIN/MAX the minimum of maximum of a numeric field in a database table;
    //   $db.findMax('accounts', '_id')
    //
    async findMax (service, field = '_id', query = {}) {
      const mRec = await store.dispatch(`${service}/find`, {
        query: {
          ...query,
          $sort: { [field]: -1 },
          $limit: 1
        }
      })
      if (mRec.length < 1) {
        Log.w(`[$db] findMax for ${service} with field ${field} not found`)
        return
      }
      return mRec[0][field]
    },
    async findMin (service, field = '_id', query = {}) {
      const mRec = await store.dispatch(`${service}/find`, {
        query: {
          ...query,
          $sort: { [field]: 1 },
          $limit: 1
        }
      })
      if (mRec.length < 1) {
        Log.w(`[$db] findMin for ${service} with field ${field} not found`)
        return
      }
      return mRec[0][field]
    },

    // CREATE a new resource with data; returns with the newly created data & append to `__collection`.
    //   const invoiceDoc = await this.$db.create('invoices', { title: 'Please pay' }, {...params} )
    //
    async create (service, data, params) {
      if (!existService(service, 'create')) { return }
      // if (!dataIsValidObject(service, 'create', data)) { return }
      const initData = store.getters[`${service}/get`]('__initData')
      const createData = Object.assign({}, initData, data)
      return await store.dispatch(`${service}/create`, { data: createData, params })
    },

    // PATCH the data in database and the __collection and current document with the full response
    //       if _id === null, then take the _id of the current active document
    //  Params
    //     $debounce: value to wait for other patches to arrive before executing the last.
    //        await this.$db.patch('users', currentId, { email: 'tom@lor.com' }, { $debounce: 2500 } )
    //     $select: array, it only patches the the listed fields from the data:
    //        await this.$db.patch('users', null, this.user, { $select: ['displayName','email'] } )
    //     $noCommit: do not commit the new data to the vuex store's *current* record, but *will* commit __collection
    //        await this.$db.patch(this.service, this.itemId, strippedItems, { $noCommit: true})
    //
    async patch (service, id, data, { $debounce, $select, ...params } = {}) {
      if (!existService(service, 'patch')) { return }

      // Reduce the data to the selected fields only
      if ($select && $select.length > 0) {
        const selectedData = {}
        $select.forEach((key) => { selectedData[key] = data[key] })
        data = Object.assign({}, selectedData)
      }

      if (!dataIsValidObject(service, 'patch', data)) { return }

      id = id || this.doc(service)._id
      // if we do not have an id, we must have an query, e.g. when patch multiple (Note: feathers service set multi option)
      if ((!params.query || Object.keys(params.query).length === 0) && !idDefined(service, 'patch', id)) {
        return
      }

      let response
      if ($debounce) {
        const debouncePatch = debounceService(service, store.dispatch, $debounce)
        response = debouncePatch(`${service}/patch`, { id, data, params })
      } else {
        response = await store.dispatch(`${service}/patch`, { id, data, params })
      }

      return response || {}
    },

    // REMOVE document by id. To delete all records matching a params.query, set id to `null`
    //   Note: to allow for multiple deletion, in the feathers service the "multi" option has to be set:  multi: [ 'remove' ], even if only one record found
    //  await this.$db.remove('users', '123abc' )
    //  await this.$db.remove('users', null, { months: 101 } ) // => delete all documents that have months set to 101
    //
    async remove (service, id, params) {
      if (!existService(service, 'remove')) { return }
      if (!idDefined(service, 'remove', id)) { return }
      const response = await store.dispatch(`${service}/remove`, { id, params })
      return response
    },

    // ## DOCUMENT FUNCTIONS / HELPERS

    // DEFAULT INIT data for document to initialize a new record. From the store definition.
    //   Return cloned non-reactive copy
    //
    initData (service) {
      if (!existService(service, 'initData')) { return }
      const initData = store.getters[`${service}/get`]('__initData')
      return JSON.parse(JSON.stringify(initData))
    },
    // Return a cloned array with all data that can be patched
    patchableData (service) {
      if (!existService(service, 'patchableData')) { return }
      const initData = store.getters[`${service}/get`]('__initData')
      const patchData = JSON.parse(JSON.stringify(initData))
      delete patchData.accountId
      delete patchData._id
      return patchData
    },
    // returns for example { $limit:20, $sort: {  createdAt: -1 } }
    defaultQuery (service) {
      if (!existService(service, 'defaultQuery')) { return }
      return store.getters[`${service}/get`]('__defaultQuery')
    },

    // RESET Set current document to the initData set in the store definition
    // resetDocument (service) {
    //   if (!existService(service, 'resetDocument')) { return }
    //   store.commit(`${service}/set`, this.initData(service))
    // },

    // ## COLLECTION FUNCTIONS / HELPERS

    // COLLECTION FILTER and return on the parameters in the query
    //   $db.collection('info-articles', { showInToc: true })
    // Params:
    //   $sort same as in feathers/mongo: { $sort: { displayName: 1 }
    //   $clone: return non -reactive duplicate
    //   let oneTip = $db.collection('info-articles', { $limit:1,  showInToc: true }).tip
    //
    // Notes:
    //   - item parameter to query can be an array as well, example above: showInToc
    //   - query parameter can be array as well
    //   - they can not be both an array type; comparison would fail
    //   return this.$db.collection('assets', { _id: ['M_500','M_505' ] })

    collection (service, { $clone, $limit, $sort, ...query } = {}) {
      if (!existService(service, 'collection')) { return }

      let data = JSON.parse(JSON.stringify(store.getters[`${service}/getCollection`]))

      // get the valid fields
      const collectionFields = store.getters[`${service}/getCollectionFields`]

      // filter the data like featherjs query does
      Object.keys(query).forEach((qKey) => {
        // skip non-field query keys, such as "$limit" or "$select" and make sure we have a valid field
        if (!qKey.includes('$')) {
          if (collectionFields.includes(qKey)) {
            data = data.filter((item) => {
              if (Array.isArray(item[qKey])) {
                return item[qKey].includes(query[qKey])
              } else if (Array.isArray(query[qKey])) {
                return query[qKey].includes(item[qKey])
              } else if (typeof query[qKey] === 'object' && query[qKey].$exists) {
                return !!item[qKey]
              } else {
                return item[qKey] === query[qKey]
              }
            })
          } else {
            Log.w(`[$db.collection.${service}] invalid query: unknown collection field "${qKey}"`)
          }
        }
      })

      // Sort objects conform featherjs such as { $sort: { displayName: 1 } }
      if ($sort) {
        if (typeof $sort !== 'object') {
          Log.e('[$db] collection: $sort must be object')
        }

        const key = Object.keys($sort)[0]
        const direction = $sort[key] // 1 or -1
        // Log.w(`[$db] collection "${service}" we need to sort on key "${key}": ${direction}`)
        // sort alphabetically
        if (direction === 1) {
          data = data.sort((a, b) => (a[key] > b[key]) ? 1 : ((b[key] > a[key]) ? -1 : 0))
        } else {
          data = data.sort((a, b) => (a[key] < b[key]) ? 1 : ((b[key] < a[key]) ? -1 : 0))
        }
      }

      let result
      if ($limit > 1) {
        result = data.slice($limit)
      } else if ($limit === 1 && data.length > 0) {
        return $clone ? JSON.parse(JSON.stringify(data[0])) : data[0]
      } else {
        result = data
      }
      return $clone ? [...result] : result
    },

    // REDUCE collection and return as array with all values of given key.
    //   Optional  filtered by a function / $sort =1/-1 / $shuffle:true
    //   $db.reduceCollection('discount-terms', 'months')
    //   $db.reduceCollection('info-articles', 'slug', { $sort: 1, $shuffle: true }, i => i.active)
    //
    reduceCollection (service, key, query = {}, filterFunc = () => { return true }) {
      if (!existService(service)) { return }
      const data = store.getters[`${service}/getCollection`]
      if (!data) { return [] }

      // reduce the array
      const filterItems = data.filter(filterFunc)
      // make array of the requested key values
      const valueArr = filterItems.map(item => item[key])
      // rework the array based on query parameters
      // @TODO: $limit
      if (query.$shuffle) {
        shuffleArray(valueArr)
      }
      if (query.$sort === 1) {
        return valueArr.sort((a, b) => a - b) // sort alphabetically
      } else if (query.$sort === -1) {
        return valueArr.sort((a, b) => b - a) // sort alphabetically
      } else {
        return valueArr
      }
    },

    // RESET COLLECTION: Clear collection completely
    clear (service) {
      if (!existService(service, 'clear')) { return }
      store.commit(`${service}/clear`)
    },
    clearAll () {
      for (const service of serviceList()) {
        store.commit(`${service}/clear`)
      }
    },

    // INITIALIZE directly after log-in each service that has state property: loadCollectionOnStart: true,
    //
    async initCollections () {
      // Populate the services that need full set of documents in their __collection
      const initTxt = []
      for (const service of serviceList()) {
        const loadCol = store.getters[`${service}/getLoadCollectionOnStart`]
        if (loadCol) {
          const collection = await this.find(service) // Initialize the whole service
          // Log.i(`[$db init-collections] loaded "${service}"`)
          initTxt.push(service)
          // also load the current document
          if (loadCol.id && collection.length > 0) {
            Log.i(`[$db init-collections] load ${service} with _id=${loadCol.id}`)
            await store.dispatch(`${service}/get`, { id: loadCol.id })
          }
        }
      }
      Log.i(`[$db] initCollections() ${initTxt.join(',')}`)
    }
  }

  // Make the object globally available via this.$db
  inject('db', dbApiService)
}
