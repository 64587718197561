<!-- Show the right drawer in an Edit Item Dialog, with activities pane

-->

<template>
  <v-navigation-drawer
    v-model="drawer"
    absolute
    right
    touchless
    temporary
    overlay-color="grey"
    class="px-2"
    :color="titleBarColor || service"
    :width="$vuetify.breakpoint.mdAndDown ? 250 : 380"
  >
    <v-sheet class="d-flex align-center" :color="titleBarColor ||service" :height="40">
      <span :class="titleTextColor + '--text body-2'">
        {{ $t('activities.label') }}
      </span>

      <v-spacer />

      <v-btn icon :class="{'rotate-ninety-right': drawer}" @click.stop="drawer=false">
        <v-icon :color="titleTextColor">mdi-dots-vertical</v-icon>
      </v-btn>
    </v-sheet>

    <!-- the drawer content -->
    <div class="d-flex justify-end pr-4 py-2 ">
      <time-tracker-panel style="height: 46px;" :service="service" :item-id="itemId" />
    </div>

    <div class="background pa-2" style="max-height: 300px; overflow-y: auto;">
      <activity-message :item-id="itemId" :service="service" class="mb-6" />
      <activities-list :item-id="itemId" />
    </div>
  </v-navigation-drawer>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import Log from '@/lib/log'

import TimeTrackerPanel from '@/components/time-trackers/form/TimeTrackerPanel'

import ActivitiesList from '@/components/activities/form/ActivitiesList'
import ActivityMessage from '@/components/activities/form/ActivityMessage'

export default {
  components: {
    ActivitiesList,
    ActivityMessage,
    TimeTrackerPanel
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    itemId: {
      type: String,
      default: ''
    },
    service: {
      type: String,
      default: ''
    },
    titleBarColor: {
      type: String,
      default: ''
    },
    titleTextColor: {
      type: String,
      default: ''
    }
  },

  computed: {
    drawer: {
      get () { return this.value },
      set (value) { this.$emit('input', value) }
    }
  }
}
</script>
