/* Invoices and Bills */

import { initialStateProperties } from '~/plugins/vuex-injects/vuex'

export default initialStateProperties({
  service: 'invoices',

  initData: {
    // invoiceNumber: 10000, // is now equal to:  _id
    userId: 0,
    status: '', // sent, paid, overdue, void, write_off, draft
    billingAddress: {
      company: '',
      department: '',
      firstName: '',
      lastName: '',
      sex: '', // m/f  male / female / undefined
      tel: [],
      email: [],
      street: '',
      houseNumber: '',
      city: '',
      zipCode: '',
      state: '',
      country: ''
    },
    lineItems: [
      {
        description: '',
        unitCostExVat: 0.00, // EUR
        quantity: 1
      }
    ],
    totalExVat: 0.00,
    serviceFeeExVat: 0.00,
    discountCoupon: '',
    discountExVat: 0.00,
    vatPercentage: 0.00, // store in invoice as VAT might change overtime
    totalVat: 0.00, //  = (totalExVat + serviceFeeExVat + discountExVat) * vatPercentage/100
    totalIncVat: 0.00 // = totalExVat + totalVat
  }
})
