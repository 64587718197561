<!-- **  View fields in a specific pipeline

  <pipeline-view :item="item" />

-->

<template>
  <v-row class="mt-2">
    <v-col cols="12" md="6">
      <v-row>
        <v-col cols="12">
          <list-assets
            v-if="item.assetIds && item.assetIds.length>0"
            v-model="item.assetIds"
            :carousel="item.assetIds.length>1"
            :variant="item.assetIds.length>1 ? 'lg': 'md'"
            class="pt-2 pb-4 mx-md-0 mx-n4"
          />
        </v-col>

        <c-field cols="7" :label="$t('pipelines.field.name')" wrap>{{ item.name }}</c-field>

        <c-check-field
          cols="3"
          :value="item.public"
          wrap
          hide-false
          small
          :label="$t('pipelines.field.public')"
          on-color="accent"
          class="mt-2"
        />
        <v-col cols="2" class="d-flex justify-center">
          <avatar
            v-if="item.templateOwnerId && item.templateOwner && item.templateOwner._id"
            :user="item.templateOwner"
            :size="36"
            class="mt-2"
            show-business-card
          />
        </v-col>

        <c-field cols="12" :label="$t('def.tags')" class="mt-2">
          <list-tags v-model="item.tags" chips />
        </c-field>

        <c-field cols="12" :label="$t('pipelines.field.description')" wrap>{{ item.description }}</c-field>

      </v-row>
    </v-col>

    <c-field cols="12" md="6" :label="$t('tasks.labels')">
      <list-tasks :items="item.tasks" />
    </c-field>
  </v-row>
</template>

<script>
import Log from '@/lib/log' // eslint-disable-line no-unused-vars

import Avatar from '@/components/users/profile/Avatar'
import ListAssets from '@/components/assets/form/ListAssets'
import ListTags from '@/components/_lib/form/tags/ListTags'
import ListTasks from '@/components/tasks/form/ListTasks'

export default {
  components: {
    ListTags,
    ListAssets,
    ListTasks,
    Avatar
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  }
}
</script>
