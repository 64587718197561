<!-- Template for Gallery; exclusive for GeneralTable

Use in table like so:

  <template v-slot:gallery-card="{ item, gallerySmall }">
    <v-card-text class="px-2 py-1 background" style="height: 100%;">
      <c-field label="title" wrap :class="{'caption':gallerySmall}">{{ item.title }}</c-field>
    </v-card-text>
  </template>

  <template v-slot:gallery-empty>
    <span class="caption primary--text ">{{ $t('pipelines.label') }}</span>
  </template>

Events:
 @select: select item(s): return object or array depending on select-multiple prop
 @edit: click on the edit-icon and return the item object

-->

<template>
  <v-card
    tile
    flat
    elevation="2"
    :width="width"
    :max-height="height"
    :min-height="50"
    style="overflow:hidden;"
    :class="[{'pl-1': itemSelected}, cardClass ]"
    @mouseover="hover = true"
    @mouseleave="hover = false"
    @click="$emit('click',item)"
  >
    <slot />
    <!-- Select check only appears on hover of the whole card -->
    <transition v-if="selectable" name="fade">
      <div style="position: absolute; bottom: 3%; left: 3%;" :class="{'grey lighten-2 rounded-circle':hover}">
        <c-icon-btn
          icon="check-bold"
          small
          color="primary"
          :class="{ 'semi-transparent': !hover }"
          i18n-tooltip-text="assets.action.select.tooltip"
          :i18n-tooltip-vars="{ name: item.title }"
          @click="toggleSelectItem()"
        />
      </div>
    </transition>

    <!-- Edit button only appears on hover of the whole card -->
    <transition v-if="!hideEdit" name="fade">
      <div style="position: absolute; bottom: 3%; right: 3%;" :class="{'grey lighten-2 rounded-circle':hover}">
        <c-icon-btn
          icon="pencil"
          small
          :class="{ 'semi-transparent': !hover }"
          color="primary"
          i18n-tooltip-text="def.edit"
          @click="$emit('edit', item)"
        />
      </div>
    </transition>

  </v-card>
</template>

<script>
import Log from '@/lib/log' // eslint-disable-line no-unused-vars

export default {
  props: {
    // selected Id(s)
    value: {
      type: [String, Array],
      default: () => []
    },
    selectable: {
      type: Boolean,
      default: false
    },
    selectMultiple: {
      type: Boolean,
      default: false
    },

    // pass item to be able to select it (applicable when 'selectable')
    item: {
      type: Object,
      default: () => {}
    },

    // show very small thumbs instead of medium
    small: {
      type: Boolean,
      default: false
    },
    // do not show edit button
    hideEdit: {
      type: Boolean,
      default: false
    },

    // appearance
    thumbClass: {
      type: String,
      default: 'background darken-1 foreground--text text--lighten-2'
    },
    thumbMdClass: {
      type: String,
      default: 'mr-1 mr-md-2 mb-2'
    },
    thumbSmClass: {
      type: String,
      default: 'ml-1 mb-1'
    },

    selectedClass: {
      type: String,
      default: 'secondary background-2--text'
    }
  },
  data () {
    return {
      hover: false
    }
  },
  computed: {
    itemSelected () {
      return (this.selectMultiple && Array.isArray(this.value))
        ? this.value.includes(this.item._id)
        : this.item._id === this.value
    },
    cardClass () {
      const colorClass = this.itemSelected ? this.selectedClass : this.thumbClass
      return (this.small ? this.thumbSmClass : this.thumbMdClass) + ' ' + colorClass
    },
    width () {
      // 'small' set by the user via a toggle button
      return this.small ? this.$config.ASSETS.THUMB_SIZE.sm : this.$config.ASSETS.THUMB_SIZE.md
      // return this.small ? 78 : 106
    },
    height () {
      return this.width * 1.15
    },
    iconAdjustWidth () {
      return this.small ? 0 : -40
    }

  },
  methods: {
    toggleSelectItem () {
      let selection
      if (this.selectMultiple && Array.isArray(this.value)) {
        selection = this.value.includes(this.item._id)
          ? this.value.filter(i => i !== this.item._id)
          : [...this.value, this.item._id]
        // Log.i('select array', this.item.title, selection)
      } else {
        selection = this.value === this.item._id ? '' : this.item._id
        // Log.i('select value', this.item.title, selection)
      }
      this.$emit('input', selection)
      this.$emit('select', selection)
    }
  }
}
</script>
