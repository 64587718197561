<template>
  <v-row>
    <!-- the flashing clock on the left -->
    <v-col cols="12" md="2">
      <div class="d-flex justify-center">
        <v-icon x-large color="primary">
          mdi-{{ $config.SERVICE_ICON['time-trackers'] }}
        </v-icon>
      </div>

      <div class="primary--text caption d-flex justify-center">
        {{ $t('time-trackers.paused') }}
      </div>

      <div class="primary--text body d-flex justify-center font-weight-bold">
        <c-date-duration :seconds="$signal.timeTrackerPauseAt" />
      </div>
      <div v-if="timer.service" class="info--text caption font-weight-medium mt-2 d-flex justify-center">
        {{ timer.service }}
      </div>
      <div v-if="timer.itemId" class="info--text caption d-flex justify-center">
        {{ timer.itemId }}
      </div>
    </v-col>

    <!-- the timer info  -->
    <v-col>
      <v-form v-model="valid">
        <v-row no-gutters>
          <c-field-date cols="6" md="3" :label="$t('time-trackers.field.start_at')" :date="timer.startAt" />
          <c-field-date cols="6" md="3" :label="$t('time-trackers.field.end_at')" :date="timer.endAt" />

          <c-field cols="4" md="3" :label="$t('time-trackers.field.passed_time')">
            <c-date-duration :start-date="timer.startAt" :end-date="timer.endAt" />
          </c-field>

          <c-field cols="4" md="3" :label="$t('time-trackers.field.run_time')">
            <c-date-duration :seconds="timer.runTime" />
          </c-field>
        </v-row>

        <v-divider class="my-4" />

        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="label"
              outlined
              dense
              autofocus
              hide-details="auto"
              :label="$t('def.label')"
              :rules="[ $validRule.required, $validRule.minLength(5).val, $validRule.maxLength(80).val ]"
            />
            <select-tags v-model="tags" dense outlined :label="$t('def.tags')" class="mt-2" />
          </v-col>
          <v-col>
            <v-textarea
              v-model="note"
              :rows="4"
              dense
              counter
              outlined
              :row-height="30"
              :label="$t('def.note')"
              :rules="[ $validRule.maxLength(300).val ]"
            />
          </v-col>

        </v-row>

      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import SelectTags from '@/components/_lib/form/tags/SelectTags'

export default {
  components: {
    SelectTags
  },
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          startAt: '', // { type: Date },
          endAt: '', // { type: Date },
          runTime: 0, // { type: Number }, // in seconds:  endAt - startAt - sun of all the paused periods in between

          label: '', // { type: String, maxlength: 80, required: true },
          service: '', // { type: String, maxlength: 80 },
          note: '', // { type: String, maxlength: 300 },
          tags: [] // [{ type: String, maxlength: 50, index: true }], // 'marketing', 'sales'
        }
      }
    }
  },
  data () {
    return {
      valid: false
    }
  },
  computed: {
    timer: {
      get () { return this.value },
      set (value) { this.$emit('input', value) }
    },
    label: {
      get () { return this.timer.label },
      set (label) { this.$emit('input', Object.assign({}, this.timer, { label })) }
    },
    note: {
      get () { return this.timer.note },
      set (note) { this.$emit('input', Object.assign({}, this.timer, { note })) }
    },
    tags: {
      get () { return this.timer.tags },
      set (tags) { this.$emit('input', Object.assign({}, this.timer, { tags })) }
    }
  },
  watch: {
    valid () {
      this.$emit('valid', this.valid)
    }
  },
  mounted () {
    ;
  }
}
</script>
