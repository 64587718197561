<template>
  <v-app>
    <v-img
      style=" background-color:#005a6f;"
      :src="require('@/assets/hero/blog-is-about-people.svg')"
    >
      <!-- content-class="contain-v-img" -->
      <api-status-bar position="position: absolute; top: 0;" />

      <div class="d-flex justify-center mt-4 mt-sm-12 px-6">
        <div style="max-width: 400px;" class="mt-6">
          <a href="https://www.bligson.com" class="d-block">
            <v-img
              class="mx-auto"
              alt="Bligson Logo"
              contain
              content-class="contain-v-img"
              :max-width="200"
              :src="require('@/assets/logo/bligson-white-txt.svg')"
            />
          </a>

          <h1 class="white--text text-center responsive mt-4 mt-sm-12 mb-3 mb-sm-8">{{ $t('home.title_IS') }}</h1>

          <h3 class="d-none d-md-block white--text text-center mb-4">{{ $t('home.subtitle') }}</h3>
          <v-sheet :height="1" color="secondary" class="mb-4" />

        </div>
      </div>

      <div class="d-flex justify-center px-6 pb-2">
        <v-main style="max-width: 400px;">
          <nuxt />
        </v-main>
      </div>

      <div class="d-flex justify-center px-6 pb-2">
        <span class="caption background-2--text  mt-6 ">v{{ $config.VERSION }}</span>
      </div>
    </v-img>
  </v-app>
</template>

<script>

import ApiStatusBar from '@/components/_default/topbar/ApiStatusBar.vue'

export default {
  name: 'LayoutGuest',
  components: {
    ApiStatusBar
  }
  // head () {
  //   return {
  //     title: 'Bligman - Login',
  //     meta: [
  //       // hid is used as unique identifier, same as in head: {...} in nuxt.config.js
  //       // { hid: 'description', name: 'description', content: this.pageDescription },
  //       // { hid: 'og:url', property: 'og:url', content: this.pageUrl },
  //       // { hid: 'og:siteTitle', property: 'og:title', content: this.pageTitle },
  //       // { hid: 'og:desc', property: 'og:description', content: this.pageDescription },
  //       // { hid: 'og:img', property: 'og:image', content: this.pageImage }
  //     ]
  //   }
  // }
}
</script>
