<!-- *** Select a filter type

Events;
  @update emits the query when a new set of tags is selected

Example:
  <table-tag-select v-model="tags" placeholder="filter your tags" @update="updateQuery" />

Please Note:
 In the backend, you need to whitelist $all in the service creation options.<template>

 Example: see ~/src/services/stories/stories.service.js

    module.exports = function (app) {
      const options = {
        Model: createModel(app),
        multi: [ 'create', 'patch', 'remove' ],
        whitelist: ['$all']
      }
    ...
    }

-->

<template>
  <div>
    <div class="d-flex align-center">
      <template v-if="showQuery">
        <select-tags
          v-model="tags"
          hide-edit-account-tags
          text
          class="caption info--text"
          outlined
          dense
          :placeholder="placeholder"
          @changed="updateTags"
        />

        <v-btn icon class="ml-n2" @click="clearTags()">
          <v-icon small color="primary">mdi-close</v-icon>
        </v-btn>
      </template>

      <div v-else class="d-flex align-center rounded-xl two-lines" :class="{'background-2': tags.length>0}">
        <div class="mt-1" @click="showQuery=true;">
          <list-tags
            :tags="tags"
            item-class="ml-0 ml-md-1 pl-1 cursor-pointer info--text caption two-lines"
            style="max-width: 90px;"
          />
        </div>

        <c-icon-btn
          icon="tag-outline"
          class="ml-1 ml-md-2 my-n2"
          color="primary"
          small
          i18n-tooltip-text="table.tooltip.tag_query"
          @click="showQuery=true;"
        />
      </div>
    </div>
  </div>
</template>

<script>

import SelectTags from '@/components/_lib/form/tags/SelectTags'
import ListTags from '@/components/_lib/form/tags/ListTags'

export default {

  components: {
    SelectTags,
    ListTags
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: 'select tags'
    }
  },
  data: () => ({
    showQuery: false
  }),
  computed: {
    tags: {
      get () { return this.value },
      set (value) { this.$emit('input', value) }
    }
  },

  methods: {
    // make item labels look nice
    label (item) {
      return (typeof item === 'string' ? item : item.text).replace(/_/g, '') // remove any "_"
    },
    clearTags () {
      this.tags = []
      this.updateTags()
    },
    updateTags () {
      this.showQuery = false
      this.$emit('update', this.tags)
    }
  }
}
</script>
