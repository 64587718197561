/* Currencies and rates */

import { initialStateProperties } from '~/plugins/vuex-injects/vuex'

export default initialStateProperties({
  service: 'time-trackers',

  loadCollectionOnStart: false,

  defaultQuery: { accountId: true, $sort: { updatedAt: 1 } },

  initData: {
    // required
    accountId: '', //  { type: String, ref: 'accounts', required: true, index: 1 },
    userId: '', // { type: String, ref: 'users' },

    // optional
    itemId: '', // { type: String, ref: activityServices.join(',') },

    startAt: '', // { type: Date },
    endAt: '', // { type: Date },
    runTime: 0, // { type: Number }, // in seconds:  endAt - startAt - sun of all the paused periods in between

    label: '', // { type: String, maxlength: 80, required: true },
    service: '', // { type: String,  },
    note: '', // { type: String, maxlength: 300 },
    tags: [] // [{ type: String, maxlength: 50, index: true }], // 'marketing', 'sales'

  },

  populatedData: {
    user: {} // { '_id', 'displayName', 'email', 'avatarImageSrc', 'avatarPrefix', 'permissions' }
  }
})
