<!-- ** Simply show a text field around a date field:

 <c-field-date cols="6" :label="$t('tasks.field.due_at')" :date="item.dueAt" not-set />

-->

<template>
  <c-field v-bind="$attrs">
    <c-date
      :date="date"
      :not-set="notSet"
      :date-format="dateFormat"
      :date-only="dateOnly"
      :time-only="timeOnly"
      :long="long"
    />
  </c-field>
</template>

<script>
export default {
  props: {
    // c-date only; other props for <c-field passed by v-bind="$attrs"
    date: {
      type: String, // ISOString format: "2011-10-05T14:48:00.000Z"
      default: ''
    },
    dateFormat: {
      type: String,
      default: ''
    },
    notSet: {
      type: Boolean,
      default: false
    },

    // expect long format
    dateOnly: {
      type: Boolean,
      default: false
    },
    timeOnly: {
      type: Boolean,
      default: false
    },
    long: {
      type: Boolean,
      default: false
    }
  }
}
</script>
