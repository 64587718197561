<template>
  <indicator-icon
    :icon="$config.SERVICE_ICON.activities"
    panel="activities"
    :unread="nrUnseenMessages"
  />
  <!-- @select="loadActivities" -->
  <!-- :total="nrActivities" -->
</template>

<script>
// eslint-disable-next-line no-unused-vars
import Log from '@/lib/log'

import IndicatorIcon from '@/components/_default/topbar/IndicatorIcon.vue'

export default {
  components: {
    IndicatorIcon
  },
  props: {
    // match any item that contains the fromFilter at any position
    fromFilter: {
      type: String,
      default: '' // suggest: '#'(system notifications) or '@'(personal messages)
    },
    icon: {
      type: String,
      default: 'bell'
    },
    panel: {
      type: String,
      default: '' // 'notifications' || 'messages' || ..
    }
  },

  computed: {
    userId () {
      return this.$db.doc('users')._id
    },
    nrUnseenMessages () {
      return this.$db.collection('activities').filter(i => i.message && !i.seenByUserIds.includes(this.userId)).length
    }
  }

}
</script>
