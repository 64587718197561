<template>
  <div>
    <general-table
      v-model="selectedIds"
      service="assets"
      :headers="headers"
      :small="small"
      :items-per-page="15"
      :filter-item-id="filterItemId"
      hide-new
      show-tag-select
      show-expand

      :show-gallery="showGallery"
      :show-gallery-toggle="showGalleryToggle"
      :hide-gallery-size-toggle="hideGallerySizeToggle"
      :selectable="selectable"
      :select-multiple="selectMultiple"

      @select="$emit('select', $event)"
    >
      <template v-slot:left-toolbar />

      <template v-slot:right-toolbar>
        <c-icon-btn
          icon="plus"
          color="primary"
          i18n-tooltip-text="assets.action.upload.tooltip"
          @click="uploadFileDialog=true"
        />
      </template>

      <template v-slot:item-table-row="{ item }">
        <gen-td :headers="headers" :item="item" value="thumb" class="d-flex justify-start align-center">
          <list-assets
            v-model="item._id"
            :width="40"
            :height="35"
            :max-width="40"
            :max-height="35"
          />
          <!-- style="overflow: hidden;" -->
        </gen-td>

        <gen-td :headers="headers" :item="item" value="title" />

        <gen-td :headers="headers" :item="item" value="description" :small="small" />

        <gen-td :headers="headers" :item="item" value="totalStorage" :small="small">
          <c-bytes-to-size v-if="item.totalStorage > 500" :bytes="item.totalStorage" decimal-basis class="info--text caption" />
        </gen-td>
      </template>

      <template v-slot:item-expand-pane="{ item }"> <!-- headers, -->
        <asset-view :item="item" />
      </template>

      <template v-slot:item-edit-form="{ item }">
        <asset-edit :item="item" />
      </template>

      <template v-slot:gallery-card="{ item }">
        <list-assets v-model="item._id" />
        <c-field class="caption pl-1 text-lowercase ">{{ item.title }}</c-field>
      </template>

      <template v-slot:gallery-empty>
        <c-icon-btn
          icon="plus"
          color="primary"
          i18n-tooltip-text="table.tooltip.add_new"
          @click="uploadFileDialog=true"
        />
      </template>
    </general-table>

    <!-- upload files popup -->
    <file-upload-dialog
      v-model="uploadFileDialog"
      :accept-mime="$config.ASSETS.MIME"
      :max-nr-files="10"
      :max-file-size="$config.ASSETS.MAX_FILE_SIZE"
      :drop-zone-height="200"
      :upload-data="{ accountId: $db.doc('users').currentAccountId }"
      hide-after-upload
      @onFinished="uploadFileDialog=false"
    />
  </div>
</template>

<script>
import Log from '@/lib/log' // eslint-disable-line no-unused-vars

import GeneralTable from '@/components/_lib/form/table/GeneralTable'
import GenTd from '@/components/_lib/form/table/GenTd'

import AssetView from '@/components/assets/table/AssetView'
import AssetEdit from '@/components/assets/table/AssetEdit'
import ListAssets from '@/components/assets/form/ListAssets'

import FileUploadDialog from '@/components/_lib/form/file/FileUploadDialog'

export default {
  components: {
    GeneralTable,
    GenTd,
    AssetView,
    AssetEdit,
    ListAssets,
    FileUploadDialog
  },
  props: {
    // selected Id(s)
    value: {
      type: [String, Array],
      default: () => []
    },
    selectable: {
      type: Boolean,
      default: false
    },
    selectMultiple: {
      type: Boolean,
      default: false
    },
    // do not allow user to select table view
    showGallery: {
      type: Boolean,
      default: false
    },
    showGalleryToggle: {
      type: Boolean,
      default: false
    },
    showNewOnEmpty: {
      type: Boolean,
      default: false
    },
    // use prop 'small' to mount size at first in small
    hideGallerySizeToggle: {
      type: Boolean,
      default: false
    },
    // open the gallery on first start with small cards
    small: {
      type: Boolean,
      default: false
    },
    query: {
      type: Object,
      default: () => {}
    },
    filterItemId: {
      type: String,
      default: ''
    }

  },
  data: () => ({
    provider: 'all',
    headers: [
      { value: 'thumb', text: '', width: '85', format: 'custom' },
      { value: 'title', text: 'title', format: 'textarea' },
      // { value: 'alt', text: 'alt', responsive: true },
      { value: 'description', text: 'description', format: 'textarea', responsive: true },
      { value: 'totalStorage', text: 'size', width: '80', format: 'custom', responsive: true }
    ],
    uploadFileDialog: false
  }),
  computed: {
    selectedIds: {
      get () { return this.value },
      set (value) { this.$emit('input', value) }
    }
  }
}
</script>
