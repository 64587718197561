<!-- Show an Image and the optional zoomed dialog popup -->

<template>
  <div class="d-flex align-center justify-center">
    <div :class="{'cursor-zoom-in': !hideDialog}" style="display: grid;" @click.stop="openDialog()">
      <v-img
        contain
        :src="src"
        :alt="alt"
        v-bind="$attrs"
      />
    </div>

    <v-dialog v-if="dialog" v-model="dialog" fullscreen scrollable>

      <div style="overflow: hidden; height: 100% !important;">
        <v-system-bar window fixed lights-out style="cursor: pointer;" @click="dialog=false;">
          <v-btn icon><v-icon>mdi-arrow-left</v-icon></v-btn>
          <v-spacer />
          <span class="caption">{{ title }}</span>
          <v-spacer />
          <v-btn icon class="mr-n2"><v-icon>mdi-close</v-icon></v-btn>
        </v-system-bar>

        <div style="height: 100% !important;" class="d-flex align-center justify-center grey darken-4">
          <v-panzoom v-if="fullSrc">
            <v-img
              id="current-image"
              :lazy-src="lazySrc"
              :src="fullSrc"
              :alt="alt"
            />
          </v-panzoom>
        </div>

        <div class="semi-transparent" style="position: absolute; right: 20px; bottom: 20px; ">
          <v-btn icon color="white" large @click="openNewWindow">
            <v-icon large>mdi-open-in-new</v-icon>
          </v-btn>
        <!-- <v-btn icon color="white" @click="downloadFile">
            <v-icon>mdi-download</v-icon>
          </v-btn> -->
        </div>
      </div>
    </v-dialog>

  </div>
</template>

<script>
import Log from '@/lib/log' // eslint-disable-line no-unused-vars

export default {
  props: {
    src: {
      type: String,
      default: ''
    },
    fullSrc: {
      type: String,
      default: ''
    },
    lazySrc: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    alt: {
      type: String,
      default: ''
    },
    hideDialog: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dialog: false
    }
  },
  watch: {
    dialog () {
      if (this.dialog) {
        document.documentElement.classList.add('hide-scrollbar')
      } else {
        document.documentElement.classList.remove('hide-scrollbar')
      }
    }
  },
  methods: {
    openDialog () {
      if (!this.hideDialog) {
        Log.i(`[image-view] fullSrc = ${this.fullSrc} lazySrc=${this.lazySrc}`)
        this.dialog = true
      }
    },
    openNewWindow () {
      window.open(this.fullSrc, '_blank')
    }
  }
}
</script>
