<template>
  <div>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="item.title"
          outlined
          hide-details="auto"
          :label="$t('assets.field.title')"
          :rules="[$validRule.required, $validRule.maxLength(100).val ]"
          @input="item.title = item.title.trim()"
        />

      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="item.orgSrc"
          hide-details="auto"
          outlined
          :label="$t('assets.field.src')"
          :rules="[$validRule.url, $validRule.required, $validRule.maxLength(100).val ]"
          @change="updateType()"
        />

      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6">
        <select-tags
          v-model="item.tags"
          :label="$t('def.tags')"
          dense
          outlined
        />
      </v-col>

      <v-col cols="12" md="6">
        <select-embed-type v-model="item.type" hide-all radio label="select type" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6">
        <v-textarea
          v-model="item.description"
          outlined
          :label="$t('assets.field.description')"
          auto-grow
          :rows="4"
          counter
          :rules="[$validRule.maxLength(1000).val ]"
        />
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field v-model="item.parameters" outlined :label="$t('assets.field.parameters')" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Log from '@/lib/log' // eslint-disable-line no-unused-vars

import SelectTags from '@/components/_lib/form/tags/SelectTags'
import SelectEmbedType from '@/components/embeds/form/SelectEmbedType'

export default {
  name: 'EmbedEdit',
  components: {
    SelectTags,
    SelectEmbedType
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  data (vm) {
    return {
      editSnippetDialog: false
    }
  },
  methods: {

    updateType () {
      if (!this.item.type) {
        if (this.item.orgSrc.includes('youtube')) {
          this.item.type = 'youtube'
        }
      // Log.i(`[asset-edit] new provider "${provider}"; item.type set to "${this.item.type}"`)
      }
    }
  }
}
</script>
