<!--  Select Archived

Show button group that selects a $db.find query
  <select-archived-query v-model="archivedQuery" class="ml-1" />
-->

<template>
  <c-btn-select-object
    v-model="archivedQuery"
    :items="selectOptions"
    i18n-prefix="pipelines.select"
    style="min-width: 85px;"
  />
<!-- 85px = 3 buttons -->
</template>

<script>

export default {
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },
  data: (vm) => {
    return {
      selectOptions: [
        { id: 'archived.all', value: { archived: null }, icon: vm.$config.SELECT_ICON.all },
        { id: 'archived.hide', value: { archived: false }, icon: vm.$config.SELECT_ICON.archived.false },
        { id: 'archived.act', value: { archived: true }, icon: vm.$config.SELECT_ICON.archived.true }
      ]
    }
  },
  computed: {
    archivedQuery: {
      get () { return this.value },
      set (value) { this.$emit('input', value) }
    }
  }
}
</script>
