<template>
  <c-field :label="label.toLowerCase()" :style="`max-width: ${maxWidth}px;`">

    <list-stories
      :item-ids="storyIdList"
      :hide-delete="hideEdit"
      @deleteIndex=" deleteItem "
    />

    <!-- Allow select if there are more -->
    <v-autocomplete
      v-if="!hideEdit && storyNotSelectedList.length > 0"
      v-model="storyIdToAdd"
      item-value="_id"
      item-text="title"
      hide-details="auto"
      outlined
      dense
      class="mt-2"
      :items="storyNotSelectedList"
      @change="addItem(storyIdToAdd)"
    />
  </c-field>
</template>

<script>

import ListStories from '@/components/stories/form/ListStories'

export default {
  components: {
    ListStories
  },

  props: {
    value: {
      type: Array,
      default: () => ([])
    },
    label: {
      type: String,
      default: ''
    },
    maxWidth: {
      type: Number,
      default: null
    },
    maxHeight: {
      type: Number,
      default: 220
    },
    showProgress: {
      type: Boolean,
      default: false
    },
    hideEdit: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      storyIdToAdd: ''
    }
  },
  computed: {
    storyIdList: {
      get () { return this.value || [] },
      set (value) { this.$emit('input', value) }
    },
    storyList () {
      return this.$db.collection('stories')
        .filter(story => this.storyIdList.includes(story._id))
    },
    storyNotSelectedList () {
      return this.$db.collection('stories')
        .filter(story => !this.storyIdList.includes(story._id))
        .sort((a, b) => {
          const fa = a.title.toLowerCase()
          const fb = b.title.toLowerCase()
          if (fa < fb) { return -1 }
          if (fa > fb) { return 1 }
          return 0
        })
    },
    nrSelected () {
      return this.storyIdList.reduce((acc, item) => {
        if (item.select) { acc = acc + 1 }
        return acc
      }, 0)
    }
  },
  methods: {
    addItem (id) {
      this.storyIdList = [...this.storyIdList, id]
    },
    deleteItem (index) {
      const listToSave = [...this.storyIdList]
      listToSave.splice(index, 1)
      this.$emit('input', listToSave)
    }

  }

}
</script>
