<!-- Display a value formatted as currency

   <cur :value="12.50">  // €12,50
   <cur :value="price"><v-icon>mdi-check</v-icon></cur> // slot is appended

-->
<template>
  <div>{{ formattedValue }}<slot /></div>
</template>

<script>
// import Log from '@/lib/log'

export default {
  props: {
    value: {
      type: Number,
      default: 0
    },
    currency: {
      type: String,
      default: ''
    },
    digits: {
      type: Number,
      default: 2
    }
  },
  computed: {
    valuta () {
      return this.currency || this.$db.doc('users').localization.currency
    },
    currencyTable () {
      return this.$db.collection('currency', { $limit: 1, currency: this.valuta })
    },
    rate () {
      return this.currencyTable.rate
    },
    formattedValue () {
      // convert value according to valuta
      const localValue = this.value * this.rate
      const valueString = localValue.toLocaleString(this.valuta, {
        style: 'currency',
        currency: this.valuta.toUpperCase(),
        minimumFractionDigits: this.digits,
        maximumFractionDigits: this.digits
      }).trim()

      return valueString.replace('US$', '$')
    }
  }
}
</script>
