<template>
  <v-row>
    <v-col cols="12" md="6">
      <v-text-field
        v-model="item.title"
        outlined
        hide-details="auto"
        :label="$t('assets.field.title')"
        :rules="[$validRule.required, $validRule.maxLength(100).val ]"
        @input="item.title = item.title.trim()"
      />
      <select-tags
        v-model="item.tags"
        :label="$t('def.tags')"
        dense
        outlined
        class="mt-2"
      />
      <v-textarea
        v-model="item.description"
        class="mt-3"
        outlined
        :label="$t('assets.field.description')"
        auto-grow
        :rows="4"
        counter
        :rules="[$validRule.maxLength(1000).val ]"
      />
    </v-col>

    <v-col cols="12" md="6">
      <list-assets v-model="item._id" variant="md" max-width="400" max-height="400" contain />
    </v-col>

    <v-col cols="12" md="12">
      <asset-file-details :item="item" />
    </v-col>
  </v-row>
</template>

<script>
import Log from '@/lib/log' // eslint-disable-line no-unused-vars

import ListAssets from '@/components/assets/form/ListAssets'
import AssetFileDetails from '@/components/assets/form/AssetFileDetails'

import SelectTags from '@/components/_lib/form/tags/SelectTags'

export default {
  name: 'AssetEdit',
  components: {
    ListAssets,
    AssetFileDetails,
    SelectTags
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  data (vm) {
    return {
      editSnippetDialog: false
    }
  },
  methods: {

  }
}
</script>
